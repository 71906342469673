import React, {useContext, useState} from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import {LineItemTypeForm} from "~/recon/components/line-item-type";
import {FlowState, getFlow, ReconStagingContext, ReconStagingOps} from "./recon-staging";
import {StripePayoutsBrowser} from "~/recon/components/stripe-payouts-browser";
import {StripePayout} from "~/stripe-payouts/stripe-payout";
import {TransfersBrowser} from "~/recon/components/transfers-browser";
import _, {last} from "lodash";
import {MoneyFormat} from "~/utils/money";
import {BigNumber} from "bignumber.js";
import {labelForLineItemType, RenderIf} from "../util";
import {Stack, StepButton, Typography} from "@mui/material";
import {getLineItemDescription, LineItemType} from "../line-item";
import PayoutTransactionsPreview from "./payout-transactions-preview";
import {Currency} from "~/currency";
import ReconReview, {Error} from "~/recon/components/recon-review";
import {bigNumberRange, plainDateRange} from "~/utils/range";
import {instantToDateTime} from "~/utils/temporal";
import {StripePayoutTransactionWithTransfer} from "~/stripe-payout-transaction/stripe-payout-transaction";
import {TransferSummary} from "~/transfer/transfer-summary";
import UnattributedWireDialog from "./unattributed-wire-dialog";

type Props = { staging: ReconStagingOps };

const SelectLineItemType = ({staging}: Props) => (
  <LineItemTypeForm value={staging.lineItemType || null}/>
);

const SelectStripePayout = ({staging}: Props) => {
  const onReconcilePayout = (payout: StripePayout) => staging.setStripePayout(payout);
  const lineItem = staging.lineItem;
  const offset = lineItem.amount.multipliedBy(0.1).integerValue();
  return (
    <StripePayoutsBrowser
        reconcilePayout={onReconcilePayout}
        backendId={staging.lineItem.backendId}
        defaultFilter={{
        amount: bigNumberRange(lineItem.amount.minus(offset), lineItem.amount.plus(offset)),
        date: plainDateRange(null, instantToDateTime(lineItem.date).toPlainDate())
       }}
    />
  );
};

const LinkStripePayoutTransactions = ({staging}: Props) => {
  const onAccept = (txs: StripePayoutTransactionWithTransfer[]) => staging.approvePayoutTransactions(txs);
  const onCancel = () => staging.reset();
  return (
    staging.stripePayout ?
      <PayoutTransactionsPreview onAccept={onAccept} onCancel={onCancel} payoutId={staging.stripePayout.id}/> :
      <Error error={{message: "Please select a payout transaction"}}/>
  );
};

const digInvestmentId = (description: String): string | undefined => last(description.match(/I\s*N\s*V(\d*)/mu));

const SelectTransfer = ({staging}: Props) => {
  const [wireDialogOpen, setWireDialogOpen] = useState<boolean>(false);
  const onWireDialogClose = (transfer: TransferSummary | null) => {
    if (transfer !== null) {
      staging.addTransfer(transfer, FlowState.Review);
    }
    setWireDialogOpen(false);
  };

  const onSubmit = (_: any) => staging.jumpTo(FlowState.Review);
  const onCancel = (_: any) => staging.reset();
  const amountsByCurrency = _.mapValues(_.groupBy(staging.transfers, t => t.currency), ts => _.reduce(ts.map(t => t.amount), (a, b) => a.plus(b), new BigNumber(0)));
  const existingTransfers = staging.existingRecons.map(r => r.transfer!.id);
  const isDeposit = staging.lineItemType === LineItemType.Deposit;
  const isTransaction = staging.lineItemType === LineItemType.VitesseTransaction;
  const isWire = isDeposit || isTransaction;

  const parsedInvestmentId = digInvestmentId(getLineItemDescription(staging.lineItem));

  const defaultFilter = isWire ?
    {reconcilable: true, amount: bigNumberRange(staging.lineItem.amount, staging.lineItem.amount), investmentId: parsedInvestmentId} :
    {reconcilable: true};

  return (
    <Box>
      <UnattributedWireDialog open={wireDialogOpen} onClose={onWireDialogClose} investmentId={parsedInvestmentId}/>
      <TransfersBrowser disabledTransfers={existingTransfers} selectTransfer={staging.addTransfer} unselectTransfer={staging.removeTransfer} defaultSort={{key: "id", dir: "desc"}} defaultFilter={defaultFilter}/>
      <Box>
        <span>{staging.transfers.length} transfers selected. Total:</span>
        <RenderIf cond={_.isEmpty(amountsByCurrency)}><span style={{marginLeft: "0.5rem"}}>N/A</span></RenderIf>
        <RenderIf cond={!_.isEmpty(amountsByCurrency)}>
          {Object.entries(amountsByCurrency).map(([currency, amount]) =>
            <span style={{marginLeft: "0.5rem"}} key={currency}>{MoneyFormat.formatBigNumber(amount, currency as "usd" | "eur")}</span>
          )}
        </RenderIf>
      </Box>
      <Stack direction="row" sx={{mb: 2, mt: 2}}>
        <Button variant="contained" disabled={staging.transfers.length === 0} onClick={onSubmit} size="small" sx={{mt: 1, mr: 1}}>Looks Good!</Button>
        {/* <Button variant="contained" onClick={onCancel} size="small" sx={{mt: 1, mr: 1}}>Cancel</Button> */}
        <RenderIf cond={isWire}>
          <Button variant="contained" onClick={_ => setWireDialogOpen(true)} color={"warning"} size="small" sx={{mt: 1, mr: 1}}>Create Wire Transfer</Button>
        </RenderIf>
      </Stack>
    </Box>
  );
};


const flowStateComponent: { [key in FlowState]: React.FunctionComponent<Props> } = {
  [FlowState.SelectLineItemType]: SelectLineItemType,
  [FlowState.SelectStripePayout]: SelectStripePayout,
  [FlowState.LinkStripePayoutTransactions]: LinkStripePayoutTransactions,
  [FlowState.SelectTransfer]: SelectTransfer,
  [FlowState.Review]: ReconReview
};

const flowStateLabel: { [key in FlowState]: String } = {
  [FlowState.SelectLineItemType]: "Select Line Item Type",
  [FlowState.SelectStripePayout]: "Select Stripe Payout",
  [FlowState.LinkStripePayoutTransactions]: "Link Stripe Payout Transactions",
  [FlowState.SelectTransfer]: "Select Transfer",
  [FlowState.Review]: "Review and Submit"
};
const prettyAmount = ({currency, amount}: {currency: Currency | null, amount: BigNumber}) => MoneyFormat.formatBigNumber(amount, currency);
const selectionText = (step: FlowState, staging: ReconStagingOps) => {
  switch (step) {
    case FlowState.SelectLineItemType:
      return labelForLineItemType(staging.lineItemType!);
    case FlowState.SelectStripePayout:
      return !staging.stripePayout ? null : `(${staging.stripePayout.id}) ${prettyAmount(staging.stripePayout)}`;
    case FlowState.SelectTransfer:
      return staging.transfers.map(tn => `(${tn.id}) ${prettyAmount(tn)}`).join(" | ");
    case FlowState.LinkStripePayoutTransactions:
    default:
      return null;
  }
};

const StepSelection = ({step, staging}: {step: FlowState, staging: ReconStagingOps}) => {
  const text = selectionText(step, staging);
  return (text !== null ? <Typography variant="caption">{text}</Typography> : null);
};

export default function ReconSteps() {
  const staging = useContext(ReconStagingContext);
  const flow = getFlow(staging.lineItemType);
  const activeStep = flow.indexOf(staging.flowState);

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {flow.map((flowState, index) => {
          const Component = flowStateComponent[flowState];
          return (
          <Step key={index}>
            <StepButton color="inherit" onClick={() => staging.jumpTo(flowState)} optional={<StepSelection step={flowState} staging={staging}/>}>
                {flowStateLabel[flowState]}
            </StepButton>
            <StepContent>
              <Component staging={staging}/>
            </StepContent>
          </Step>
        ); })}
      </Stepper>
    </Box>
  );
}
