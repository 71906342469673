import {useCallback, useState} from "react";

import {MarkRequired} from "ts-essentials";

import {_ImplInitializer, Initializer, UpdateFn} from "./hook-utils";

//Structural type to support both Synthetic and native Events
type StructuralChangeEvent = {readonly target: EventTarget | null};

export interface UseInputStateOptions<T> {
    /** A function to parse the input value. Can be omitted for string values. */
    readonly parser?: (str: string) => T;
}

type InputValueTuple<T> = [
    value: T,
    onValueInput: (ev: StructuralChangeEvent) => void,
    setValue: UpdateFn<T>
];

/**
 * Convenience hook for the common pattern of binding state to an input
 *
 * NOTE: The value will not be re-evaluated if the parser changes
 *
 * @param initializer - Initializes the state hook
 * @param options - Optional parameters
 *
 * @returns A tuple of the value, an `input`/`change` event handler, and the raw setter
 */
export function useInputState(
    initializer: Initializer<string>,
    options?: UseInputStateOptions<string>
): InputValueTuple<string>;
export function useInputState<T extends string>(
    initializer: Initializer<T>,
    options?: UseInputStateOptions<T>
): InputValueTuple<T>;
export function useInputState<T>(
    initializer: Initializer<T>,
    options: MarkRequired<UseInputStateOptions<T>, "parser">
): InputValueTuple<T>;
export function useInputState<T>(
    initializer: _ImplInitializer<T>,
    {parser = x => x as any as T}: UseInputStateOptions<T> = {}
): InputValueTuple<T> {
    const [value, setValue] = useState<T>(initializer);
    const onValueInput = useCallback(
        (ev: StructuralChangeEvent) => setValue(parser((ev.target as HTMLInputElement).value)),
        //Intentionally omitting parser
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [setValue]
    );

    return [value, onValueInput, setValue];
}
