import {Box, Button, Stack} from "@mui/material";
import {startCase} from "lodash";
import React, {useCallback, useMemo} from "react";
import {StripePayoutTransactionWithTransfer} from "~/stripe-payout-transaction/stripe-payout-transaction";
import {MoneyFormat} from "~/utils/money";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import LineItems from "~/repositories/line-items";
import {useQuery} from "react-query";
import {Error} from "~/recon/components/recon-review";
import {BigNumber} from "bignumber.js";

const InEscrow = ({net, fee, currency}: {net: BigNumber, fee: BigNumber, currency: "usd" | "eur" | undefined}) => {
  const netAmount = MoneyFormat.formatBigNumber(net, currency);
  const feeAmount = MoneyFormat.formatBigNumber(fee, currency);
  return (
    <Stack direction="column" pt={2} pb={2}>
      <span>Net: {netAmount}</span>
      <span>Fee: {feeAmount}</span>
    </Stack>
  );
};

type Item = StripePayoutTransactionWithTransfer;
const columns: GridColDef[] = [
  {field: "id", flex: 1, headerName: "Id"},
  {field: "category", flex: 0.6, headerName: "Category", valueGetter: ({row}: {row: Item}) => row.payoutTransaction.reportingCategory},
  {field: "amount", flex: 1, headerName: "Amount", renderCell: ({row}: {row: Item}) => <InEscrow net={row.payoutTransaction.netAmount} fee={row.payoutTransaction.feeAmount} currency={row.payoutTransaction.currency}/>},
  {field: "transferId", flex: 0.6, headerName: "T.ID", valueGetter: ({row}: {row: Item}) => row.transfer?.id || "N/A"},
  {field: "transferState", flex: 1, headerName: "T.State", valueGetter: ({row}: {row: Item}) => startCase(row.transfer?.state)},
  {field: "transferIntention", flex: 1, headerName: "T.State", valueGetter: ({row}: {row: Item}) => startCase(row.transfer?.intention)},
  {field: "fromAccount", flex: 1, headerName: "From", valueGetter: ({row}: {row: Item}) => row.transfer?.fromAccount?.name || "N/A"},
  {field: "toAccount", flex: 1, headerName: "To", valueGetter: ({row}: {row: Item}) => row.transfer?.toAccount?.name || "N/A"}
];


export const PayoutTransactionsPreview = ({payoutId, onAccept}: {payoutId: string, onAccept: (_: StripePayoutTransactionWithTransfer[]) => void, onCancel: () => void}) => {
  const {data, isLoading, isError, error} = useQuery(LineItems.payoutTransactions(payoutId));
  const rows = useMemo(() => (data ? data.data : []), [data]);
  const onConfirm = useCallback(() => onAccept(rows), [onAccept, rows]);

  return (
    <Stack>
      {isError && <Error error={error}/>}
      <DataGrid
          rows={rows}
          columns={columns}
          getRowHeight={() => "auto"}
          autoHeight
          rowsPerPageOptions={[10, 30, 50, 100]}
          disableSelectionOnClick
          disableDensitySelector
          hideFooterSelectedRowCount
          paginationMode={"client"}
          loading={isLoading}
      />
      <Box>
        <Button variant="contained" onClick={onConfirm} size="small" sx={{mt: 1, mr: 1}}>Looks Good!</Button>
      </Box>
    </Stack>
  );
};

export default PayoutTransactionsPreview;
