import {errorContainer, ErrorContainer} from "../error-container";

export * from "./loadable-container";
export * from "./use-loadable";

type LoadableState<T> =
    | {readonly state: "pending"}
    | {
        readonly state: "loading",

        /** Optional stale value from initialization or a previous load */
        readonly staleValue?: T
      }
    | {readonly state: "error"} & ErrorContainer
    | {
        readonly state: "loaded",

        /** The value returned from the operation */
        readonly value: T
      };

type LoadedStateHandler<S extends Loadable.State, T, U> =
    "loaded" extends S ? {
        /** Handles the loaded state. Is passed the loaded value. */
        readonly loaded: (value: T) => U
    } : {};

type ValueStateHandlers<S extends Loadable.State, T, U> =
    & ("loading" extends S ? {
          /** Handles the loading state */
          readonly loading: (staleValue: T | undefined) => U
      } : {})
    & (
        | LoadedStateHandler<S, T, U>
        | Partial<LoadedStateHandler<S, T, U>> & {
              /** Handles either the loaded state, or the loading state if it has a stale value */
              readonly hasValue: (value: T, stale: boolean) => U
          }
    );

type StateHandlers<S extends Loadable.State, T, U> =
    & ("pending" extends S ? {
          /** Handles the pending state */
          readonly pending: () => U
      } : {})
    & ("error" extends S ? {
          /** Handles the error state. Is passed the error message and Error instance. */
          readonly error: (errorMessage: string, error: Error | null) => U
      } : {})
    & ("loading" extends S ? ValueStateHandlers<S, T, U> : {})
    & ("loaded" extends S ? ValueStateHandlers<S, T, U> : {});

/** Handler interface used for pattern matching on Loadables */
export type LoadableCaseHandler<S extends Loadable.State, T, U> =
    | StateHandlers<S, T, U>
    | Partial<StateHandlers<S, T, U>> & {
        /** Handles any cases not otherwise handled specifically */
        readonly else: () => U
      };

abstract class LoadableApi {
    //Using an abstract class here as a concise way of defining both
    //a prototype for Loadable values and its accompanying interface.
    //The T type parameter is on each method rather than on the class
    //because doing the opposite causes problematic interactions with DeepReadonly.
    //The DeepReadonly type can't update the `this` parameters, so you end up with a receiver
    //incompatible with its own methods. The individual type params allow the binding to happen late.
    //I don't _think_ having the parameter on each method will cause any big issues.

    /** Checks whether this loadable value is in the pending state */
    public isPending<T>(this: Loadable<T>): this is {state: "pending"} {
        return this.state === "pending";
    }

    /** Checks whether this loadable value is in the loading state */
    public isLoading<T>(this: Loadable<T>): this is {state: "loading"} {
        return this.state === "loading";
    }

    /** Checks whether this loadable value is in the error state */
    public hasError<T>(this: Loadable<T>): this is {state: "error"} {
        return this.state === "error";
    }

    /** If this loadable is in the error state, returns the error container. Else returns null. */
    public errorOrNull<T>(this: Loadable<T>): ErrorContainer | null {
        if (this.state === "error") return this;
        else return null;
    }

    /** Checks whether this loadable value is loaded */
    public isLoaded<T>(this: Loadable<T>): this is {state: "loaded"} {
        return this.state === "loaded";
    }

    /** Checks whether this loadable value has a stale or current value */
    public hasStaleOrCurrentValue<T>(this: Loadable<T>): boolean {
        return this.state === "loaded" || this.state === "loading" && "staleValue" in this;
    }

    /** Returns any stale or current value held by this Loadable, and otherwise returns null */
    public staleOrCurrentValueOrNull<T>(this: Loadable<T>): T | null {
        return this.case({
            hasValue: value => value,
            else: () => null
        });
    }

    /** Returns the stale or current value held by this Loadable, or throws if there isn't one */
    public getStaleOrCurrentValue<T>(this: Loadable<T>): T {
        return this.case({
            hasValue: value => value,
            else: () => { throw new TypeError("Assertion failed: Loadable does not have a stale or current value"); }
        });
    }

    /**
     * Returns a new Loadable in the loading state, with any stale or current value in this loadable as a stale value
     */
    public toLoadingWithStaleValue<T>(this: Loadable<T>): Loadable<T> & {state: "loading"} {
        return this.case({
            hasValue: loadingWithStaleValue,
            else: () => loading
        });
    }

    /** Returns null if the operation has not completed; otherwise returns the loaded value */
    public valueOrNull<T>(this: Loadable<T>): T | null {
        return this.isLoaded() ? this.value : null;
    }

    /** Asserts that the operation has succeeded and then returns the loaded value */
    public getValue<T>(this: Loadable<T>): T {
        if (!this.isLoaded()) throw new TypeError(`Assertion failed: Loadable is in ${this.state} state`);
        return this.value;
    }

    /**
     * Pattern-matches a deferred loadable value
     *
     * @param handler - A handler that returns an applicable value for each state
     *
     * @returns The result of calling the appropriate handler function
     */
    //This signature works and allows calling off of already narrowed values.
    //It just doesn't work as an implementation signature (probably too complex). Overloads to the rescue!
    public case<T, U, S extends Loadable.State>(
        this: Loadable<T> & {state: S},
        handler: LoadableCaseHandler<S, T, U>
    ): U;
    public case<T, U>(this: Loadable<T>, handler: LoadableCaseHandler<Loadable.State, T, U>): U {
        //I guess due to the complexity of how I'm generating the union, TypeScript won't accept just `handler.pending`
        if (this.state === "pending" && "pending" in handler && handler.pending) {
            return handler.pending();
        }
        else if (this.state === "loaded") {
            if ("loaded" in handler && handler.loaded) return handler.loaded(this.value);
            else if ("hasValue" in handler && handler.hasValue) return handler.hasValue(this.value, false);
        }
        else if (this.state === "loading") {
            if ("staleValue" in this && "hasValue" in handler && handler.hasValue)
                return handler.hasValue(this.staleValue!, true);
            else if ("loading" in handler && handler.loading) return handler.loading(this.staleValue);
        }
        else if (this.state === "error" && "error" in handler && handler.error) {
            return handler.error(this.errorMessage, this.error);
        }

        //This should never happen, but TypeScript just can't track that this is safe
        if (!("else" in handler)) throw new TypeError(`No handler was specified for case ${this.state}`);

        return handler.else();
    }

    /** If the Loadable is in the loaded state, executes fn with the loaded value. Returns this. */
    public tap<T>(this: Loadable<T>, fn: (value: T) => void): Loadable<T> {
        if (this.isLoaded()) {
            fn(this.value);
        }

        return this;
    }

    /** If the Loadable has a stale or current value, executes fn with the value. Returns this. */
    public tapStaleOrCurrent<T>(this: Loadable<T>, fn: (value: T) => void): Loadable<T> {
        if (this.hasStaleOrCurrentValue()) {
            fn(this.getStaleOrCurrentValue());
        }

        return this;
    }

    public toString(this: Loadable<unknown>): string {
        return this.case({
            pending: () => "pending",
            loaded: value => `loaded(${value})`,
            hasValue: staleValue => `loading(${staleValue})`,
            loading: () => "loading",
            error: (errorMessage, error) => `error(${error ?? errorMessage})`
        });
    }
}

/** A utility type for tracking the state of asynchronous or long-running operations */
export type Loadable<T> = LoadableState<T> & LoadableApi;

export namespace Loadable {
    /** The state of a Loadable */
    export type State = LoadableState<any>["state"];

    type EagerLoadableState<T> = Exclude<LoadableState<T>, {state: "pending"}>;

    /** A Loadable that cannot be in the "pending" state */
    export type Eager<T> = EagerLoadableState<T> & LoadableApi;

    export namespace Eager {
        /** The state of an Eager Loadable */
        export type State = EagerLoadableState<any>["state"];
    }
}

/** The singleton Loadable in the pending state */
export const pending: Loadable<any> & {state: "pending"} =
    Object.freeze(Object.assign(Object.create(LoadableApi.prototype), {state: "pending"}));

/** The singleton Loadable in the loading state with no stale value */
export const loading: Loadable.Eager<any> & {state: "loading"} =
    Object.freeze(Object.assign(Object.create(LoadableApi.prototype), {state: "loading"}));

/** Creates a Loadable in the loading state with a stale value */
export const loadingWithStaleValue = <T>(value: T) =>
    Object.freeze(Object.assign(Object.create(LoadableApi.prototype), {
        state: "loading",
        staleValue: value
    })) as Loadable.Eager<T> & {state: "loading"};

/**
* Creates a Loadable in the error state
*
* @param errorSource - An object containing an error message. Can be an instance of Error.
*
* @returns A new Loadable in the error state with message and error from the given source
*/
export const error = <T>(errorSource: {message: string}) =>
    Object.freeze(Object.assign(Object.create(LoadableApi.prototype), {
        state: "error",
        ...errorContainer(errorSource)
    })) as Loadable.Eager<T> & {state: "error"};

/**
* Creates a Loadable in the loaded state
*
* @param value - The loaded value, if any
*
* @returns A new Loadable in the loaded state with the given value
*/
export function loaded(): Loadable.Eager<void> & {state: "loaded"};
export function loaded<T>(value: T): Loadable.Eager<T> & {state: "loaded"};
export function loaded<T>(value?: T) {
    return Object.freeze(Object.assign(Object.create(LoadableApi.prototype), {
        state: "loaded",
        value
    })) as Loadable.Eager<T> & {state: "loaded"};
}
