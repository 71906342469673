import React, {ForwardedRef, forwardRef, useCallback} from "react";

import {Box, ButtonBase, SxProps, Theme} from "@mui/material";

import {FastTreeItem, FastTreeItemProps, FastTreeView, FastTreeViewProps} from "./ext/fast/tree-view";
import {Icon} from "./icon";
import {transition} from "~/utils/styles";

export interface TreeViewProps extends FastTreeViewProps {
    readonly preventSelection?: boolean;
    readonly sx?: SxProps<Theme>;
}

export const TreeView = forwardRef(function TreeView({
    preventSelection = false,
    onClickCapture: onClickCaptureProp,
    sx = [],
    ...props
}: TreeViewProps, ref: ForwardedRef<FastTreeView>) {
    const onClickCapture = useCallback((ev: React.MouseEvent<FastTreeView>) => {
        onClickCaptureProp?.(ev);

        //The FAST tree items only toggle selection if the target of the click event is _exactly_ the tree item.
        //This behavior strikes me as unideal, but I can take advantage of it to prevent selection
        //without a brief flash of the selected state on click and without breaking clicking chilren.
        if (preventSelection && (ev.target as HTMLElement).tagName === "FAST-TREE-ITEM") {
            ev.stopPropagation();
        }
    }, [onClickCaptureProp, preventSelection]);

    return <Box
        component={FastTreeView}
        ref={ref}
        sx={[
            preventSelection && {
                "& fast-tree-item": {
                    cursor: "default"
                }
            },
            sx
        ].flat()}
        onClickCapture={onClickCapture}
        {...props}
    />;
});

export type TreeItem = FastTreeItem;

export interface TreeItemProps extends Omit<FastTreeItemProps, "onExpandedChange" | "onSelectedChange"> {
    readonly onExpandedChange?: (expanded: boolean) => void;

    readonly onSelectedChange?: (selected: boolean) => void;

    readonly sx?: SxProps<Theme>;
}

const defaultExpandCollapseGlyph =
    <ButtonBase component="div" centerRipple tabIndex={-1} sx={{width: 1, height: 1}}>
        <Icon
            fa="angle-right"
            sx={{
                ...transition(["transform", "shortest", "easeInOut"]),

                "fast-tree-item[expanded] > [slot='expand-collapse-glyph'] > div > &": {
                    transform: "rotate(90deg)"
                }
            }}
        />
    </ButtonBase>;

export const TreeItem = forwardRef(function TreeItem({
    onExpandedChange,
    onSelectedChange,
    sx = [],
    expandCollapseGlyph = defaultExpandCollapseGlyph,
    ...props
}: TreeItemProps, ref: ForwardedRef<FastTreeItem>) {
    const rawOnExpandedChange = useCallback((ev: Event) => {
        const treeItem = ev.currentTarget as FastTreeItem;

        if (treeItem.expanded) {
            setTimeout(() => treeItem.scrollIntoView({block: "nearest", behavior: "smooth"}), 0);
        }

        onExpandedChange?.(treeItem.expanded);
        ev.stopPropagation();
    }, [onExpandedChange]);

    const rawOnSelectedChange = useCallback((ev: Event) => {
        onSelectedChange?.((ev.currentTarget as FastTreeItem).selected);
        ev.stopPropagation();
    }, [onSelectedChange]);

    return <Box
        component={FastTreeItem}
        ref={ref}
        sx={[{
            background: "inherit",
            color: "inherit",
            font: "inherit",

            "&::part(positioning-region)": {
                background: "inherit",

                "&:hover": {
                    backgroundColor: "action.hover"
                }
            },

            //Give the row selection accent a more Material Design look.
            "&[selected]": {
                //The default accent is a pseudo-element on the root, which presents problems with dynamic height.
                //So hide it here and put it on the positioning region instead.
                "&::after": {
                    display: "none"
                },

                "&::part(positioning-region)": {
                    backgroundColor: "action.selected",

                    "&::after": {
                        content: "''",
                        display: "block",
                        position: "absolute",
                        height: 1,
                        width: "3px",
                        top: 0,
                        left: 0,
                        backgroundColor: "primary.light"
                    }
                }
            },

            "&::part(expand-collapse-button)": {
                borderRadius: "100%",
                //Same as MUI buttons
                ...transition(["background", "short", "easeInOut"])
            }
        }, sx].flat()}
        onExpandedChange={rawOnExpandedChange}
        onSelectedChange={rawOnSelectedChange}
        expandCollapseGlyph={expandCollapseGlyph}
        {...props}
    />;
});
