import {PaginationEnvelope} from "~/api/pagination-envelope";
import {wfGet, wfPost} from "~/utils/wf-fetch";
import {LineItemWithRecons} from "~/recon/line-item";
import {constructDto, paginatedDto} from "./util";
import {StripePayoutTransactionWithTransfer} from "~/stripe-payout-transaction/stripe-payout-transaction";
import BigNumber from "bignumber.js";
import {TransferSummary} from "~/transfer/transfer-summary";

const basePath = (backendId: string | number) => `${process.env.API_BASE}/line_items/${backendId}`;
const unattributedWirePath = (lineItemId: string | number) => `${process.env.API_BASE}/line_items/${lineItemId}/wire`;
const transactionsPath = (payoutId: string | number) => `${process.env.API_BASE}/line_items/payouts/${payoutId}/transactions`;
const LineItems = {
  list: (backendId: string, params?: {[param: string]: unknown}) => ({
    queryKey: ["line_items", backendId, params],
    queryFn: () => wfGet<PaginationEnvelope<LineItemWithRecons[]>>(basePath(backendId), params, paginatedDto(LineItemWithRecons)),
    keepPreviousData: true,
    staleTime: 3 * 60 * 1000 // 3 minutes
  }),
  payoutTransactions: (payoutId: string) => ({
    queryKey: ["stripe_payout", payoutId, "transactions"],
    queryFn: () => wfGet<PaginationEnvelope<StripePayoutTransactionWithTransfer[]>>(transactionsPath(payoutId), undefined, paginatedDto(StripePayoutTransactionWithTransfer)),
    keepPreviousData: true,
    staleTime: 10 * 60 * 1000 // 10 minutes
  }),
  reconcile: (backendId: string) => ({
    mutationFn: (data: ReconRequest) => wfPost<ReconRequest, LineItemWithRecons>(data, basePath(backendId), {constructor: constructDto(LineItemWithRecons)})
  }),
  sync: (lineItemId: string) => ({
    mutationFn: () => wfPost<any, LineItemWithRecons>(null, `${basePath(lineItemId)}/sync`, {constructor: constructDto(LineItemWithRecons)})
  }),
  unattributedWire: (lineItemId: number) => ({
    mutationFn: (params: UnattributedWireRequest) => wfPost<UnattributedWireRequest, TransferSummary>(params, unattributedWirePath(lineItemId), {constructor: constructDto(TransferSummary)})
  })
};

export enum ReconRequestType {
  Deposit = "deposit_params",
  EpayTransaction = "epay_transaction_params",
  StripePayout = "stripe_payout_params",
  VitesseTransaction = "vitesse_transaction_params"
}

export interface DepositRequest {
  lineItemId: number;
  lineItemTargetType: string;
  transferIds: number[];
  adjust: boolean;
  readonly type: ReconRequestType.Deposit;
}

export interface EpayTransactionRequest {
  lineItemId: number;
  lineItemTargetType: string;
  transferId: number;
  readonly type: ReconRequestType.EpayTransaction;
}

export interface VitesseTransactionRequest {
  lineItemId: number;
  lineItemTargetType: string;
  vitesseTransactionId: string | undefined;
  transferId: number;
  readonly type: ReconRequestType.VitesseTransaction;
}

export interface StripePayoutRequest {
  lineItemId: number;
  lineItemTargetType: string;
  stripePayoutId: string;
  transfersToPayoutTransactions: [number, string][];
  readonly type: ReconRequestType.StripePayout;
}

export type ReconRequest = DepositRequest | EpayTransactionRequest | VitesseTransactionRequest | StripePayoutRequest;

export interface UnattributedWireRequest {
  amount: BigNumber;
  destinationAccountId: number;
}

export default LineItems;
