import React, {memo} from "react";

import {BigNumber} from "bignumber.js";
import {isEmpty} from "lodash";

import {TreeItem, TreeView} from "./tree-view";
import {Temporal} from "@js-temporal/polyfill";

/**
 * Calls toJSON on an object if it is not a known class.
 * Returns the result along with an indication of whether processing occurred.
 */
function handleToJSON(value: any): [unknown, boolean] {
    if (
        typeof value === "object"
            && value !== null
            && "toJSON" in value
            && ![BigNumber.prototype, Temporal.Instant.prototype].includes(Object.getPrototypeOf(value))
    ) return [(value.toJSON as (key: string) => unknown)(""), true];
    else return [value, false];
}

type JsonDisplayComposite = readonly unknown[] | {readonly [key: string]: unknown};

const isJsonDisplayComposite = (value: unknown, toJSONCalled: boolean): value is JsonDisplayComposite =>
    Array.isArray(value)
        || value !== null
            && typeof value === "object"
            //Treat objects returned from toJSON as plain, as that's essentially the contract of toJSON
            && (toJSONCalled || [null, Object.prototype].includes(Object.getPrototypeOf(value)));

/** Formats "primitives" for display by JsonDisplay. Returns a simple type string for unsupported types. */
function formatPrimitive(value: unknown): string {
    if (typeof value === "bigint") return `${value}n`;
    if (BigNumber.isBigNumber(value)) return `BigNumber(${value})`;
    if (value instanceof Temporal.Instant) return value.toLocaleString();
    if (value !== null && typeof value === "object") return `[instanceof ${value.constructor.name}]`;
    if (value === undefined) return "undefined";
    return JSON.stringify(value);
}

const EnumerateJson = ({json, hideNils}: {json: JsonDisplayComposite, hideNils: boolean}) => <>
    {/* Object.entries works on arrays in the desirable way; no need to branch on type here */}
    {Object.entries(json).map(([key, _value]) => {
        const [value, toJSONCalled] = handleToJSON(_value);

        if (hideNils && value === null || value === undefined) return null;

        const isComposite = isJsonDisplayComposite(value, toJSONCalled);
        const keyDisplay = <b>{Array.isArray(json) ? `[${key}]` : key}</b>;
        const valueDisplay =
            isComposite ?
                isEmpty(value) ? Array.isArray(value) ? "[]" : "{}" : null :
                formatPrimitive(value);
        return <TreeItem
            key={key}
            content={valueDisplay ? <>{keyDisplay}: {valueDisplay}</> : keyDisplay}
            items={isComposite && <EnumerateJson json={value} hideNils={hideNils}/>}
        />;
    })}
</>;

export interface JsonDisplayProps {
    readonly json: unknown;
    readonly hideNils?: boolean;
}

/**
 * Renders arbitrary JSON in a TreeView.
 * For convenience with parsed DTOs, it also supports bigints, BigNumbers, and Temporal.Instants.
 * All other non-JSON object types are not handled.
 */
export const JsonDisplay = memo(function JsonDisplay({json: _json, hideNils = false}: JsonDisplayProps) {
    const [json, toJSONCalled] = handleToJSON(_json);
    return (
        <TreeView
            className="PcJsonDisplay-root"
            preventSelection
            sx={{
                "& fast-tree-item": {
                    "&::part(positioning-region), &::part(content-region)": {
                        height: "auto",
                        minHeight: "30px" //Based on the height of the expand-collapse glyph
                    }
                }
            }}
        >
            {isJsonDisplayComposite(json, toJSONCalled) ?
                <EnumerateJson json={json} hideNils={hideNils}/>
            :
                <TreeItem content={formatPrimitive(json)}/>
            }
        </TreeView>
    );
});
