import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";

export abstract class SignInResponseImpl extends JsonDto {
    public static get [JsonDto.subtypes]() {
        return {
            sign_in_success: SignInSuccess,
            auth_challenge_required: AuthChallengeRequired,
            auth_challenge_setup_required: AuthChallengeSetupRequired
        } as const;
    }
}

export type SignInResponse = SignInSuccess | AuthChallengeRequired | AuthChallengeSetupRequired;

export class SignInSuccess extends SignInResponseImpl {
    public readonly type = "sign_in_success";

    @jsonProp()
    public readonly token!: string;

    @jsonProp(JsonTypes.id)
    public readonly userId!: string;

    @jsonProp()
    public readonly userFirstName!: string;

    @jsonProp()
    public readonly userLastName!: string;

    public constructor(init?: unknown) {
        super();
        this.init(SignInSuccess, init);
    }
}

export class AuthChallengeRequired extends SignInResponseImpl {
    public readonly type = "auth_challenge_required";

    @jsonProp()
    public readonly sessionToken!: string;

    /** Currently unused */
    @jsonProp(JsonTypes.string, {optional: true})
    public readonly challengeMethod!: string | null;

    public constructor(init?: unknown) {
        super();
        this.init(AuthChallengeRequired, init);
    }
}

export class AuthChallengeSetupRequired extends SignInResponseImpl {
    public readonly type = "auth_challenge_setup_required";

    @jsonProp()
    public readonly sessionToken!: string;

    public constructor(init?: unknown) {
        super();
        this.init(AuthChallengeSetupRequired, init);
    }
}
