import React, {useCallback} from "react";

import {Box} from "@mui/material";
import {omit} from "lodash";

import {IconButton} from "./button";
import {Icon} from "./icon";
import {TextField, TextFieldProps} from "./text-field";

export type PersistentTextFieldProps<T extends string> = TextFieldProps & {
    readonly persistedValue?: T,
    readonly forceDirty?: boolean,
    readonly value: T,
    readonly onValueChange: (value: T) => void
};

export function PersistentTextField<T extends string>({
    sx = [],
    label,
    onValueChange,
    onInput: onInputProp,
    value,
    forceDirty = false,
    select,
    ...props
}: PersistentTextFieldProps<T>) {
    const dirty = forceDirty || "persistedValue" in props && value !== props.persistedValue;

    const onInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        onInputProp?.(ev);
        onValueChange(ev.target.value as T);
    }, [onInputProp, onValueChange]);

    const revertChanges = useCallback(
        () => onValueChange(props.persistedValue!),
        [onValueChange, props.persistedValue]
    );

    const showRevertButton = dirty && "persistedValue" in props;

    return (
        <Box className="PcPersistentTextField-root" sx={[{position: "relative"}, sx].flat()}>
            {dirty &&
                <Icon
                    className="PcPersistentTextField-modifiedDot"
                    fa="primitive-dot"
                    sx={{
                        color: "primary.main",
                        position: "absolute",
                        left: 0,
                        top: "50%",
                        transform: "translateX(-100%) translateY(-50%)",
                        pr: 1
                    }}
                />
            }
            <TextField
                sx={[
                    {width: 1},
                    showRevertButton && {"& input": {pr: select ? 11.75 : 6.75}}
                ]}
                {...(dirty && {"aria-label": `${label} (modified)`})}
                {...{onInput, label, value, select}}
                //React chokes when an unknown camelcase prop ends up on a native element
                {...omit(props, "persistedValue")}
            />
            {showRevertButton &&
                <IconButton
                    className="PcPersistentTextField-revertButton"
                    title={`Revert changes to ${label}`}
                    autoTooltip
                    size="small"
                    onClick={revertChanges}
                    sx={{
                        position: "absolute",
                        //Need to make room for the dropdown arrow in select mode
                        right: t => t.spacing(select ? 5 : 1),
                        top: "50%",
                        transform: "translateY(-50%)"
                    }}
                >
                    <Icon fa="undo"/>
                </IconButton>
            }
        </Box>
    );
}
