import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {green, red} from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import {IconButton} from "@mui/material";
import {CheckCircle, CheckCircleOutline, Error, SvgIconComponent} from "@mui/icons-material";
import {useMemo} from "react";

export enum CircularProgressState {
  Initial = "initial",
  Working = "working",
  Success = "success",
  Failure = "failure"
}
export const confirmButtonStateFromFlags = (success: boolean, failure: boolean, progress: boolean): CircularProgressState => {
  if (success)  return CircularProgressState.Success;
  if (failure)  return CircularProgressState.Failure;
  if (progress) return CircularProgressState.Working;

  return CircularProgressState.Initial;
};

const progressSx = {
  color: green[400],
  position: "absolute",
  left: -1,
  zIndex: 1
};

const buttonSxFor = (state: CircularProgressState) => {
  switch (state) {
    case CircularProgressState.Failure:
      return ({bgcolor: red[400], "&:hover": {bgcolor: red[600]}});
    case CircularProgressState.Success:
      return ({bgcolor: green[400], "&:hover": {bgcolor: green[600]}});
    default:
      return {color: green[600]};
  }
};

const iconFor = (state: CircularProgressState): SvgIconComponent => {
  switch (state) {
    case CircularProgressState.Success: return CheckCircle;
    case CircularProgressState.Failure: return Error;
    case CircularProgressState.Initial: return CheckIcon;
    case CircularProgressState.Working: return CheckIcon;
  }
};

interface CircularProgressButtonProps {
  state: CircularProgressState;
  isDisabled?: boolean | undefined;
  error?: string | undefined;
  onClick: () => void;
}

export default function CircularProgressButton({state, onClick, isDisabled = false, error}: CircularProgressButtonProps) {
  const buttonSx = buttonSxFor(state);
  const Icon = useMemo(() => iconFor(state), [state]);
  const isWorking = state === CircularProgressState.Working;

  return (
    <Box sx={{position: "relative"}} fontSize={"smaller"}>
      <IconButton aria-label="toggle edit" onClick={onClick} size="small" disabled={isWorking || isDisabled} sx={buttonSx} title={error}>
        <Icon fontSize="small"/>
      </IconButton>
      {isWorking && <CircularProgress size={31} sx={progressSx}/>}
    </Box>
  );
}
