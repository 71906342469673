import React, {ReactNode} from "react";

import {TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {startCase} from "lodash";
import {Observer} from "mobx-react-lite";

import {applyLens, Lens} from "~/utils/lens";
import {Sequence} from "~/utils/sequence";

export type ColumnDefinition<T> = ((row: T) => ReactNode) | [string, (row: T) => ReactNode];

export type ColumnMap<T> = {readonly [colId: string]: ColumnDefinition<T>};

export const makeColumns = <T extends unknown>() => <TCols extends ColumnMap<T>>(columns: TCols) => columns;

export type ColumnOrder<ColId extends string> = readonly (ColId | {readonly hidden: ColId})[];

export const columnName = (colId: string, col: ColumnDefinition<never>) =>
    Array.isArray(col) ? col[0] : startCase(colId);

export interface TableColumnsProps<T, TCols extends ColumnMap<T>> {
    readonly rowData: Iterable<T>;
    readonly keyRowsBy: Lens<T, string | number, [number]>;
    readonly columnOrder?: ColumnOrder<keyof TCols & string>;
    readonly observer?: boolean;
    readonly children: TCols;
    readonly isSelected?: (v: T) => boolean;
}

/** A column-oriented API for populating a Table. Allows specifying a column order and visibility. */
export function TableColumns<T, TCols extends ColumnMap<T>>({
    rowData,
    keyRowsBy,
    observer = false,
    children: columns,
    columnOrder = Object.keys(columns),
    isSelected = _ => false
}: TableColumnsProps<T, TCols>) {
    return <>
        <TableHead>
            <TableRow>
                {columnOrder.map(orderEntry =>
                    (typeof orderEntry === "string" && orderEntry in columns) &&
                        <TableCell key={orderEntry}>{columnName(orderEntry, columns[orderEntry])}</TableCell>
                )}
            </TableRow>
        </TableHead>
        <TableBody>
            {Sequence.from(rowData).map((row, i) =>
                <TableRow key={applyLens(keyRowsBy, row, i)} selected={isSelected(row)}>
                    {columnOrder.map(orderEntry => {
                        if (typeof orderEntry === "object" || !(orderEntry in columns)) return null;

                        //TypeScript was having trouble narrowing this correctly without the type hint,
                        //so I broke it out into a variable
                        const renderColumn: (row: T) => ReactNode = Array.isArray(columns[orderEntry]) ?
                            columns[orderEntry][1] :
                            columns[orderEntry];

                        return (
                            <TableCell key={orderEntry}>
                                {observer ?
                                    <Observer>{() => <>{renderColumn(row)}</>}</Observer>
                                :
                                    renderColumn(row)
                                }
                            </TableCell>
                        );
                    })}
                </TableRow>
            )}
        </TableBody>
    </>;
}
