import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";

export class User extends JsonDto {
    public static readonly ROLES = ["admin", "support", "wefunder_api"] as const;

    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp()
    public readonly email!: string;

    @jsonProp()
    public readonly firstName!: string;

    @jsonProp()
    public readonly lastName!: string;

    @jsonProp(JsonTypes.stringUnion(User.ROLES))
    public readonly role!: User.Role;

    public constructor(init?: unknown) {
        super();
        this.init(User, init);
    }
}

export namespace User {
    export type Role = (typeof User.ROLES)[number];
}
