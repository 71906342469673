import {BigNumber} from "bignumber.js";

import {Transfer} from "./transfer";
import {TransferMetadata} from "./transfer-metadata";
import {VirtualAccountSummary} from "~/account/virtual-account-summary";
import {Entity} from "~/entity/entity";
import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {TransferIntentions, TransferStates} from "~/transfer/index";
import {CURRENCIES, Currency} from "~/currency";

export class TransferSummary extends JsonDto {

    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp(JsonTypes.id, {optional: true})
    public readonly parentId!: string | null;

    @jsonProp(JsonTypes.stringUnion(TransferStates))
    public readonly state!: Transfer.State;

    @jsonProp(JsonTypes.stringUnion(TransferIntentions))
    public readonly intention!: Transfer.Intention;

    @jsonProp(JsonTypes.bigNumber)
    public readonly amount!: BigNumber;

    @jsonProp(JsonTypes.dto(VirtualAccountSummary), {optional: true})
    public readonly fromAccount!: VirtualAccountSummary | null;

    @jsonProp(JsonTypes.dto(VirtualAccountSummary), {optional: true})
    public readonly toAccount!: VirtualAccountSummary | null;

    @jsonProp(JsonTypes.stringUnion(CURRENCIES), {optional: true})
    public readonly currency!: Currency | null;

    @jsonProp(JsonTypes.dto(TransferMetadata), {optional: true})
    public readonly intentionData!: TransferMetadata | null;

    @jsonProp()
    public readonly tag!: string;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly investment!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly fundraise!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly company!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly investor!: Entity | null;

    public constructor(init?: unknown) {
        super();
        this.init(TransferSummary, init);
    }
}
