import {PaginationEnvelope} from "~/api/pagination-envelope";
import {wfGet} from "~/utils/wf-fetch";
import {constructDto, paginatedDto} from "./util";
import {EscrowDetails, EscrowListItem} from "~/escrow/escrow";

const Escrow = {
  list: {
    queryKey: ["escrow"],
    queryFn: () => wfGet<{ data: EscrowListItem[] }>(`${process.env.API_BASE}/escrow`, {}),
    staleTime: Infinity
  },
  get: (id: string) => ({
    queryKey: ["escrow", id],
    queryFn: () => wfGet<EscrowDetails>(`${process.env.API_BASE}/escrow/${id}`, {}, constructDto(EscrowDetails)),
    staleTime: Infinity
  })
};

export default Escrow;
