import {makeObservable, observable} from "mobx";

import {error, Loadable, loaded, loading} from "./index";

//To correctly model this, I would need to be able to set a *lower* bound on TLoadable
//(really it's just a placeholder for either Loadable or Loadable.Eager).
//TypeScript doesn't allow lower bounds on type parameters, so instead I keep the fully generic class private
//and export type aliases that pin the second parameter with matching factory functions.
//Then I just typecast within the class to allow the assignments I know will work.

class _LoadableContainer<T, TLoadable extends Loadable<T> = Loadable<T>> {
    /** The number of active `run` calls for this container */
    private _processInstanceCount: number = 0;

    /** The number of active `run` calls for this container */
    public get processInstanceCount() { return this._processInstanceCount; }

    /** The state to which to reset when onAbort: "revert" is used. Stored in a field to help with re-entrancy. */
    private resetState: TLoadable | null = null;

    /** The `Loadable` value */
    @observable.ref
    public l: TLoadable;

    public constructor(loadable: TLoadable, name?: string) {
        makeObservable(this, undefined, {name});

        this.l = loadable;
    }

    /**
     * Implements the common pattern of wrapping API calls made from Stores in Loadables.
     *
     * @param thisVal - The `this` value to pass to `fn`
     * @param fn
     * The function to execute. This should be a generator function as usable with MobX `@flow`.
     * Before the function is executed, `l` will be set to `loading`.
     * When the function finishes, either by returning a value or throwing an exception, `l` will be set to
     * either `error` or `loaded`.
     * @param onAbort
     * Specifies a value to set `l` to if an `AbortError` is caught.
     * Specifying "revert" (the default) returns the Loadable to the state it had before run was called.
     * Specifying null or undefined keeps the AbortError as an error state.
     * @param keepStaleValue - If true, any previous value will be kept as stale while loading
     */
    public * run<This>(
        thisVal: This,
        fn: (this: This) => Generator<PromiseLike<any>, T, any>,
        {
            onAbort = "revert",
            keepStaleValue = false
        }: {onAbort?: TLoadable | "revert" | null, keepStaleValue?: boolean} = {}
    ): Generator<PromiseLike<any>, void, any> {
        this._processInstanceCount++;

        //Don't set the resetState to loading unless it is null.
        //This helps avoid leaving the state as loading incorrectly
        //if a second process starts in parallel and then aborts
        if (!this.resetState || !this.l.isLoading()) {
            this.resetState = this.l;
        }

        try {
            this.l = (keepStaleValue ? this.l.toLoadingWithStaleValue() : loading) as TLoadable;
            const result = yield* fn.call(thisVal);
            this.l = loaded(result) as TLoadable;
        }
        catch (err) {
            const isAbortError = err instanceof DOMException && err.name === "AbortError";

            if (isAbortError && onAbort) {
                //Only apply a fallback value if we are still in a loading state and are the only process running.
                //This prevents a race condition where the abort fallback clobbers a subsequent attempt.
                if (this.l.isLoading() && this._processInstanceCount === 1) {
                    if (onAbort === "revert") {
                        this.l = this.resetState;
                    }
                    else {
                        this.l = onAbort;
                    }
                }

                return;
            }

            //Even if we don't have an onAbort value, logging AbortErrors is mostly just unwanted noise
            if (!isAbortError) {
                console.error(err);
            }

            if ("message" in err) {
                this.l = error(err) as TLoadable;
            }
            else {
                this.l = error({message: typeof err === "string" ? err : JSON.stringify(err)}) as TLoadable;
            }
        }
        finally {
            this._processInstanceCount--;

            //If we were the last process, null out the resetState;
            //otherwise, update it so in-progress processes will reset to an up-to-date value
            if (this._processInstanceCount === 0) {
                this.resetState = null;
            }
            else {
                this.resetState = this.l;
            }
        }
    }
}

/** Container for a single Loadable providing convenience for use within stores */
export type LoadableContainer<T> = _LoadableContainer<T>;

export namespace LoadableContainer {
    /** Container for a single Loadable.Eager providing convenience for use within stores */
    export type Eager<T> = _LoadableContainer<T, Loadable.Eager<T>>;
}

export interface LoadableContainerInit {
    readonly name?: string;
}

/** Constructs a LoadableContainer */
export function loadableContainer<T>(loadable: Loadable<T>, {name}: LoadableContainerInit = {}) {
    return new _LoadableContainer<T>(loadable, name);
}

export namespace loadableContainer {
    /** Constructs a LoadableContainer.Eager */
    export function eager<T>(loadable: Loadable.Eager<T>, {name}: LoadableContainerInit = {}) {
        return new _LoadableContainer<T, Loadable.Eager<T>>(loadable, name);
    }
}
