import {CURRENCIES, Currency} from "~/currency";
import {JsonDto, jsonName, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {VirtualAccount} from "../account/virtual-account";
import BigNumber from "bignumber.js";


export class InvestmentStats extends JsonDto {
  @jsonProp(JsonTypes.bigNumber)
  public readonly settled!: BigNumber;

  @jsonProp(JsonTypes.bigNumber)
  public readonly incoming!: BigNumber;

  @jsonProp(JsonTypes.bigNumber)
  public readonly outgoing!: BigNumber;

  @jsonProp(JsonTypes.bigNumber)
  public readonly failed!: BigNumber;

  public constructor(init?: unknown) {
    super();
    this.init(InvestmentStats, init);
  }
}

export class TransferStat extends JsonDto {
  @jsonProp(JsonTypes.string)
  public readonly tag!: string;

  @jsonProp(JsonTypes.string, {optional: true})
  public readonly state!: string | undefined;

  @jsonProp(JsonTypes.number)
  public readonly count!: number;

  @jsonProp(JsonTypes.number)
  public readonly total!: number;

  public constructor(init?: unknown) {
    super();
    this.init(TransferStat, init);
  }
}

export class AccountRoleBreakdown extends JsonDto {
  @jsonProp(JsonTypes.string)
  public readonly laneType!: string;

  @jsonProp(JsonTypes.string)
  public readonly accountRole!: string;

  @jsonProp(JsonTypes.number)
  public readonly amount!: string;

  public constructor(init?: unknown) {
    super();
    this.init(AccountRoleBreakdown, init);
  }
}

export class TransferTagBreakdown extends JsonDto {
  @jsonProp(JsonTypes.string)
  public readonly laneType!: string;

  @jsonProp(JsonTypes.string)
  public readonly tag!: string;

  @jsonProp(JsonTypes.number)
  public readonly amount!: string;

  public constructor(init?: unknown) {
    super();
    this.init(TransferTagBreakdown, init);
  }
}

export class StripeAccountBalanceBreakdown extends JsonDto {

  @jsonProp(JsonTypes.bigNumber)
  public readonly amount!: BigNumber;

  @jsonProp(JsonTypes.stringUnion(CURRENCIES))
  public readonly currency!: Currency;

  @jsonProp(JsonTypes.object(JsonTypes.bigNumber))
  public readonly sourceTypes!: { [key: string]: BigNumber };

  public constructor(init?: unknown) {
    super();
    this.init(StripeAccountBalanceBreakdown, init);
  }
}

export class StripeAccountBalance extends JsonDto {
  @jsonProp(JsonTypes.id)
  public readonly id!: string;

  @jsonProp(JsonTypes.object(JsonTypes.array<StripeAccountBalanceBreakdown>(JsonTypes.dto(StripeAccountBalanceBreakdown))))
  public readonly balance!: { [key: string]: StripeAccountBalanceBreakdown[] };

  public constructor(init?: unknown) {
    super();
    this.init(StripeAccountBalance, init);
  }
}

export class EscrowDetails extends JsonDto {
  @jsonProp(JsonTypes.id)
  public readonly id!: string;

  @jsonProp()
  public readonly name!: string;

  @jsonProp()
  public readonly escrowProvider!: string;

  @jsonProp(JsonTypes.stringUnion(CURRENCIES))
  public readonly currency!: Currency;

  @jsonProp(JsonTypes.dto(VirtualAccount))
  public readonly stripeFeesAccount!: VirtualAccount;

  @jsonProp(JsonTypes.dto(VirtualAccount))
  public readonly adjustmentAccount!: VirtualAccount;

  @jsonProp(JsonTypes.dto(InvestmentStats))
  public readonly investmentStats!: InvestmentStats;

  @jsonProp(JsonTypes.array(JsonTypes.dto(AccountRoleBreakdown)))
  public readonly accountRoleBreakdown!: AccountRoleBreakdown[];

  @jsonProp(JsonTypes.array(JsonTypes.dto(TransferTagBreakdown)))
  public readonly transferTagBreakdown!: TransferTagBreakdown[];

  @jsonProp(JsonTypes.array(JsonTypes.dto(TransferStat)))
  public readonly transferStats!: TransferStat[];

  @jsonProp(JsonTypes.dto(StripeAccountBalance))
  public readonly stripeAccountBalance!: StripeAccountBalance;

  public constructor(init?: unknown) {
    super();
    this.init(EscrowDetails, init);
  }
}

export class EscrowListItem extends JsonDto {
  @jsonProp(JsonTypes.id)
  public readonly backendId!: string;

  @jsonProp()
  public readonly name!: string;

  public constructor(init?: unknown) {
    super();
    this.init(EscrowListItem, init);
  }
}

