import React, {useCallback, useEffect, useMemo, useRef} from "react";

import {BigNumber} from "bignumber.js";
import {flowResult} from "mobx";
import {observer} from "mobx-react-lite";

import {VirtualAccountCreationForm} from "../components/virtual-account-creation-form";
import {VirtualAccountFilters} from "../components/virtual-account-filters";
import {VirtualAccountListDisplay} from "../components/virtual-account-list-display";
import {VirtualAccountStore} from "../store";
import {VirtualAccount} from "../virtual-account";
import {PageParams, SortDirection, SortParams} from "~/api/common-query-params";
import {pageNumberSuffix} from "~/api/pagination-envelope";
import {Backend} from "~/backend/backend";
import {Button} from "~/components/button";
import {Dialog, DialogHandle} from "~/components/dialog";
import {FilterAndSortBar} from "~/components/filter-and-sort-bar";
import {CollapsibleFilterGrid} from "~/components/filter-grid";
import {LoadingTreatment} from "~/components/loading-treatment";
import {Page} from "~/components/page";
import {FundraiseExemption, FundraiseState} from "~/entity/wefunder-types";
import {parseBoolean} from "~/utils/booleans";
import {inject} from "~/utils/di";
import {parsePositiveInt} from "~/utils/numbers";
import {parseBigNumberRange, Range} from "~/utils/range";
import {useAbortableEffect} from "~/utils/use-abortable-effect";
import {useDebouncedValue} from "~/utils/use-debounced-value";
import {useIsFirstRender} from "~/utils/use-is-first-render";
import {useQueryParam} from "~/utils/use-query-param";
import {useStateObject, useTypedStateObject} from "~/utils/use-state-object";
import {TRANSFER_SORT_KEYS, TransferSortKey} from "~/transfer/api";
import {SortOptions} from "~/components/sort-options";
import {VIRTUAL_ACCOUNT_SORT_KEYS, VirtualAccountSortKey} from "~/account/api";

/** Dashboard for managing VirtualAccounts */
export const VirtualAccountDashboard = inject(
    {virtualAccountStore: VirtualAccountStore},
    observer(function VirtualAccountDashboard({virtualAccountStore}: {virtualAccountStore: VirtualAccountStore}) {
        const {virtualAccounts} = virtualAccountStore;

        const [pageParams, setPageParams] = useTypedStateObject<PageParams>()({
            page: useQueryParam("page", 1, {parser: parsePositiveInt, push: true, immediate: true}),
            perPage: useQueryParam("perPage", 10, {parser: parsePositiveInt, push: true, immediate: true})
        });

        const [sortParams, setSortParams] = useTypedStateObject<SortParams<VirtualAccountSortKey>>()({
            sortBy: useQueryParam<VirtualAccountSortKey | undefined>("sortBy", undefined),
            sortDir: useQueryParam<SortDirection | undefined>("sortDir", undefined)
        });

        const [filters, setFilters] = useStateObject({
            name: useQueryParam<string | undefined>("name", undefined),
            accountType: useQueryParam<VirtualAccount.AccountType | undefined>("accountType", undefined),
            accountRole: useQueryParam<VirtualAccount.AccountRole | undefined>("accountRole", undefined),
            backendType: useQueryParam<Backend.Type | undefined>("backendType", undefined),
            backendName: useQueryParam<string | undefined>("backendName", undefined),
            investmentId: useQueryParam<string | undefined>("investmentId", undefined),
            fundraiseId: useQueryParam<string | undefined>("fundraiseId", undefined),
            fundraiseExemption: useQueryParam<FundraiseExemption | undefined>("fundraiseExemption", undefined),
            fundraiseState: useQueryParam<FundraiseState | undefined>("fundraiseState", undefined),
            companyId: useQueryParam<string | undefined>("companyId", undefined),
            companyName: useQueryParam<string | undefined>("companyName", undefined),
            investorId: useQueryParam<string | undefined>("investorId", undefined),
            investorName: useQueryParam<string | undefined>("investorName", undefined),
            investorAdmin: useQueryParam<boolean | undefined>("investorAdmin", undefined, {parser: parseBoolean})
            // nestedMulti: useQueryParam<readonly ("simpleString" | "x")[] | undefined>(
            //     "nestedMulti",
            //     undefined,
            //     {
            //         //In order to handle an empty input without making the filter disappear,
            //         //we need to somehow store an empty array differently from undefined.
            //         //Storing as the empty string.
            //         parser: (...xs) => {
            //             if (xs.length === 1 && xs[0] === "") return [];
            //             return xs as readonly ("simpleString" | "x")[];
            //         },
            //         serializer: x => {
            //             if (x?.length === 0) return "";
            //             return x ?? [];
            //         }
            //     }
            // )
        });

        const [investmentAmountRange, setInvestmentAmountRange] = useQueryParam<Range<BigNumber> | undefined>(
            "investmentAmount",
            undefined,
            {parser: parseBigNumberRange}
        );

        //Reset the page to 1 when the filters change
        const isFirstRender = useIsFirstRender();
        useEffect(() => {
            if (!isFirstRender) {
                setPageParams.page(1, {immediate: false});
            }
        }, [filters, sortParams]); //eslint-disable-line react-hooks/exhaustive-deps

        const fetchParams = useMemo(() => ({
            ...pageParams,
            ...filters,
            ...sortParams,
            ...investmentAmountRange?.toParams("investmentAmount")
        }), [filters, sortParams, investmentAmountRange, pageParams]);

        const debouncedFetchParams = useDebouncedValue(fetchParams, 150);
        useAbortableEffect(
            abortSignal => flowResult(virtualAccountStore.fetchVirtualAccounts(debouncedFetchParams, abortSignal)),
            [virtualAccountStore, debouncedFetchParams]
        );

        const createAccountDialog = useRef<DialogHandle>(null);

        const createAccount = useCallback(() => createAccountDialog.current!.show(), [createAccountDialog]);

        const pageTitle = "Virtual Accounts";
        const pageNumberTitle = virtualAccounts.case({
            hasValue: accounts => pageNumberSuffix(accounts.pagination),
            else: () => ""
        });

        return (
            <Page
                title={pageTitle}
                tabTitle={pageTitle + pageNumberTitle}
                height="fixed"
                actions={
                    virtualAccounts.hasStaleOrCurrentValue() &&
                        <Button variant="contained" onClick={createAccount} disabled={virtualAccounts.isLoading()}>
                            Create New
                        </Button>
                }
            >
                <FilterAndSortBar>{expandSort => <>
                    <CollapsibleFilterGrid>
                        <VirtualAccountFilters
                            name={filters.name}
                            setName={setFilters.name}
                            accountType={filters.accountType}
                            setAccountType={setFilters.accountType}
                            accountRole={filters.accountRole}
                            setAccountRole={setFilters.accountRole}
                            backendType={filters.backendType}
                            setBackendType={setFilters.backendType}
                            backendName={filters.backendName}
                            setBackendName={setFilters.backendName}
                            investmentId={filters.investmentId}
                            setInvestmentId={setFilters.investmentId}
                            fundraiseId={filters.fundraiseId}
                            setFundraiseId={setFilters.fundraiseId}
                            fundraiseExemption={filters.fundraiseExemption}
                            setFundraiseExemption={setFilters.fundraiseExemption}
                            fundraiseState={filters.fundraiseState}
                            setFundraiseState={setFilters.fundraiseState}
                            companyId={filters.companyId}
                            setCompanyId={setFilters.companyId}
                            companyName={filters.companyName}
                            setCompanyName={setFilters.companyName}
                            investorId={filters.investorId}
                            setInvestorId={setFilters.investorId}
                            investorName={filters.investorName}
                            setInvestorName={setFilters.investorName}
                            investorAdmin={filters.investorAdmin}
                            setInvestorAdmin={setFilters.investorAdmin}
                            {...{investmentAmountRange, setInvestmentAmountRange}}
                        />
                    </CollapsibleFilterGrid>
                    <SortOptions
                        sortKeys={VIRTUAL_ACCOUNT_SORT_KEYS}
                        sortBy={sortParams.sortBy}
                        onSortByChange={setSortParams.sortBy}
                        sortDirection={sortParams.sortDir}
                        onSortDirectionChange={setSortParams.sortDir}
                        expanded={expandSort}
                    />
                </>}</FilterAndSortBar>
                <LoadingTreatment loadable={virtualAccounts} description="accounts" observer renderStaleValues>{(
                    accounts,
                    stale
                ) =>
                    <VirtualAccountListDisplay
                        accounts={[...accounts.data.values()]}
                        paginationState={accounts.pagination}
                        stale={stale}
                        onPageChange={setPageParams.page}
                        onRowsPerPageChange={setPageParams.perPage}
                    />
                }</LoadingTreatment>

                <Dialog ref={createAccountDialog} dialogTitle="Create Virtual Account">
                    <VirtualAccountCreationForm/>
                </Dialog>
            </Page>
        );
    })
);
