import {PaginationEnvelope} from "~/api/pagination-envelope";
import {wfGet} from "~/utils/wf-fetch";
import {paginatedDto} from "./util";
import {VirtualAccountListItem} from "~/account/virtual-account-list-item";

const basePath = `${process.env.API_BASE}/virtual_accounts`;

export const VirtualAccounts = {
  list: (params?: {[param: string]: unknown}) => ({
    queryKey: ["virtual_accounts", params],
    queryFn: () => wfGet<PaginationEnvelope<VirtualAccountListItem[]>>(basePath, params, paginatedDto(VirtualAccountListItem)),
    keepPreviousData: true,
    staleTime: 3 * 60 * 1000 // 3 minutes
  })
};

export default VirtualAccounts;
