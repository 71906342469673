import {ObservableMap} from "mobx";

import {getOrCompute} from "../maps";
import {Sequence} from "../sequence";
import {Loadable, loaded, pending} from "./index";
import {loadableContainer, LoadableContainer} from "./loadable-container";

//This class can't implement or extend Map or ObservableMap because the way it maps to/from Loadables
//doesn't quite follow the interface. Also ObservableMap doesn't support subclassing.
export class LoadableMap<K, V> {
    private readonly _map: ObservableMap<K, LoadableContainer<V>>;

    public get size(): number { return this._map.size; }

    public constructor(init: Iterable<readonly [K, V]> = []) {
        this._map = Sequence.from(init)
            .map(([k, v]) => [k, loadableContainer(loaded(v))] as const)
            .collectToObservableMap();
    }

    /**
     * Executes LoadableContainer#run for an entry in the map, using the given function to compute the map value
     *
     * @param thisVal - The `this` value to pass to `fn`
     * @param key - The key to run the computation for
     * @param fn
     * The function to execute. This should be a generator function as usable with MobX `@flow`.
     * Before the function is executed, the map entry will be set to `loading`.
     * When the function finishes, either by returning a value or throwing an exception, the map entry will be set to
     * either `error` or `loaded`.
     * @param onAbort
     * Specifies a value to set the map entry to if an `AbortError` is caught.
     * Specifying "revert" (the default) returns the Loadable to the state it had before run was called.
     * Specifying null or undefined keeps the AbortError as an error state.
     * @param keepStaleValue - If true, any previous value will be kept as stale while loading
     */
    public * run<This>(
        thisVal: This,
        key: K,
        fn: (this: This) => Generator<PromiseLike<any>, V, any>,
        {
            onAbort = "revert",
            keepStaleValue = false
        }: {onAbort?: Loadable<V> | "revert" | null, keepStaleValue?: boolean} = {}
    ): Generator<PromiseLike<any>, void, any> {
        const lc = getOrCompute(this._map, key, () => loadableContainer(pending));

        yield* lc.run(thisVal, fn, {onAbort, keepStaleValue});

        //Have to re-fetch from the map to do the check for deletion,
        //as a call to clear() could have gotten ordered inbetween the start of this method and here.
        //This can occur, for instance, with React Strict Mode double-mounts.
        const currentLc = this._map.get(key);
        if (currentLc?.processInstanceCount === 0 && currentLc.l.isPending()) {
            this._map.delete(key);
        }
    }

    public has(key: K): boolean { return this._map.has(key); }

    public set(key: K, value: V): this {
        this._map.set(key, loadableContainer(loaded(value)));
        return this;
    }

    public delete(key: K): boolean { return this._map.delete(key); }

    public get(key: K): Loadable<V> {
        return this._map.get(key)?.l ?? pending;
    }

    public keys(): IterableIterator<K> { return this._map.keys(); }

    public * values(): IterableIterator<Loadable<V>> {
        for (const lc of this._map.values()) yield lc.l;
    }

    public * entries(): IterableIterator<readonly [K, Loadable<V>]> {
        for (const [k, lc] of this._map.entries()) yield [k, lc.l];
    }

    public forEach(callback: (value: Loadable<V>, key: K, object: this) => void): void;
    public forEach<This>(
        callback: (this: This, value: Loadable<V>, key: K, object: this) => void,
        thisArg: This
    ): void;
    public forEach<This>(
        callback: (this: This, value: Loadable<V>, key: K, object: this) => void,
        thisArg?: This
    ): void {
        for (const [key, lc] of this._map) {
            callback.call(thisArg as This, lc.l, key, this);
        }
    }

    public merge(other: Iterable<readonly [K, V]>): this {
        this._map.merge(
            Sequence.from(other)
                .map(([k, v]) => [k, loadableContainer(loaded(v))])
                //Have to cast to a mutable data structure as MobX's API isn't const-correct
                .collectToArray() as [K, LoadableContainer<V>][]
        );
        return this;
    }

    public clear(): void { this._map.clear(); }

    /** If there is a loaded value for the given key, updates it with the provided function */
    public updateIfLoaded(key: K, update: (value: V) => V): void {
        if (this.get(key).isLoaded()) {
            this.set(key, update(this.get(key).getValue()));
        }
    }

    public [Symbol.iterator](): IterableIterator<readonly [K, Loadable<V>]> { return this.entries(); }
}
