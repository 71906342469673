import {useEffect, useRef} from "react";

/** Returns the value of the given variable during the last render */
export function usePreviousValue<T>(val: T): T | null {
    const previousValue = useRef<T | null>(null);

    useEffect(() => {
        previousValue.current = val;
    }, [val]);

    return previousValue.current;
}
