import {useQuery} from "react-query";
import LineItems from "~/repositories/line-items";
import {useCallback, useMemo, useState} from "react";
import {Range} from "~/utils/range";
import {BigNumber} from "bignumber.js";
import {Temporal} from "@js-temporal/polyfill";
import {GridSortModel} from "@mui/x-data-grid/models/gridSortModel";
import {first} from "lodash";
import {plainDateRangeToInstant} from "~/utils/temporal";

export interface LineItemFilter {
  reconciled?: boolean;
  amount?: Range<BigNumber>;
  description?: string;
  reference?: string;
  date?: Range<Temporal.PlainDate>|"";
}

export type LineItemFilterSetter = <K extends keyof LineItemFilter>(key: K, value: LineItemFilter[K]) => void;

export default function useLineItemQuery(backendId: string, defaultFilter?: LineItemFilter, defaultSort?: GridSortModel) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [sort, setSort] = useState<GridSortModel|undefined>(defaultSort);

  const [amount, setAmount] = useState<Range<BigNumber> | undefined>(defaultFilter?.amount);
  const [description, setDescription] = useState<string|undefined>(defaultFilter?.description);
  const [reference, setReference] = useState<string|undefined>(defaultFilter?.reference);
  const [reconciled, setReconciled] = useState<boolean|undefined>(defaultFilter?.reconciled);
  const [date, setDate] = useState<Range<Temporal.PlainDate>|""|undefined>(defaultFilter?.date);

  const filters = useMemo(() => ({
    description, reference, amount, date, reconciled
  }), [description, reference, amount, date, reconciled]);

  const setFilters = useCallback(<K extends keyof LineItemFilter>(key: K, value: LineItemFilter[K]) => {
    switch (key) {
      case "amount": return setAmount(value as any);
      case "date":   return setDate(value as any);
      case "description": return setDescription(value as any);
      case "reference":   return setReference(value as any);
      case "reconciled":  return setReconciled(value as any);
    }
  }, []);

  const params = {
    ...filters,
    date: filters.date ? plainDateRangeToInstant(filters.date) : undefined,
    page: page,
    perPage: pageSize,
    sortBy: first(sort)?.field,
    sortDir: first(sort)?.sort
  };
  const lineItemsQuery = useQuery(LineItems.list(backendId, params));

  return {query: lineItemsQuery, filters, setFilters, sort, setSort, page, setPage, pageSize, setPageSize};
}
