import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  MenuItem,
  Paper,
  Slide,
  Stack,
  SxProps,
  TextField,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, {ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {useMutation, useQuery} from "react-query";
import ReconSteps from "~/recon/components/recon-steps";
import Backends from "~/repositories/backends";
import {LineItem} from "./line-item";
import {ReconStagingProvider} from "./components/recon-staging";
import {IfDefined, RenderIf} from "./util";
import {NavSidebarStateContext} from "~/nav-sidebar";
import Recon from "~/recon/recon";
import {StripePayout} from "~/stripe-payouts/stripe-payout";
import {PreviousRecon} from "~/recon/components/previous-recon";
import Button from "@mui/material/Button";
import {usePreviousValue} from "~/utils/use-previous-value";
import {LineItemView} from "~/recon/components/recon-review";
import {useQueryParam} from "~/utils/use-query-param";
import {LineItemTable} from "~/recon/components/line-item-table";
import LineItems from "~/repositories/line-items";
import {LoadingButton} from "@mui/lab";


export const Panel = ({children, sx}: { children: ReactNode, sx?:SxProps }) => (
  <Paper elevation={1} square sx={{height: "100%", width: "100%", ...sx}}>
    <Container maxWidth={false} sx={{height: "inherit"}}>
      {children}
    </Container>
  </Paper>
);

const PreviousReconPanel = ({data, addPanelVisible, expandAddPanel}: {data: ExistingLineItem, addPanelVisible: boolean, expandAddPanel: () => void}) => {
  const {mutateAsync, isLoading, isError, isSuccess, error} = useMutation(LineItems.sync(data.lineItem!.id.toString()));
  const onSync = async (_: any) => await mutateAsync();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary aria-controls="previous-recons-content" id="previous-recons-header">
        <Typography variant="body2">Previous Recon</Typography>
      </AccordionSummary>
      <AccordionDetails sx={addPanelVisible ? {marginBottom: 18} : {}}>
        <PreviousRecon {...data}/>
        {!addPanelVisible && <Button variant="contained" onClick={expandAddPanel} color="primary" size="small" sx={{mt: 4, mr: 1}}>Add New Recon</Button>}
        <LoadingButton variant="contained" loading={isLoading} onClick={onSync} color={isSuccess ? "success" : "primary"} size="small" sx={{mt: 4, mr: 1}}>Sync With Wefunder</LoadingButton>
      </AccordionDetails>
    </Accordion>
  );
};

const ReconSecondary = ({lineItem, existingData}: {lineItem: LineItem, existingData: ExistingLineItem}) => {
  const previousRecons = existingData.recons.length > 0;
  const [addPanelVisible, setPanelVisible] = useState<boolean | null>(null);
  const previousLineItem = usePreviousValue(lineItem.id);
  useEffect(() => {
    if (addPanelVisible === null || previousLineItem !== lineItem.id) {
      setPanelVisible(!previousRecons);
    }
  }, [previousRecons, addPanelVisible, lineItem.id, previousLineItem]);

  return (
    <Stack spacing={1} sx={{height: "100%", width: "100%"}}>
      <ReconStagingProvider lineItem={lineItem} existingRecons={existingData.recons}>
        <Panel sx={{height: "min-content", pt: 4, pb: 4}}>
          <LineItemView lineItem={lineItem}/>
        </Panel>
        {previousRecons && <PreviousReconPanel data={existingData} expandAddPanel={() => setPanelVisible(true)} addPanelVisible={addPanelVisible || false}/>}
        {addPanelVisible && <Panel><ReconSteps/></Panel>}
      </ReconStagingProvider>
    </Stack>
  );
};

export interface PageLayout {
  primary: number;
  secondary: number;
}

export interface ExistingLineItem {
  lineItem: LineItem | null;
  recons: Recon[];
  stripePayout: StripePayout | null;
}

export const ReconPage = () => {
  const backendsQuery = useQuery(Backends.list);
  const [backendId, selectBackendId] = useQueryParam<string|undefined>("backend", undefined, {push: true, immediate: true});

  const [lineItem, setLineItem] = useState<LineItem | null>(null);
  const [existingData, setExistingData] = useState<ExistingLineItem>({recons: [], lineItem: null, stripePayout: null});

  const setLineItemAndRecons = useCallback(({lineItem, recons, stripePayout}: ExistingLineItem) => {
    setLineItem(lineItem);
    setExistingData({recons, lineItem, stripePayout});
  }, []);

  const onBackendSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    selectBackendId(e.target.value);
    setLineItem(null);
  };

  const currentBackend = backendsQuery.data?.data.find(b => b.id === backendId);

  const layout: PageLayout = (lineItem === null) ? {primary: 12, secondary: 0} : {primary: 4, secondary: 8};
  const {setCollapsed: setNavSidebarCollapsed} = useContext(NavSidebarStateContext);

  useEffect(() => {
    setNavSidebarCollapsed(lineItem?.id !== undefined);
  }, [lineItem?.id, setNavSidebarCollapsed]);

  return (
    <Grid container spacing={1} sx={{width: "100%", height: "100%"}}>
      <RenderIf cond={backendsQuery.isSuccess}>
        <Grid xs={layout.primary}>
          <Panel>
            <TextField
                select
                fullWidth
                label={"Escrow account"}
                value={currentBackend?.id || ""}
                onChange={onBackendSelected}
                SelectProps={{multiple: false}}
                sx={{mt: 3, mb: 2}}
            >
              {backendsQuery.data?.data.map(value =>
                <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
              )}
            </TextField>
            <RenderIf cond={currentBackend !== undefined}>
              <LineItemTable backend={currentBackend!} onSelected={setLineItemAndRecons}/>
            </RenderIf>
          </Panel>
        </Grid>
      </RenderIf>
      <Slide direction="left" in={lineItem !== null} mountOnEnter unmountOnExit timeout={150}>
        <Grid xs={layout.secondary}>
          <IfDefined value={lineItem}>
            <ReconSecondary lineItem={lineItem!} existingData={existingData}/>
          </IfDefined>
        </Grid>
      </Slide>
    </Grid>
  );
};
