import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";

export const BANK_ACCOUNT_TYPES = ["checking", "savings"] as const;

export type BankAccountType = (typeof BANK_ACCOUNT_TYPES)[number];

export class AchAccount extends JsonDto {
    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp()
    public readonly accountHolder!: string;

    @jsonProp()
    public readonly maskedAccountNumber!: string;

    @jsonProp(JsonTypes.stringUnion(BANK_ACCOUNT_TYPES))
    public readonly accountType!: BankAccountType;

    public constructor(init?: unknown) {
        super();
        this.init(AchAccount, init);
    }
}
