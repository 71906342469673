import * as React from "react";
import {styled, keyframes, css} from "@mui/system";
import SnackbarUnstyled from "@mui/base/SnackbarUnstyled";
import {ClickAwayListener} from "@mui/material";
import {blue, green, grey, red, yellow} from "@mui/material/colors";

const snackbarInRight = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

const StyledSnackbar = styled(SnackbarUnstyled)(
  ({theme, color}) => {
    const palette = getPalette(color);
    return css`
      position: fixed;
      z-index: 5500;
      display: flex;
      right: 16px;
      top: 16px;
      left: auto;
      justify-content: start;
      max-width: 560px;
      min-width: 300px;
      background-color: ${theme.palette.mode === "dark" ? palette[700] : palette[50]};
      border-radius: 8px;
      border: 1px solid ${theme.palette.mode === "dark" ? palette[400] : palette[400]};
      box-shadow: ${theme.palette.mode === "dark"
        ? "0 5px 13px -3px rgba(0,0,0,0.4)"
        : `0 5px 13px -3px ${grey[200]}`};
      padding: 0.75rem;
      color: ${theme.palette.mode === "dark" ? "#fff" : palette[700]};
      font-weight: 400;
      font-size: 14px;
      animation: ${snackbarInRight} 300ms;
      transition: transform 0.2s ease-out;
    `;
  }
);

const getPalette = (str: string | undefined) => {
  switch (str) {
    case "red":
      return red;
    case "yellow":
      return yellow;
    case "blue":
      return blue;
    case "green":
      return green;
    default:
      return blue;
  }
};

interface SnackbarCustomProps {
  autoHideDuration?: number;
  message: string;
  open: boolean;
  onClose: () => void;
  color: "blue" | "green" | "yellow" | "red";
}

export default function SnackbarCustom({open, onClose, message, color, autoHideDuration}: SnackbarCustomProps) {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <StyledSnackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose} color={color}>
        {message}
      </StyledSnackbar>
    </ClickAwayListener>
  );
}
