
import {LineItem} from "~/recon/line-item";
import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";

export class InferredRecons extends JsonDto {
    @jsonProp(JsonTypes.map(JsonTypes.dto(LineItem)))
    public readonly inferred!: { [key: string]: LineItem | null };

    public constructor(init?: unknown) {
        super();
        this.init(InferredRecons, init);
   }
}
