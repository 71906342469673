import {JsonDto, jsonName, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {MaskedWireInfo} from "~/backend/MaskedWireInfo";

export class Backend extends JsonDto {
    public static readonly TYPES = ["internal_escrow", "internal_source", "external_bank", "external_stripe", "wire_in", "wire_out", "corporate", "company"] as const;

    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp()
    public readonly name!: string;

    @jsonProp(JsonTypes.stringUnion(Backend.TYPES))
    public readonly backendType!: Backend.Type;

    @jsonProp(JsonTypes.dto(MaskedWireInfo), {optional: true})
    public readonly wireInfo!: MaskedWireInfo | null;

    public constructor(init?: unknown) {
        super();
        this.init(Backend, init);
    }
}

export namespace Backend {
    export type Type = (typeof Backend.TYPES)[number];
}
