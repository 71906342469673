import {experimental_sx as sx} from "@mui/material";

import {ComponentOverrides} from "./mui-theme-utils";

export {MenuItem, MenuItemProps} from "@mui/material";

export const menuItemOverrides: ComponentOverrides = {
    MuiMenuItem: {
        styleOverrides: {
            root: sx({
                fontSize: "inherit"
            })
        }
    }
};
