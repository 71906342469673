import {VirtualAccountSummary} from "~/account/virtual-account-summary";
import React from "react";
import {Link} from "~/components/link";
import {Box, Divider, IconButton, Popover, Stack, Typography} from "@mui/material";
import {Icon} from "~/components/icon";
import DetailGrid from "~/utils/detail-grid";
import {VirtualAccount, VirtualAccountRole} from "~/account/virtual-account";
import {startCase} from "lodash";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {EntityDisplay} from "~/entity/components/entity-display";

const wefunder = "https://wefunder.com";
const RoleComponent = ({role, sx}: {role: VirtualAccountRole, sx?: SxProps<Theme>}) => {
  switch (role.type) {
    case "investment":
      return (<Link href={`${wefunder}/admin/investments/${role.wfInvestmentId}`} target="_blank" sx={sx}>{role.wfInvestmentId}</Link>);
    case "payment_method":
    case "wallet":
      return (<Typography variant="body2" sx={sx}>User ID {role.wfUserId}</Typography>);
    case "adjustments":
    case "service_fees":
    case "sink":
    case "source":
      return (<Typography variant="body2" sx={sx}>{JSON.stringify(role)}</Typography>);
    default:
      return null;
  }
};

export const VirtualAccountInfo = ({account}: {account: VirtualAccountSummary}) => (
  <Stack>
    <DetailGrid>
      <Typography variant="body2" color="text.secondary">Id:</Typography>
      <Typography variant="body2">{account.id}</Typography>

      <Typography variant="body2" color="text.secondary">Name:</Typography>
      <Typography variant="body2">{account.name}</Typography>

      <Typography variant="body2" color="text.secondary">Currency:</Typography>
      <Typography variant="body2">{account.currency}</Typography>

      <Typography variant="body2" color="text.secondary">Enabled:</Typography>
      <Typography variant="body2">{account.enabled ? "Yes" : "No"}</Typography>

      <Typography variant="body2" color="text.secondary">Backend:</Typography>
      <Link to={`/backends/${account.backendId}`}>{account.backendId}</Link>

      <Typography variant="body2" color="text.secondary">{startCase(account.accountRole.type)}:</Typography>
      <RoleComponent role={account.accountRole} sx={{pl: 2}}/>
    </DetailGrid>
    <Divider/>
    <EntityDisplay model={account as VirtualAccount} entities={["investment", "fundraise", "company", "investor"]}/>
  </Stack>
);

const VirtualAccountLinkInfo = ({account}: {account: VirtualAccountSummary}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);
  const toggleFn = open ? handlePopoverClose : handlePopoverOpen;

  return (
    <div>
      <Stack direction="row" spacing={1}>
        <IconButton color="warning" size="small" onClick={toggleFn} title={"Detailed view"}>
            <Icon fa="info"/>
        </IconButton>
        <Link to={`/accounts/${account.id}`}>
          {account.name} ({account.id})
        </Link>
      </Stack>

      <Popover
          id={`account-${account.id}-popover`}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{vertical: "bottom", horizontal: "left"}}
          transformOrigin={{vertical: "top", horizontal: "left"}}
          onClose={handlePopoverClose}
          disableRestoreFocus
      >
        <Box sx={{p: 4}}>
          <IconButton sx={{position: "absolute", right: 4, top: 4}} onClick={handlePopoverClose}>
            <Icon fa="close"/>
          </IconButton>
          <VirtualAccountInfo account={account}/>
        </Box>
      </Popover>
    </div>);
};

export const VirtualAccountPreviewOpt = (data: VirtualAccountSummary | null) => data && <VirtualAccountLinkInfo account={data}/>;

export default VirtualAccountLinkInfo;
