import React, {ForwardedRef, forwardRef, ReactNode} from "react";

import {
    Box,
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
    experimental_sx as sx,
    IconButton as MuiIconButton,
    IconButtonProps as MuiIconButtonProps
} from "@mui/material";

import {ComponentOverrides} from "./mui-theme-utils";
import {Tooltip, TooltipProps} from "./tooltip";
import {Span} from "~/utils/styles";

//LoadingButton is annoyingly a separate component. I'm on the fence on whether the lightweight variant makes sense
//for it, so I'm saving myself a bit of work and skipping it for now. I might add it later.
//It already has a "start" slot built-in.
export {LoadingButton, LoadingButtonProps} from "@mui/lab";

//I am still setting its default variant to outlined
export const loadingButtonOverrides: ComponentOverrides = {
    MuiLoadingButton: {
        defaultProps: {
            variant: "outlined"
        }
    }
};

declare module "@mui/material" {
    interface ButtonPropsVariantOverrides {
        //Recreating the "lightweight" appearance from FAST
        lightweight: true;
    }
}

export const buttonOverrides: ComponentOverrides = {
    MuiButton: {
        defaultProps: {
            //Text buttons aren't as obviously buttons out of context.
            //Something with a visible border is a more usable default, IMO.
            variant: "outlined"
        },

        styleOverrides: {
            root: sx({
                whiteSpace: "nowrap"
            })
        }
    }
};

//This needs to be a separate interface for the definition of ButtonLink
export interface ButtonPropExtensions {
    //TODO: Somehow I completely missed that the MUI button component already has a startIcon slot,
    //so the wrapper should be refactored to remove this unnecessary extra one
    /** Content to place prior to the main button content */
    readonly start?: ReactNode;
}

//Omit link-related props from the MUI button, as links need to use the Link component for router integration
export type ButtonProps = Omit<MuiButtonProps, "href" | "LinkComponent"> & ButtonPropExtensions;
export const Button = forwardRef(function Button({
    variant,
    sx = [],
    start,
    children,
    ...props
}: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) {
    const content = <>
        {start &&
            //Give the start container an invisible border to line it up with the main content
            <Box sx={{display: "inline-block", mr: 1, borderBottom: 2, borderTop: 2, borderColor: "transparent"}}>
                {start}
            </Box>
        }
        <Box className="PcButton-mainContent" sx={{display: "inline-block"}}>{children}</Box>
    </>;

    if (variant === "lightweight")
        //MUI allows defining custom variants as part of the theming system,
        //but custom variants can't override props, and I need to disable the ripple effect for this variant
        return <MuiButton
            ref={ref}
            //Using the text variant as the base
            variant="text"
            disableRipple
            sx={[{
                pl: 0,
                pr: 0,
                background: "transparent",
                textTransform: "none",

                "& .PcButton-mainContent": {
                    lineHeight: "1",

                    //Start with a transparent border to avoid a slight jump
                    borderBottom: 2,
                    //Also include a top border to keep things centered
                    borderTop: 2,
                    borderColor: "transparent"
                },

                "&:hover": {
                    background: "transparent",
                    color: "primary.light",

                    "& .PcButton-mainContent": {
                        borderBottomColor: "currentColor"
                    }
                },

                //The default focus style for MUI buttons uses a ripple effect, which isn't appropriate for a
                //link-style button, so I'm applying the system focus ring, which is what MUI Links do
                "&.Mui-focusVisible": {
                    outline: "revert"
                },

                "&:active": {
                    background: "transparent",
                    color: "primary.dark"
                },

                "& .MuiButton-startIcon, & .MuiButton-endIcon": {
                    "& .PcIcon-root": {
                        fontSize: "inherit"
                    }
                }
            }, sx].flat()}
            {...props}
        >
            {content}
        </MuiButton>;
    else return <MuiButton ref={ref} variant={variant} sx={sx} {...props}>{content}</MuiButton>;
});

export interface IconButtonProps extends MuiIconButtonProps {
    readonly title: string;

    /**
     * If true, an MUI tooltip appears automatically when the button is hovered or focused.
     * By default, a simple HTML `title` is used.
     */
    readonly autoTooltip?: boolean;

    readonly tooltipProps?: TooltipProps;
}

export const IconButton = forwardRef(function IconButton({
    title,
    autoTooltip,
    tooltipProps,
    className,
    sx = [],
    ...iconButtonProps
}: IconButtonProps, ref: ForwardedRef<HTMLButtonElement>) {
    if (autoTooltip) return (
        <Tooltip title={title} {...tooltipProps}>
            {/* span so that the tooltip still works when the button is disabled */}
            <Span className={className} width="min-content" height="min-content" sx={sx}>
                <MuiIconButton ref={ref} aria-label={title} {...iconButtonProps}/>
            </Span>
        </Tooltip>
    );
    else return (
        // Wrap in a span so the className/sx target is consistent with the above
        <Span className={className} width="min-content" height="min-content" sx={sx}>
            <MuiIconButton ref={ref} title={title} {...iconButtonProps}/>
        </Span>
    );
});
