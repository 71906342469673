import {AchBatchItem} from "./ach-batch-item";
import {Backend} from "~/backend/backend";
import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {Temporal} from "@js-temporal/polyfill";

export class AchBatch extends JsonDto {
    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp(JsonTypes.string, {optional: true})
    public readonly fileUrl!: string | null;

    @jsonProp()
    public readonly origin!: Backend;

    @jsonProp(JsonTypes.array(JsonTypes.dto(AchBatchItem)))
    public readonly items!: readonly AchBatchItem[];

    @jsonProp()
    public readonly createdAt!: Temporal.Instant;

    @jsonProp(JsonTypes.instant, {optional: true})
    public readonly generatedAt!: Temporal.Instant | null;

    @jsonProp(JsonTypes.instant, {optional: true})
    public readonly submittedAt!: Temporal.Instant | null;

    public isGenerated(): this is {readonly generatedAt: Temporal.Instant} { return !!this.generatedAt; }

    public isSubmitted(): this is {readonly submittedAt: Temporal.Instant} { return !!this.submittedAt; }

    public constructor(init?: unknown) {
        super();
        this.init(AchBatch, init);
    }
}
