import {useLayoutEffect} from "react";

import {ColumnMap, ColumnOrder} from "./table-columns";
import {HookValueTuple} from "~/utils/hook-utils";
import {useLocalStorage} from "~/utils/use-local-storage";
import {omit, pick} from "lodash";

/**
 * A hook that can be used for persisting column order to LocalStorage
 *
 * @param key - The key to store the column order under
 * @param columns - The columns dictionary for the table
 * @param optionalColumns
 * If there are any columns that may not appear in the dictionary
 * but should still be preserved in the order, list them here
 * @param hiddenColumns
 * The list of hidden columns.
 *
 * @returns The current column order, including hidden columns
 */
export function useLocalStorageColumnOrder<TCols extends ColumnMap<never>>(
    key: string,
    columns: TCols,
    optionalColumns: readonly (keyof TCols & string)[] = [],
    hiddenColumns: readonly (keyof TCols)[] = []
): HookValueTuple<ColumnOrder<keyof TCols & string>> {
    const [columnOrder, setColumnOrder] = useLocalStorage<ColumnOrder<keyof TCols & string>>(
        key,
        () => {
            const visibleColumns = Object.keys(omit(columns, hiddenColumns)) as ColumnOrder<keyof TCols & string>;
            const invisibleColumns = Object.keys(pick(columns, hiddenColumns)).map(key => ({hidden: key}));

            return [...visibleColumns, ...invisibleColumns];
        },
        {parser: JSON.parse, serializer: JSON.stringify}
    );

    //Make sure that if any columns get added/removed we don't require the user to manually clear their localStorage.
    //We should only need to do this on initial render.
    useLayoutEffect(() => {
        const updatedColumnOrder = [...columnOrder];
        const allColumns = new Set([...Object.keys(columns), ...optionalColumns]);
        const columnsInOrder = new Set(columnOrder.map(col => typeof col === "string" ? col : col.hidden));
        let updatesToSave = false;

        let colIndex = 0;
        for (const col of allColumns) {
            if (!columnsInOrder.has(col)) {
                updatedColumnOrder.splice(colIndex, 0, col);
                updatesToSave = true;
            }

            colIndex++;
        }

        for (let i = 0; i < columnOrder.length; i++) {
            const col = columnOrder[i];
            if (!allColumns.has(typeof col === "string" ? col : col.hidden)) {
                updatedColumnOrder.splice(i--, 1);
                updatesToSave = true;
            }
        }

        if (updatesToSave) {
            setColumnOrder(updatedColumnOrder);
        }
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    return [columnOrder, setColumnOrder];
}
