import {Stack} from "@mui/material";
import Paper from "@mui/material/Paper";
import {first, sortBy, startCase} from "lodash";
import React from "react";
import BasicTable from "~/components/basic-table";
import {LabeledItem} from "~/components/labeled-item";
import {IfDefined} from "~/recon/util";
import {CompanyDetails, FundraiseDetails} from "~/transfer/views/transfer-view";
import {MoneyFormat} from "~/utils/money";
import {TransferEvent} from "../transfer/transfer-event";
import {Disbursement} from "./disbursement";

export const DisbursementTransferView = ({transfer}: {transfer: Disbursement}) => {
  const events = sortBy(transfer.events, e => e.id);
  const firstEvent = first(events);
  const eventsColumns = [
    {id: "id", title: "ID"},
    {id: "state", title: "State"},
    {id: "message", title: "Message"},
    {id: "occuredAt", title: "Occured At", valueGetter: (v: TransferEvent) => v.occurredAt.toLocaleString()}
  ];

  return (
    <Paper sx={{p: 2}}>
      <Stack direction="column">
        <LabeledItem label="Amount">
          {MoneyFormat.formatBigNumber(transfer.amount, transfer.currency)}
        </LabeledItem>
        <LabeledItem label="State">
          {startCase(transfer.state)}
        </LabeledItem>
        <LabeledItem label="Tag">
          {startCase(transfer.tag)}
        </LabeledItem>
        <IfDefined value={transfer.intentionData.reference}>
          <LabeledItem label="Reference">
            {transfer.intentionData.reference}
          </LabeledItem>
        </IfDefined>

        <IfDefined value={firstEvent?.occurredAt}>
          <LabeledItem label="Created At">
            {firstEvent?.occurredAt.toLocaleString()}
          </LabeledItem>
        </IfDefined>

        <LabeledItem label="Backend">
          #{transfer.backend.id} {transfer.backend.name} - {startCase(transfer.backend.backendType)}
        </LabeledItem>

        <IfDefined value={transfer.fundraise}>
          <FundraiseDetails fundraise={transfer.fundraise!}/>
        </IfDefined>

        <IfDefined value={transfer.company}>
          <CompanyDetails company={transfer.company!}/>
        </IfDefined>

        <LabeledItem label="Events">
          <BasicTable columns={eventsColumns} rows={events}/>
        </LabeledItem>
      </Stack>
    </Paper>
  );
};

