import {useRef, useState} from "react";

import {bindAll} from "lodash";

import {getInitialValue, Initializer} from "./hook-utils";

/** A subclass of Set that accepts a notify function for use with React change detection */
export class HookedSet<T> extends Set<T> {
    //Return regular Maps for derived collections
    public override get [Symbol.species]() { return Set; }

    public constructor(private readonly notify: () => void, data?: Iterable<T> | null | undefined) {
        super(data);
        bindAll(this, "add", "clear", "delete", "has");
    }

    public override add(value: T): this {
        const hadValue = this.has(value);

        super.add(value);

        if (!hadValue) {
            //notify might not be defined yet if we're here from the constructor
            //eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            this.notify?.();
        }

        return this;
    }

    public override delete(value: T): boolean {
        const deleted = super.delete(value);

        if (deleted) {
            this.notify();
        }

        return deleted;
    }

    public override clear(): void {
        const wasEmpty = this.size > 0;

        super.clear();

        if (!wasEmpty) {
            this.notify();
        }
    }
}

/**
 * Allows efficiently using a Set in functional components
 *
 * @param init - Initial data for the Set, or a function returning initial data. Can be null or undefined.
 *
 * @returns
 * A Set that will cause a re-render when modified.
 * The methods "add", "clear", "delete", and "has" are automatically bound.
 * The Set itself is a stable reference.
 * If you need to propagate re-renders from this Set to memoized components, you can pass set.atom as a dependency.
 */
export function useSet<T>(init?: Initializer<Iterable<T> | null | undefined>): Set<T> & {readonly atom: symbol} {
    //Change a symbol reference to notify React
    const [atom, setAtom] = useState(Symbol());
    const ref = useRef<Set<T> & {atom: symbol} | null>(null);
    ref.current ??= Object.assign(new HookedSet(() => setAtom(Symbol()), getInitialValue(init)), {atom});
    ref.current.atom = atom;
    return ref.current;
}
