import {BigNumber} from "bignumber.js";

import {Transfer} from "./transfer";
import {TransferCreationDto} from "./transfer-creation-dto";
import {TransferSummary} from "./transfer-summary";
import {BaseApi} from "~/api/base-api";
import {PageParams, RangeParams, SortDirection} from "~/api/common-query-params";
import {FetchClient} from "~/api/fetch-client";
import {PaginationEnvelope} from "~/api/pagination-envelope";
import {FundraiseExemption, FundraiseState} from "~/entity/wefunder-types";
import {autobindAll} from "~/utils/autobind";

export type TransferFilterParams = RangeParams<BigNumber, "amount"> & {
    readonly fromAccountId?: string,
    readonly toAccountId?: string,
    readonly relatedAccountId?: string,
    readonly intention?: Transfer.Intention,
    readonly investmentId?: string,
    readonly fundraiseId?: string,
    readonly fundraiseExemption?: FundraiseExemption,
    readonly fundraiseState?: FundraiseState,
    readonly companyId?: string,
    readonly companyName?: string,
    readonly investorId?: string,
    readonly investorName?: string,
    readonly investorAdmin?: boolean
};

export const TRANSFER_SORT_KEYS = [{key: "id"}, {key: "intention"}, {key: "amount"}, {key: "fromAccountId"}, {key: "toAccountId"}, {key: "state"}, {key: "tag"}] as const;

export type TransferSortKey = (typeof TRANSFER_SORT_KEYS)[number]["key"];

export interface DefaultSort {
  key: TransferSortKey;
  dir: SortDirection;
}

/** API for managing Transfers */
@autobindAll
export class TransferApi extends BaseApi<Transfer, TransferSummary> {
    public constructor(fetchClient: FetchClient, errorHandler: (err: Error) => boolean) {
        super(fetchClient, errorHandler, "/transfers", Transfer, TransferSummary);
    }

    /** Gets all Transfer trees */
    public async getTransfers(
        params: PageParams & TransferFilterParams,
        signal?: AbortSignal
    ): Promise<PaginationEnvelope<TransferSummary[]>> {
        return await this.find(params, signal);
    }

    /** Gets the full details of a Transfer by ID */
    public async getTransfer(id: string, signal?: AbortSignal): Promise<Transfer> {
        return await this.get(id, signal);
    }

    /** Creates a new Transfer */
    public async createTransfer(dto: TransferCreationDto, signal?: AbortSignal): Promise<Transfer> {
        //Only RecoverableTransfers can be directly created
        return await this.create(dto, signal);
    }

    /** Starts a pending Transfer */
    public async startTransfer(id: string, signal?: AbortSignal): Promise<void> {
        return await this.fetchVoid("POST", `${id}/start`, {signal});
    }

    /** Cancels a pending or a running Transfer */
    public async cancelTransfer(id: string, signal?: AbortSignal): Promise<void> {
        return await this.fetchVoid("POST", `${id}/cancel`, {signal});
    }
}
