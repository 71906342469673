import {BigNumber} from "bignumber.js";
import {Currency} from "~/currency";
import Recon from "~/recon/recon";
import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {Temporal} from "@js-temporal/polyfill";
import {compact} from "lodash";
import {StripePayout} from "~/stripe-payouts/stripe-payout";

export class LineItemEntryImpl extends JsonDto {
  public static get [JsonDto.subtypes]() {
    return {
      vitesse_account_entry: LineItemEntry.VitesseEntry,
      svb_account_entry: LineItemEntry.SVBAccountEntry
    };
  }

  public constructor(init?: unknown) {
    super();
    this.init(LineItemEntryImpl, init);
  }
}

export type LineItemEntry =
  | LineItemEntry.VitesseEntry
  | LineItemEntry.SVBAccountEntry;

export namespace LineItemEntry {
  export class VitesseEntry extends LineItemEntryImpl {
    public constructor(init?: unknown) {
      super();
      this.init(VitesseEntry, init);
    }

    public readonly type = "vitesse_account_entry";

    @jsonProp(JsonTypes.id)
    public readonly accountEntryId!: string;

    @jsonProp(JsonTypes.id)
    public readonly accountId!: string;

    @jsonProp(JsonTypes.string)
    public readonly entryType!: string;

    @jsonProp(JsonTypes.string, {optional: true})
    public readonly narrative?: string;

    @jsonProp(JsonTypes.string, {optional: true})
    public readonly reference1!: string | null;

    @jsonProp(JsonTypes.string, {optional: true})
    public readonly reference2!: string | null;
  }

  export class SVBAccountEntry extends LineItemEntryImpl  {
    public constructor(init?: unknown) {
      super();
      this.init(SVBAccountEntry, init);
    }
    public readonly type = "svb_account_entry";
  }

  export function isVitesseEntry(entry: VitesseEntry | SVBAccountEntry): entry is VitesseEntry {
    return entry.type === "vitesse_account_entry";
  }

}

export const LineItemTargetTypes = ["deposit_target", "epay_transaction_target", "stripe_payout_target", "vitesse_transaction_target", "disbursement_wire_target"];

export class LineItemTarget extends JsonDto {
  @jsonProp(JsonTypes.stringUnion(LineItemTargetTypes))
  public readonly type!: string;

  @jsonProp(JsonTypes.id, {optional: true})
  public readonly vitesseTransactionId!: string | null;

  @jsonProp(JsonTypes.id, {optional: true})
  public readonly stripePayoutId!: string | null;

  public constructor(init?: unknown) {
    super();
    this.init(LineItemTarget, init);
  }
}

export class LineItem extends JsonDto {
  @jsonProp(JsonTypes.number)
  public readonly id!: number;

  @jsonProp(JsonTypes.id)
  public readonly backendId!: string;

  @jsonProp(JsonTypes.stringUnion(["usd", "eur"]))
  public readonly currency!: Currency;

  @jsonProp()
  public readonly amount!: BigNumber;

  @jsonProp()
  public readonly date!: Temporal.Instant;

  @jsonProp(JsonTypes.dto(LineItemTarget), {optional: true})
  public readonly target!: LineItemTarget | null;

  @jsonProp(JsonTypes.dto(LineItemEntryImpl))
  public readonly entry!: LineItemEntry;

  public statusColor?: string;

  public constructor(init?: unknown) {
      super();
      this.init(LineItem, init);
  }

  public lineItemType(): (LineItemType | null) {
    switch (this.target?.type) {
      case "deposit_target":
        return LineItemType.Deposit;
      case "epay_transaction_target":
        return LineItemType.EpayTransaction;
      case "stripe_payout_target":
        return LineItemType.StripePayout;
      case "vitesse_transaction_target":
        return LineItemType.VitesseTransaction;
      case "disbursement_wire_target":
        return LineItemType.DisbursementWire;
      default:
        return null;
    }
  }
}

export class LineItemWithRecons extends JsonDto {
  @jsonProp(JsonTypes.dto(LineItem))
  public readonly lineItem!: LineItem;

  @jsonProp(JsonTypes.array(JsonTypes.dto(Recon)))
  public readonly recons!: Recon[];

  @jsonProp(JsonTypes.dto(StripePayout), {optional: true})
  public readonly stipePayout!: StripePayout | null;

  @jsonProp(JsonTypes.bigNumber)
  public readonly reconAmount!: BigNumber;

  public constructor(init?: unknown) {
    super();
    this.init(LineItemWithRecons, init);
  }
}

export enum LineItemType {
  StripePayout = "stripe_payout",
  Deposit = "deposit",
  VitesseTransaction = "vitesse_transaction",
  EpayTransaction = "epay_transaction",
  DisbursementWire = "disbursement_wire"
}

export function getLineItemDescription(lineItem: LineItem) {
  const entry = lineItem.entry;
  if (LineItemEntry.isVitesseEntry(entry)) {
    return compact([entry.narrative, entry.reference1, entry.reference2]).join("; ");
  } else {
    return "N/A";
  }
}

export function getLineItemBank(lineItem: LineItem) {
  const entry = lineItem.entry;
  if (LineItemEntry.isVitesseEntry(entry)) {
    return "Vitesse";
  } else {
    return "SVB";
  }
}
