import {action, flow, makeObservable} from "mobx";

import {TransferFilterParams} from "./api";
import {Transfer} from "./transfer";
import {TransferCreationDto} from "./transfer-creation-dto";
import {TransferSummary} from "./transfer-summary";
import {Api} from "~/api/api";
import {PageParams} from "~/api/common-query-params";
import {mapPageData, PaginationEnvelope} from "~/api/pagination-envelope";
import {constructorDependencies} from "~/utils/di";
import {Loadable, loadableContainer, loading, pending} from "~/utils/loadable";
import {LoadableMap} from "~/utils/loadable/loadable-map";
import {awt} from "~/utils/mobx";
import {ObservableOrderableMap, ReadonlyOrderableMap} from "~/utils/orderable-map";
import {Sequence} from "~/utils/sequence";

@constructorDependencies
export class TransferStore {
    private readonly _transfers = loadableContainer.eager<
        PaginationEnvelope<ObservableOrderableMap<string, TransferSummary>>
    >(loading);

    public get transfers(): Loadable.Eager<PaginationEnvelope<ReadonlyOrderableMap<string, TransferSummary>>> {
        return this._transfers.l;
    }

    private readonly transferDetails = new LoadableMap<string, Transfer>();

    private readonly _transferCreationStatus = loadableContainer<Transfer>(pending);

    public get transferCreationStatus(): Loadable<Transfer> { return this._transferCreationStatus.l; }

    private readonly externalProcessRefreshStatusMap = new LoadableMap<`${string}/${"incoming" | "outgoing"}`, void>();

    public constructor(private readonly api: Api) {
        makeObservable(this, undefined, {name: "TransferStore"});
    }

    @flow.bound
    public * fetchTransfers(params: PageParams & TransferFilterParams, signal?: AbortSignal) {
        yield* this._transfers.run(this, function*() {
            const page = yield* awt(this.api.transfers.getTransfers(params, signal));
            return mapPageData(page, d => Sequence.from(d)
                .keyBy("id")
                .collectToObservableOrderableMap({name: "transfers", deep: false})
            );
        }, {keepStaleValue: true});
    }

    @flow.bound
    public * fetchTransferDetails(id: string, signal?: AbortSignal) {
        yield* this.transferDetails.run(this, id, function*() {
            return yield* awt(this.api.transfers.getTransfer(id, signal));
        }, {keepStaleValue: true});
    }

    public getTransferDetails(id: string): Loadable<Transfer> {
        return this.transferDetails.get(id);
    }

    @action.bound
    public clearBatchDetails(...ids: string[]) {
        if (ids.length === 0) {
            this.transferDetails.clear();
        }
        else {
            ids.forEach(id => this.transferDetails.delete(id));
        }
    }

    @flow.bound
    public * createTransfer(dto: TransferCreationDto, signal?: AbortSignal) {
        yield* this._transferCreationStatus.run(this, function*() {
            const transfer = yield* awt(this.api.transfers.createTransfer(dto, signal));
            //Insert the new account at the start of the list so we know it's visible
            this._transfers.l.valueOrNull()?.data.set(transfer.id, transfer, "start");
            return transfer;
        }, {onAbort: pending});
    }

    @flow.bound
    public * startTransfer(transferId: string, signal?: AbortSignal) {
        yield* awt(this.api.transfers.startTransfer(transferId, signal));
        yield* this.fetchTransferDetails(transferId, signal);
    }

    @flow.bound
    public * cancelTransfer(transferId: string, signal?: AbortSignal) {
        yield* awt(this.api.transfers.cancelTransfer(transferId, signal));
        this.fetchTransferDetails(transferId, signal);
    }

    @action.bound
    public resetTransferCreationStatus() {
        this._transferCreationStatus.l = pending;
    }

    public getExternalProcessRefreshStatus(transferId: string, process: "incoming" | "outgoing"): Loadable<void> {
        return this.externalProcessRefreshStatusMap.get(`${transferId}/${process}`);
    }

    @action.bound
    public clearExternalProcessRefreshStatus(...ids: [transferId: string, process?: "incoming" | "outgoing"][]) {
        if (ids.length === 0) {
            this.externalProcessRefreshStatusMap.clear();
        }
        else {
            for (const [transferId, _process] of ids) {
                for (const process of _process ? [_process] : ["incoming", "outgoing"] as const) {
                    this.externalProcessRefreshStatusMap.delete(`${transferId}/${process}`);
                }
            }
        }
    }
}
