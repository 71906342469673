import React, {
    ForwardedRef,
    forwardRef,
    ReactNode,
    RefObject,
    useCallback,
    useImperativeHandle,
    useLayoutEffect,
    useState
} from "react";

import {Drawer as MuiDrawer, Stack, SxProps, Theme, useMediaQuery} from "@mui/material";

import {AppHeader} from "./app-header";
import {IconButton} from "./button";
import {Icon} from "./icon";
import {LazyJsx, renderLazy} from "~/utils/lazy-jsx";
import {useRenderRef} from "~/utils/use-render-ref";

//This component is very much a higher-level abstraction tailored to this application.
//As such I'm not directly extending the MUI props interface but exposing props one-by-one.

//There are situations where there might be a need to control open/pinned state without affecting focus,
//so I'm exposing that state as optional controlled props. However, I'm still providing an imperative handle
//which should be used when opening the drawer based on a user action and will focus the drawer root.

const DRAWER_MIN_PX = 450;
export const DRAWER_MIN_WIDTH = `${DRAWER_MIN_PX}px`;

//I want to require the drawer width to be a fixed value (either in vw or px),
//but I want to allow custom widths and simply provide a few standard ones as constants

export type DrawerWidth = `${number}${"vw" | "px"}`;

export namespace DrawerWidth {
    export const NARROW = "30vw";
    export const MEDIUM = "45vw";
    export const FULL = "90vw";
}

export type DrawerHeight = "scroll" | "fixed";

export interface DrawerHandle {
    /**
     * Opens the drawer and focuses it.
     * Should be used instead of directly setting the open prop when responding to user input to open the drawer.
     */
    readonly open: () => void;

    /** Closes the drawer */
    readonly close: () => void;

    /** Focuses the drawer if it is currently open. Otherwise, does nothing. */
    readonly focusIfOpen: () => void;

    /** Toggles the pinned state of the drawer */
    readonly togglePinned: () => void;
}

export interface DrawerProps {
    readonly open?: boolean;
    readonly defaultOpen?: boolean;
    readonly onOpenChange?: (open: boolean) => void;
    readonly hideCloseButton?: boolean;
    readonly pinned?: boolean;
    readonly defaultPinned?: boolean;
    readonly onPinnedChange?: (pinned: boolean) => void;
    readonly hidePinButton?: boolean;
    readonly renderWhenClosed?: boolean;
    readonly width?: DrawerWidth;
    readonly height?: DrawerHeight;
    readonly pinnedWidth?: DrawerWidth;
    readonly unpinnedWidth?: DrawerWidth;
    readonly elevation?: number;
    readonly sx?: SxProps<Theme>;
    readonly title?: LazyJsx;
    readonly subtitle?: LazyJsx;
    readonly renderHeaderContents?: () => ReactNode;
    readonly actions?: LazyJsx;
    readonly children?: LazyJsx;

    /** Reserved for use by the Page component */
    readonly _onPinnedOpenChange?: (pinnedOpen: boolean) => void;

    /** Reserved for use by the Page component */
    readonly _pageHeaderRef?: RefObject<HTMLDivElement>;

    /** Reserved for use by the Page component */
    readonly _onWidthChange?: (width: string) => void;
}

/** Drawer component intended to appear at the right-hand side of a page */
export const Drawer = forwardRef(function Drawer({
    open: openProp,
    defaultOpen = false,
    onOpenChange,
    hideCloseButton = false,
    pinned: pinnedProp,
    defaultPinned = false,
    onPinnedChange,
    hidePinButton = false,
    renderWhenClosed = false,
    width: defaultWidth = DrawerWidth.NARROW,
    pinnedWidth = defaultWidth,
    unpinnedWidth = defaultWidth,
    height: heightSetting = "scroll",
    elevation = 16,
    sx = [],
    title,
    subtitle,
    renderHeaderContents,
    actions,
    children,
    _onPinnedOpenChange,
    _pageHeaderRef,
    _onWidthChange
}: DrawerProps, ref: ForwardedRef<DrawerHandle>) {
    const drawerContentRoot = useRenderRef<HTMLDivElement>(null);

    const [_open, setOpen] = useState(defaultOpen);
    const open = openProp ?? _open;

    const [_renderContents, setRenderContents] = useState(open);
    const renderContents = renderWhenClosed || _renderContents;

    useLayoutEffect(() => {
        if (open) {
            setRenderContents(true);
        }
    }, [open, setRenderContents]);

    const onDrawerExited = useCallback(() => setRenderContents(false), [setRenderContents]);

    //Disable pinning if the drawer would leave less than 750px of remaining space
    const MIN_REMAINING_PX = 750;
    const [, pinnedWidthValueStr, pinnedWidthUnit] = /^(\d+)(vw|px)$/u.exec(pinnedWidth) ?? [];
    const pinnedWidthValue = Number.parseInt(pinnedWidthValueStr);
    const minScreenWidth = pinnedWidthUnit === "vw" ?
        //Had to use WolframAlpha for this, lmao
        pinnedWidthValue < 100 * DRAWER_MIN_PX / (DRAWER_MIN_PX + MIN_REMAINING_PX) ?
            MIN_REMAINING_PX + DRAWER_MIN_PX :
            -(100 * MIN_REMAINING_PX / (pinnedWidthValue - 100)) :
        MIN_REMAINING_PX + Math.max(pinnedWidthValue, DRAWER_MIN_PX);
    const canPin = useMediaQuery(`(min-width: ${minScreenWidth}px)`);

    const [_pinned, setPinned] = useState(defaultPinned);
    const pinned = canPin && (pinnedProp ?? _pinned);

    useLayoutEffect(() => {
        _onPinnedOpenChange?.(pinned && open);
    }, [_onPinnedOpenChange, open, pinned]);

    const width = `max(${pinned ? pinnedWidth : unpinnedWidth}, ${DRAWER_MIN_WIDTH})`;
    useLayoutEffect(() => _onWidthChange?.(width), [_onWidthChange, width]);

    const openDrawer = useCallback(() => {
        onOpenChange?.(true);

        if (openProp === undefined) {
            setOpen(true);
        }

        setTimeout(() => drawerContentRoot.current!.focus(), 0);
    }, [onOpenChange, openProp, setOpen, drawerContentRoot]);

    const closeDrawer = useCallback(() => {
        onOpenChange?.(false);

        if (openProp === undefined) {
            setOpen(false);
        }
    }, [onOpenChange, openProp, setOpen]);

    const onClose = useCallback(() => closeDrawer(), [closeDrawer]);

    const togglePinned = useCallback(() => {
        onPinnedChange?.(!pinned);

        if (pinnedProp === undefined) {
            setPinned(!pinned);
        }
    }, [onPinnedChange, pinned, pinnedProp]);

    const focusIfOpen = useCallback(() => {
        if (open) {
            drawerContentRoot.current!.focus();
        }
    }, [open, drawerContentRoot]);

    useImperativeHandle(ref, () => ({
        open: openDrawer,
        close: closeDrawer,
        focusIfOpen,
        togglePinned
    }), [openDrawer, closeDrawer, focusIfOpen, togglePinned]);

    //Wiring up the observer here so as to not cause the whole page to re-render
    const [headerMinHeight, setHeaderMinHeight] = useState("none");
    useLayoutEffect(() => {
        if (!_pageHeaderRef?.current) return;

        setHeaderMinHeight(`${_pageHeaderRef.current.getBoundingClientRect().height}px`);

        const observer = new ResizeObserver(
            //Safari only just started supporting the final version of the spec and doesn't reliably provide
            //borderBoxSize, so use target.getBoundingClientRect() instead
            ([{target}]) => setHeaderMinHeight(`${target.getBoundingClientRect().height}px`)
        );
        observer.observe(_pageHeaderRef.current, {box: "border-box"});

        return () => observer.disconnect();
    }, [_pageHeaderRef, setHeaderMinHeight]);

    //If no header content props were passed, we should hide the header and render margin on the content instead
    const showHeader = title || subtitle || renderHeaderContents || actions || !hidePinButton || !hideCloseButton;

    return (
        <MuiDrawer
            variant={pinned ? "persistent" : "temporary"}
            className="PcDrawer-root"
            sx={[{height: 1}, sx].flat()}
            anchor="right"
            {...{open, onClose, elevation}}
            SlideProps={{onExited: onDrawerExited}}
            PaperProps={{
                ref: drawerContentRoot,
                sx: [
                    {
                        width,
                        height: 1,
                        display: "grid",
                        grid: `
                            "header"  auto
                            "content" minmax(0, 1fr) /
                             100%
                        `,
                        overflowX: "hidden"
                    },
                    pinned && {position: "absolute"}
                ]
            }}
        >
            {(open || renderContents) && <>
                {showHeader &&
                    <AppHeader
                        scrollContainer={drawerContentRoot.current ?? undefined}
                        title={renderLazy(title)}
                        titleSize="small"
                        headingLevel={3}
                        subtitle={renderLazy(subtitle)}
                        scrolledElevation={pinned ? 3 : elevation + 1}
                        {...(renderHeaderContents && {children: renderHeaderContents()})}
                        className="PcDrawer-header"
                        sx={[
                            {
                                gridArea: "header",

                                //Subtract one from the header z-index so it falls behind the shadow of the main header
                                zIndex: t => t.zIndex.appBar - 1,

                                "& .PcAppHeader-toolbar": {
                                    px: 2,

                                    "& .PcAppHeader-actions": {
                                        gap: 1
                                    }
                                }
                            },

                            //Make the min height of the header match the page's while pinned
                            pinned && {
                                "& .PcAppHeader-toolbar": {
                                    minHeight: headerMinHeight
                                }
                            }
                        ]}
                        actions={<>
                            {renderLazy(actions)}
                            {(canPin && !hidePinButton) &&
                                <IconButton
                                    title={pinned ? "Unpin drawer" : "Pin drawer"}
                                    aria-pressed={pinned}
                                    onClick={togglePinned}
                                >
                                    <Icon fa="pin" sx={{color: pinned ? "primary.main" : "text.primary"}}/>
                                </IconButton>
                            }
                            {!hideCloseButton &&
                                <IconButton title="Close Drawer" onClick={closeDrawer}>
                                    <Icon fa="close"/>
                                </IconButton>
                            }
                        </>}
                    />
                }
                <Stack
                    className="PcDrawer-content"
                    sx={[
                        {
                            gridArea: "content",
                            px: 2,

                            //Scroll-end padding trick
                            "&::after": {
                                content: "''",
                                display: "block",
                                height: (t: Theme) => t.spacing(2),
                                flexShrink: 0
                            },

                            //In this context, we don't want shrinking
                            "& > *": {
                                flexShrink: 0
                            }
                        },
                        !showHeader && pinned && {mt: headerMinHeight},
                        heightSetting === "fixed" && {
                            display: "grid",
                            gridTemplateRows: "auto minmax(0, 1fr)",
                            gridTemplateColumns: "100%",
                            alignItems: "start"
                        }
                    ]}
                >
                    {renderLazy(children)}
                </Stack>
            </>}
        </MuiDrawer>
    );
});
