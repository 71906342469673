import {useEffect, useRef} from "react";

/**
 * Similar to useEffect, but runs the effect *synchronously, during the render*.
 * This can be useful in certain niche cases, but should be used with great caution.
 * Avoid setting any non-ref state in a synchronous effect, as this can cause problems for React.
 */
export function useSynchronousEffect(effect: () => (() => void) | void, deps: readonly unknown[]): void {
    const prevDeps = useRef<readonly unknown[]>([]);
    const cleanup = useRef<(() => void) | void>();

    useEffect(
        () => {
            prevDeps.current = deps;
        },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        deps
    );

    //Run cleanup one final time on component unmount
    useEffect(() => () => cleanup.current?.(), [cleanup]);

    for (let i = 0; i < deps.length; i++) {
        if (!Object.hasOwn(prevDeps.current, i) || prevDeps.current[i] !== deps[i]) {
            cleanup.current?.();
            cleanup.current = effect();
            break;
        }
    }
}
