import React, {FunctionComponent, ReactElement} from "react";

import {
    Fade as _Fade,
    FadeProps as _FadeProps,
    Grow as _Grow,
    GrowProps as _GrowProps,
    Slide as _Slide,
    SlideProps as _SlideProps,
    Zoom as _Zoom,
    ZoomProps as _ZoomProps
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import {MarkOptional} from "ts-essentials";

import {ComponentOverrides} from "./mui-theme-utils";

export const transitionOverrides: ComponentOverrides = {
    MuiCollapse: {
        defaultProps: {
            appear: false
        }
    },

    MuiFade: {
        defaultProps: {
            appear: false
        }
    },

    MuiGrow: {
        defaultProps: {
            appear: false
        }
    },

    MuiZoom: {
        defaultProps: {
            appear: false
        }
    }
};

//Re-exporting the transition components from here, not because they are functional,
//but so that we can cast away the requirement to pass children to accomodate the usage of ComposeTransitions

//Except for Collapse, which doesn't have that issue for some reason
export {Collapse, CollapseProps} from "@mui/material";

export type FadeProps = MarkOptional<_FadeProps, "children">;
export const Fade = _Fade as FunctionComponent<FadeProps>;
//A bit of a hack, but I see no harm. There's sadly no way to just turn off runtime propTypes validation in React.
delete Fade.propTypes?.children;

export type GrowProps = MarkOptional<_GrowProps, "children">;
export const Grow = _Grow as FunctionComponent<GrowProps>;
delete Grow.propTypes?.children;

export type SlideProps = MarkOptional<_SlideProps, "children">;
//For some reason Slide isn't in the override system
export const Slide = ({appear = false, ...props}: SlideProps) => <_Slide appear={appear} {...props as any}/>;

export type ZoomProps = MarkOptional<_ZoomProps, "children">;
export const Zoom = _Zoom as FunctionComponent<ZoomProps>;
delete Zoom.propTypes?.children;

export type Transition = ReactElement<TransitionProps>;

export interface ComposeTransitionsProps extends TransitionProps {
    /**
     *  The transition elements to compose.
     * Props on individual transitions will take priority over ones on the root element.
     */
    readonly transitions: readonly Transition[];
}

/** Utility component for composing transitions from MUI */
export function ComposeTransitions({transitions, children, ...props}: ComposeTransitionsProps) {
    function renderTransition(transition: Transition, ...rest: Transition[]) {
        //Messing with the actual JSX elements feels a bit weird, but MUI itself does it,
        //and this is WAY easier to get working in TypeScript than trying to do it by passing component types
        return {...transition, props: {
            ...props,
            ...transition.props,
            children:
                rest.length > 0 ?
                    //Due to the way the MUI transition components use refs,
                    //it's necessary to have a div between each transition
                    <div>
                        {/* Really, TypeScript? You can't understand cons-style argument spread? */}
                        {renderTransition(...rest as [Transition, ...Transition[]])}
                    </div>
                :
                    children
        }};
    }

    return renderTransition(...transitions as [Transition, ...Transition[]]);
}
