import React, {useState} from "react";
import {CollapsibleFilterGrid} from "~/components/filter-grid";
import {NumberFilter, SelectFilter, TextFilter} from "~/components/filters";
import {debounce, first} from "lodash";
import {VirtualAccount} from "~/account/virtual-account";
import {Backend} from "~/backend/backend";
import BigNumber from "bignumber.js";
import {Range} from "~/utils/range";
import {GridSortModel} from "@mui/x-data-grid/models/gridSortModel";
import {useQuery} from "react-query";
import VirtualAccounts from "~/repositories/virtual-accounts";

export interface VirtualAccountFilter {
  accountType?: VirtualAccount.AccountType;
  accountRole?: VirtualAccount.AccountRole;
  name?: string;
  backendType?: Backend.Type;
  investmentAmount?: Range<BigNumber>;
  investmentId?: string;
  fundraiseId?: string;
  companyId?: string;
  companyName?: string;
  investorId?: string;
  investorName?: string;
}

export type VirtualAccountFilterSetter = <K extends keyof VirtualAccountFilter>(key: K, value: VirtualAccountFilter[K]) => void;

export const useVirtualAccountQuery = ({defaultFilters, defaultSort}: {defaultFilters: VirtualAccountFilter, defaultSort?: GridSortModel}) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [sort, setSort] = useState<GridSortModel|undefined>(defaultSort);
  const [filters, setFilters] = useState<VirtualAccountFilter>(defaultFilters);

  const filterSetter: VirtualAccountFilterSetter = (k, v) => {
    setFilters({...filters, [k]: v});
  };

  const params = {
    ...filters,
    page: page,
    perPage: pageSize,
    sortBy: first(sort)?.field,
    sortDir: first(sort)?.sort
  };

  const virtualAccountsQuery = useQuery(VirtualAccounts.list(params));
  return {query: virtualAccountsQuery, filters, filterSetter, sort, setSort, page, setPage, pageSize, setPageSize};
};

export const VirtualAccountFilters = ({filter, setFilter}: {filter: VirtualAccountFilter, setFilter: VirtualAccountFilterSetter }) => {
  const setFilterDebounced = debounce((key, value) => setFilter(key, value), 300);

  return (
    <CollapsibleFilterGrid
        sx={{
        mb: 2,
        "& .MuiAccordionDetails-root": {
          maxHeight: "30vh",
          overflow: "auto"
        }
      }}
    >
      <TextFilter
          filterKey="accountName"
          value={filter.name}
          onValueChange={value => setFilterDebounced("name", value)}
      />
      <SelectFilter
          filterKey="accountType"
          options={VirtualAccount.ACCOUNT_TYPES}
          value={filter.accountType}
          onValueChange={value => setFilterDebounced("accountType", value)}
      />
      <SelectFilter
          filterKey="accountRole"
          options={VirtualAccount.ACCOUNT_ROLES}
          value={filter.accountRole}
          onValueChange={value => setFilterDebounced("accountRole", value)}
      />
      <SelectFilter
          filterKey="backendType"
          options={Backend.TYPES}
          value={filter.backendType}
          onValueChange={value => setFilterDebounced("backendType", value)}
      />
      <NumberFilter
          type="BigNumber"
          filterKey="investmentAmount"
          value={filter.investmentAmount}
          onValueChange={value => setFilterDebounced("investmentAmount", value)}
      />
      <TextFilter
          filterKey="investmentId"
          value={filter.investmentId}
          onValueChange={value => setFilterDebounced("investmentId", value)}
      />
      <TextFilter
          filterKey="fundraiseId"
          value={filter.fundraiseId}
          onValueChange={value => setFilterDebounced("fundraiseId", value)}
      />
      <TextFilter
          filterKey="companyId"
          value={filter.companyId}
          onValueChange={value => setFilterDebounced("companyId", value)}
      />
      <TextFilter
          filterKey="companyName"
          value={filter.companyName}
          onValueChange={value => setFilterDebounced("companyName", value)}
      />
      <TextFilter
          filterKey="investorId"
          value={filter.investorId}
          onValueChange={value => setFilterDebounced("investorId", value)}
      />
      <TextFilter
          filterKey="investorName"
          value={filter.investorName}
          onValueChange={value => setFilterDebounced("investorName", value)}
      />
    </CollapsibleFilterGrid>
  );
};
