import {SignInResponse, SignInResponseImpl, SignInSuccess} from "./sign-in-response";
import {TOTPSetup} from "./totp-setup";
import {BaseApi} from "~/api/base-api";
import {FetchClient} from "~/api/fetch-client";
import {autobindAll} from "~/utils/autobind";

export interface SignInRequestDto {
    readonly email: string;
    readonly password: string;
}

/** API for authentication operations */
@autobindAll
export class AuthApi extends BaseApi<SignInResponse> {
    public constructor(fetchClient: FetchClient) {
        //Not using the common error handling for auth APIs as it could cause a loop
        super(fetchClient, () => false, "auth", SignInResponseImpl);
    }

    /** Attempts to authenticate with the given credentials */
    public async signIn(credentials: SignInRequestDto, signal?: AbortSignal): Promise<SignInResponse> {
        return await this.fetchJson<SignInResponse>(SignInResponseImpl, "POST", "/", {json: credentials, signal});
    }

    public async answerChallenge(token: string, answer: string, signal?: AbortSignal): Promise<SignInSuccess> {
        return await this.fetchJson(
            SignInSuccess,
            "POST",
            "2fa",
            {json: {session: token, answer}, signal}
        );
    }

    public async setupTOTP(token: string, signal?: AbortSignal): Promise<TOTPSetup> {
        return await this.fetchJson(TOTPSetup, "POST", "2fa/setup", {json: {session: token}, signal});
    }

    public async verifyNewTOTP(token: string, answer: string, signal?: AbortSignal): Promise<SignInSuccess> {
        return await this.fetchJson(
            SignInSuccess,
            "POST",
            "2fa/verify",
            {json: {session: token, answer}, signal}
        );
    }
}
