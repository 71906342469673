import React, {Fragment, memo} from "react";

import {Stack} from "@mui/material";
import {startCase} from "lodash";

import {VirtualAccountListItem} from "../virtual-account-list-item";
import {PaginationState} from "~/api/pagination-envelope";
import {IconButton} from "~/components/button";
import {Icon} from "~/components/icon";
import {Link} from "~/components/link";
import {
    EditColumnsFlyout,
    makeColumns,
    PaginatedTable,
    TableColumns,
    useLocalStorageColumnOrder
} from "~/components/table";
import {takeArgs} from "~/utils/event-helpers";
import {NoWrap} from "~/utils/styles";

export interface VirtualAccountListDisplayProps {
    readonly elevation?: number;
    readonly accounts: Iterable<VirtualAccountListItem>;
    readonly paginationState: PaginationState;
    readonly stale: boolean;
    readonly onPageChange: (page: number) => void;
    readonly onRowsPerPageChange: (rowsPerPage: number) => void;

    //Adding supprt for arbitrary custom columns would be too complex due to how column order works.
    //Just gonna add a bespoke column for selection and expose it through a callback.
    readonly onSelectAccount?: (accountId: string, as: "from" | "to") => void;
}

export const VirtualAccountListDisplay = memo(function VirtualAccountListDisplay({
    elevation = 1,
    accounts,
    paginationState,
    stale,
    onPageChange,
    onRowsPerPageChange,
    onSelectAccount
}: VirtualAccountListDisplayProps) {
    const columns = makeColumns<VirtualAccountListItem>()({
        ...(onSelectAccount && {select: acct =>
            <Stack direction="row" gap={1}>
                {/*
                    I'm gonna abstain from memoizing these handlers.
                    I feel in this case the inline arrow functions are lighter.
                */}
                <IconButton
                    title="Select as Source"
                    autoTooltip
                    aria-label={`Select account ${acct.id} as Source`}
                    size="small"
                    onClick={() => onSelectAccount(acct.id, "from")}
                >
                    <Icon fa="alignment-aligned-to" sx={{fontSize: "1.2em", transform: "rotate(-90deg)"}}/>
                </IconButton>
                <IconButton
                    title="Select as Destination"
                    autoTooltip
                    aria-label={`Select account ${acct.id} as Destination`}
                    size="small"
                    onClick={() => onSelectAccount(acct.id, "to")}
                >
                    <Icon fa="alignment-align" sx={{fontSize: "1.2em", transform: "rotate(180deg)"}}/>
                </IconButton>
            </Stack>
        }),
        id: ["ID", acct => <Link to={acct.id}>{acct.id}</Link>],
        name: acct => acct.name,
        accountType: acct => startCase(acct.accountType),
        accountRole: acct => startCase(acct.accountRole.type),
        balance: acct =>
            acct.balanceSummary.map((segment, i) => <Fragment key={i}>
                <NoWrap>{segment}</NoWrap>{" "}
            </Fragment>),
        backend: acct => `${acct.backend.name} (${acct.backend.id})`,
        /* eslint-disable @typescript-eslint/no-unsafe-return */
        investorName: acct => acct.investor?.data.full_name,
        fundraiseExemption: acct => acct.fundraise?.data.exemption,
        companyName: acct => acct.company?.data.name
        /* eslint-enable @typescript-eslint/no-unsafe-return */
    });

    const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder("virtualAccountColumnOrder", columns, ["select"]);

    return (
        <PaginatedTable
            elevation={elevation}
            paginationState={paginationState}
            loadingNextPage={stale}
            onPageChange={takeArgs(onPageChange, 1)}
            onRowsPerPageChange={takeArgs(onRowsPerPageChange, 1)}
            renderExtraToolbarContents={() =>
                <EditColumnsFlyout columns={columns} columnOrder={columnOrder} onColumnOrderChange={setColumnOrder}/>
            }
        >
            <TableColumns rowData={accounts} keyRowsBy="id" columnOrder={columnOrder}>
                {columns}
            </TableColumns>
        </PaginatedTable>
    );
});
