import {inject} from "~/utils/di";
import {TransferStore} from "~/transfer/store";
import {observer} from "mobx-react-lite";
import {TransferSummary} from "~/transfer/transfer-summary";
import {useStateObject, useTypedStateObject} from "~/utils/use-state-object";
import {PageParams, SortDirection, SortParams} from "~/api/common-query-params";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {DefaultSort, TRANSFER_SORT_KEYS, TransferSortKey} from "~/transfer/api";
import {Transfer} from "~/transfer/transfer";
import {FundraiseExemption, FundraiseState} from "~/entity/wefunder-types";
import {Range} from "~/utils/range";
import {BigNumber} from "bignumber.js";
import {useIsFirstRender} from "~/utils/use-is-first-render";
import {useDebouncedValue} from "~/utils/use-debounced-value";
import {useAbortableEffect} from "~/utils/use-abortable-effect";
import {flowResult} from "mobx";
import {makeColumns, PaginatedTable, TableColumns} from "~/components/table";
import {Link} from "~/components/link";
import {NoWrap, Span} from "~/utils/styles";
import {startCase} from "lodash";
import {MoneyFormat} from "~/utils/money";
import {Button} from "~/components/button";
import {Box} from "@mui/material";
import {FilterAndSortBar} from "~/components/filter-and-sort-bar";
import {CollapsibleFilterGrid} from "~/components/filter-grid";
import {TransferFilters} from "~/transfer/components/transfer-filters";
import {SortOptions} from "~/components/sort-options";
import {LoadingTreatment} from "~/components/loading-treatment";
import {takeArgs} from "~/utils/event-helpers";
import {ReconStagingContext} from "~/recon/components/recon-staging";
import VirtualAccountLinkInfo from "~/recon/components/account-link-info";

export interface DefaultFilter {
  amount?: Range<BigNumber>;
  fromAccountId?: string;
  toAccountId?: string;
  relatedAccountId?: string;
  intention?: Transfer.Intention;
  state?: Transfer.State;
  investmentId?: string;
  fundraiseId?: string;
  fundraiseExemption?: FundraiseExemption;
  fundraiseState?: FundraiseState;
  companyId?: string;
  companyName?: string;
  investorId?: string;
  investorName?: string;
  investorAdmin?: boolean;
  reconcilable?: boolean;
}

export const TransfersBrowser = inject({transferStore: TransferStore}, observer(function TransfersBrowser({
        transferStore,
        disabledTransfers = [],
        selectTransfer = _ => null,
        unselectTransfer = _ => null,
        defaultSort = undefined,
        defaultFilter = undefined
    } : {
        transferStore: TransferStore,
        disabledTransfers: string[],
        selectTransfer: (transfer: TransferSummary) => void,
        unselectTransfer: (transfer: TransferSummary) => void,
        defaultSort?: DefaultSort,
        defaultFilter?: DefaultFilter}) {
    const {transfers} = transferStore;
    const staging = useContext(ReconStagingContext);
    const isSelected = (transfer: TransferSummary) => staging.transfers.findIndex(t => t.id === transfer.id) >= 0;
    const isDisabled = (transfer: TransferSummary) => disabledTransfers.findIndex(id => id === transfer.id) >= 0;

    const [pageParams, setPageParams] = useTypedStateObject<PageParams>()({
        page: useState<number>(1),
        perPage: useState<number>(10)
    });

    const [sortParams, setSortParams] = useTypedStateObject<SortParams<TransferSortKey>>()({
        sortBy: useState<TransferSortKey | undefined>(defaultSort?.key),
        sortDir: useState<SortDirection | undefined>(defaultSort?.dir)
    });

    const [filters, setFilters] = useStateObject({
        fromAccountId: useState<string | undefined>(defaultFilter?.fromAccountId),
        toAccountId: useState<string | undefined>(defaultFilter?.toAccountId),
        relatedAccountId: useState<string | undefined>(defaultFilter?.relatedAccountId),
        intention: useState<Transfer.Intention | undefined>(defaultFilter?.intention),
        investmentId: useState<string | undefined>(defaultFilter?.investmentId),
        fundraiseId: useState<string | undefined>(defaultFilter?.fundraiseId),
        fundraiseExemption: useState<FundraiseExemption | undefined>(defaultFilter?.fundraiseExemption),
        fundraiseState: useState<FundraiseState | undefined>(defaultFilter?.fundraiseState),
        companyId: useState<string | undefined>(defaultFilter?.companyId),
        companyName: useState<string | undefined>(defaultFilter?.companyName),
        investorId: useState<string | undefined>(defaultFilter?.investorId),
        investorName: useState<string | undefined>(defaultFilter?.investorName),
        investorAdmin: useState<boolean | undefined>(defaultFilter?.investorAdmin),
        state: useState<Transfer.State | undefined>(defaultFilter?.state),
        reconcilable: useState<boolean | undefined>(defaultFilter?.reconcilable)
    });

    const [amountRange, setAmountRange] = useState<Range<BigNumber> | undefined>(defaultFilter?.amount);

    //Reset the page to 1 when the filters change
    const isFirstRender = useIsFirstRender();
    useEffect(() => {
        if (!isFirstRender) {
            setPageParams.page(1);
        }
    }, [sortParams, filters]); //eslint-disable-line react-hooks/exhaustive-deps

    const fetchParams = useMemo(() => ({
        ...pageParams,
        ...filters,
        ...sortParams,
        ...amountRange?.toParams("amount")
    }), [filters, sortParams, pageParams, amountRange]);

    const debouncedFetchParams = useDebouncedValue(fetchParams, 150);
    useAbortableEffect(
        signal => flowResult(transferStore.fetchTransfers(debouncedFetchParams, signal)),
        [transferStore, debouncedFetchParams]
    );

    const columns = makeColumns<TransferSummary>()({
        id: ["ID", transfer => <Link to={`/transfers/${transfer.id}`}>{transfer.id}</Link>],
        intention: transfer => <NoWrap>{startCase(transfer.intention)}</NoWrap>,
        description: transfer =>
            transfer.intentionData &&
            <Span display="inline-block" minWidth="30ex">{transfer.intentionData.description}</Span>,
        amount: transfer => MoneyFormat.formatBigNumber(transfer.amount, transfer.currency, {negativesWithParenthesis: true}),
        fromAccount: transfer => transfer.fromAccount && <VirtualAccountLinkInfo account={transfer.fromAccount}/>,
        toAccount: transfer => transfer.toAccount && <VirtualAccountLinkInfo account={transfer.toAccount}/>,
        actions: transfer => {
            if (isDisabled(transfer)) {
                return <>Already added</>;
            } else {
                if (isSelected(transfer)) {
                    return <Button variant={"text"} size={"small"} color={"warning"} onClick={() => unselectTransfer(transfer)}>Remove</Button>;
                } else {
                    return <Button variant={"text"} size={"small"} color={"warning"} onClick={() => selectTransfer(transfer)}>Add</Button>;
                }
            }
        }
    });

    return <Box>
        <FilterAndSortBar>{expandSort => <>
            <CollapsibleFilterGrid
                sx={{
                    mb: 2,

                    "& .MuiAccordionDetails-root": {
                        maxHeight: "30vh",
                        overflow: "auto"
                    }
                }}
            >
                <TransferFilters
                    fromAccountId={filters.fromAccountId}
                    setFromAccountId={setFilters.fromAccountId}
                    toAccountId={filters.toAccountId}
                    setToAccountId={setFilters.toAccountId}
                    relatedAccountId={filters.relatedAccountId}
                    setRelatedAccountId={setFilters.relatedAccountId}
                    intention={filters.intention}
                    setIntention={setFilters.intention}
                    investmentId={filters.investmentId}
                    setInvestmentId={setFilters.investmentId}
                    fundraiseId={filters.fundraiseId}
                    setFundraiseId={setFilters.fundraiseId}
                    fundraiseExemption={filters.fundraiseExemption}
                    setFundraiseExemption={setFilters.fundraiseExemption}
                    fundraiseState={filters.fundraiseState}
                    setFundraiseState={setFilters.fundraiseState}
                    companyId={filters.companyId}
                    setCompanyId={setFilters.companyId}
                    companyName={filters.companyName}
                    setCompanyName={setFilters.companyName}
                    investorId={filters.investorId}
                    setInvestorId={setFilters.investorId}
                    investorName={filters.investorName}
                    setInvestorName={setFilters.investorName}
                    investorAdmin={filters.investorAdmin}
                    setInvestorAdmin={setFilters.investorAdmin}
                    state={filters.state}
                    setState={setFilters.state}
                    amountRange={amountRange}
                    setAmountRange={setAmountRange}
                />
            </CollapsibleFilterGrid>
            <SortOptions
                sortKeys={TRANSFER_SORT_KEYS}
                sortBy={sortParams.sortBy}
                onSortByChange={setSortParams.sortBy}
                sortDirection={sortParams.sortDir}
                onSortDirectionChange={setSortParams.sortDir}
                expanded={expandSort}
            />
        </>}</FilterAndSortBar>

        <LoadingTreatment loadable={transfers} renderStaleValues observer>{(transfers, stale) =>
            <PaginatedTable
                paginationState={transfers.pagination}
                loadingNextPage={stale}
                onPageChange={takeArgs(setPageParams.page, 1)}
                onRowsPerPageChange={takeArgs(setPageParams.perPage, 1)}
            >
                <TableColumns rowData={[...transfers.data.values()]} keyRowsBy="id" observer isSelected={isSelected}>
                    {columns}
                </TableColumns>
            </PaginatedTable>
        }</LoadingTreatment>
    </Box>;
}));
