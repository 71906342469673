import {Collapse, Grid, Link, Stack, Typography} from "@mui/material";
import React, {ReactNode} from "react";
import {Icon} from "./icon";
import {ExpandLess, ExpandMore} from "@mui/icons-material";


export const ExternalLink = ({url, newTab}: { url: string, newTab?: boolean }) =>
  <Link href={url} sx={{ml: 2}} target={newTab ? "_blank" : undefined}>
    <Icon fa="external-link"/>
  </Link>
  ;

  export const LabeledItem = ({children, label}: { children: ReactNode, label: string }) => (
  <Grid container spacing={2} sx={{mb: 1}}>
    <Grid item width="20%">
      <Typography variant="body2" color="text.secondary">{label}</Typography>
    </Grid>
    <Grid item width="80%">
      <Typography variant="body2" component="span">{children}</Typography>
    </Grid>
  </Grid>
);

// For simple values, will not render at all if value is null or undefined
export const LabeledValue = ({label, value}: { label: string, value: any }) => {
  if (value === null || value === undefined) {
    return null;
  } else {
    return <LabeledItem label={label}>{value}</LabeledItem>;
  }
};

export const LabeledItemWithDetails = ({children, label, summary}: { children: ReactNode, label: String, summary: ReactNode }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Grid container spacing={2} sx={{mb: 1}}>
      <Grid item width="20%">
        <Typography variant="body2" color="text.secondary">{label}</Typography>
      </Grid>
      <Grid item width="80%">
        <Stack direction="column">
          <Stack direction="row">
            <Link component="button" variant="body2" onClick={() => setOpen(!open)} sx={{ml: -6, px: 1}}>
              {open ? <ExpandLess/> : <ExpandMore/>}
            </Link>
            <Typography variant="body2" component="span">{summary}</Typography>
          </Stack>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* <Paper variant="outlined" sx={{p: 1}}> */}
            {children}
            {/* </Paper> */}
          </Collapse>
        </Stack>
      </Grid>
    </Grid>
  );
};