export const TransferStates = [
    "pending",
    "running",
    "done",
    "failed",
    "reconciling",
    "canceled"
] as const;

export const TransferIntentions = [
    "general_transfer",
    "historic_transfer",
    "investment_adjustment",
    "investment_charge",
    "investment_refund",
    "immediate_transfer",
    "reversal",
    "disbursement",
    "managed"
] as const;

export const TransferTags = [
    "general",
    "wire",
    "charge",
    "adjustment",
    "refund",
    "chargeback",
    "chargeback_reversal",
    "stripe_service_fees",
    "stripe_dispute_fees",
    "stripe_dispute_fee_reversal",
    "stripe_released",
    "stripe_reserved",
    "disbursement_tranche",
    "investor_fee",
    "wire_group",
    "company_fee",
    "disbursement_item"
] as const;
