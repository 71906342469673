import {ResponseError} from "~/errors";

/** RequestInit sub-interface with additional body properties */
export interface FetchRequestOptions extends RequestInit {
    /**
     * If specified, the provided value will be serialized to JSON and used as the body.
     * The Content-Type header will also be set to application/json
     */
    json?: any;

    /** If specified, the provided dictionary will be used to generate query parameters to append to the URL */
    queryParams?: {readonly [param: string]: unknown};

    /** If true, the empty string is allowed as a query parameter. By default it is filtered out. */
    allowEmptyQueryParams?: boolean;
}

//Using the same logic for applying the base URL as aurelia-fetch-client
//https://github.com/aurelia/fetch-client/blob/master/src/http-client.ts#L265
const absoluteUrlRegexp = /^([a-z][a-z0-9+\-.]*:)?\/\//ui;

/**
 * Normalizes a base URL by removing trailing slashes
 *
 * @param url - The base URL to normalize
 *
 * @returns The normalized base URL
 */
const normalizeBaseUrl = (url: string): string => url.endsWith("/") ? url.substring(0, url.length - 1) : url;

/**
 * Creates a formatted query parameter string from a dictionary object
 *
 * @param params - The query parameter dictionary
 *
 * @returns A formatted query parameter string
 */
function prepareQueryParams(params: {[param: string]: unknown}, allowEmptyQueryParams: boolean = false): string {
    const disallowedValues: any[] = [undefined];
    if (!allowEmptyQueryParams) {
        disallowedValues.push("");
    }

    const entries = Object.entries(params);

    return entries.length === 0 ? "" : "?" + entries
        .map(([param, val]) => !disallowedValues.includes(val) ? `${param}=${val}` : undefined)
        .filter(Boolean)
        .join("&");
}

/** Constructor parameters for FetchClient class */
interface FetchClientInit {
    /** The base URL to be applied to non-absolute requests */
    baseUrl?: string;

    /** A function which applies authentication to a request */
    authApplicator?: ((options: RequestInit) => void) | null;
}

/**
 * Lightweight wrapper around the standard Fetch API.
 *
 * I previously used aurelia-fetch-client for this purpose, but that package now depends on other parts of the
 * Aurelia framework (which I am not using for this app), and I'm not super keen on pulling in the core of a
 * different framework for one small dependency.
 *
 * The interface of this class is based on aurelia-fetch-client's HttpClient class.
 * (See: https://aurelia.io/docs/api/fetch-client/class/HttpClient)
 * However, since this class only serves this one application, I'm making it less generic/configurable
 * and baking my desired defaults in for sake of simplicity.
 */
export class FetchClient {
    public static readonly defaultHeaders = {
        "Accept": "application/json"
    };

    /** The base URL to be applied to non-absolute requests */
    public readonly baseUrl: string;

    /** Indicates whether this FetchClient is set up to use authentication */
    public readonly authenticated: boolean;

    private readonly authApplicator: ((options: RequestInit) => void) | null;

    /** Constructs a new FetchClient */
    public constructor({baseUrl = "/api", authApplicator = null}: FetchClientInit = {}) {
        //Normalize base URL
        this.baseUrl = normalizeBaseUrl(baseUrl);
        this.authApplicator = authApplicator;
        this.authenticated = !!authApplicator;
    }

    /**
     * Constructs a child of this FetchClient with a nested base URL
     *
     * @param childBaseUrl - A URL fragment to append to this FetchClient's baseUrl
     *
     * @returns A new FetchClient with the same authentication as the parent and a baseUrl with childBaseUrl appended
     */
    public childClient(childBaseUrl: string): FetchClient {
        return new FetchClient({
            baseUrl: this.applyBaseUrl(normalizeBaseUrl(childBaseUrl)),
            authApplicator: this.authApplicator
        });
    }

    /**
     * Makes a copy of this FetchClient with a new authApplicator
     *
     * @param authApplicator - The authApplicator to use
     *
     * @returns The new FetchClient
     */
    public withAuthentication(authApplicator: (options: RequestInit) => void): FetchClient {
        return new FetchClient({baseUrl: this.baseUrl, authApplicator});
    }

    /**
     * Performs an HTTP request using the Fetch API
     *
     * @param path - The URL to request
     * @param options - The options for constructing the Request
     *
     * @returns
     * A Promise that resolves to the Response if it has a 2XX response code,
     * and rejects with a ResponseError otherwise
     */
    public async fetch(path: string, options: FetchRequestOptions = {}): Promise<Response> {
        //Prevent modifying argument
        options = {...options};

        path = this.applyBaseUrl(path);

        //Normalize by removing trailing slashes
        path = path.replace(/\/$/u, "");

        //Handle query parameters
        if (options.queryParams) {
            path += prepareQueryParams(options.queryParams);
        }

        //Merge in default headers
        options.headers = {...FetchClient.defaultHeaders, ...options.headers};

        //Handle JSON serialization
        if (options.json) {
            options.body = JSON.stringify(options.json);
            options.headers["Content-Type"] = "application/json";
        }

        //Handle authentication
        if (this.authApplicator) {
            this.authApplicator(options);
        }

        const response = await fetch(path, options);

        //Log and throw on error responses
        if (!response.ok) {
            console.error(`Error making ${options.method} request to ${path}`);

            let respBody: string | undefined = undefined;
            try {
                respBody = (await response.text()) || undefined;
                if (respBody) {
                    console.error(`Response body:\n${respBody}`);
                }
            }
            catch {
                //If we can't get the response body, don't worry about it
            }

            throw new ResponseError(response, respBody);
        }

        return response;
    }

    /**
     * Performs an HTTP GET request using the Fetch API
     *
     * @param path - The URL to request
     * @param options - The options for constructing the Request
     *
     * @returns
     * A Promise that resolves to the Response if it has a 2XX response code,
     * and rejects with a ResponseError otherwise
     */
    public async get(path: string, options: FetchRequestOptions = {}): Promise<Response> {
        return await this.fetch(path, {method: "GET", ...options});
    }

    /**
     * Performs an HTTP POST request using the Fetch API
     *
     * @param path - The URL to request
     * @param options - The options for constructing the Request
     *
     * @returns
     * A Promise that resolves to the Response if it has a 2XX response code,
     * and rejects with a ResponseError otherwise
     */
    public async post(path: string, options: FetchRequestOptions = {}): Promise<Response> {
        return await this.fetch(path, {method: "POST", ...options});
    }

    /**
     * Performs an HTTP PATCH request using the Fetch API
     *
     * @param path - The URL to request
     * @param options - The options for constructing the Request
     *
     * @returns
     * A Promise that resolves to the Response if it has a 2XX response code,
     * and rejects with a ResponseError otherwise
     */
    public async patch(path: string, options: FetchRequestOptions = {}): Promise<Response> {
        return await this.fetch(path, {method: "PATCH", ...options});
    }

    /**
     * Performs an HTTP DELETE request using the Fetch API
     *
     * @param path - The URL to request
     * @param options - The options for constructing the Request
     *
     * @returns
     * A Promise that resolves to the Response if it has a 2XX response code,
     * and rejects with a ResponseError otherwise
     */
    public async delete(path: string, options: FetchRequestOptions = {}): Promise<Response> {
        return await this.fetch(path, {method: "DELETE", ...options});
    }

    /**
     * Prepends the base URL to a given URL if it is not absolute.
     * Leading slashes on the URL will be normalized.
     *
     * @param url - The URL to process
     *
     * @returns A processed URL
     */
    private applyBaseUrl(url: string): string {
        if (absoluteUrlRegexp.test(url)) return url;

        if (!url.startsWith("/")) {
            url = "/" + url;
        }

        return this.baseUrl + url;
    }
}
