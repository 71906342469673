import {useQuery} from "react-query";
import {useCallback, useMemo, useState} from "react";
import {Range} from "~/utils/range";
import {BigNumber} from "bignumber.js";
import {Temporal} from "@js-temporal/polyfill";
import {GridSortModel} from "@mui/x-data-grid/models/gridSortModel";
import {first} from "lodash";
import {plainDateRangeToInstant} from "~/utils/temporal";
import TransferRepo from "~/repositories/transfers";
import {Transfer} from "~/transfer/transfer";

export interface TransferFilter {
  amount?: Range<BigNumber>;
  date?: Range<Temporal.PlainDate>|"";
  tag?: Transfer.Tag;
}

export type TransferFilterSetter = <K extends keyof TransferFilter>(key: K, value: TransferFilter[K]) => void;

export default function useTransferQuery(defaultFilter?: TransferFilter, defaultSort?: GridSortModel) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [sort, setSort] = useState<GridSortModel|undefined>(defaultSort);

  const [amount, setAmount] = useState<Range<BigNumber> | undefined>(defaultFilter?.amount);
  const [date, setDate] = useState<Range<Temporal.PlainDate>|""|undefined>(defaultFilter?.date);
  const [tag, setTag] = useState<Transfer.Tag|undefined>(defaultFilter?.tag);


  const filters = useMemo(() => ({
    amount, date, tag
  }), [amount, date, tag]);

  const setFilters = useCallback(<K extends keyof TransferFilter>(key: K, value: TransferFilter[K]) => {
    switch (key) {
      case "amount": return setAmount(value as any);
      case "date":   return setDate(value as any);
      case "tag":    return setTag(value as any);
    }
  }, []);

  const {amount: amountFilter, date: dateFilter, ...otherFilters} = filters;
  const params = {
    ...otherFilters,
    amountLt: filters.amount?.upperBound,
    amountGt: filters.amount?.lowerBound,
    date: filters.date ? plainDateRangeToInstant(filters.date) : undefined,
    page: page,
    perPage: pageSize,
    sortBy: first(sort)?.field,
    sortDir: first(sort)?.sort
  };
  const transferQuery = useQuery(TransferRepo.list(params));

  return {query: transferQuery, filters, setFilters, sort, setSort, page, setPage, pageSize, setPageSize};
}
