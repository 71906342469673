import {wfGet, wfPost} from "~/utils/wf-fetch";
import {constructDto, paginatedDto} from "./util";
import {UserTask} from "~/user-task/user-task";
import {PaginationEnvelope} from "~/api/pagination-envelope";

const basePath = `${process.env.API_BASE}/user_tasks`;
const staleTime = 3 * 60 * 1000; // 3 minutes

export type TaskActionCommand = {
  taskId: number,
  action: any
};

const UserTasks = {
  list: (params?: {[param: string]: unknown}) => ({
    queryKey: ["user_tasks", params],
    queryFn: () => wfGet<PaginationEnvelope<UserTask[]>>(basePath, params, paginatedDto(UserTask)),
    keepPreviousData: true,
    staleTime: staleTime
  }),
  resolve: (data: TaskActionCommand) => ({
    mutationFn: () => wfPost<TaskActionCommand, UserTask>(data, `${basePath}/resolve`, {constructor: constructDto(UserTask)})
  })
};

export default UserTasks;
