import {useEffect, useRef} from "react";

/**
 * Stabilizes an otherwise unstable value using a custom comparator
 *
 * @param value - The value to stabilize
 * @param areEqual - A comparator function that should return false when the stable value should update
 *
 * @returns A stable value that updates based on the given comparator
 */
export function useStabilizedValue<T>(value: T, areEqual: (a: T, b: T) => boolean): T {
    const stableValue = useRef(value);

    const valueChanged = value !== stableValue.current && !areEqual(value, stableValue.current);

    //As of React 18, it's no longer acceptable to change ref state during render, so push it to an effect
    useEffect(() => {
        if (valueChanged) {
            stableValue.current = value;
        }
    });

    return valueChanged ? value : stableValue.current;
}
