import React, {ReactNode, useLayoutEffect, useRef, useState} from "react";

import {Stack} from "@mui/material";

/** Utility container for the standard layout of collapsible filter and sort at the top of a dashboard */
export function FilterAndSortBar({children}: {readonly children: (expandSort: boolean) => ReactNode}) {
    const container = useRef<HTMLDivElement>(null);

    const [expandSort, setExpandSort] = useState(false);
    useLayoutEffect(() => {
        const MIN_EXPAND_SORT_PX = 1145;

        setExpandSort(container.current!.getBoundingClientRect().width > MIN_EXPAND_SORT_PX);

        const observer = new ResizeObserver(
            //Safari only just started supporting the final version of the spec and doesn't reliably provide
            //borderBoxSize, so use target.getBoundingClientRect() instead
            ([{target}]) => setExpandSort(target.getBoundingClientRect().width > MIN_EXPAND_SORT_PX)
        );
        observer.observe(container.current!);

        return () => observer.disconnect();
    }, [container, setExpandSort]);

    return (
        <Stack
            ref={container}
            direction="row"
            gap={2}
            sx={{
                width: 1,
                mb: 2,

                "& .PcCollapsibleFilterGrid-root": {
                    flex: 1,

                    "& .MuiAccordionDetails-root": {
                        maxHeight: "30vh",
                        overflow: "auto"
                    }
                },

                //Don't like hard-coding the margin/height to match the Filters accordion,
                //but I can't just use 100% since that will cause the sort options to expand with the accordion
                "& .PcSortOptions-button": {
                    mt: "6.5px" // ((filter height = 56px) - (button height = 40px)) / 2
                },

                "& .PcSortOptions-expanded": {
                    width: 1 / 3,
                    height: "56px"
                }
            }}
        >
            {children(expandSort)}
        </Stack>
    );
}
