import {useCallback, useMemo, useState} from "react";

import {MarkRequired} from "ts-essentials";

import {_ImplInitializer, getInitialValue, getUpdatedValue, HookValueTuple, Initializer, Updater} from "./hook-utils";
import {getOrCompute} from "./maps";
import {useSynchronousEffect} from "./use-synchronous-effect";

//Map from localStorage keys to the state setters of all active hooks corresponding to them
const setterMap = new Map<string, Set<Updater<any>>>();

export interface UseLocalStorageOptions<T> {
    /** A function to parse the string value from LocalStorage. Can be omitted if storing arbitrary strings. */
    readonly parser?: (s: string) => T;

    /** A function to serialize the value for storing in LocalStorage. Defaults to calling toString(). */
    readonly serializer?: (x: T) => string;
}

/**
 * Allows using a value from LocalStorage in a React component.
 *
 * NOTE: The value will not be recomputed if the parser or serializer changes.
 *
 * @param key - The LocalStorage key to use to save and load the value
 * @param initializer
 * A value to use to initialize LocalStorage if the key is not found, or a function providing a value
 * @param options - Optional parameters
 *
 * @returns A tuple containing the stored value and a setter function
 */
export function useLocalStorage(
    key: string,
    initializer: Initializer<string>,
    options?: UseLocalStorageOptions<string>
): HookValueTuple<string>;
export function useLocalStorage<T>(
    key: string,
    initializer: Initializer<T>,
    ...options:
        [T] extends [string | null | undefined] ? [o?: UseLocalStorageOptions<T>] :
        [string] extends [T] ? [o?: UseLocalStorageOptions<T>] :
        [MarkRequired<UseLocalStorageOptions<T>, "parser">]
): HookValueTuple<T>;
export function useLocalStorage<T>(
    key: string,
    initializer: _ImplInitializer<T>,
    {parser = x => x as any as T, serializer = x => `${x}`}: UseLocalStorageOptions<T> = {}
): HookValueTuple<T> {
    const storeValue = useCallback(
        (value: T) => {
            try {
                localStorage.setItem(key, serializer(value));
            }
            catch (err) {
                if (err instanceof DOMException && err.name === "QuotaExceededError") {
                    console.warn(`Unable to store "${key}" in localStorage. Got QuotaExceededError.`);
                }
                else throw err;
            }
        },
        //Intentionally omitting serializer
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [key]
    );

    const loadedValue = useMemo(
        () => {
            const savedValue = localStorage.getItem(key);
            if (savedValue === null) {
                const initialValue = getInitialValue(initializer as Initializer<T>);
                storeValue(initialValue);
                return initialValue;
            }
            else return parser(savedValue);
        },
        //Intentionally omitting initialValue and parser
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [key]
    );

    const [value, setLocalValue] = useState(loadedValue);

    useSynchronousEffect(() => {
        const setters = getOrCompute(setterMap, key, () => new Set());
        setters.add(setLocalValue);

        return () => {
            const setters = setterMap.get(key);
            if (!setters) return;

            setters.delete(setLocalValue);

            if (setters.size === 0) {
                setterMap.delete(key);
            }
        };
    }, [key, setLocalValue]);

    const setValue = useCallback((updater: Updater<T>) => {
        setLocalValue(prevValue => {
            //Compute the updated value
            const newValue = getUpdatedValue(updater, prevValue);

            //Save to LocalStorage
            storeValue(newValue);

            //Update other hooks with the same key
            //(Not sure if it's safe to call a setter from inside another setter...
            //but I don't have the new value until this point...)
            const setters = setterMap.get(key);
            if (setters) {
                for (const setter of setters) {
                    if (setter !== setLocalValue) {
                        setter(newValue);
                    }
                }
            }

            return newValue;
        });
    }, [storeValue, setLocalValue, key]);

    return [value, setValue];
}
