import React, {useCallback, useEffect, useMemo, useState} from "react";

import {Box, Paper} from "@mui/material";
import {BigNumber} from "bignumber.js";
import {startCase} from "lodash";

import {Api} from "~/api/api";
import {PageParams} from "~/api/common-query-params";
import {Accordion, AccordionDetails, AccordionSummary} from "~/components/accordion";
import {Alert} from "~/components/alert";
import {useConfirm} from "~/components/dialog";
import {CollapsibleFilterGrid} from "~/components/filter-grid";
import {ButtonLink} from "~/components/link";
import {LoadingTreatment} from "~/components/loading-treatment";
import {Menu} from "~/components/menu";
import {MenuItem} from "~/components/menu-item";
import {Page} from "~/components/page";
import {CircularProgress} from "~/components/progress";
import {EntityDisplay} from "~/entity/components/entity-display";
import {FundraiseExemption, FundraiseState} from "~/entity/wefunder-types";
import {TransferFilters} from "~/transfer/components/transfer-filters";
import {TransferListDisplay} from "~/transfer/components/transfer-list-display";
import {Transfer} from "~/transfer/transfer";
import {inject} from "~/utils/di";
import {useLoadable, useLoadableCallback} from "~/utils/loadable";
import {Range} from "~/utils/range";
import {useRequiredParams} from "~/utils/routing";
import {WordGroup} from "~/utils/styles";
import {useAbortController} from "~/utils/use-abort-controller";
import {useDebouncedValue} from "~/utils/use-debounced-value";
import {useIsFirstRender} from "~/utils/use-is-first-render";
import {useStateObject, useTypedStateObject} from "~/utils/use-state-object";
import {VirtualAccountDetails} from "~/account/components/virtual-account-details";

//There is a lot of copy/paste here that I just don't know a great way to abstract.
//There is absolutely room for improvement with this filtering API.



export const VirtualAccountDetailsPage = inject({api: Api}, function VirtualAccountDetailsPage({api}: {api: Api}) {
    const {accountId} = useRequiredParams("accountId");

    const confirm = useConfirm();

    const [accountDetails] = useLoadable(
        api.virtualAccounts.getAccount,
        [accountId, useAbortController()],
        {eager: true}
    );

    const accountDisableAbortController = useAbortController();
    const [disableAccount, accountDisable] = useLoadableCallback(
        () => api.virtualAccounts.disableAccount(accountId, accountDisableAbortController.signal),
        [accountId, accountDisableAbortController]
    );

    const accountEnableAbortController = useAbortController();
    const [enableAccount, accountEnable] = useLoadableCallback(
        () => api.virtualAccounts.enableAccount(accountId, accountEnableAbortController.signal),
        [accountId, accountEnableAbortController]
    );

    const accountIsEnabled = accountEnable.isLoaded()
        || !accountDisable.isLoaded() && accountDetails.isLoaded() && accountDetails.value.enabled;

    const onDisableAccount = useCallback(async () => {
        const acct = accountDetails.getValue();

        if (!accountIsEnabled) return;

        if (await confirm({title: `Disable Virtual Account ${acct.name} (ID ${acct.id})?`})) {
            accountEnableAbortController.abort();
            disableAccount();
        }
    }, [accountDetails, accountEnableAbortController, accountIsEnabled, confirm, disableAccount]);

    const onEnableAccount = useCallback(async () => {
        const acct = accountDetails.getValue();

        if (accountIsEnabled) return;

        if (await confirm({title: `Re-enable Virtual Account ${acct.name} (ID ${acct.id})?`})) {
            accountDisableAbortController.abort();
            enableAccount();
        }
    }, [accountDetails, accountDisableAbortController, accountIsEnabled, confirm, enableAccount]);

    const titleSegments = accountDetails.case({
        loaded: acct => [
            `Virtual Account ${acct.id}: ${acct.name}`,
            `(${startCase(acct.accountType)} ${startCase(acct.accountRole.type)})`
        ],
        else: () => [`Virtual Account ${accountId}`]
    });

    return (
        <Page
            renderTitle={() => <>{titleSegments[0]} <WordGroup>{titleSegments[1]}</WordGroup></>}
            tabTitle={titleSegments.join(" ")}
            titleSize="medium"
            sx={{
                //Can't use gap 'cause it messes with the scroll padding pseudo-element
                "& .PcPage-content > * + *": {
                    mt: 2
                }
            }}
            actions={
                accountDetails.isLoaded() && <>
                    <ButtonLink variant="contained" to={`/transfers/create?initFrom=${accountDetails.value.id}`}>
                        New Transfer
                    </ButtonLink>
                    <Menu label="More Options">
                        {accountIsEnabled ?
                            <MenuItem sx={{color: "error.main"}} onClick={onDisableAccount}>Disable</MenuItem>
                        :
                            <MenuItem onClick={onEnableAccount}>Re-Enable</MenuItem>
                        }
                    </Menu>
                </>
            }
        >
            <LoadingTreatment loadable={accountDetails} description="account details">{acct => <>
                {accountEnable.case({
                    loading: () => <Alert icon={<CircularProgress size={22}/>}>Re-enabling account...</Alert>,
                    error: errorMsg => <Alert severity="error">Error re-enabling account: {errorMsg}</Alert>,
                    else: () => null
                })}
                {accountDisable.case({
                    loading: () =>
                        <Alert severity="warning" icon={<CircularProgress size={22}/>}>Disabling account...</Alert>,
                    error: errorMsg =>
                        <Alert severity="error">Error disabling account: {errorMsg}</Alert>,
                    else: () =>
                        !accountIsEnabled &&
                            <Alert severity="warning">Account is disabled</Alert>
                })}
                <VirtualAccountDetails account={acct}/>
            </>}</LoadingTreatment>
        </Page>
    );
});
