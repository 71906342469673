import React, {memo, useCallback} from "react";

import {useFilterGridContext} from "../filter-grid";
import {FormControlLabel} from "../form-control-label";
import {Radio, RadioGroup} from "../radio";

export interface BooleanFilterProps {
    readonly filterKey: string;
    readonly label?: string;
    readonly trueLabel?: string;
    readonly falseLabel?: string;
    readonly value: boolean | undefined;
    readonly onValueChange: (value: boolean | undefined) => void;
}

export const BooleanFilter = memo(function BooleanFilter({
    filterKey,
    label,
    trueLabel = "Yes",
    falseLabel = "No",
    value,
    onValueChange
}: BooleanFilterProps) {
    const {GridFilter} = useFilterGridContext();

    const onRadioChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>, value: string) => onValueChange(value === "true"),
        [onValueChange]
    );

    const onRemove = useCallback(() => onValueChange(undefined), [onValueChange]);

    const getSummary = useCallback(
        (label: string) => `${label}: ${value ? trueLabel : falseLabel}`,
        [falseLabel, trueLabel, value]
    );

    return (
        <GridFilter
            filterKey={filterKey}
            label={label}
            hasValue={value !== undefined}
            getSummary={getSummary}
            onRemove={onRemove}
            sx={{alignItems: "center"}}
        >{(focusRef, renderBaseFilterUi) =>
            renderBaseFilterUi(
                <RadioGroup row sx={{flex: 1}} value={value ?? ""} onChange={onRadioChange}>
                    <FormControlLabel control={<Radio/>} inputRef={focusRef} value={true} label={trueLabel}/>
                    <FormControlLabel control={<Radio/>} value={false} label={falseLabel}/>
                </RadioGroup>
            )
        }</GridFilter>
    );
});
