import React from "react";
import {Panel} from "~/recon/recon-page";
import useTransferQuery from "~/disbursement/use-transfer-query";
import {LinearProgress, Stack} from "@mui/material";
import {Error} from "~/recon/components/recon-review";
import {DataGrid, GridColDef, GridRowId, GridRowsProp} from "@mui/x-data-grid";
import {first} from "lodash";
import {MoneyFormat} from "~/utils/money";
import {DisbursementFilters} from "~/disbursement/disbursement-filters";
import {TransferSummary} from "~/transfer/transfer-summary";
import {usePreviousValue} from "~/utils/use-previous-value";
import {VirtualAccountPreviewOpt} from "~/recon/components/account-link-info";

const columns: GridColDef[] = [
  {flex: 0.7, field: "id", headerName: "Id"},
  {flex: 0.8, field: "state", headerName: "State"},
  {flex: 0.7, field: "tranche", headerName: "Tranche", valueGetter: (({row}: {row: TransferSummary}) => row.intentionData?.wfTrancheId)},
  {flex: 1, field: "amount", headerName: "Amount", valueGetter: (({row}: {row: TransferSummary}) => MoneyFormat.formatNumber(row.amount.toNumber(), row.toAccount?.currency))},
  {flex: 3, field: "toAccountId", headerName: "Company", renderCell: params => VirtualAccountPreviewOpt((params.row as TransferSummary).toAccount)},
  {flex: 3, field: "description", headerName: "Description", valueGetter: (({row}: {row: TransferSummary}) => row.intentionData?.description)}
];

const DisbursementList = ({transferId, onSelected}: {transferId: string | undefined, onSelected: (_: string | undefined) => void}) => {
  const {query, filters, setFilters, sort, setSort, setPage, setPageSize, page, pageSize} =
    useTransferQuery({tag: "disbursement_tranche"}, [{field: "id", sort: "desc"}]);
  const previousRowCount = usePreviousValue(query.data?.pagination.totalItems) || 0;
  const rows = query.data?.data || [];

  return (
    <Panel sx={{overflow: "auto"}}>
      <Stack>
        <DisbursementFilters filter={filters} setFilter={setFilters}/>
        {query.isFetching && <LinearProgress/>}
        {query.isError && <Error error={query.error}/>}
        <DataGrid
            rows={rows as GridRowsProp}
            columns={columns}
            autoHeight
            rowsPerPageOptions={[10, 30, 50, 100]}
            onSelectionModelChange={ids => onSelected(first(ids) as (string | undefined))}
            selectionModel={transferId ? [transferId as GridRowId] : []}
            disableDensitySelector
            disableColumnFilter
            disableColumnSelector
            hideFooterSelectedRowCount
            onSortModelChange={setSort}
            sortModel={sort}
            onPageSizeChange={setPageSize}
            onPageChange={page => setPage(page + 1)}
            sortingMode={"server"}
            paginationMode={"server"}
            pageSize={query.data?.pagination.perPage || pageSize}
            page={(query.data?.pagination.currentPage || page) - 1}
            rowCount={query.data?.pagination.totalItems || previousRowCount}
        />
      </Stack>
    </Panel>
  );
};

export default DisbursementList;

