import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {CURRENCIES, Currency} from "~/currency";

export class MaskedWireInfo extends JsonDto {
  @jsonProp()
  public readonly number!: string;

  @jsonProp(JsonTypes.string, {optional: true})
  public readonly country?: string;

  @jsonProp(JsonTypes.stringUnion(CURRENCIES))
  public readonly currency!: Currency;

  public constructor(init?: unknown) {
    super();
    this.init(MaskedWireInfo, init);
  }
}
