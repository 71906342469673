import React, {ForwardedRef, useCallback, useId} from "react";

import {Paper, Stack, SxProps, Theme} from "@mui/material";
import {startCase} from "lodash";

import {IconButton} from "./button";
import {Flyout} from "./flyout";
import {Icon} from "./icon";
import {MenuItem} from "./menu-item";
import {TextField} from "./text-field";
import {SortDirection} from "~/api/common-query-params";
import {forwardRefGeneric} from "~/utils/forward-ref-generic";
import {Span} from "~/utils/styles";
import {Focusable} from "~/utils/types";

export interface SortOptionsProps<SortKey extends string> {
    readonly sortKeys: readonly {readonly key: SortKey, readonly label?: string}[];
    readonly sortBy: SortKey | undefined;
    readonly onSortByChange: (key: SortKey | undefined) => void;
    readonly sortDirection: SortDirection | undefined;
    readonly onSortDirectionChange: (dir: SortDirection | undefined) => void;
    readonly expanded?: boolean;
    readonly sx?: SxProps<Theme>;
}

export const SortOptions = forwardRefGeneric(function SortOptions<SortKey extends string>({
    sortKeys,
    sortBy,
    onSortByChange,
    sortDirection = "asc",
    onSortDirectionChange,
    expanded = false,
    sx = []
}: SortOptionsProps<SortKey>, ref: ForwardedRef<Focusable>) {
    const onSelectSort = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => onSortByChange(
            (ev.target.value || undefined) as SortKey | undefined
        ),
        [onSortByChange]
    );

    const onSelectSortDirection = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => onSortDirectionChange(ev.target.value as SortDirection),
        [onSortDirectionChange]
    );

    const resetToDefaults = useCallback(() => {
        onSortByChange(undefined);
        onSortDirectionChange(undefined);
    }, [onSortByChange, onSortDirectionChange]);

    const sortById = useId();
    const sortByLabelId = useId();

    const renderOptions = (sx: SxProps<Theme> = []) =>
        <Stack direction="row" alignItems="baseline" gap={1} sx={sx}>
            <label id={sortByLabelId} htmlFor={sortById}>Sort by</label>
            <TextField
                select
                {...(expanded && {inputRef: ref})}
                id={sortById}
                variant="standard"
                size="small"
                sx={{flex: 1}}
                SelectProps={{displayEmpty: true, labelId: sortByLabelId}}
                value={sortBy ?? ""}
                onChange={onSelectSort}
            >
                {/* Instead of a placeholder, you have to use a MenuItem with the value "" */}
                <MenuItem value=""><Span color="text.secondary">(Default)</Span></MenuItem>
                {sortKeys.map(({key, label}) =>
                    <MenuItem key={key} value={key}>{label ?? startCase(key)}</MenuItem>
                )}
            </TextField>
            {sortBy && <>
                <TextField
                    select
                    variant="standard"
                    size="small"
                    value={sortDirection}
                    onChange={onSelectSortDirection}
                >
                    {/* Using abbreviated labels for horizontal brevity in the UI */}
                    <MenuItem value="asc">Asc</MenuItem>
                    <MenuItem value="desc">Desc</MenuItem>
                </TextField>
                <IconButton title="Reset to default" size="small" onClick={resetToDefaults}>
                    <Icon fa="undo"/>
                </IconButton>
            </>}
        </Stack>;

    if (expanded) return (
        <Stack
            component={Paper}
            className="PcSortOptions-root PcSortOptions-expanded"
            direction="row"
            alignItems="baseline"
            p={2}
            gap={1}
            sx={sx}
        >
            {/* The sizing of this icon is a little weird. It looks better at 1.6em. */}
            <Icon fa="sort" sx={{fontSize: "1.6em"}}/>
            {renderOptions({flex: 1, my: "auto"})}
        </Stack>
    );
    else return (
        <Flyout
            label="Sort"
            buttonVariant="icon"
            icon={<Icon fa="sort"/>}
            buttonProps={{autoTooltip: true, ref: ref as any, className: "PcSortOptions-root PcSortOptions-button", sx}}
            popoverProps={{
                anchorOrigin: {horizontal: "right", vertical: "bottom"},
                transformOrigin: {horizontal: "right", vertical: "top"}
            }}
        >
            {renderOptions({p: 2})}
        </Flyout>
    );
});
