import {BigNumber} from "bignumber.js";
import {makeObservable} from "mobx";

import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {Temporal} from "@js-temporal/polyfill";

export class TransferAmountStat extends JsonDto {
  @jsonProp()
  public readonly time!: Temporal.Instant;

  @jsonProp(JsonTypes.string)
  public readonly state!: string;

  @jsonProp(JsonTypes.number)
  public readonly count!: number;

  @jsonProp(JsonTypes.number)
  public readonly amount!: number;

  public constructor(init?: unknown) {
    super();
    this.init(TransferAmountStat, init);
 }

}
