import React, {ForwardedRef, forwardRef, useCallback, useEffect, useRef} from "react";

import {startCase} from "lodash";
import {flowResult} from "mobx";
import {observer} from "mobx-react-lite";
import {MarkRequired} from "ts-essentials";

import {UserCreationDto} from "../api";
import {UserStore} from "../store";
import {User} from "../user";
import {Form, FormProps} from "~/components/form";
import {MenuItem} from "~/components/menu-item";
import {TextField} from "~/components/text-field";
import {inject} from "~/utils/di";
import {useInputState} from "~/utils/use-input-state";
import {useTypedStateObject} from "~/utils/use-state-object";
import {useCustomValidity} from "~/utils/use-custom-validity";

export interface UserCreationFormProps extends FormProps {
    readonly userStore?: UserStore;
    readonly onSuccess?: () => void;
}

export const UserCreationForm = inject({userStore: UserStore}, observer(forwardRef(function UserCreationForm({
    userStore,
    onSuccess,
    onSubmit: onSubmitProp,
    sx = [],
    ...formProps
}: MarkRequired<UserCreationFormProps, "userStore">, ref: ForwardedRef<HTMLFormElement>) {
    const confirmEmailInput = useRef<HTMLInputElement>(null);
    const confirmPasswordInput = useRef<HTMLInputElement>(null);

    const [dto, setDto] = useTypedStateObject<UserCreationDto>()({
        email: useInputState(""),
        firstName: useInputState(""),
        lastName: useInputState(""),
        password: useInputState(""),
        role: useInputState<User.Role>("support")
    });

    const [confirmEmail, onConfirmEmailInput] = useInputState("");
    const [confirmPassword, onConfirmPasswordInput] = useInputState("");

    useCustomValidity(confirmEmailInput, "Must match Email Address", confirmEmail === dto.email);

    useCustomValidity(confirmPasswordInput, "Must match Password", confirmPassword === dto.password);

    const onSubmit = useCallback(async (ev: React.FormEvent<HTMLFormElement>) => {
        onSubmitProp?.(ev);
        if (!ev.defaultPrevented) {
            ev.preventDefault();
            await flowResult(userStore.createUser(dto));
            onSuccess?.();
        }
    }, [dto, onSubmitProp, userStore, onSuccess]);

    useEffect(() => () => userStore.resetUserCreationStatus(), [userStore]);

    return (
        <Form
            ref={ref}
            submitStatus={userStore.userCreationStatus}
            sx={[{minWidth: "30vw"}, sx].flat()}
            {...{onSubmit}}
            {...formProps}
        >
            <TextField type="email" label="Email Address" required value={dto.email} onInput={setDto.email}/>
            <TextField
                type="email"
                inputRef={confirmEmailInput}
                label="Confirm Email Address"
                required
                value={confirmEmail}
                onInput={onConfirmEmailInput}
            />
            <TextField type="password" label="Password" required value={dto.password} onInput={setDto.password}/>
            <TextField
                type="password"
                inputRef={confirmPasswordInput}
                label="Confirm Password"
                required
                value={confirmPassword}
                onInput={onConfirmPasswordInput}
            />
            <TextField label="First Name" required value={dto.firstName} onInput={setDto.firstName}/>
            <TextField label="Last Name" required value={dto.lastName} onInput={setDto.lastName}/>
            <TextField select label="Role" required value={dto.role} onChange={setDto.role}>
                {User.ROLES.map(role =>
                    <MenuItem key={role} value={role}>{startCase(role)}</MenuItem>
                )}
            </TextField>
        </Form>
    );
})));
