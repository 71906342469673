import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {BigNumber} from "bignumber.js";
import {Temporal} from "@js-temporal/polyfill";
import {TransferSummary} from "~/transfer/transfer-summary";

export class StripePayoutTransaction extends JsonDto {
    public static readonly STATUSES = ["pending", "available"] as const;
    public static readonly BALANCE_TRANSACTION_TYPES = [
        "adjustment",
        "advance",
        "advance_funding",
        "anticipation_repayment",
        "application_fee",
        "application_fee_refund",
        "charge",
        "connect_collection_transfer",
        "contribution",
        "issuing_authorization_hold",
        "issuing_authorization_release",
        "issuing_dispute",
        "issuing_transaction",
        "payment",
        "payment_failure_refund",
        "payment_refund",
        "payout",
        "payout_cancel",
        "payout_failure",
        "refund",
        "refund_failure",
        "reserve_transaction",
        "reserved_funds",
        "stripe_fee   ",
        "stripe_fx_fee ",
        "taxFee",
        "topup",
        "topup_reversal",
        "transfer",
        "transfer_cancel",
        "transfer_failure",
        "transfer_refund"
    ] as const;

    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp(JsonTypes.id)
    public readonly stripePayoutId!: number;

    @jsonProp(JsonTypes.id, {optional: true})
    public readonly stripeObjectId!: number | undefined;

    @jsonProp(JsonTypes.bigNumber)
    public readonly amount!: BigNumber;

    @jsonProp(JsonTypes.bigNumber)
    public readonly feeAmount!: BigNumber;

    @jsonProp(JsonTypes.bigNumber)
    public readonly netAmount!: BigNumber;

    @jsonProp(JsonTypes.string, {optional: true})
    public readonly currency: "usd" | "eur" | undefined;

    @jsonProp(JsonTypes.stringUnion(StripePayoutTransaction.STATUSES))
    public readonly status!: StripePayoutTransaction.Status;

    @jsonProp(JsonTypes.stringUnion(StripePayoutTransaction.BALANCE_TRANSACTION_TYPES))
    public readonly stripeType!: StripePayoutTransaction.BalanceTransactionType;

    @jsonProp()
    public readonly reportingCategory!: String;

    @jsonProp()
    public readonly updatedAt!: Temporal.Instant;

    @jsonProp()
    public readonly createdAt!: Temporal.Instant;

    //TODO: Add the related transfer here

    public constructor(init?: unknown) {
        super();
        this.init(StripePayoutTransaction, init);
    }
}

export class StripePaymentIntent extends JsonDto {
    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp(JsonTypes.id)
    public readonly stripeAccountId!: string;

    @jsonProp(JsonTypes.id)
    public readonly stripeCustomerId!: string;

    @jsonProp(JsonTypes.id)
    public readonly stripePaymentMethodId!: string;

    @jsonProp(JsonTypes.bigNumber)
    public readonly amount!: BigNumber;

    @jsonProp(JsonTypes.bigNumber)
    public readonly stripeFeeAmount!: BigNumber;

    @jsonProp(JsonTypes.string, {optional: true})
    public readonly currency: "usd" | "eur" | undefined;

    public constructor(init?: unknown) {
        super();
        this.init(StripePaymentIntent, init);
    }
}

export class StripePayoutTransactionWithTransfer extends JsonDto {
    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp(JsonTypes.dto(StripePayoutTransaction))
    public readonly payoutTransaction!: StripePayoutTransaction;

    @jsonProp(JsonTypes.dto(TransferSummary), {optional: true})
    public readonly transfer!: TransferSummary | null;

    @jsonProp(JsonTypes.dto(StripePaymentIntent), {optional: true})
    public readonly stripePaymentIntent!: StripePaymentIntent | null;

    public constructor(init?: unknown) {
        super();
        this.init(StripePayoutTransactionWithTransfer, init);
    }
}

export namespace StripePayoutTransaction {
    export type Status = (typeof StripePayoutTransaction.STATUSES)[number];
    export type BalanceTransactionType = (typeof StripePayoutTransaction.BALANCE_TRANSACTION_TYPES)[number];
}
