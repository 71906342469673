export const FUNDRAISE_EXEMPTIONS = ["C", "D", "A"] as const;

export type FundraiseExemption = (typeof FUNDRAISE_EXEMPTIONS)[number];

export const FUNDRAISE_STATES = [
    "legacy",
    "draft",
    "ready",
    "successful",
    "pending_review",
    "open",
    "preparing",
    "aborted"
];

export type FundraiseState = (typeof FUNDRAISE_STATES)[number];
