import {useCallback, useMemo} from "react";
import {useSearchParams} from "react-router-dom";

import {castArray} from "lodash";
import {MarkRequired} from "ts-essentials";

import {_ImplInitializer, getInitialValue, Initializer, Updater} from "./hook-utils";
import {useForceRender} from "./use-force-render";

/* Used to stage changes to query params so that they can be committed asynchronously as a batch */

/** The number of milliseconds to debounce changes to query params by */
const QUERY_PARAM_DEBOUNCE_MS = 250;

/** A dictionary containing un-serialized pending values */
let pendingQueryParamValues: {[key: string]: any} = {};

/** The URLSearchParams object containing the serialized pending changes */
let pendingSearchParams: URLSearchParams | null = null;

/**
 * Whether to push a new history entry when committing the query param changes.
 * Should be true if any changed param had push: true.
 */
let pushNextParams = false;

/** The timeout ID for committing query param changes */
let queryParamUpdateTimeout: ReturnType<typeof setTimeout> | null = null;

export interface UseQueryParamOptions<T> {
    /**
     * A function to parse the value from the URL parameter.
     *
     * URL query parameters are repeatable and so the serialized value can be an array.
     * For convenience in the common case, such arrays are spread into the parser.
     *
     * Strings provided are already URI-decoded.
     *
     * In the event that the parameter value cannot be parsed, returning undefined will use the default value.
     *
     * Can be omitted for simple string values. The default parser simply returns its first argument unchanged.
     */
    readonly parser?: (str: string, ...strs: string[]) => T | undefined;

    /**
     * A function to serialize the value into the URL parameter.
     * Its output will be URI-encoded.
     * An array can be returned, which corresponds to multiple instances of the query parameter.
     * Returning undefined removes the query parameter.
     * The default serializer performs string coercion on all values other than undefined.
     */
    readonly serializer?: (x: T) => string | readonly string[] | undefined;

    /** If set to true, creates a history entry for each change to the value. By default performs a replace. */
    readonly push?: boolean;

    /**
     * By default query param changes are batched and debounced.
     * Setting this to true causes the parameter to immediately update the query string.
     * This setting can be overridden when setting the value.
     */
    readonly immediate?: boolean;
}

type QueryParamValueTuple<T> = [
    value: T,
    setValue: (updater: Updater<T>, options?: {readonly immediate?: boolean}) => void
];

/**
 * Hook for storing a state value in the URL query string.
 * The parser and serializer functions are intentionally not watched for changes.
 *
 * @param param - The query parameter to use
 * @param initializer - Provides an initial value to use if the query parameter is not present in the URL
 * @param options - Optional parameters
 *
 * @returns A standard hook value tuple over the value of the query parameter
 */
export function useQueryParam(
    param: string,
    initializer: Initializer<string>,
    options?: UseQueryParamOptions<string>
): QueryParamValueTuple<string>;
export function useQueryParam<T>(
    param: string,
    initializer: Initializer<T>,
    ...options:
        [T] extends [string | null | undefined] ? [o?: UseQueryParamOptions<T>] :
        [string] extends [T] ? [o?: UseQueryParamOptions<T>] :
        [MarkRequired<UseQueryParamOptions<T>, "parser">]
): QueryParamValueTuple<T>;
export function useQueryParam<T>(
    param: string,
    initializer: _ImplInitializer<T>,
    {
        parser = x => x as any as T,
        serializer = x => x !== undefined ? String(x) : undefined,
        push = false,
        immediate: paramImmediate = false
    }: UseQueryParamOptions<T> = {}
): QueryParamValueTuple<T> {
    const [searchParams, setSearchParams] = useSearchParams();

    const getCurrentValue = useCallback(
        (searchParams: URLSearchParams) => {
            const [str, ...strs] = searchParams.getAll(param);
            //Why the hell does TS not mark str as potentially undefined? Arrays can be empty...
            //eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const parsedValue = str !== undefined ? parser(str, ...strs) : str;
            return parsedValue !== undefined ? parsedValue : getInitialValue(initializer as Initializer<T>);
        },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [param] //initializer and parser intentionally omitted
    );

    const currentParamValue = useMemo(() => getCurrentValue(searchParams), [getCurrentValue, searchParams]);
    const value: T = param in pendingQueryParamValues ? pendingQueryParamValues[param] : currentParamValue;

    const forceRender = useForceRender();
    const setValue = useCallback(
        (updater: Updater<T>, {immediate = paramImmediate}: {readonly immediate?: boolean} = {}) => {
            //To keep the setter stable, we need to freshly parse the current search params rather than use a closure
            let _currentSearchParams: URLSearchParams | null = null;
            const getCurrentSearchParams = () => _currentSearchParams ??= new URLSearchParams(document.location.search);

            const newValue = typeof updater === "function" ?
                updater(
                    param in pendingQueryParamValues ?
                        pendingQueryParamValues[param] :
                        getCurrentValue(getCurrentSearchParams())
                ) :
                updater;

            pendingQueryParamValues[param] = newValue;
            forceRender(); //Since we're staging the value outside of React's control

            const serializedValue = serializer(newValue);
            pendingSearchParams ??= getCurrentSearchParams();
            pendingSearchParams.delete(param);
            if (serializedValue !== undefined) {
                for (const val of castArray(serializedValue)) {
                    pendingSearchParams.append(param, val);
                }
            }

            pushNextParams ||= push;

            if (queryParamUpdateTimeout !== null) {
                clearTimeout(queryParamUpdateTimeout);
            }

            //It shouldn't matter which instance of the hook this comes from,
            //since the individual params are serialized ahead of time,
            //and any setSearchParams function should be equally valid to use
            function commitQueryParamChanges() {
                if (pendingSearchParams) {
                    setSearchParams(pendingSearchParams, {replace: !pushNextParams});
                }

                pendingQueryParamValues = {};
                pendingSearchParams = null;
                pushNextParams = false;
                queryParamUpdateTimeout = null;
            }

            if (immediate) {
                commitQueryParamChanges();
            }
            else {
                queryParamUpdateTimeout = setTimeout(commitQueryParamChanges, QUERY_PARAM_DEBOUNCE_MS);
            }
        },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [getCurrentValue, param, push, setSearchParams] //serializer intentionally omitted
    );

    return [value, setValue];
}
