import {useEffect} from "react";

import {BASE_PAGE_TITLE} from "~/constants";

/** Sets the title of the page */
export function usePageTitle(title: string | null | undefined): void {
    useEffect(() => {
        if (title) {
            document.title = `${title} - ${BASE_PAGE_TITLE}`;
        }

        return () => {
            document.title = BASE_PAGE_TITLE;
        };
    }, [title]);
}
