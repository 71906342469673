import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

import {BigNumber} from "bignumber.js";
import {flowResult} from "mobx";
import {observer} from "mobx-react-lite";

import {InitiateTransferForm, InitiateTransferFormHandle} from "../components/initiate-transfer-form";
import {VirtualAccountFilters} from "~/account/components/virtual-account-filters";
import {VirtualAccountListDisplay} from "~/account/components/virtual-account-list-display";
import {VirtualAccountStore} from "~/account/store";
import {VirtualAccount} from "~/account/virtual-account";
import {PageParams} from "~/api/common-query-params";
import {Backend} from "~/backend/backend";
import {Drawer, DrawerHandle, DrawerWidth} from "~/components/drawer";
import {FilterAndSortBar} from "~/components/filter-and-sort-bar";
import {CollapsibleFilterGrid} from "~/components/filter-grid";
import {LoadingTreatment} from "~/components/loading-treatment";
import {Page, PageWidth} from "~/components/page";
import {FundraiseExemption, FundraiseState} from "~/entity/wefunder-types";
import {inject} from "~/utils/di";
import {Range} from "~/utils/range";
import {useAbortableEffect} from "~/utils/use-abortable-effect";
import {useDebouncedValue} from "~/utils/use-debounced-value";
import {useIsFirstRender} from "~/utils/use-is-first-render";
import {useStateObject, useTypedStateObject} from "~/utils/use-state-object";
import {Transfer} from "~/transfer/transfer";

export const InitiateTransferPage = inject(
    {virtualAccountStore: VirtualAccountStore},
    observer(function InitiateTransferPage({virtualAccountStore}: {virtualAccountStore: VirtualAccountStore}) {
        const [queryParams] = useSearchParams();
        const initFrom = queryParams.get("initFrom") ?? undefined;
        const initTo = queryParams.get("initTo") ?? undefined;

        const navigate = useNavigate();

        const {virtualAccounts} = virtualAccountStore;

        const accountsSearchDrawer = useRef<DrawerHandle>(null);
        const formHandle = useRef<InitiateTransferFormHandle>(null);

        const [pageParams, setPageParams] = useTypedStateObject<PageParams>()({
            page: useState(1),
            perPage: useState(10)
        });

        const [filters, setFilters] = useStateObject({
            name: useState<string | undefined>(undefined),
            accountType: useState<VirtualAccount.AccountType | undefined>(undefined),
            accountRole: useState<VirtualAccount.AccountRole | undefined>(undefined),
            backendType: useState<Backend.Type | undefined>(undefined),
            backendName: useState<string | undefined>(undefined),
            investmentId: useState<string | undefined>(undefined),
            fundraiseId: useState<string | undefined>(undefined),
            fundraiseExemption: useState<FundraiseExemption | undefined>(undefined),
            fundraiseState: useState<FundraiseState | undefined>(undefined),
            companyId: useState<string | undefined>(undefined),
            companyName: useState<string | undefined>(undefined),
            investorId: useState<string | undefined>(undefined),
            investorName: useState<string | undefined>(undefined),
            investorAdmin: useState<boolean | undefined>(undefined)
        });

        const [investmentAmountRange, setInvestmentAmountRange] = useState<Range<BigNumber> | undefined>(undefined);

        //Reset the page to 1 when the filters change
        const isFirstRender = useIsFirstRender();
        useEffect(() => {
            if (!isFirstRender) {
                setPageParams.page(1);
            }
        }, [filters]); //eslint-disable-line react-hooks/exhaustive-deps

        const fetchParams = useMemo(() => ({
            ...pageParams,
            ...filters,
            ...investmentAmountRange?.toParams("investmentAmount")
        }), [filters, investmentAmountRange, pageParams]);

        const debouncedFetchParams = useDebouncedValue(fetchParams, 150);
        useAbortableEffect(
            abortSignal => flowResult(virtualAccountStore.fetchVirtualAccounts(debouncedFetchParams, abortSignal)),
            [virtualAccountStore, debouncedFetchParams]
        );

        const [accountsSearchOpen, setAccountsSearchOpen] = useState(false);
        const openAccountsSearch = useCallback(() => accountsSearchDrawer.current!.open(), [accountsSearchDrawer]);

        const onSelectAccountFromTable = useCallback((id: string, as: "from" | "to") => {
            if (as === "from") {
                formHandle.current!.setFromAccountId(id);
            }
            else {
                formHandle.current!.setToAccountId(id);
            }
        }, [formHandle]);

        const onTransferCreated = useCallback(
            (newTransfer: Transfer) => navigate(`/transfers/${newTransfer.id}`),
            [navigate]
        );

        return (
            <Page
                title="Initiate Transfer"
                width={PageWidth.NARROW}
                drawer={
                    <Drawer
                        ref={accountsSearchDrawer}
                        title="Select Virtual Accounts"
                        onOpenChange={setAccountsSearchOpen}
                        pinned
                        hidePinButton
                        renderWhenClosed
                        width={DrawerWidth.MEDIUM}
                        unpinnedWidth={DrawerWidth.FULL}
                        height="fixed"
                    >
                        <FilterAndSortBar>{() =>
                            <CollapsibleFilterGrid>
                                <VirtualAccountFilters
                                    name={filters.name}
                                    setName={setFilters.name}
                                    accountType={filters.accountType}
                                    setAccountType={setFilters.accountType}
                                    accountRole={filters.accountRole}
                                    setAccountRole={setFilters.accountRole}
                                    backendType={filters.backendType}
                                    setBackendType={setFilters.backendType}
                                    backendName={filters.backendName}
                                    setBackendName={setFilters.backendName}
                                    investmentId={filters.investmentId}
                                    setInvestmentId={setFilters.investmentId}
                                    fundraiseId={filters.fundraiseId}
                                    setFundraiseId={setFilters.fundraiseId}
                                    fundraiseExemption={filters.fundraiseExemption}
                                    setFundraiseExemption={setFilters.fundraiseExemption}
                                    fundraiseState={filters.fundraiseState}
                                    setFundraiseState={setFilters.fundraiseState}
                                    companyId={filters.companyId}
                                    setCompanyId={setFilters.companyId}
                                    companyName={filters.companyName}
                                    setCompanyName={setFilters.companyName}
                                    investorId={filters.investorId}
                                    setInvestorId={setFilters.investorId}
                                    investorName={filters.investorName}
                                    setInvestorName={setFilters.investorName}
                                    investorAdmin={filters.investorAdmin}
                                    setInvestorAdmin={setFilters.investorAdmin}
                                    {...{investmentAmountRange, setInvestmentAmountRange}}
                                />
                            </CollapsibleFilterGrid>
                        }</FilterAndSortBar>
                        <LoadingTreatment
                            loadable={virtualAccounts}
                            description="accounts"
                            observer
                            renderStaleValues
                        >{(accounts, stale) =>
                            <VirtualAccountListDisplay
                                accounts={[...accounts.data.values()]}
                                paginationState={accounts.pagination}
                                stale={stale}
                                onPageChange={setPageParams.page}
                                onRowsPerPageChange={setPageParams.perPage}
                                onSelectAccount={onSelectAccountFromTable}
                            />
                        }</LoadingTreatment>
                    </Drawer>
                }
            >
                <InitiateTransferForm
                    onSuccess={onTransferCreated}
                    defaultFromAccountId={initFrom}
                    defaultToAccountId={initTo}
                    handleRef={formHandle}
                    {...{openAccountsSearch, accountsSearchOpen}}
                />
            </Page>
        );
    })
);
