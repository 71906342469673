import React, {useState} from "react";

import {BigNumber} from "bignumber.js";
import {at} from "lodash";

import {Transfer} from "../transfer";
import {BaseFilterSetProps} from "~/components/filter-grid";
import {BooleanFilter, NestedFilterGrid, NumberFilter, SelectFilter, TextFilter} from "~/components/filters";
import {FUNDRAISE_EXEMPTIONS, FUNDRAISE_STATES, FundraiseExemption, FundraiseState} from "~/entity/wefunder-types";
import {Range} from "~/utils/range";
import {Setters} from "~/utils/types";
import {TransferIntentions, TransferStates} from "~/transfer";

//I still feel like there's some alternative API or refactoring that can reduce some boilerplate with reusing filters...

export interface TransferFilterState {
    readonly fromAccountId: string | undefined;
    readonly toAccountId: string | undefined;
    readonly relatedAccountId: string | undefined;
    readonly state: Transfer.State | undefined;
    readonly intention: Transfer.Intention | undefined;
    readonly investmentId: string | undefined;
    readonly fundraiseId: string | undefined;
    readonly fundraiseExemption: FundraiseExemption | undefined;
    readonly fundraiseState: FundraiseState | undefined;
    readonly companyId: string | undefined;
    readonly companyName: string | undefined;
    readonly investorId: string | undefined;
    readonly investorName: string | undefined;
    readonly investorAdmin: boolean | undefined;
    readonly amountRange: Range<BigNumber> | undefined;
}

export type TransferFiltersProps<OmitFilters extends keyof TransferFilterState = never> =
    BaseFilterSetProps<TransferFilterState, OmitFilters>;

export function TransferFilters<OmitFilters extends keyof TransferFilterState = never>({
    omit: _omit = [],
    ..._filterProps
}: TransferFiltersProps<OmitFilters>) {
    //These are impossible to use otherwise
    const omit = _omit as readonly (keyof TransferFilterState)[];
    const filterProps = _filterProps as any as TransferFilterState & Setters<TransferFilterState>;

    const [fundraiseFiltersActive, setFundraiseFiltersActive] = useState(
        () => at(filterProps, "fundraiseId", "fundraiseExemption", "fundraiseState").some(x => x !== undefined)
    );

    const [companyFiltersActive, setCompanyFiltersActive] = useState(
        () => at(filterProps, "companyId", "companyName").some(x => x !== undefined)
    );

    const [investorFiltersActive, setInvestorFiltersActive] = useState(
        () => at(filterProps, "investorId", "investorName", "investorAdmin").some(x => x !== undefined)
    );

    return <>
        {!omit.includes("fromAccountId") &&
            <TextFilter
                filterKey="fromAccountId"
                label="Source Account ID"
                value={filterProps.fromAccountId}
                onValueChange={filterProps.setFromAccountId}
            />
        }
        {!omit.includes("toAccountId") &&
            <TextFilter
                filterKey="toAccountId"
                label="Destination Account ID"
                value={filterProps.toAccountId}
                onValueChange={filterProps.setToAccountId}
            />
        }
        {!omit.includes("relatedAccountId") &&
            <TextFilter
                filterKey="relatedAccountId"
                label="Related Account ID (Source or Destination)"
                value={filterProps.relatedAccountId}
                onValueChange={filterProps.setRelatedAccountId}
            />
        }
        {!omit.includes("intention") &&
            <SelectFilter
                filterKey="intention"
                options={TransferIntentions}
                value={filterProps.intention}
                onValueChange={filterProps.setIntention}
            />
        }
        {!omit.includes("state") &&
            <SelectFilter
                filterKey="state"
                options={TransferStates}
                value={filterProps.state}
                onValueChange={filterProps.setState}
            />
        }
        {!omit.includes("investmentId") &&
            <TextFilter
                filterKey="investmentId"
                label="Investment ID"
                value={filterProps.investmentId}
                onValueChange={filterProps.setInvestmentId}
            />
        }
        {!omit.includes("amountRange") &&
            <NumberFilter
                type="BigNumber"
                filterKey="amount"
                value={filterProps.amountRange}
                onValueChange={filterProps.setAmountRange}
            />
        }
        <NestedFilterGrid
            filterKey="fundraise"
            active={fundraiseFiltersActive}
            onActiveChange={setFundraiseFiltersActive}
        >
            {!omit.includes("fundraiseId") &&
                <TextFilter
                    filterKey="id"
                    label="ID"
                    value={filterProps.fundraiseId}
                    onValueChange={filterProps.setFundraiseId}
                />
            }
            {!omit.includes("fundraiseExemption") &&
                <SelectFilter
                    filterKey="exemption"
                    options={FUNDRAISE_EXEMPTIONS}
                    value={filterProps.fundraiseExemption}
                    onValueChange={filterProps.setFundraiseExemption}
                />
            }
            {!omit.includes("fundraiseState") &&
                <SelectFilter
                    filterKey="state"
                    options={FUNDRAISE_STATES}
                    value={filterProps.fundraiseState}
                    onValueChange={filterProps.setFundraiseState}
                />
            }
        </NestedFilterGrid>
        <NestedFilterGrid
            filterKey="company"
            active={companyFiltersActive}
            onActiveChange={setCompanyFiltersActive}
        >
            {!omit.includes("companyId") &&
                <TextFilter
                    filterKey="id"
                    label="ID"
                    value={filterProps.companyId}
                    onValueChange={filterProps.setCompanyId}
                />
            }
            {!omit.includes("companyName") &&
                <TextFilter
                    filterKey="name"
                    value={filterProps.companyName}
                    onValueChange={filterProps.setCompanyName}
                />
            }
        </NestedFilterGrid>
        <NestedFilterGrid
            filterKey="investor"
            active={investorFiltersActive}
            onActiveChange={setInvestorFiltersActive}
        >
            {!omit.includes("investorId") &&
                <TextFilter
                    filterKey="id"
                    label="ID"
                    value={filterProps.investorId}
                    onValueChange={filterProps.setInvestorId}
                />
            }
            {!omit.includes("investorName") &&
                <TextFilter
                    filterKey="name"
                    value={filterProps.investorName}
                    onValueChange={filterProps.setInvestorName}
                />
            }
            {!omit.includes("investorAdmin") &&
                <BooleanFilter
                    filterKey="admin"
                    label="Is Admin"
                    value={filterProps.investorAdmin}
                    onValueChange={filterProps.setInvestorAdmin}
                />
            }
        </NestedFilterGrid>
    </>;
}
