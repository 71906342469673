import localforage from "localforage";

import {Authentication} from "./authentication";

/** Service for storing session authentication */
export class AuthenticationRepository {
    private static readonly LOCALFORAGE_KEY = "authentication";

    /** Attempts to load a saved Authentication and returns it if one is found */
    public async loadAuthentication(): Promise<Authentication | null> {
        return await localforage.getItem(AuthenticationRepository.LOCALFORAGE_KEY);
    }

    /**
     * Saves an Authentication
     *
     * @param auth - The Authentication to save
     */
    public async saveAuthentication(auth: Authentication): Promise<void> {
        await localforage.setItem(AuthenticationRepository.LOCALFORAGE_KEY, auth);
    }

    /**
     * Clears any saved Authentication
     */
    public async clearAuthentication(): Promise<void> {
        await localforage.removeItem(AuthenticationRepository.LOCALFORAGE_KEY);
    }
}
