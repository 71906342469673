import React, {useCallback} from "react";

import {TablePagination as MuiTablePagination, TablePaginationProps as MuiTablePaginationProps} from "@mui/material";

export {Pagination, PaginationProps} from "@mui/material";

export interface TablePaginationProps extends Omit<MuiTablePaginationProps, "onPageChange" | "onRowsPerPageChange"> {
    readonly onPageChange: (page: number, ev: React.MouseEvent<HTMLButtonElement> | null) => void;
    readonly onRowsPerPageChange?: (
        perPage: number,
        ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
}

export function TablePagination({
    page,
    rowsPerPage,
    onPageChange: onPageChangeProp,
    onRowsPerPageChange: onRowsPerPageChangeProp,
    ...props
}: TablePaginationProps) {
    //It's remarkably stupid that MUI makes you parse the value yourself
    const onRowsPerPageChange = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const origRowsPerPage = rowsPerPage;
        const newRowsPerPage = Number.parseInt(ev.target.value);
        onRowsPerPageChangeProp?.(newRowsPerPage, ev);

        //Update the current page so we're in the same relative position
        const origOffset = (page - 1) * origRowsPerPage;
        onPageChangeProp(Math.floor(origOffset / newRowsPerPage) + 1, null);
    }, [onPageChangeProp, onRowsPerPageChangeProp, page, rowsPerPage]);

    //For convenience and consistency,
    //reverse the order of the arguments to match the above handler and make the page 1-based
    const onPageChange = useCallback((ev: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        onPageChangeProp(page + 1, ev);
    }, [onPageChangeProp]);

    return <MuiTablePagination
        component="div" //Why TF does this default to <td>??
        page={page - 1} //Make page 1-based
        rowsPerPage={rowsPerPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        {...props}
    />;
}
