import {inject} from "~/utils/di";
import {Api} from "~/api/api";
import {useStateObject, useTypedStateObject} from "~/utils/use-state-object";
import {PageParams} from "~/api/common-query-params";
import React, {useEffect, useMemo, useState} from "react";
import {Transfer} from "~/transfer/transfer";
import {FundraiseExemption, FundraiseState} from "~/entity/wefunder-types";
import {Range} from "~/utils/range";
import {BigNumber} from "bignumber.js";
import {useIsFirstRender} from "~/utils/use-is-first-render";
import {useDebouncedValue} from "~/utils/use-debounced-value";
import {useLoadable} from "~/utils/loadable";
import {useAbortController} from "~/utils/use-abort-controller";
import {CollapsibleFilterGrid} from "~/components/filter-grid";
import {TransferFilters} from "~/transfer/components/transfer-filters";
import {LoadingTreatment} from "~/components/loading-treatment";
import {TransferListDisplay} from "~/transfer/components/transfer-list-display";

export const AccountTransfers = inject({api: Api}, function AccountTransfers({
                                                                          api,
                                                                          relatedAccountId
                                                                      }: {api: Api, relatedAccountId: string}) {
    const [pageParams, setPageParams] = useTypedStateObject<PageParams>()({
        page: useState(1),
        perPage: useState(10)
    });

    const [filters, setFilters] = useStateObject({
        fromAccountId: useState<string | undefined>(undefined),
        toAccountId: useState<string | undefined>(undefined),
        intention: useState<Transfer.Intention | undefined>(undefined),
        state: useState<Transfer.State | undefined>(undefined),
        investmentId: useState<string | undefined>(undefined),
        fundraiseId: useState<string | undefined>(undefined),
        fundraiseExemption: useState<FundraiseExemption | undefined>(undefined),
        fundraiseState: useState<FundraiseState | undefined>(undefined),
        companyId: useState<string | undefined>(undefined),
        companyName: useState<string | undefined>(undefined),
        investorId: useState<string | undefined>(undefined),
        investorName: useState<string | undefined>(undefined),
        investorAdmin: useState<boolean | undefined>(undefined)
    });

    const [amountRange, setAmountRange] = useState<Range<BigNumber> | undefined>(undefined);

    //Reset the page to 1 when the filters change
    const isFirstRender = useIsFirstRender();
    useEffect(() => {
        if (!isFirstRender) {
            setPageParams.page(1);
        }
    }, [filters]); //eslint-disable-line react-hooks/exhaustive-deps

    const fetchParams = useMemo(() => ({
        ...pageParams,
        ...filters,
        ...amountRange?.toParams("amount"),
        relatedAccountId
    }), [filters, amountRange, pageParams, relatedAccountId]);

    const debouncedFetchParams = useDebouncedValue(fetchParams, 150);
    const [transfers] = useLoadable(
        api.transfers.getTransfers,
        [debouncedFetchParams, useAbortController()],
        {eager: true, keepStaleValues: true}
    );

    return <>
        <CollapsibleFilterGrid accordionProps={{elevation: 2}} sx={{mb: 2}}>
            <TransferFilters
                omit={["relatedAccountId"]}
                fromAccountId={filters.fromAccountId}
                setFromAccountId={setFilters.fromAccountId}
                toAccountId={filters.toAccountId}
                setToAccountId={setFilters.toAccountId}
                intention={filters.intention}
                setIntention={setFilters.intention}
                investmentId={filters.investmentId}
                setInvestmentId={setFilters.investmentId}
                fundraiseId={filters.fundraiseId}
                setFundraiseId={setFilters.fundraiseId}
                fundraiseExemption={filters.fundraiseExemption}
                setFundraiseExemption={setFilters.fundraiseExemption}
                fundraiseState={filters.fundraiseState}
                setFundraiseState={setFilters.fundraiseState}
                companyId={filters.companyId}
                setCompanyId={setFilters.companyId}
                companyName={filters.companyName}
                setCompanyName={setFilters.companyName}
                investorId={filters.investorId}
                setInvestorId={setFilters.investorId}
                investorName={filters.investorName}
                setInvestorName={setFilters.investorName}
                investorAdmin={filters.investorAdmin}
                setInvestorAdmin={setFilters.investorAdmin}
                setState={setFilters.state}
                state={filters.state}
                amountRange={amountRange}
                setAmountRange={setAmountRange}
            />
        </CollapsibleFilterGrid>
        <LoadingTreatment loadable={transfers} description="transfers" renderStaleValues>{(transfers, stale) =>
            <TransferListDisplay
                elevation={2}
                transfers={transfers.data}
                paginationState={transfers.pagination}
                stale={stale}
                onPageChange={setPageParams.page}
                onRowsPerPageChange={setPageParams.perPage}
            />
        }</LoadingTreatment>
    </>;
});
