import React, {useState} from "react";

import {BigNumber} from "bignumber.js";
import {at} from "lodash";

import {VirtualAccount} from "../virtual-account";
import {Backend} from "~/backend/backend";
import {BaseFilterSetProps} from "~/components/filter-grid";
import {BooleanFilter, NestedFilterGrid, NumberFilter, SelectFilter, TextFilter} from "~/components/filters";
import {FUNDRAISE_EXEMPTIONS, FUNDRAISE_STATES, FundraiseExemption, FundraiseState} from "~/entity/wefunder-types";
import {Range} from "~/utils/range";
import {Setters} from "~/utils/types";

export interface VirtualAccountFilterState {
    name: string | undefined;
    accountType: VirtualAccount.AccountType | undefined;
    accountRole: VirtualAccount.AccountRole | undefined;
    backendType: Backend.Type | undefined;
    backendName: string | undefined;
    investmentId: string | undefined;
    fundraiseId: string | undefined;
    fundraiseExemption: FundraiseExemption | undefined;
    fundraiseState: FundraiseState | undefined;
    companyId: string | undefined;
    companyName: string | undefined;
    investorId: string | undefined;
    investorName: string | undefined;
    investorAdmin: boolean | undefined;
    investmentAmountRange: Range<BigNumber> | undefined;
}

export type VirtualAccountFiltersProps<OmitFilters extends keyof VirtualAccountFilterState = never> =
    BaseFilterSetProps<VirtualAccountFilterState, OmitFilters>;

export function VirtualAccountFilters<OmitFilters extends keyof VirtualAccountFilterState = never>({
    omit: _omit = [],
    ..._filterProps
}: VirtualAccountFiltersProps<OmitFilters>) {
    //These are impossible to use otherwise
    const omit = _omit as readonly (keyof VirtualAccountFilterState)[];
    const filterProps = _filterProps as any as VirtualAccountFilterState & Setters<VirtualAccountFilterState>;

    const [backendFiltersActive, setBackendFiltersActive] = useState(
        () => at(filterProps, "backendType", "backendName").some(x => x !== undefined)
    );

    const [investmentFiltersActive, setInvestmentFiltersActive] = useState(
        () => at(filterProps, "investmentId", "investmentAmountRange").some(x => x !== undefined)
    );

    const [fundraiseFiltersActive, setFundraiseFiltersActive] = useState(
        () => at(filterProps, "fundraiseId", "fundraiseExemption", "fundraiseState").some(x => x !== undefined)
    );

    const [companyFiltersActive, setCompanyFiltersActive] = useState(
        () => at(filterProps, "companyId", "companyName").some(x => x !== undefined)
    );

    const [investorFiltersActive, setInvestorFiltersActive] = useState(
        () => at(filterProps, "investorId", "investorName", "investorAdmin").some(x => x !== undefined)
    );

    return <>
        {!omit.includes("name") &&
            <TextFilter filterKey="name" value={filterProps.name} onValueChange={filterProps.setName}/>
        }
        {!omit.includes("accountType") &&
            <SelectFilter
                filterKey="accountType"
                options={VirtualAccount.ACCOUNT_TYPES}
                value={filterProps.accountType}
                onValueChange={filterProps.setAccountType}
            />
        }
        {!omit.includes("accountRole") &&
            <SelectFilter
                filterKey="accountRole"
                options={VirtualAccount.ACCOUNT_ROLES}
                value={filterProps.accountRole}
                onValueChange={filterProps.setAccountRole}
            />
        }
        <NestedFilterGrid
            filterKey="backend"
            active={backendFiltersActive}
            onActiveChange={setBackendFiltersActive}
        >
            {!omit.includes("backendType") &&
                <SelectFilter
                    filterKey="type"
                    options={Backend.TYPES}
                    value={filterProps.backendType}
                    onValueChange={filterProps.setBackendType}
                />
            }
            {!omit.includes("backendName") &&
                <TextFilter
                    filterKey="name"
                    value={filterProps.backendName}
                    onValueChange={filterProps.setBackendName}
                />
            }
        </NestedFilterGrid>
        <NestedFilterGrid
            filterKey="investment"
            active={investmentFiltersActive}
            onActiveChange={setInvestmentFiltersActive}
        >
            {!omit.includes("investmentId") &&
                <TextFilter
                    filterKey="id"
                    label="ID"
                    value={filterProps.investmentId}
                    onValueChange={filterProps.setInvestmentId}
                />
            }
            {!omit.includes("investmentAmountRange") &&
                <NumberFilter
                    filterKey="amount"
                    type="BigNumber"
                    value={filterProps.investmentAmountRange}
                    onValueChange={filterProps.setInvestmentAmountRange}
                />
            }
        </NestedFilterGrid>
        <NestedFilterGrid
            filterKey="fundraise"
            active={fundraiseFiltersActive}
            onActiveChange={setFundraiseFiltersActive}
        >
            {!omit.includes("fundraiseId") &&
                <TextFilter
                    filterKey="id"
                    label="ID"
                    value={filterProps.fundraiseId}
                    onValueChange={filterProps.setFundraiseId}
                />
            }
            {!omit.includes("fundraiseExemption") &&
                <SelectFilter
                    filterKey="exemption"
                    options={FUNDRAISE_EXEMPTIONS}
                    value={filterProps.fundraiseExemption}
                    onValueChange={filterProps.setFundraiseExemption}
                />
            }
            {!omit.includes("fundraiseState") &&
                <SelectFilter
                    filterKey="state"
                    options={FUNDRAISE_STATES}
                    value={filterProps.fundraiseState}
                    onValueChange={filterProps.setFundraiseState}
                />
            }
        </NestedFilterGrid>
        <NestedFilterGrid
            filterKey="company"
            active={companyFiltersActive}
            onActiveChange={setCompanyFiltersActive}
        >
            {!omit.includes("companyId") &&
                <TextFilter
                    filterKey="id"
                    label="ID"
                    value={filterProps.companyId}
                    onValueChange={filterProps.setCompanyId}
                />
            }
            {!omit.includes("companyName") &&
                <TextFilter
                    filterKey="name"
                    value={filterProps.companyName}
                    onValueChange={filterProps.setCompanyName}
                />
            }
        </NestedFilterGrid>
        <NestedFilterGrid
            filterKey="investor"
            active={investorFiltersActive}
            onActiveChange={setInvestorFiltersActive}
        >
            {!omit.includes("investorId") &&
                <TextFilter
                    filterKey="id"
                    label="ID"
                    value={filterProps.investorId}
                    onValueChange={filterProps.setInvestorId}
                />
            }
            {!omit.includes("investorName") &&
                <TextFilter
                    filterKey="name"
                    value={filterProps.investorName}
                    onValueChange={filterProps.setInvestorName}
                />
            }
            {!omit.includes("investorAdmin") &&
                <BooleanFilter
                    filterKey="admin"
                    label="Is Admin"
                    value={filterProps.investorAdmin}
                    onValueChange={filterProps.setInvestorAdmin}
                />
            }
        </NestedFilterGrid>
    </>;
}
