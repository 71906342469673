import {BigNumber} from "bignumber.js";
import {identity} from "lodash";

/** Like Number.parseInt(str, 10), but returns undefined instead of NaN for invalid numbers */
export function parseIntOrUndefined(str: string): number | undefined {
    const n = Number.parseInt(str, 10);
    if (Number.isNaN(n)) return undefined;
    return n;
}

/** Like Number.parseFloat, but returns undefined instead of NaN for invalid numbers */
export function parseFloatOrUndefined(str: string): number | undefined {
    const n = Number.parseFloat(str);
    if (Number.isNaN(n)) return undefined;
    return n;
}

/** Attempts to parse a bigint and returns undefined for invalid numbers instead of throwing a SyntaxError */
export function parseBigIntOrUndefined(str: string): bigint | undefined {
    try {
        return BigInt(str);
    }
    catch (err) {
        if (!(err instanceof SyntaxError)) throw err;
        return undefined;
    }
}

/** Attempts to parse a BigNumber and returns undefined instead of NaN for invalid numbers */
export function parseBigNumberOrUndefined(str: string): BigNumber | undefined {
    const n = new BigNumber(str);
    if (n.isNaN()) return undefined;
    return n;
}

/** Parses a positive integer from a string, or returns undefined if the string is not one */
export function parsePositiveInt(str: string): number | undefined {
    const n = Number.parseInt(str, 10);
    if (Number.isNaN(n) || n < 1) return undefined;
    return n;
}

export function sumBigNumberBy<T>(items: T[], getter: (_: T) => BigNumber): BigNumber {
  return items.reduce((total, item: T) => total.plus(getter(item)), new BigNumber(0));
}

export function sumBigNumber(items: BigNumber[]): BigNumber {
  return sumBigNumberBy(items, identity);
}

