import {action, flow, makeObservable} from "mobx";

import {VirtualAccountCreationDto, VirtualAccountFilterParams} from "./api";
import {VirtualAccountListItem} from "./virtual-account-list-item";
import {Api} from "~/api/api";
import {PageParams} from "~/api/common-query-params";
import {mapPageData, PaginationEnvelope} from "~/api/pagination-envelope";
import {constructorDependencies} from "~/utils/di";
import {Loadable, loadableContainer, loading, pending} from "~/utils/loadable";
import {awt} from "~/utils/mobx";
import {ObservableOrderableMap, ReadonlyOrderableMap} from "~/utils/orderable-map";
import {Sequence} from "~/utils/sequence";

@constructorDependencies
export class VirtualAccountStore {
    private readonly _virtualAccounts = loadableContainer.eager<
        PaginationEnvelope<ObservableOrderableMap<string, VirtualAccountListItem>>
    >(loading);

    public get virtualAccounts(): Loadable.Eager<
        PaginationEnvelope<ReadonlyOrderableMap<string, VirtualAccountListItem>>
    > {
        return this._virtualAccounts.l;
    }

    private readonly _virtualAccountCreationStatus = loadableContainer<void>(pending);

    public get virtualAccountCreationStatus(): Loadable<void> { return this._virtualAccountCreationStatus.l; }

    public constructor(private readonly api: Api) {
        makeObservable(this, undefined, {name: "VirtualAccountStore"});
    }

    @flow.bound
    public * fetchVirtualAccounts(params: PageParams & VirtualAccountFilterParams, signal?: AbortSignal) {
        yield* this._virtualAccounts.run(this, function*() {
            const page = yield* awt(this.api.virtualAccounts.getAccounts(params, signal));
            return mapPageData(page, d => Sequence.from(d)
                .keyBy("id")
                .collectToObservableOrderableMap({name: "virtualAccounts", deep: false})
            );
        }, {keepStaleValue: true});
    }

    @flow.bound
    public * createVirtualAccount(dto: VirtualAccountCreationDto, signal?: AbortSignal) {
        yield* this._virtualAccountCreationStatus.run(this, function*() {
            const account = yield* awt(this.api.virtualAccounts.createAccount(dto, signal));
            //Insert the new account at the start of the list so we know it's visible
            this._virtualAccounts.l.valueOrNull()?.data.set(account.id, account, "start");
        }, {onAbort: pending});
    }

    @action.bound
    public resetVirtualAccountCreationStatus() {
        this._virtualAccountCreationStatus.l = pending;
    }
}
