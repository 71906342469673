import {UserTask} from "./user-task";
import {BaseApi} from "~/api/base-api";
import {PageParams, SortParams} from "~/api/common-query-params";
import {FetchClient} from "~/api/fetch-client";
import {PaginationEnvelope} from "~/api/pagination-envelope";

export interface UserTaskFilterParams {
    readonly userId?: string;
    readonly taskType?: UserTask.Type;
    readonly taskState?: UserTask.State;
    readonly includeUnassigned?: boolean;
}

export const USER_TASK_SORT_KEYS = [{key: "id", label: "ID"}, {key: "taskType"}, {key: "taskState"}] as const;

export type UserTaskSortKey = (typeof USER_TASK_SORT_KEYS)[number]["key"];

export class UserTaskApi extends BaseApi<UserTask> {
    public constructor(fetchClient: FetchClient, errorHandler: (err: Error) => boolean) {
        super(fetchClient, errorHandler, "/user_tasks", UserTask);
    }

    public async getTask(id: string, signal?: AbortSignal): Promise<UserTask> {
        return await this.get(id, signal);
    }

    public async findTasks(
        params: PageParams & SortParams<UserTaskSortKey> & UserTaskFilterParams,
        signal?: AbortSignal
    ): Promise<PaginationEnvelope<UserTask[]>> {
        return await this.find(params, signal);
    }
}
