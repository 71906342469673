import {Transfer} from "./transfer";
import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {TransferStates} from "~/transfer/index";
import {Temporal} from "@js-temporal/polyfill";

export class TransferEvent extends JsonDto {
    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp(JsonTypes.id)
    public readonly transferId!: string;

    @jsonProp(JsonTypes.stringUnion(TransferStates))
    public readonly state!: Transfer.State;

    @jsonProp()
    public readonly message!: string;

    //TODO: Make this more specific
    @jsonProp(JsonTypes.any, {optional: true})
    public readonly eventData!: any;

    @jsonProp()
    public readonly occurredAt!: Temporal.Instant;

    public constructor(init?: unknown) {
        super();
        this.init(TransferEvent, init);
    }
}
