function _autobind<T extends (...args: any[]) => any>(
    target: Object,
    methodName: PropertyKey,
    origMethod: T
): TypedPropertyDescriptor<T> {
    let method = origMethod;
    let bound = new WeakMap<Object, T>();
    return {
        enumerable: true,
        configurable: true,
        get() {
            if (this === target) return method;
            else {
                if (!bound.has(this)) {
                    bound.set(this, method.bind(this) as T);
                }

                return bound.get(this)!;
            }
        },
        set(value) {
            bound.delete(this);

            //Monkey-patching the original class
            if (this === target) {
                method = value;
                bound = new WeakMap();
            }
            //Subclass (making educated guess based on "constructor" property)
            else if (Object.hasOwn(this, "constructor")) {
                Object.defineProperty(this, methodName, _autobind(this, methodName, value));
            }
            //Monkey-patching an individual object. Can just bind once.
            else {
                Object.defineProperty(this, methodName, {
                    enumerable: true,
                    configurable: true,
                    writable: true,
                    value: value.bind(this)
                });
            }
        }
    };
}

export function autobind<T extends (...args: any[]) => any>(
    target: Object,
    methodName: PropertyKey,
    {value: origMethod}: TypedPropertyDescriptor<T>
): TypedPropertyDescriptor<T> {
    if (typeof target === "function") throw new TypeError("Autobind cannot be used on static methods");
    if (!origMethod) throw new TypeError("Autobind cannot be used on getters");

    return _autobind(target, methodName, origMethod);
}

export function autobindAll(clazz: abstract new (...params: any[]) => any): void {
    const proto = clazz.prototype;
    for (const key of Reflect.ownKeys(proto)) {
        if (key === "constructor") continue;

        const descriptor = Reflect.getOwnPropertyDescriptor(proto, key)!;
        if (typeof descriptor.value === "function") {
            Object.defineProperty(proto, key, _autobind(proto, key, descriptor.value));
        }
    }
}
