import {getLineItemBank, getLineItemDescription, LineItem, LineItemWithRecons} from "~/recon/line-item";
import {Box, LinearProgress, Stack} from "@mui/material";
import {DataGrid, GridColDef, GridRowId, GridRowsProp} from "@mui/x-data-grid";
import {MoneyFormat} from "~/utils/money";
import {Temporal} from "@js-temporal/polyfill";
import {amber, lightGreen, red} from "@mui/material/colors";
import {first, fromPairs} from "lodash";
import {Backend} from "~/backend/backend";
import {useQueryParam} from "~/utils/use-query-param";
import React, {useEffect, useMemo} from "react";
import {LineItemFilters} from "~/recon/components/line-item-filters";
import {ExistingLineItem} from "~/recon/recon-page";
import useLineItemQuery, {LineItemFilter} from "~/recon/use-line-item-query";
import {usePreviousValue} from "~/utils/use-previous-value";
import {Error} from "~/recon/components/recon-review";

const ReconStatus = ({row}: {row: LineItem}) => {
  return (<Box sx={{width: 8, height: "80%", backgroundColor: row.statusColor}}/>);
};

const columns: GridColDef[] = [
  {field: "status", headerName: "", flex: 0, hideSortIcons: true, sortable: false, filterable: false, maxWidth: 5, renderCell: params => <ReconStatus row={params.row}/>},
  {field: "id", headerName: "Id", flex: 0.2},
  {field: "description", headerName: "Description", flex: 1, valueGetter: (params) => getLineItemDescription(params.row)},
  {field: "entry_type", headerName: "Type", flex: 0.5, valueGetter: (params) => getLineItemBank(params.row)},
  {field: "amount", headerName: "Amount", valueGetter: (({row}: {row: LineItem}) => MoneyFormat.formatNumber(row.amount.toNumber(), row.currency))},
  {field: "created_at", headerName: "Date", flex: 0.8, type: "date", valueGetter: ({row}: {row: LineItem}) => row.date, valueFormatter: ({value}: {value: Temporal.Instant}) => value.toLocaleString(), sortComparator: Temporal.Instant.compare}
];

const colorForState = ({recons, lineItem, reconAmount}: LineItemWithRecons) => {
  if (recons.length === 0) return red[400];
  return (reconAmount.isEqualTo(lineItem.amount)) ? lightGreen[400] : amber[300];
};

export const LineItemTable = ({backend, onSelected, defaultFilter}: { backend: Backend, onSelected: (data: ExistingLineItem) => void, defaultFilter?: LineItemFilter }) => {
  const [lineItemId, selectLineItemId] = useQueryParam<string|undefined>("line_item", undefined, {push: true, immediate: false});
  const {query, filters, setFilters, sort, setSort, setPage, setPageSize, page, pageSize} =
    useLineItemQuery(backend.id, defaultFilter || {reconciled: false}, [{field: "id", sort: "desc"}]);
  const previousRowCount = usePreviousValue(query.data?.pagination.totalItems) || 0;

  // I hate my life
  const rows: LineItem[] = useMemo(() => {
    return query.data ?
      query.data.data.map(g => {
        const li = g.lineItem;
        li.statusColor = colorForState(g);
        return li;
      }) : [];
  }, [query.data]);

  useEffect(() => {
    if (lineItemId === undefined) {
      // onSelected({recons: [], lineItem: null, stripePayout: null});
    } else {
      const id = parseInt(lineItemId);
      const lineItem = rows.find(li => li.id === id) || null;
      const lineItemRecons = fromPairs(query.data ? query.data.data.map(g => [g.lineItem.id, {recons: g.recons, payout: g.stipePayout}]) : []);
      const recons = lineItem === null ? [] : lineItemRecons[lineItem.id].recons;
      const stripePayout = lineItem === null ? null : lineItemRecons[lineItem.id].payout || null;
      if (lineItem) {
        onSelected({lineItem, recons, stripePayout});
      }
    }
  }, [lineItemId, query.data, rows, onSelected]);

  return (
    <Stack>
      <LineItemFilters filter={filters} setFilter={setFilters}/>
      {query.isFetching && <LinearProgress/>}
      {query.isError && <Error error={query.error}/>}
      <DataGrid
          rows={rows as GridRowsProp}
          columns={columns}
          autoHeight
          rowsPerPageOptions={[10, 30, 50, 100]}
          onSelectionModelChange={ids => selectLineItemId(first(ids) as (string | undefined))}
          selectionModel={lineItemId ? [lineItemId as GridRowId] : []}
          disableDensitySelector
          disableColumnFilter
          disableColumnSelector
          hideFooterSelectedRowCount
          onSortModelChange={setSort}
          sortModel={sort}
          onPageSizeChange={setPageSize}
          onPageChange={page => setPage(page + 1)}
          paginationMode={"server"}
          sortingMode={"server"}
          pageSize={query.data?.pagination.perPage || pageSize}
          page={(query.data?.pagination.currentPage || page) - 1}
          rowCount={query.data?.pagination.totalItems || previousRowCount}
      />
    </Stack>
  );
};
