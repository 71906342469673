import React, {ForwardedRef, forwardRef} from "react";

import {
    Box,
    BoxProps,
    darken as _darken,
    emphasize as _emphasize,
    experimental_sx as sx,
    lighten as _lighten,
    styled,
    Theme
} from "@mui/material";
import {castArray, get} from "lodash";

/** The type of the SX sizing properties */
export type SxSize = string | number | ((theme: Theme) => string | number);

//Based on https://github.com/mui/material-ui/blob/d5d7188ceb3c080b948f131fc8acd24ab088a75a/packages/mui-system/src/sizing.js#L5

/**
 * Transforms an SX size value for use outside of the built-in size properties
 *
 * @param size - The size value to transform
 *
 * @returns A new size value that has (potentially) been converted from a number to a string with units
 */
export function sxSize(size: SxSize): SxSize {
    if (typeof size === "number" && size !== 0) return size <= 1 ? `${size * 100}%` : `${size}px`;
    else return size;
}

export type TransitionValue = [property: string | string[], duration?: string, easing?: string, delay?: string];

/** The transition shorthand that sx ought to have */
export const transition = (...transitions: TransitionValue[]) => ({
    transition: (t: Theme) =>
        transitions
            .flatMap(([properties, ...settings]) => castArray(properties).map(property => [property, ...settings]))
            .map(([property, duration = "", easing = "", delay = ""]) => {
                const themeDuration = get(t.transitions.duration, duration);
                duration = themeDuration && `${themeDuration}ms` || duration;

                const themeEasing = get(t.transitions.easing, easing);
                easing = themeEasing ?? easing;

                return [property, duration, easing, delay].filter(Boolean).join(" ");
            })
            .join(", ")
});

/** Version of the built-in MUI color lighten function that accepts references to the theme palette */
export const lighten = (color: string, coefficient: number) => (t: Theme) =>
    _lighten(get(t.palette, color) || color, coefficient);

/** Version of the built-in MUI color darken function that accepts references to the theme palette */
export const darken = (color: string, coefficient: number) => (t: Theme) =>
    _darken(get(t.palette, color) || color, coefficient);

/** Version of the built-in MUI color emphasize function that accepts references to the theme palette */
export const emphasize = (color: string, coefficient?: number) => (t: Theme) =>
    _emphasize(get(t.palette, color) || color, coefficient);

/** Convenient version of Box that renders a span */
export const Span = forwardRef((props: Omit<BoxProps<"span">, "component">, ref: ForwardedRef<HTMLSpanElement>) =>
    <Box component="span" ref={ref} {...props}/>);

/** Utility component for making a group of words wrap together */
export const WordGroup = styled("span")(sx({
    display: "inline-block"
}));

/** Utility component for making a group of words unwrappable (more convenient than typing &nbsp; repeatedly) */
export const NoWrap = styled("span")(sx({
    whiteSpace: "nowrap"
}));

export const UnbulletedList = styled("ul")(sx({
    listStyle: "none",
    m: 0,
    p: 0,

    //Since we're hiding bullets, we need the magic fix for the dumb Safari semantics bug
    //See: https://unfetteredthoughts.net/2017/09/26/voiceover-and-list-style-type-none/
    "& li::before": {
        content: "'\\200B'",
        //For some reason this messes with alignment sometimes unless it has position: absolute
        position: "absolute"
    }
}));

export const InlineTerm = styled("dt")(sx({
    display: "inline",
    m: 0,
    fontWeight: "bold",

    "&::after": {
        content: "':'",
        mr: "0.75ex"
    }
}));

export const InlineDefinition = styled("dd")(sx({
    display: "inline",
    m: 0
}));
