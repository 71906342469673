import React, {useCallback} from "react";

import {startCase} from "lodash";

import {useFilterGridContext} from "../filter-grid";
import {ListItemText} from "../list";
import {MenuItem} from "../menu-item";
import {TextField} from "../text-field";
import {memoGeneric} from "~/utils/memo-generic";

export interface SelectFilterProps<T extends string> {
    readonly filterKey: string;
    readonly label?: string;
    readonly inputLabel?: string;
    readonly options: readonly (T | {readonly label: string, readonly value: T})[];
    readonly value: T | undefined;
    readonly onValueChange: (value: T | undefined) => void;
}

export const SelectFilter = memoGeneric(function SelectFilter<T extends string>({
    filterKey,
    label,
    inputLabel = "Value",
    options,
    value,
    onValueChange
}: SelectFilterProps<T>) {
    const {GridFilter} = useFilterGridContext();

    const getSummary = useCallback((label: string) => {
        const valueOption = options.find(opt =>
            opt === value || typeof opt === "object" && opt.value === value
        );
        const valueLabel = typeof valueOption === "object" ? valueOption.label : startCase(value);
        return `${label}: ${valueLabel}`;
    }, [options, value]);

    const onSelect = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => onValueChange(ev.target.value as T),
        [onValueChange]
    );

    const onRemove = useCallback(() => onValueChange(undefined), [onValueChange]);

    return (
        <GridFilter
            filterKey={filterKey}
            label={label}
            hasValue={value !== undefined}
            getSummary={getSummary}
            onRemove={onRemove}
        >{(focusRef, renderBaseFilterUi) =>
            renderBaseFilterUi(<>
                <ListItemText sx={{flex: "none"}}>{"="}</ListItemText>
                <TextField
                    select
                    inputRef={focusRef}
                    variant="standard"
                    size="small"
                    sx={{flex: 1}}
                    label={inputLabel}
                    value={value ?? ""}
                    onChange={onSelect}
                >
                    {options.map(opt => {
                        const value = typeof opt === "string" ? opt : opt.value;
                        const label = typeof opt === "string" ? startCase(opt) : opt.label;
                        return <MenuItem key={value} value={value}>{label}</MenuItem>;
                    })}
                </TextField>
            </>)
        }</GridFilter>
    );
});
