import React, {
    createContext,
    ForwardedRef,
    forwardRef,
    FunctionComponent,
    ReactNode,
    Ref,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {createPortal} from "react-dom";

import {Box, SxProps, Theme, useTheme} from "@mui/material";
import {castArray, startCase} from "lodash";

import {Accordion, AccordionDetails, AccordionProps, AccordionSummary} from "./accordion";
import {Button, IconButton} from "./button";
import {Chip} from "./chip";
import {Icon} from "./icon";
import {List, ListItem, ListItemText} from "./list";
import {MenuItem} from "./menu-item";
import {TextField} from "./text-field";
import {Collapse, Fade} from "./transitions";
import {dropArgs} from "~/utils/event-helpers";
import {Sequence} from "~/utils/sequence";
import {setDiff} from "~/utils/sets";
import {transition} from "~/utils/styles";
import {Focusable, Setters} from "~/utils/types";
import {useMap} from "~/utils/use-map";
import {usePreviousValue} from "~/utils/use-previous-value";
import {useSet} from "~/utils/use-set";

export interface GridFilterProps {
    /** A unique key to identify this filter in the grid */
    readonly filterKey: string;

    /** A human-readable name for this filter. If omitted, startCase(filterKey) is used. */
    readonly label?: string;

    /**
     * Whether this filter currently has a value. Used to propagate changes in state passed in from the parent.
     * Note that if this becomes false, the filter is automatically removed.
     * Therefore, most filters should have two "empty" states: one that indicates the absence of a value,
     * and one that allows the user to clear the input without the filter disappearing (e.g., undefined vs "").
     */
    readonly hasValue: boolean;

    /**
     * A function returning a short string summarizing the current value of the filter.
     * Multiple summaries can also be returned, as well as null to hide/omit the summary.
     * Is passed the (possibly default) label of the filter.
     * Is only called when hasValue is true.
     */
    readonly getSummary: (label: string) => string | string[] | null;

    /** Called when the filter is removed from the grid. Should be used to clear the value. */
    readonly onRemove: () => void;

    /** Styles applied to the ListItem that forms the root of the filter */
    readonly sx?: SxProps<Theme>;

    /**
     * Renders the contents of the filter
     *
     * @param focusRef - Should be assigned to the element that should receive focus when the filter is added
     * @param renderBaseFilterUi
     * A function which renders the base UI for a filter (label and remove button).
     * This function should almost always be called when rendering a filter, but you can choose to either use it
     * to wrap the contents of your filter (which renders them inline, between the label and remove button),
     * or separately, which can allow you to arrange things differently.
     */
    readonly children: (focusRef: Ref<Focusable>, renderBaseFilterUi: (children?: ReactNode) => ReactNode) => ReactNode;
}

/**
 * Context used to implement Grid filter components.
 * Most filters should only need to directly interact with the GridFilter component.
 * Other members of the context should be considered "open" implementation details.
 */
export interface FilterGridContext {
    /** The set of keys of all available filters in the grid */
    readonly availableFilters: Set<string>;

    /** A map from filter keys to human-readable names */
    readonly filterNames: Map<string, string>;

    /** A map from filter keys to refs that can be used to focus their first input */
    readonly focusRefs: Map<string, RefObject<Focusable>>;

    /** The set of all filters that are active (visible) in the grid */
    readonly activeFilters: Set<string>;

    /** A map from filter keys to functions that can be called to remove the filter */
    readonly removalCallbacks: Map<string, () => void>;

    /** Portal component used to render the contents of a filter component when it is active */
    readonly GridFilter: FunctionComponent<GridFilterProps>;
}

const filterGridContext = createContext<FilterGridContext | null>(null);

/** Returns the context of the parent FilterGrid */
export function useFilterGridContext(): FilterGridContext {
    const ctx = useContext(filterGridContext);
    if (!ctx) throw new TypeError("Component is not being rendered inside of a FilterGrid");
    return ctx;
}

/**
 * Additional, optional context used by higher-level components to provide a summary of applied filters.
 * Filter components should mostly not need to worry about this, as it's handled by GridFilter.
 */
export interface FilterGridSummaryContext {
    /**
     * A map from filter keys to human-readable summaries of the applied filters.
     * If present, filter components should add a summary to this when they have a value.
     */
    readonly filterSummaries: Map<string, string | string[]>;
}

const filterGridSummaryContext = createContext<FilterGridSummaryContext | null>(null);

export const FilterGridSummaryContextProvider = filterGridSummaryContext.Provider;

/** Returns any FilterGridSummaryContext present in the component tree */
export const useFilterGridSummaryContext = () => useContext(filterGridSummaryContext);

export interface FilterGridHandle {
    /** If the filter grid is empty, focuses the add menu. Otherwise focuses the first filter. */
    readonly focus: () => void;

    /** Clears all the filters in the grid, emptying it */
    readonly clear: () => void;
}

export interface FilterGridProps {
    /** The label to use on the menu for adding filters. Defaults to "Add Filter". */
    readonly addFilterLabel?: string;

    /** The label to use on the button to clear the filter grid. Defaults to "Clear Filters" */
    readonly clearLabel?: string;

    /** Callback fired when a filter is added to the grid. Is passed the key of the added filter. */
    readonly onFilterAdded?: (filterKey: string) => void;

    readonly sx?: SxProps<Theme>;

    readonly children: ReactNode;
}

/**
 * Reusable component for defining a grid-like filter UI where filters can be added and removed from the list
 * (Compare to managing headers in Postman).
 *
 * This component manages individual filter components passed in as children.
 *
 * Filter components can be implemented by making use of FilterGridContext and FilterGridSummaryContext.
 */
export const FilterGrid = forwardRef(function FilterGrid({
    addFilterLabel = "Add Filter",
    clearLabel = "Clear Filters",
    onFilterAdded,
    sx = [],
    children
}: FilterGridProps, ref: ForwardedRef<FilterGridHandle>) {
    const addFilterMenu = useRef<HTMLSelectElement>(null);

    const availableFilters = useSet<string>();
    const filterNames = useMap<string, string>();
    const focusRefs = useMap<string, RefObject<Focusable>>();
    const activeFilters = useSet<string>();
    const removalCallbacks = useMap<string, () => void>();
    const inactiveFilters = [...setDiff(availableFilters, activeFilters)];

    const clear = useCallback(() => {
        for (const filterKey of activeFilters) {
            removalCallbacks.get(filterKey)!();
        }
    }, [activeFilters, removalCallbacks]);

    useImperativeHandle(ref, () => ({
        focus() {
            if (activeFilters.size === 0) {
                addFilterMenu.current!.focus();
            }
            else {
                focusRefs.get(Sequence.first(activeFilters)!)!.current!.focus();
            }
        },

        clear
    }), [activeFilters, focusRefs, clear]);

    const portalRoot = useRef<HTMLDivElement>(null);
    const GridFilter = useCallback(function GridFilter({
        filterKey,
        label = startCase(filterKey),
        hasValue,
        getSummary,
        onRemove,
        sx = [],
        children
    }: GridFilterProps) {
        const theme = useTheme();

        //Accessing this state via context rather than closure to keep this inner component stable
        const {availableFilters, filterNames, focusRefs, activeFilters, removalCallbacks} = useFilterGridContext();
        const summaryCtx = useFilterGridSummaryContext();

        const active = activeFilters.has(filterKey);

        const focusRef = useRef<Focusable>(null);

        //Control whether we render with a separate state atom from `active`
        //in order to allow for animation on the way out
        const [render, setRender] = useState(false);

        useLayoutEffect(() => {
            if (active) {
                setRender(true);
            }
        }, [active, setRender]);

        const onTransitionEnd = useCallback(() => setRender(false), [setRender]);

        const remove = useCallback(() => {
            onRemove();
            activeFilters.delete(filterKey);
        }, [activeFilters, filterKey, onRemove]);

        //Register on initial mount
        useLayoutEffect(() => {
            availableFilters.add(filterKey);
            filterNames.set(filterKey, label);
            focusRefs.set(filterKey, focusRef);
            removalCallbacks.set(filterKey, remove);

            //Immediately add if we already have a value
            if (hasValue) {
                activeFilters.add(filterKey);
            }
        }, []); //eslint-disable-line react-hooks/exhaustive-deps

        //Remove self if the value is removed externally
        const didHaveValue = usePreviousValue(hasValue);
        useLayoutEffect(() => {
            if (didHaveValue && !hasValue) {
                remove();
            }
        }, [didHaveValue, hasValue, remove]);

        //Keep summary up-to-date
        useEffect(
            () => {
                if (summaryCtx) {
                    if (active && hasValue) {
                        const summary = getSummary(label);
                        if (summary) {
                            summaryCtx.filterSummaries.set(filterKey, summary);
                        }
                    }
                    else {
                        summaryCtx.filterSummaries.delete(filterKey);
                    }
                }
            },
            //eslint-disable-next-line react-hooks/exhaustive-deps
            [active, filterKey, getSummary, label, hasValue] //summaryCtx omitted intentionally to avoid render loop
        );

        const renderBaseFilterUi = (children?: ReactNode) => <>
            <ListItemText
                sx={{
                    width: 1 / 3,
                    pl: 2,
                    flex: "none",

                    "& .MuiTypography-root": {
                        //display: inline-block causes the baseline alignment to use the last line instead of the first
                        display: "inline-block",
                        fontWeight: 500
                    }
                }}
            >
                {label}
            </ListItemText>
            {children}
            <IconButton
                title={`Remove ${label} Filter`}
                onClick={remove}
                size="small"
                color="error"
                sx={{ml: -0.5, mr: 0.5}}
            >
                <Icon fa="trash"/>
            </IconButton>
        </>;

        if (render) return createPortal(
            <Collapse
                in={active}
                timeout={100}
                easing={{enter: theme.transitions.easing.easeIn, exit: theme.transitions.easing.easeOut}}
                appear
                onExited={onTransitionEnd}
                sx={{
                    contain: "content",

                    "&:not(:first-child)": {
                        pt: 1
                    }
                }}
            >
                <ListItem
                    className="PcGridFilter-root"
                    sx={[
                        {
                            p: 0,
                            mx: 2,
                            width: (t: Theme) => `calc(100% - ${t.spacing(4)})`,
                            alignItems: "baseline",
                            gap: 1.5,
                            borderBottom: 1,
                            borderColor: "divider"
                        },
                        sx
                    ].flat()}
                >
                    {children(focusRef, renderBaseFilterUi)}
                </ListItem>
            </Collapse>,
            portalRoot.current!
        );
        else return null;
    }, [portalRoot]);

    const ctx = useMemo(
        () => ({availableFilters, filterNames, focusRefs, activeFilters, removalCallbacks, GridFilter}),
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [availableFilters.atom, filterNames.atom, focusRefs.atom, activeFilters.atom, removalCallbacks.atom, GridFilter]
    );

    const onAddFilter = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const filterKey = ev.target.value;

        activeFilters.add(filterKey);

        //Wait for the input to mount
        setTimeout(
            () => focusRefs.get(filterKey)!.current!.focus(),
            //Wait for the animation to finish before focusing. Reduces jank.
            125
        );

        onFilterAdded?.(filterKey);
    }, [activeFilters, focusRefs, onFilterAdded]);

    //The individual filter components will render themselves by utilizing the above portal component.
    //The order in which they are mounted will determine their order.
    //(Technically that order will not be stable across re-mounts in React 18,
    //however, if the filter list is off-screened, I don't think anyone will care if the filters reorder.
    //All that matters is that they stay in the order they were added for as long as they're being rendered.)

    return <>
        <filterGridContext.Provider value={ctx}>
            {children}
        </filterGridContext.Provider>
        <List className="PcFilterGrid-root" sx={sx}>
            <Box ref={portalRoot} display="contents"/>
            <ListItem sx={{py: 0, alignItems: "baseline", justifyContent: "space-between"}}>
                <TextField
                    select
                    variant="standard"
                    size="small"
                    sx={{width: 1 / 3}}
                    label={<><Icon fa="plus"/> {addFilterLabel}</>}
                    value=""
                    onChange={onAddFilter}
                    disabled={inactiveFilters.length === 0}
                    InputLabelProps={{shrink: false}}
                    inputRef={addFilterMenu}
                >
                    {inactiveFilters.map(key =>
                        <MenuItem key={key} value={key}>{filterNames.get(key)}</MenuItem>
                    )}
                </TextField>
                <Button
                    variant="text"
                    size="small"
                    color="error"
                    sx={{lineHeight: "normal", position: "relative", bottom: "1px"}}
                    onClick={clear}
                    start={<Icon fa="trash"/>}
                    disabled={activeFilters.size === 0}
                >
                    {clearLabel}
                </Button>
            </ListItem>
        </List>
    </>;
});

/**
 * Utility type for defining props for a reusable set of filters.
 * Takes a filter state type and adds setters and an omit prop.
 */
export type BaseFilterSetProps<FilterState extends object, OmitFilters extends keyof FilterState = never> =
    Omit<FilterState, OmitFilters> & Setters<Omit<FilterState, OmitFilters>> & {
        readonly omit?: readonly OmitFilters[]
    };

export interface CollapsibleFilterGridProps extends FilterGridProps {
    readonly accordionProps?: Omit<AccordionProps, "expanded" | "onChange" | "children">;
}

export function CollapsibleFilterGrid({
    onFilterAdded: onFilterAddedProp,
    sx = [],
    children,
    accordionProps,
    ...filterGridProps
}: CollapsibleFilterGridProps) {
    const filterSummaries = useMap<string, string | string[]>();
    const summaryCtx = useMemo(
        () => ({filterSummaries}),
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [filterSummaries.atom]
    );

    const [expanded, setExpanded] = useState(false);

    const [scrolled, setScrolled] = useState(false);
    const onAccordionScroll = useCallback((ev: React.SyntheticEvent<HTMLDivElement>) => {
        setScrolled(ev.currentTarget.scrollTop > 0);
    }, [setScrolled]);

    const accordionDetails = useRef<HTMLDivElement>(null);
    const onFilterAdded = useCallback((filterKey: string) => {
        onFilterAddedProp?.(filterKey);
        setTimeout(() => {
            accordionDetails.current!.scrollTo({top: accordionDetails.current!.scrollHeight, behavior: "smooth"});
        }, 200);
    }, [accordionDetails, onFilterAddedProp]);

    return (
        <Box className="PcCollapsibleFilterGrid-root" sx={sx}>
            <Accordion
                expanded={expanded}
                onChange={dropArgs(setExpanded, 1)}
                sx={[{
                    contain: "content",

                    "&:hover .MuiCollapse-root": {
                        willChange: "height"
                    }
                }, accordionProps?.sx ?? []].flat()}
                {...accordionProps}
            >
                <AccordionSummary
                    sx={[
                        {
                            gap: 1,
                            ...transition(["box-shadow", "shortest", "linear"]),

                            "& .MuiAccordionSummary-content": {
                                flex: 1,
                                minWidth: 0,
                                gap: "1ex"
                            }
                        },
                        (expanded && scrolled) && {boxShadow: 1}
                    ]}
                >
                    <Icon fa="filter"/> Filters
                    <Fade in={!expanded && filterSummaries.size > 0}>
                        <Chip
                            variant="outlined"
                            sx={{
                                color: "text.secondary",
                                cursor: "inherit",
                                flexShrink: 1,
                                minWidth: 0,

                                "& > .MuiChip-label": {
                                    //The default auto-grid will shrink wider columns first
                                    display: "grid",
                                    gridAutoFlow: "column",
                                    alignItems: "center",
                                    gap: "1ex"
                                }
                            }}
                            label={<>
                                {filterSummaries.size} applied:
                                {Sequence.from(filterSummaries).flatMap(([key, summaries]) =>
                                    castArray(summaries).map((summary, i) =>
                                        <Chip
                                            key={typeof summaries === "string" ? key : `${key}[${i}]`}
                                            size="small"
                                            sx={{cursor: "inherit", minWidth: "5ex"}}
                                            label={summary}
                                        />
                                    )
                                )}
                            </>}
                        />
                    </Fade>
                </AccordionSummary>
                <AccordionDetails ref={accordionDetails} sx={{px: 0}} onScroll={onAccordionScroll}>
                    <FilterGridSummaryContextProvider value={summaryCtx}>
                        <FilterGrid onFilterAdded={onFilterAdded} sx={{p: 0}} {...filterGridProps}>
                            {children}
                        </FilterGrid>
                    </FilterGridSummaryContextProvider>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
