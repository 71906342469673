import {StateLane} from "~/account/state-lane";

export const StateLaneTypes = [
    "Incoming",
    "Outgoing",
    "Settled",
    "Failed",
    "Authorized",
    "Sent",
    "External"
] as const;

export type StateLaneType = (typeof StateLaneTypes)[number];
