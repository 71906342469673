import {action, flow, makeObservable} from "mobx";

import {AchBatch} from "./ach-batch";
import {AchBatchSummary} from "./ach-batch-summary";
import {AchBatchCreationDto, AchBatchFilterParams, AchBatchSortKey, AchBatchUpdateParams} from "./api";
import {Api} from "~/api/api";
import {PageParams, SortParams} from "~/api/common-query-params";
import {mapPageData, PaginationEnvelope} from "~/api/pagination-envelope";
import {constructorDependencies} from "~/utils/di";
import {Loadable, loadableContainer, loading, pending} from "~/utils/loadable";
import {LoadableMap} from "~/utils/loadable/loadable-map";
import {awt} from "~/utils/mobx";
import {ObservableOrderableMap, ReadonlyOrderableMap} from "~/utils/orderable-map";
import {Sequence} from "~/utils/sequence";

@constructorDependencies
export class AchStore {
    private lastFetchParams: PageParams & SortParams<AchBatchSortKey> & AchBatchFilterParams | null = null;

    private readonly _batches = loadableContainer.eager<
        PaginationEnvelope<ObservableOrderableMap<string, AchBatchSummary>>
    >(loading);

    public get batches(): Loadable.Eager<PaginationEnvelope<ReadonlyOrderableMap<string, AchBatchSummary>>> {
        return this._batches.l;
    }

    private readonly _batchDetails = new LoadableMap<string, AchBatch>();

    private readonly _batchCreationStatus = loadableContainer<void>(pending);

    public get batchCreationStatus() { return this._batchCreationStatus.l; }

    private readonly batchUpdateStatusMap = new LoadableMap<string, void>();

    private readonly batchDeletionStatusMap = new LoadableMap<string, void>();

    private readonly batchSubmissionStatusMap = new LoadableMap<string, void>();

    public constructor(private readonly api: Api) {
        makeObservable(this, undefined, {name: "AchStore"});
    }

    @flow.bound
    public * fetchBatches(
        params: PageParams & SortParams<AchBatchSortKey> & AchBatchFilterParams,
        signal?: AbortSignal
    ) {
        yield* this._batches.run(this, function*() {
            const page = yield* awt(this.api.ach.getBatches(params, signal));
            this.lastFetchParams = params;
            return mapPageData(page, d => Sequence.from(d)
                .keyBy("id")
                .collectToObservableOrderableMap({name: "batches", deep: false})
            );
        }, {keepStaleValue: true});
    }

    @flow.bound
    public * fetchBatchDetails(id: string, signal?: AbortSignal) {
        yield* this._batchDetails.run(this, id, function*() {
            return yield* awt(this.api.ach.getBatch(id, signal));
        }, {keepStaleValue: true});
    }

    public getBatchDetails(id: string): Loadable<AchBatch> {
        return this._batchDetails.get(id);
    }

    @action.bound
    public clearBatchDetails(...ids: string[]) {
        if (ids.length === 0) {
            this._batchDetails.clear();
        }
        else {
            ids.forEach(id => this._batchDetails.delete(id));
        }
    }

    @flow.bound
    public * createBatch(dto: AchBatchCreationDto, signal?: AbortSignal) {
        yield* this._batchCreationStatus.run(this, function*() {
            yield* awt(this.api.ach.createBatch(dto, signal));

            //AchBatch is not assignable to AchBatchSummary, so need to refresh the list. No need to await.
            //Not really a reasonable source for an AbortSignal here... Not gonna worry about it.
            if (this.lastFetchParams) {
                this.fetchBatches(this.lastFetchParams);
            }
        }, {onAbort: pending});
    }

    @action.bound
    public resetBatchCreationStatus() {
        this._batchCreationStatus.l = pending;
    }

    @flow.bound
    public * updateBatch(id: string, params: AchBatchUpdateParams, signal?: AbortSignal) {
        yield* this.batchUpdateStatusMap.run(this, id, function*() {
            yield* awt(this.api.ach.updateBatch(id, params, signal));

            //Same as above: we know we've had changes, so refresh the list
            if (this.lastFetchParams) {
                this.fetchBatches(this.lastFetchParams);
            }
        }, {onAbort: pending});
    }

    public getBatchUpdateStatus(id: string): Loadable<void> {
        return this.batchUpdateStatusMap.get(id);
    }

    @action.bound
    public resetBatchUpdateStatus(id: string) {
        this.batchUpdateStatusMap.delete(id);
    }

    @flow.bound
    public * deleteBatch(id: string, signal?: AbortSignal) {
        yield* this.batchDeletionStatusMap.run(this, id, function*() {
            yield* awt(this.api.ach.deleteBatch(id, signal));

            //In this case, since the only change is a deletion, we can just delete without a network call
            this._batches.l.valueOrNull()?.data.delete(id);
        }, {onAbort: pending});
    }

    public getBatchDeletionStatus(id: string): Loadable<void> {
        return this.batchDeletionStatusMap.get(id);
    }

    @action.bound
    public resetBatchDeletionStatus(id: string) {
        this.batchDeletionStatusMap.delete(id);
    }

    @flow.bound
    public * markBatchSubmitted(id: string, signal?: AbortSignal) {
        yield* this.batchSubmissionStatusMap.run(this, id, function*() {
            yield* awt(this.api.ach.markBatchSubmitted(id, signal));

            //Have to re-fetch again here, since submittedAt is a timestamp
            if (this.lastFetchParams) {
                this.fetchBatches(this.lastFetchParams);
            }
        }, {onAbort: pending});
    }

    public getBatchSubmissionStatus(id: string): Loadable<void> {
        return this.batchSubmissionStatusMap.get(id);
    }

    @action.bound
    public resetBatchSubmissionStatus(id: string) {
        this.batchSubmissionStatusMap.delete(id);
    }
}
