import {Backend} from "./backend";
import {BaseApi} from "~/api/base-api";
import {FetchClient} from "~/api/fetch-client";
import {autobindAll} from "~/utils/autobind";
import {VirtualAccount} from "~/account/virtual-account";
import {PageParams, SortParams} from "~/api/common-query-params";
import {PaginationEnvelope} from "~/api/pagination-envelope";

export interface BackendFilterParams {
    readonly backendType?: Backend.Type;
    readonly name?: string;
}

export const BACKEND_SORT_KEYS = [{key: "id", label: "ID"}, {key: "name"}] as const;

export type BackendSortKey = (typeof BACKEND_SORT_KEYS)[number]["key"];

@autobindAll
export class BackendApi extends BaseApi<Backend> {
    public constructor(fetchClient: FetchClient, errorHandler: (err: Error) => boolean) {
        super(fetchClient, errorHandler, "/backends", Backend);
    }

    /** Gets all Backends */
    public async getBackends(params?: PageParams & SortParams<BackendSortKey> & BackendFilterParams | undefined, signal?: AbortSignal): Promise<PaginationEnvelope<Backend[]>> {
        return await this.find(params, signal);
    }

    public async getBackend(id: string, signal?: AbortSignal): Promise<Backend> {
        return await this.get(id, signal);
    }
}
