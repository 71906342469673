import React, {ReactNode} from "react";

import {Box} from "@mui/material";
import {Observer} from "mobx-react-lite";

import {LinearProgress} from "./progress";
import {Alert} from "~/components/alert";
import {ApiError} from "~/errors";
import {Loadable} from "~/utils/loadable";

/** The default pending state of LoadingTreatment. Accepts a curried description. */
export const defaultRenderPending = (description: string = "") => () => null;

/** The default loading state of LoadingTreatment. Accepts a curried description. */
export const defaultRenderLoading = (description: string = "") => () =>
    <Box width={1}>
        {description && <Box component="p" sx={{textAlign: "center"}}>Loading {description}...</Box>}
        <LinearProgress/>
    </Box>;

/** The default error state of LoadingTreatment. Accepts a curried description. */
export const defaultRenderError = (description: string = "") => (errorMessage: string, error: Error | null) =>
    <Alert severity="error">
        {description && `Error loading ${description}: `}
        {(error && !(error instanceof ApiError)) && `(${error.name}) `}
        {errorMessage}
    </Alert>;

export interface LoadingTreatmentProps<T> {
    /** The Loadable value to display a loading treatment for */
    readonly loadable: Loadable<T>;

    /** An optional short description of the items being loaded (e.g., "widgets") */
    readonly description?: string;

    /** If true, renders all states inside of MobX `<Observer>` */
    readonly observer?: boolean;

    /** If true, the children will be rendered when there is a stale value present */
    readonly renderStaleValues?: boolean;

    /**
     * A function rendering the loaded state based on the result of the Loadable.
     * If `renderStaleValues` is true, will also be called when the Loadable is loading with a stale value present.
     * In those cases, the second argument, `stale`, will be true.
     */
    readonly children: (result: T, stale: boolean) => ReactNode;

    /** A function rendering the pending state. Defaults to `defaultRenderPending`. */
    readonly renderPending?: () => ReactNode;

    /** A function rendering the loading state. Defaults to `defaultRenderLoading`. */
    readonly renderLoading?: () => ReactNode;

    /** A function rendering the error state. Defaults to `defaultRenderError`. */
    readonly renderError?: (errorMessage: string, error: Error | null) => ReactNode;
}

/** Implements a standard loading treatment over a Loadable */
export function LoadingTreatment<T>({
    loadable,
    description,
    observer = false,
    renderStaleValues = false,
    children,
    renderPending = defaultRenderPending(description),
    renderLoading = defaultRenderLoading(description),
    renderError = defaultRenderError(description)
}: LoadingTreatmentProps<T>) {
    const render = () => <>
        {loadable.case({
            pending: renderPending,
            loading: renderLoading,
            error: renderError,
            ...(renderStaleValues ? {hasValue: children} : {loaded: value => children(value, false)})
        })}
    </>;

    return observer ? <Observer>{render}</Observer> : render();
}
