import {StateLane} from "./state-lane";
import {Backend} from "~/backend/backend";
import {Entity} from "~/entity/entity";
import {JsonDto, jsonName, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {Sequence} from "~/utils/sequence";
import {BigNumber} from "bignumber.js";
import {MoneyFormat} from "~/utils/money";
import {CURRENCIES, Currency} from "~/currency";

export class VirtualAccountRoleImpl extends JsonDto {
    public static get [JsonDto.subtypes]() {
        return {
            investment: VirtualAccountRole.Investment,
            wallet: VirtualAccountRole.Wallet,
            payment_method: VirtualAccountRole.PaymentMethod,
            source: VirtualAccountRole.Source,
            sink: VirtualAccountRole.Sink,
            service_fees: VirtualAccountRole.ServiceFees,
            adjustments: VirtualAccountRole.Adjustments,
            reserved: VirtualAccountRole.Reserved,
            released: VirtualAccountRole.Released,
            corporate: VirtualAccountRole.Corporate,
            company: VirtualAccountRole.Company
        };
    }

    public constructor(init?: unknown) {
        super();
        this.init(VirtualAccountRoleImpl, init);
    }
}

export type VirtualAccountRole =
    | VirtualAccountRole.Investment
    | VirtualAccountRole.Wallet
    | VirtualAccountRole.PaymentMethod
    | VirtualAccountRole.Source
    | VirtualAccountRole.Sink
    | VirtualAccountRole.ServiceFees
    | VirtualAccountRole.Adjustments
    | VirtualAccountRole.Reserved
    | VirtualAccountRole.Released
    | VirtualAccountRole.Corporate;

export namespace VirtualAccountRole {
    export class Investment extends VirtualAccountRoleImpl {
        public readonly type = "investment";

        @jsonProp(JsonTypes.id)
        @jsonName("id")
        public readonly wfInvestmentId!: string;

        public constructor(init?: unknown) {
            super();
            this.init(Investment, init);
        }
    }

    export class Wallet extends VirtualAccountRoleImpl {
        public readonly type = "wallet";

        @jsonProp(JsonTypes.id)
        @jsonName("id")
        public readonly wfUserId!: string;

        public constructor(init?: unknown) {
            super();
            this.init(Wallet, init);
        }
    }

    export class PaymentMethod extends VirtualAccountRoleImpl {
        public readonly type = "payment_method";

        @jsonProp(JsonTypes.id)
        @jsonName("user")
        public readonly wfUserId!: string;

        public constructor(init?: unknown) {
            super();
            this.init(PaymentMethod, init);
        }
    }

    export class Source extends VirtualAccountRoleImpl {
        public readonly type = "source";

        public constructor(init?: unknown) {
            super();
            this.init(Source, init);
        }
    }

    export class Sink extends VirtualAccountRoleImpl {
        public readonly type = "sink";

        public constructor(init?: unknown) {
            super();
            this.init(Sink, init);
        }
    }

    export class ServiceFees extends VirtualAccountRoleImpl {
        public readonly type = "service_fees";

        public constructor(init?: unknown) {
            super();
            this.init(ServiceFees, init);
        }
    }

    export class Adjustments extends VirtualAccountRoleImpl {
        public readonly type = "adjustments";

        public constructor(init?: unknown) {
            super();
            this.init(Adjustments, init);
        }
    }

    export class Reserved extends VirtualAccountRoleImpl {
        public readonly type = "reserved";

        public constructor(init?: unknown) {
            super();
            this.init(Reserved, init);
        }
    }

    export class Released extends VirtualAccountRoleImpl {
        public readonly type = "released";

        public constructor(init?: unknown) {
            super();
            this.init(Released, init);
        }
    }

    export class Corporate extends VirtualAccountRoleImpl {
      public readonly type = "corporate";

      public constructor(init?: unknown) {
        super();
        this.init(Corporate, init);
      }
    }

    export class Company extends VirtualAccountRoleImpl {
      public readonly type = "company";

      public constructor(init?: unknown) {
        super();
        this.init(Company, init);
      }
    }
}

export class VirtualAccount extends JsonDto {
    public static readonly ACCOUNT_TYPES = ["internal", "external"] as const;
    public static readonly ACCOUNT_ROLES = ["adjustments", "investment", "wallet", "service_fees", "sink", "source", "payment_method", "released", "reserved", "corporate", "company"] as const;

    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp()
    public readonly name!: string;

    @jsonProp(JsonTypes.stringUnion(VirtualAccount.ACCOUNT_TYPES))
    public readonly accountType!: VirtualAccount.AccountType;

    @jsonProp(JsonTypes.dto(VirtualAccountRoleImpl))
    public readonly accountRole!: VirtualAccountRole;

    @jsonProp(JsonTypes.stringUnion(CURRENCIES))
    public readonly currency!: Currency;

    @jsonProp(JsonTypes.id)
    public readonly backendId!: string;

    @jsonProp()
    public readonly backend!: Backend;

    @jsonProp(JsonTypes.array(JsonTypes.dto(StateLane)))
    public readonly stateLanes!: readonly StateLane[];

    @jsonProp()
    public readonly enabled!: boolean;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly investment!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly fundraise!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly company!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly investor!: Entity | null;

    public readonly balanceSummary: readonly string[];

    public constructor(init?: unknown) {
        super();
        this.init(VirtualAccount, init);

        this.balanceSummary = VirtualAccount.computeBalanceSummary(this.backend, this.stateLanes, this.currency);
    }

    public static computeBalanceSummary(backend: Backend, stateLanes: readonly StateLane[], currency: Currency|null): string[] {
        const lanesByType = Sequence.from(stateLanes).keyBy("laneType").collectToObject();
        const zero = new BigNumber(0);
        switch (backend.backendType) {
            case "internal_escrow": {
                const total = (lanesByType.Settled?.amount ?? zero)
                    .plus(lanesByType.Incoming?.amount ?? zero)
                    .minus(lanesByType.Outgoing?.amount ?? zero);
                const available = (lanesByType.Settled?.amount ?? zero);
                return [`${MoneyFormat.formatBigNumber(total, currency)}`, `(${MoneyFormat.formatBigNumber(available, currency)} available)`];
            }
            case "wire_in":
                return [`${MoneyFormat.formatBigNumber((lanesByType.Authorized?.amount ?? zero).negated(), currency)} authorized`];
            case "wire_out":
                return [`${MoneyFormat.formatBigNumber((lanesByType.Authorized?.amount ?? zero).negated(), currency)} authorized`];
            case "internal_source":
                return [`${MoneyFormat.formatBigNumber((lanesByType.Authorized?.amount ?? zero).negated(), currency)} authorized`];
            case "external_bank":
                return [`${MoneyFormat.formatBigNumber((lanesByType.Authorized?.amount ?? zero).negated(), currency)} authorized`];
            case "corporate":
              return [`${MoneyFormat.formatBigNumber((lanesByType.Authorized?.amount ?? zero).negated(), currency)} authorized`];
            case "company":
              return [`${MoneyFormat.formatBigNumber((lanesByType.Authorized?.amount ?? zero).negated(), currency)} authorized`];
            case "external_stripe":
                return [`${MoneyFormat.formatBigNumber((lanesByType.Authorized?.amount ?? zero).negated(), currency)} authorized`];
        }
    }
}

export namespace VirtualAccount {
    export type AccountType = (typeof VirtualAccount.ACCOUNT_TYPES)[number];
    export type AccountRole = (typeof VirtualAccount.ACCOUNT_ROLES)[number];
}
