import {VirtualAccount, VirtualAccountRole, VirtualAccountRoleImpl} from "./virtual-account";
import {CURRENCIES, Currency} from "~/currency";
import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";

export class VirtualAccountSummary extends JsonDto {
    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp()
    public readonly name!: string;

    @jsonProp(JsonTypes.stringUnion(CURRENCIES))
    public readonly currency!: Currency;

    @jsonProp(JsonTypes.dto(VirtualAccountRoleImpl))
    public readonly accountRole!: VirtualAccountRole;

    @jsonProp(JsonTypes.id)
    public readonly backendId!: string;

    @jsonProp()
    public readonly enabled!: boolean;

    public constructor(init?: unknown) {
        super();
        this.init(VirtualAccountSummary, init);
    }
}
