import React, {useCallback, useState} from "react";

import {Stack} from "@mui/material";
import {BigNumber} from "bignumber.js";

import {useFilterGridContext} from "../filter-grid";
import {ListItemText} from "../list";
import {MenuItem} from "../menu-item";
import {TextField} from "../text-field";
import {memoGeneric} from "~/utils/memo-generic";
import {
    parseBigIntOrUndefined,
    parseBigNumberOrUndefined,
    parseFloatOrUndefined,
    parseIntOrUndefined
} from "~/utils/numbers";
import {bigIntRange, bigNumberRange, numberRange, Range} from "~/utils/range";

type NumberType = "int" | "float" | "bigint" | "BigNumber";

interface NumberTypeMap {
    int: number;
    float: number;
    bigint: bigint;
    BigNumber: BigNumber;
}

const numberParsers: {readonly [Type in NumberType]: (s: string) => NumberTypeMap[Type] | undefined} = {
    float: parseFloatOrUndefined,
    int: parseIntOrUndefined,
    bigint: parseBigIntOrUndefined,
    BigNumber: parseBigNumberOrUndefined
};

const makeRange: {
    readonly [Type in NumberType]: (
        l: NumberTypeMap[Type] | null,
        u?: NumberTypeMap[Type] | null
    ) => Range<NumberTypeMap[Type]>
} = {
    float: numberRange,
    int: numberRange,
    bigint: bigIntRange,
    BigNumber: bigNumberRange
};

const zero: {readonly [Type in NumberType]: NumberTypeMap[Type]} = {
    float: 0,
    int: 0,
    bigint: 0n,
    BigNumber: new BigNumber(0)
};

type MaybeRange<T, Singleton extends boolean> = Singleton extends true ? T : Range<T>;

type Value<Type extends NumberType, Singleton extends boolean> =
    MaybeRange<NumberTypeMap[Type], Singleton> | undefined;

type Operator = "=" | "≤" | "≥" | "∈";

export interface NumberFilterProps<Type extends NumberType = "float", Singleton extends boolean = false> {
    readonly filterKey: string;
    readonly label?: string;
    readonly inputLabel?: string;
    readonly minInputLabel?: string;
    readonly maxInputLabel?: string;
    readonly type?: Type;
    readonly singleton?: Singleton;
    readonly value: Value<Type, Singleton>;
    readonly onValueChange: (value: Value<Type, Singleton>) => void;
}

export const NumberFilter = memoGeneric(function NumberFilter<
    Type extends NumberType = "float",
    Singleton extends boolean = false
>({
    filterKey,
    label,
    inputLabel = "Value",
    minInputLabel = "Min",
    maxInputLabel = "Max",
    type = "float" as Type,
    singleton = false as Singleton,
    value,
    onValueChange
}: NumberFilterProps<Type, Singleton>) {
    const {GridFilter} = useFilterGridContext();

    const parse = numberParsers[type];

    const [operator, setOperator] = useState<Operator>(() =>
        value === undefined || !(value instanceof Range) || value.isSingleton ? "=" :
        value.lowerBound === null ? "≤" :
        value.upperBound === null ? "≥" :
        "∈"
    );

    const onOperatorChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const newOperator = ev.target.value as Operator;
        setOperator(newOperator);

        const currentValue = value as Range<NumberTypeMap[Type]> | undefined;
        if (!currentValue) return;
        switch (newOperator) {
            case "=": {
                const singletonBound = currentValue.lowerBound ?? currentValue.upperBound;
                onValueChange(makeRange[type](singletonBound, singletonBound) as Value<Type, Singleton>);
                break;
            }
            case "≤":
                //If there is only a lower bound (switching from > to <), keep it so as not to discard input
                if (currentValue.upperBound !== null) {
                    onValueChange(currentValue.withLowerBound(null) as Value<Type, Singleton>);
                }
                else {
                    onValueChange(makeRange[type](null, currentValue.lowerBound) as Value<Type, Singleton>);
                }
                break;
            case "≥":
                if (currentValue.lowerBound !== null) {
                    onValueChange(currentValue.withUpperBound(null) as Value<Type, Singleton>);
                }
                else {
                    onValueChange(makeRange[type](currentValue.upperBound) as Value<Type, Singleton>);
                }
                break;
            case "∈":
                //Nothing to do - any existing value will work
                break;
        }
    }, [onValueChange, type, value]);

    const getSummary = useCallback((label: string) => {
        const valueSummary = value instanceof Range ? value.toMathString() : `= ${value}`;
        return `${label} ${valueSummary}`;
    }, [value]);

    const onValueInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const parsed = ev.target.value ? parse(ev.target.value) : zero[type];

        //Swallow invalid inputs
        if (parsed === undefined) return;

        switch (operator) {
            case "=":
                onValueChange((singleton ? parsed : makeRange[type](parsed, parsed)) as Value<Type, Singleton>);
                break;
            case "≤":
                onValueChange(makeRange[type](null, parsed) as Value<Type, Singleton>);
                break;
            case "≥":
                onValueChange(makeRange[type](parsed) as Value<Type, Singleton>);
                break;
        }
    }, [onValueChange, type, parse, operator, singleton]);

    const onRangeMinInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const currentValue = value as Range<NumberTypeMap[Type]> | undefined;

        if (!ev.target.value) {
            onValueChange(currentValue?.withLowerBound(zero[type]) as Value<Type, Singleton>);
            return;
        }

        const parsed = parse(ev.target.value);

        //Swallow invalid inputs
        if (parsed === undefined) return;

        const newValue = currentValue ? currentValue.withLowerBound(parsed) : makeRange[type](parsed);
        onValueChange(newValue as Value<Type, Singleton>);
    }, [onValueChange, type, value, parse]);

    const onRangeMaxInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const currentValue = value as Range<NumberTypeMap[Type]> | undefined;

        if (!ev.target.value) {
            onValueChange(currentValue?.withUpperBound(zero[type]) as Value<Type, Singleton>);
            return;
        }

        const parsed = parse(ev.target.value);

        //Swallow invalid inputs
        if (parsed === undefined) return;

        const newValue = currentValue ? currentValue.withUpperBound(parsed) : makeRange[type](null, parsed);
        onValueChange(newValue as Value<Type, Singleton>);
    }, [onValueChange, value, type, parse]);

    const onRemove = useCallback(() => onValueChange(undefined), [onValueChange]);

    //step defaults to 1 and needs to be set to "any" to allow arbitrary floats
    const inputStep = type === "float" || type === "BigNumber" ? "any" : 1;

    return (
        <GridFilter
            filterKey={filterKey}
            label={label}
            hasValue={value !== undefined}
            getSummary={getSummary}
            onRemove={onRemove}
        >{(focusRef, renderBaseFilterUi) =>
            renderBaseFilterUi(<>
                {singleton ?
                    <ListItemText sx={{flex: "none"}}>{"="}</ListItemText>
                :
                    <TextField
                        select
                        inputRef={focusRef}
                        variant="standard"
                        size="small"
                        sx={{flex: "none"}}
                        title="Operator"
                        value={operator}
                        onChange={onOperatorChange}
                    >
                        <MenuItem value="=">{"="}</MenuItem>
                        <MenuItem value="≤">{"≤"}</MenuItem>
                        <MenuItem value="≥">{"≥"}</MenuItem>
                        <MenuItem value="∈">{"∈"}</MenuItem>
                    </TextField>
                }
                {operator === "∈" ?
                    <Stack direction="row" alignItems="baseline" gap="5px" flex={1}>
                        [
                        <TextField
                            semiControlled
                            type="number"
                            variant="standard"
                            size="small"
                            sx={{flex: 1}}
                            label={minInputLabel}
                            inputProps={{step: inputStep}}
                            value={(value as Range<NumberTypeMap[Type]> | undefined)?.lowerBound ?? ""}
                            onInput={onRangeMinInput}
                        />
                        {", "}
                        <TextField
                            semiControlled
                            type="number"
                            variant="standard"
                            size="small"
                            sx={{flex: 1}}
                            label={maxInputLabel}
                            inputProps={{step: inputStep}}
                            value={(value as Range<NumberTypeMap[Type]> | undefined)?.upperBound ?? ""}
                            onInput={onRangeMaxInput}
                        />
                        ]
                    </Stack>
                :
                    <TextField
                        semiControlled
                        inputRef={singleton ? focusRef : null}
                        type="number"
                        variant="standard"
                        size="small"
                        sx={{flex: 1}}
                        label={inputLabel}
                        inputProps={{step: inputStep}}
                        value={`${(value instanceof Range ? value.lowerBound ?? value.upperBound : value) ?? ""}`}
                        onInput={onValueInput}
                    />
                }
            </>)
        }</GridFilter>
    );
});
