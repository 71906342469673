import {BigNumber} from "bignumber.js";

import {parseBigIntOrUndefined, parseBigNumberOrUndefined, parseFloatOrUndefined, parseIntOrUndefined} from "./numbers";
import {parseInstantOrUndefined, parsePlainDateOrUndefined} from "./temporal";
import {Temporal} from "@js-temporal/polyfill";

//I am genuinely surprised I couldn't find a flexible Range class on NPM. Maybe one exists somewhere.
//TODO: Not going to implement every theoretically possible feature all at once.
//The following features are potentially useful but not implemented:
//* (Partially) exclusive ranges
//* Iteration (I tried but couldn't find a generalization between numbers and Instants+Durations quickly enough)

export interface RangeInit<T> {
    /** Standard numeric comparator for type T */
    readonly compare: (a: T, b: T) => number;

    /** Function to convert a bound into a string. Defaults to String coercion. */
    readonly boundToString?: (x: T) => string;

    /** The lower bound of the range */
    readonly lowerBound?: T | null;

    /** The upper bound of the range */
    readonly upperBound?: T | null;
}

export interface RangeParseParams<T> extends Omit<RangeInit<T>, "lowerBound" | "upperBound"> {
    /** The separator to parse the range with. Defaults to "..". */
    readonly separator?: string;

    /** Function for parsing individual bounds */
    readonly parseBound: (str: string) => T | undefined;
}

export interface RangeToParamsOptions<
    T,
    BaseName extends string,
    U = T,
    Min extends string = `${BaseName}Gt`,
    Max extends string = `${BaseName}Lt`,
    Eq extends string = `${BaseName}Eq`
> {
    /** The parameter name for the lower bound. Defaults to baseName + "Gt". */
    readonly min?: Min;

    /** The parameter name for the upper bound. Defaults to baseName + "Lt". */
    readonly max?: Max;

    /** The parameter name to use if the range is a singleton. Defaults to baseName + "Eq". */
    readonly eq?: Eq;

    /**
     * Allows transforming the endpoints before they are returned.
     * The second argument indicates which endpoint/singleton value is being transformed.
     */
    readonly transform?: (x: T, role: "singleton" | "lower" | "upper") => U;
}

/** Represents an inclusive range over an orderable and incrementable type */
export class Range<T> {
    private readonly compare: (a: T, b: T) => number;
    private readonly boundToString: (x: T) => string;

    /** The lower bound of the range */
    public readonly lowerBound: T | null;

    /** The upper bound of the range */
    public readonly upperBound: T | null;

    /** Whether the range contains only one value (i.e., the upper and lower bounds are equal) */
    public readonly isSingleton: boolean;

    /** Parses a Range from a string */
    public static parse<T>(
        str: string,
        {
            compare,
            separator = "..",
            parseBound,
            ...rest
        }: RangeParseParams<T>
    ): Range<T> | undefined {
        if (!str) return undefined;

        const [lowerStr, upperStr] = str.split(separator);

        const lowerBound =
            lowerStr ? parseBound(lowerStr) : //x..(x)
            null; //..x
        if (lowerBound === undefined) return undefined;

        const upperBound =
            upperStr ? parseBound(upperStr) : //(x)..x
            upperStr === "" ? null : //x..
            lowerBound; //x
        if (upperBound === undefined) return undefined;

        return new Range<T>({compare, lowerBound, upperBound, ...rest});
    }

    public constructor({
        compare,
        boundToString = String,
        lowerBound = null,
        upperBound = null
    }: RangeInit<T>) {
        if (lowerBound === null && upperBound === null) throw new RangeError("A Range must have at least one bound");

        this.compare = compare;
        this.boundToString = boundToString;

        this.lowerBound = lowerBound;
        this.upperBound = upperBound;
        this.isSingleton = upperBound !== null && lowerBound !== null && compare(upperBound, lowerBound) === 0;
    }

    /** Tests if a value is included in the range */
    public includes(x: T): boolean {
        return (
            (this.lowerBound === null || this.compare(this.lowerBound, x) <= 0)
                && (this.upperBound === null || this.compare(x, this.upperBound) <= 0)
        );
    }

    /** Constructs a new Range with this Range's lowerBound and the given upperBound */
    public withUpperBound(upperBound: T | null): Range<T> {
        return new Range({
            compare: this.compare,
            boundToString: this.boundToString,
            lowerBound: this.lowerBound,
            upperBound
        });
    }

    /** Constructs a new Range with this Range's upperBound and the given lowerBound */
    public withLowerBound(lowerBound: T | null): Range<T> {
        return new Range({
            compare: this.compare,
            boundToString: this.boundToString,
            lowerBound,
            upperBound: this.upperBound
        });
    }

    /**
     * Serializes the range to query parameters
     *
     * @param baseName - The base parameter name. This name will be used for a singleton value.
     * @param options - Optional parameters
     *
     * @returns An object containing:
     * * A single baseName property if the range is a singleton
     * * Either or both of the min and max properties if the range is not a singleton
     */
    public toParams<
        BaseName extends string,
        Min extends string = `${BaseName}Gt`,
        Max extends string = `${BaseName}Lt`,
        Eq extends string = `${BaseName}Eq`
    >(
        baseName: BaseName,
        options?: RangeToParamsOptions<T, BaseName, T, Min, Max, Eq>
    ): {[K in Eq]: T} | {[K in Min]?: T} & {[K in Max]?: T};
    public toParams<
        BaseName extends string,
        U,
        Min extends string = `${BaseName}Gt`,
        Max extends string = `${BaseName}Lt`,
        Eq extends string = `${BaseName}Eq`
    >(
        baseName: BaseName,
        options?: RangeToParamsOptions<T, BaseName, U, Min, Max, Eq>
    ): {[K in Eq]: U} | {[K in Min]?: U} & {[K in Max]?: U};
    public toParams<
        BaseName extends string,
        U,
        Min extends string = `${BaseName}Gt`,
        Max extends string = `${BaseName}Lt`,
        Eq extends string = `${BaseName}Eq`
    >(
        baseName: BaseName,
        {
            min = baseName + "Gt" as Min,
            max = baseName + "Lt" as Max,
            eq = baseName + "Eq" as Eq,
            transform = x => x as any as U
        }: RangeToParamsOptions<T, BaseName, U, Min, Max, Eq> = {}
    ): {[K in Eq]: U} | {[K in Min]?: U} & {[K in Max]?: U} {
        //I feel like there should be some way of avoiding the typecasts for this signature, but I'm not sure how
        /*eslint-disable @typescript-eslint/no-unsafe-return*/
        if (this.isSingleton) return {[eq]: transform(this.lowerBound!, "singleton")} as any;
        else return {
            ...(!this.lowerBound ? {} : {[min]: transform(this.lowerBound, "lower")}),
            ...(!this.upperBound ? {} : {[max]: transform(this.upperBound, "upper")})
        } as any;
        /*eslint-enable @typescript-eslint/no-unsafe-return*/
    }

    /** Formats the range as a mathematical expression using =, ≤, ≥, or ∈ */
    public toMathString(): string {
        if (this.isSingleton) return "= " + this.boundToString(this.lowerBound!);
        else if (this.lowerBound === null) return "≤ " + this.boundToString(this.upperBound!);
        else if (this.upperBound === null) return "≥ " + this.boundToString(this.lowerBound);
        else {
            const lowerBoundStr = this.boundToString(this.lowerBound);
            const upperBoundStr = this.boundToString(this.upperBound);

            //Edge case: The values can technically be different, but have the same string representation.
            //When this happens, the user-facing string should collapse to =.
            if (lowerBoundStr === upperBoundStr) return `= ${lowerBoundStr}`;
            else return `∈ [${lowerBoundStr}, ${upperBoundStr}]`;
        }
    }

    /** Returns true if this Range is equal to the other given */
    public equals(other: Range<T>): boolean {
        if (this === other) return true;

        if ((this.lowerBound !== null) !== (other.lowerBound !== null)) return false;
        if (this.lowerBound !== null && !this.compare(this.lowerBound, other.lowerBound!)) return false;

        if ((this.upperBound !== null) !== (other.upperBound !== null)) return false;
        if (this.upperBound !== null && !this.compare(this.upperBound, other.upperBound!)) return false;

        return true;
    }

    /**
     * Serializes the range to a string
     *
     * @param separator - The separator to use between the bounds. Defaults to "..".
     */
    public toString(separator: string = ".."): string {
        if (this.isSingleton) return this.boundToString(this.lowerBound!);
        else return (this.lowerBound ? this.boundToString(this.lowerBound) : "")
            + separator
            + (this.upperBound ? this.boundToString(this.upperBound) : "");
    }
}

const numericComparator = <T extends number | bigint>(a: T, b: T) => a < b ? -1 : a > b ? 1 : 0;

export const numberRange = (
    lowerBound: number | null,
    upperBound?: number | null,
    opts?: {boundToString?: (bound: number) => string}
) => new Range({
    lowerBound,
    upperBound,
    compare: numericComparator,
    ...opts
});

export const parseIntRange = (str: string, separator?: string) => Range.parse(str, {
    parseBound: parseIntOrUndefined,
    separator,
    compare: numericComparator
});

export const parseFloatRange = (str: string, separator?: string) => Range.parse(str, {
    parseBound: parseFloatOrUndefined,
    separator,
    compare: numericComparator
});

export const bigIntRange = (
    lowerBound: bigint | null,
    upperBound?: bigint | null,
    opts?: {boundToString?: (bound: bigint) => string}
) => new Range({
    lowerBound,
    upperBound,
    compare: numericComparator,
    ...opts
});

export const parseBigIntRange = (str: string, separator?: string) => Range.parse(str, {
    parseBound: parseBigIntOrUndefined,
    separator,
    compare: numericComparator
});

export const bigNumberRange = (
    lowerBound: BigNumber | null,
    upperBound?: BigNumber | null,
    opts?: {boundToString?: (bound: BigNumber) => string}
) => new Range({
    lowerBound,
    upperBound,
    compare: (a, b) => a.comparedTo(b),
    ...opts
});

export const parseBigNumberRange = (str: string, separator?: string) => Range.parse(str, {
    parseBound: parseBigNumberOrUndefined,
    separator,
    compare: (a, b) => a.comparedTo(b)
});

export const instantRange = (
    lowerBound: Temporal.Instant | null,
    upperBound?: Temporal.Instant | null,
    opts?: {boundToString?: (bound: Temporal.Instant) => string}
) => new Range({
    lowerBound,
    upperBound,
    compare: Temporal.Instant.compare,
    ...opts
});

export const parseInstantRange = (str: string, separator?: string) => Range.parse(str, {
    parseBound: parseInstantOrUndefined,
    separator,
    compare: Temporal.Instant.compare
});

export const plainDateRange = (
    lowerBound: Temporal.PlainDate | null,
    upperBound?: Temporal.PlainDate | null,
    opts?: {boundToString?: (bound: Temporal.PlainDate) => string}
) => new Range({
    lowerBound,
    upperBound,
    compare: Temporal.PlainDate.compare,
    ...opts
});

export const parsePlainDateRange = (str: string, separator?: string) => Range.parse<Temporal.PlainDate>(str, {
    parseBound: parsePlainDateOrUndefined,
    separator,
    compare: Temporal.PlainDate.compare
});
