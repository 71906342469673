import {Transfer} from "~/transfer/transfer";
import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {latestInstant} from "~/utils/temporal";
import {User} from "~/user/user";
import {Temporal} from "@js-temporal/polyfill";

export class ActionDescription extends JsonDto {
    @jsonProp(JsonTypes.id)
    public readonly taskId!: string;

    @jsonProp(JsonTypes.string)
    public readonly name!: string;

    @jsonProp(JsonTypes.string, {optional: true})
    public readonly description?: string;

    @jsonProp(JsonTypes.any)
    public readonly action: any;

    public constructor(init?: unknown) {
        super();
        this.init(ActionDescription, init);
    }
}

export class EventLog extends JsonDto {
    @jsonProp(JsonTypes.string)
    public readonly fromState!: string;

    @jsonProp(JsonTypes.string)
    public readonly toState!: string;

    @jsonProp(JsonTypes.any, {optional: true})
    public readonly context: any;

    public constructor(init?: unknown) {
        super();
        this.init(EventLog, init);
    }
}


export class Event extends JsonDto {
    @jsonProp(JsonTypes.any)
    public readonly args!: any;

    @jsonProp(JsonTypes.array(JsonTypes.dto(EventLog)))
    public readonly eventLog!: EventLog[];

    public constructor(init?: unknown) {
        super();
        this.init(Event, init);
    }
}


export class UserTask extends JsonDto {
    public static readonly STATES = ["pending", "resolved", "canceled"] as const;
    public static readonly TYPES = ["failed_transfer", "event_defect", "unfinished_transfer"] as const;

    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp(JsonTypes.stringUnion(UserTask.TYPES))
    public readonly taskType!: UserTask.Type;

    @jsonProp(JsonTypes.dto(User), {optional: true})
    public readonly user!: User | null;

    @jsonProp(JsonTypes.any, {optional: true})
    public readonly taskData!: any;

    @jsonProp(JsonTypes.stringUnion(UserTask.STATES))
    public readonly state!: UserTask.State;

    @jsonProp(JsonTypes.string, {optional: true})
    public readonly note!: string | null;

    @jsonProp(JsonTypes.dto(Transfer), {optional: true})
    public readonly transfer!: Transfer | null;

    @jsonProp(JsonTypes.dto(Event), {optional: true})
    public readonly event!: Event | null;

    @jsonProp()
    public readonly createdAt!: Temporal.Instant;

    @jsonProp(JsonTypes.instant, {optional: true})
    public readonly resolvedAt!: Temporal.Instant | null;

    @jsonProp(JsonTypes.instant, {optional: true})
    public readonly canceledAt!: Temporal.Instant | null;

    @jsonProp(JsonTypes.array(JsonTypes.dto(ActionDescription)))
    public readonly actions!: ActionDescription[];

    public get updatedAt(): Temporal.Instant { return latestInstant(this.canceledAt, this.resolvedAt, this.createdAt); }

    public constructor(init?: unknown) {
        super();
        this.init(UserTask, init);
    }
}

export namespace UserTask {
    export type Type = (typeof UserTask.TYPES)[number];

    export type State = (typeof UserTask.STATES)[number];

    export const isState = (value: string) => {
        return UserTask.STATES.some(state => state === value);
    };
}
