import {sortBy} from "lodash";
import {CreateObservableOptions, IObservableArray, observable, ObservableMap} from "mobx";
import {PickKeys} from "ts-essentials";

import {applyLens, Lens} from "./lens";
import {ObservableOrderableMap, ObservableOrderableMapOptions, SimpleOrderableMap} from "./orderable-map";

//This class contains utility functions for working with iterables.
//Functions and features are added here on an as-needed basis.
//It exists because I couldn't find a good, modern, Lodash-like library with good types and Iterable support.
//It is a class rather than loose functions because the current state of piping in TypeScript is very poor.

//Since writing this class I have found https://github.com/iter-tools/iter-tools
//At some point, it would probably be best to rewrite applicable operations on top of that library.

/** A wrapper class around Iterables and Generators, providing chainable operations */
export class Sequence<out T> implements Iterable<T> {
    /** Constructs a Sequence from an Iterable */
    public static from<T>(xs: Iterable<T>): Sequence<T> {
        return new Sequence(() => xs[Symbol.iterator]());
    }

    /** Constructs a Sequence from individual elements */
    public static of<T>(...xs: readonly T[]): Sequence<T> {
        return new Sequence(() => xs[Symbol.iterator]());
    }

    public constructor(private readonly getIterator: () => Iterator<T>) {}

    /**
     * Adds a step to the Sequence operation chain. Mainly used to define other methods.
     *
     * @param step
     * The function (typically a generator) to call to get the transformed Iterable.
     * This function is called lazily when the Sequence is iterated.
     *
     * @returns A new Sequence containing the items of the transformed Iterable
     */
    public chain<U>(step: (xs: Iterable<T>) => Iterable<U>): Sequence<U> {
        return new Sequence(() => step(this)[Symbol.iterator]());
    }

    /**
     * Collects a Sequence into a data structure
     *
     * @param collector - The collector function
     *
     * @returns The collected data structure
     */
    public collect<U>(collector: (iterable: Iterable<T>) => U): U {
        return collector(this);
    }

    /** A collector function returning an Array */
    public static collectToArray<T>(xs: Iterable<T>): T[] {
        return [...xs];
    }

    /**
     * Collects a Sequence into an Array
     *
     * @returns An Array containing the elements in the Sequence
     */
    public collectToArray(): T[] {
        return Sequence.collectToArray(this);
    }

    /** A collector function returning a Map */
    public static collectToMap<K, V>(xs: Iterable<[K, V]>): Map<K, V> {
        return new Map(xs);
    }

    /**
     * Collects a Sequence into a Map.
     * Can only be used if the Sequence contains key-value pairs.
     *
     * @returns A Map constructed from the pairs in the Sequence
     */
    public collectToMap(): T extends readonly [infer K, infer V] ? Map<K, V> : never {
        //The Map constructor will throw if it gets something that isn't a pair,
        //so there's no need for any extra runtime checks here. Just typecast as needed to make the compiler happy.
        return Sequence.collectToMap(this as any) as never;
    }

    /** A collector function returning a MobX observable array. Curried for convenience. */
    public static collectToObservableArray =
        (options?: CreateObservableOptions) =>
        <T>(xs: Iterable<T>): IObservableArray<T> =>
            observable.array([...xs], options);

    /**
     * Collects a Sequence into a MobX observable array
     *
     * @returns An observable array containing the elements in the Sequence
     */
    public collectToObservableArray(options?: CreateObservableOptions): IObservableArray<T> {
        return Sequence.collectToObservableArray(options)(this);
    }

    /** A collector function that returns a MobX ObservableMap */
    public static collectToObservableMap =
        (options?: CreateObservableOptions) =>
        <K, V>(xs: Iterable<[K, V]>): ObservableMap<K, V> =>
            //FIXME: MobX really ought to support Iterables for initializing maps
            observable.map([...xs], options);

    /**
     * Collects a Sequence into a MobX ObservableMap.
     * Can only be used if the Sequence contains key-value pairs.
     *
     * @returns An ObservableMap constructed from the pairs in the Sequence
     */
    public collectToObservableMap(
        options?: CreateObservableOptions
    ): T extends readonly [infer K, infer V] ? ObservableMap<K, V> : never {
        return Sequence.collectToObservableMap(options)(this as any) as never;
    }

    /** A collector function that returns a simple dictionary object */
    public static collectToObject<K extends PropertyKey, V>(xs: Iterable<[K, V]>): Record<K, V> {
        //Why is Object.fromEntries not generic in the key type?
        return Object.fromEntries(xs) as Record<K, V>;
    }

    /**
     * Collects a Sequence into an object.
     * Can only be used if the Sequence contains key-value pairs where the keys are valid property keys.
     *
     * @returns An object constructed from the pairs in the Sequence
     */
    public collectToObject(): T extends readonly [infer K extends PropertyKey, infer V] ? Partial<Record<K, V>> : never {
        return Sequence.collectToObject(this as any) as never;
    }

    /** A collector function that returns a SimpleOrderableMap */
    public static collectToOrderableMap<K, V>(xs: Iterable<[K, V]>): SimpleOrderableMap<K, V> {
        return new SimpleOrderableMap(xs);
    }

    /**
     * Collects a Sequence into a SimpleOrderableMap.
     * Can only be used if the Sequence contains key-value pairs.
     *
     * @returns A SimpleOrderableMap constructed from the pairs in the Sequence
     */
    public collectToOrderableMap(): T extends readonly [infer K, infer V] ? SimpleOrderableMap<K, V> : never {
        return Sequence.collectToOrderableMap(this as any) as never;
    }

    /** A collector function that returns an ObserableObservableMap */
    public static collectToObservableOrderableMap =
        (options?: {mapOptions?: CreateObservableOptions, arrayOptions?: CreateObservableOptions}) =>
        <K, V>(xs: Iterable<[K, V]>): ObservableOrderableMap<K, V> =>
            new ObservableOrderableMap(xs, undefined, options);

    /**
     * Collects a Sequence into an ObservableOrderableMap.
     * Can only be used if the Sequence contains key-value pairs.
     *
     * @returns An ObservableOrderableMap constructed from the pairs in the Sequence
     */
    public collectToObservableOrderableMap(
        options?: ObservableOrderableMapOptions
    ): T extends readonly [infer K, infer V] ? ObservableOrderableMap<K, V> : never {
        return Sequence.collectToObservableOrderableMap(options)(this as any) as never;
    }

    /** A collector function that returns a Set */
    public static collectToSet<T>(xs: Iterable<T>): Set<T> {
        return new Set(xs);
    }

    /** Collects a Sequence into a Set */
    public collectToSet(): Set<T> {
        return Sequence.collectToSet(this);
    }

    /**
     * A collector function that joins items into a string
     *
     * @param xs - The Iterable to join
     * @param joiners
     * Strings to be placed at the start, between items, and end of the string.
     * A single string can be passed and it will be used as the separator.
     */
    public static collectToString<T>(
        xs: Iterable<T>,
        joiners: string | readonly [start: string, separator: string, end: string] = ["", "", ""]
    ): string {
        const [start, separator, end] = typeof joiners === "string" ? ["", joiners, ""] : joiners;

        //See: https://josephmate.github.io/java/javascript/stringbuilder/2020/07/27/javascript-does-not-need-stringbuilder.html
        let accum = start;

        let first = true;
        for (const x of xs) {
            if (!first) {
                accum += separator;
            }
            first = false;

            accum += x;
        }

        return accum + end;
    }

    /**
     * Collects (joins) a Sequence into a string
     *
     * @param joiners
     * Strings to be placed at the start, between items, and end of the string.
     * A single string can be passed and it will be used as the separator.
     */
    public collectToString(joiners?: string | readonly [start: string, separator: string, end: string]): string {
        return Sequence.collectToString(this, joiners);
    }

    /**
     * Concatenates the elements of this Sequence with those of one or more others.
     * The order of the Iterables and their elements is preserved.
     *
     * @param others - One or more other Iterables to concatenate onto the end of the Sequence
     *
     * @returns The concatenated Sequence
     */
    public concat<U>(...others: Iterable<U>[]): Sequence<T | U> {
        return this.chain(function*(xs) {
            yield* xs;

            for (const other of others) {
                yield* other;
            }
        });
    }

    /**
     * Counts the number of elements in an Iterable that match an optional predicate
     *
     * @param xs - The Iterable to count
     * @param pred - A predicate. If provided, only elements for which it returns true will be counted.
     *
     * @returns The total count
     */
    public static count<T>(xs: Iterable<T>, pred: (x: T) => boolean = () => true): number {
        let count = 0;
        for (const x of xs) {
            if (pred(x)) {
                count++;
            }
        }

        return count;
    }

    /**
     * Counts the number of elements in a Sequence that match an optional predicate
     *
     * @param pred - A predicate. If provided, only elements for which it returns true will be counted.
     *
     * @returns The total count
     */
    public count(pred?: (x: T) => boolean): number {
        return Sequence.count(this, pred);
    }

    /**
     * Filters the elements of a Sequence based on a predicate
     *
     * @param pred - The filtering predicate
     *
     * @returns A new Sequence containing just the elements for which the predicate returns true
     */
    public filter<U extends T>(pred: (el: T) => el is U): Sequence<U>;
    public filter(pred: (el: T) => boolean): Sequence<T>;
    public filter(pred: (el: T) => boolean): Sequence<T> {
        return this.chain(function*(xs) {
            for (const el of xs) {
                if (pred(el)) yield el;
            }
        });
    }

    /**
     * Finds the first element in an Iterable that matches a predicate
     *
     * @param xs - The Iterable to search in
     * @param pred - The predicate to identify the desired item
     *
     * @returns The first item to match the predicate, or undefined if there is none
     */
    public static find<T, U extends T>(xs: Iterable<T>, pred: (el: T) => el is U): U | undefined;
    public static find<T>(xs: Iterable<T>, pred: (el: T) => boolean): T | undefined;
    public static find<T>(xs: Iterable<T>, pred: (el: T) => boolean): T | undefined {
        for (const el of xs) {
            if (pred(el)) return el;
        }

        return undefined;
    }

    /**
     * Finds the first element of a Sequence that matches a predicate
     *
     * @param pred - The predicate to identify the desired item
     *
     * @returns The first item to match the predicate, or undefined if there is none
     */
    public find<U extends T>(pred: (el: T) => el is U): U | undefined;
    public find(pred: (el: T) => boolean): T | undefined;
    public find(pred: (el: T) => boolean): T | undefined {
        return Sequence.find(this, pred);
    }

    /** Returns the first elememt of an Iterable, or undefined if it is empty */
    public static first<T>(xs: Iterable<T>): T | undefined {
        for (const x of xs) return x;
        return undefined;
    }

    /** Returns the first element of a Sequence, or undefined if it is empty */
    public first(): T | undefined {
        return Sequence.first(this);
    }

    /**
     * Maps the elements of a Sequence through a function that returns an Iterable, concatenating the results
     *
     * @param lens - The mapping function. Must return an Iterable.
     *
     * @returns A new Sequence containing the concatenated results of all invocations of the lens
     */
    public flatMap<U>(lens: (x: T) => Iterable<U>): Sequence<U>;
    public flatMap<U, K extends PickKeys<T, Iterable<U>>>(lens: K): Sequence<U>;
    public flatMap<U>(lens: Lens<T, Iterable<U>>): Sequence<U> {
        return this.chain(function*(xs) {
            for (const x of xs) yield* applyLens(lens, x);
        });
    }

    /**
     * Replaces the contents of a Sequence with the given Iterable if the Sequence is empty
     *
     * @param alt - The Iterable to use to replace the empty Sequence, or a function returning an Iterable
     *
     * @returns A new Sequence that has its original contents if there were any or the contents of `alt` otherwise
     */
    public ifEmpty<U>(alt: Iterable<U> | (() => Iterable<U>)): Sequence<T | U> {
        return this.chain(function*(xs) {
            let empty = true;
            for (const x of xs) {
                empty = false;
                yield x;
            }

            if (empty) yield* typeof alt === "function" ? alt() : alt;
        });
    }

    /**
     * Maps the elements of a Sequence onto key-value pairs by retrieving a key for each element
     *
     * @param getKey - Lens getting the key for each item
     *
     * @returns A new Sequence of key-value pairs
     */
    public keyBy<Key extends keyof T>(getKey: Key): Sequence<readonly [T[Key], T]>;
    public keyBy<K>(getKey: (x: T, i: number) => K): Sequence<readonly [K, T]>; //Separate overloads for type inference
    public keyBy<K>(getKey: Lens<T, K, [number]>): Sequence<readonly [K, T]> {
        return this.map((x, i) => [applyLens(getKey, x, i), x] as const);
    }

    /**
     * Maps the elements of a Sequence through a function
     *
     * @param fn - The mapping function
     *
     * @returns A new Sequence containing the mapped elements
     */
    public map<K extends keyof T>(lens: K): Sequence<T[K]>;
    public map<U>(lens: (x: T, i: number) => U): Sequence<U>;
    public map<U>(lens: Lens<T, U, [number]>): Sequence<U> {
        return this.chain(function*(xs) {
            let i = 0;
            for (const el of xs) {
                yield applyLens(lens, el, i++);
            }
        });
    }

    /**
     * Maps the elements of a Sequence onto key-value pairs by deriving a value from each element
     *
     * @param getValue - Lens deriving a value for each item
     *
     * @returns A new Sequence of key-value pairs
     */
    public mapTo<Key extends keyof T>(getValue: Key): Sequence<readonly [T, T[Key]]>;
    public mapTo<V>(getValue: (x: T, i: number) => V): Sequence<readonly [T, V]>;
    public mapTo<V>(getValue: Lens<T, V, [number]>): Sequence<readonly [T, V]> {
        return this.map((x, i) => [x, applyLens(getValue, x, i)] as const);
    }

    /**
     * Removes elements of a Sequence that are null or undefined
     *
     * @returns A new Sequence with the null and undefined elements removed
     */
    public nonNil(): Sequence<Exclude<T, null | undefined>> {
        return this.filter((el): el is Exclude<T, null | undefined> => el !== null && el !== undefined);
    }

    /**
     * Partitions this Sequence into two groups,
     * the first of which contains elements pred returns true for,
     * the second of which contains elements pred returns false for.
     *
     * @param pred - The partitioning predicate
     *
     * @returns [trueGroup, falseGroup]
     */
    public partition<U extends T>(pred: (el: T) => el is U): [Sequence<U>, Sequence<Exclude<T, U>>];
    public partition(pred: (el: T) => boolean): [Sequence<T>, Sequence<T>];
    public partition(pred: (el: T) => boolean): [Sequence<T>, Sequence<T>] {
        const trueGroup = [];
        const falseGroup = [];
        for (const el of this) {
            if (pred(el)) {
                trueGroup.push(el);
            }
            else {
                falseGroup.push(el);
            }
        }

        return [Sequence.from(trueGroup), Sequence.from(falseGroup)];
    }

    /**
     * Sorts an Iterable using JavaScript's standard sorting algorithm.
     *
     * @param xs - The Iterable to sort
     * @param comparator - An optional comparator function to provide to `Array#sort`
     *
     * @returns The result as a newly-constructed sorted array
     */
    public static sort<T>(xs: Iterable<T>, comparator?: (a: T, b: T) => number): T[] {
        return [...xs].sort(comparator);
    }

    /**
     * Sorts a Sequence using JavaScript's standard sorting algorithm.
     *
     * @param comparator - An optional comparator function to provide to `Array#sort`
     *
     * @returns A new Sequence which, when iterated, will internally construct a sorted array
     */
    public sort(comparator?: (a: T, b: T) => number): Sequence<T> {
        return this.chain(xs => Sequence.sort(xs, comparator));
    }

    /**
     * Sorts an Iterable using Lodash `sortBy`
     *
     * @param xs - The Iterable to sort
     * @param lenses - Ordered Lenses providing the value(s) to sort by
     *
     * @returns The result as a newly-constructed sorted array
     */
    public static sortBy<T>(xs: Iterable<T>, ...lenses: Lens<T, unknown>[]): T[] {
        return sortBy([...xs], lenses);
    }

    /**
     * Sorts a Sequence using Loadash `sortBy`
     *
     * @param lenses - Ordered Lenses providing the value(s) to sort by
     *
     * @returns A new Sequence which, when iterated, will internally construct a sorted array
     */
    public sortBy(...lenses: Lens<T, unknown>[]): Sequence<T> {
        return this.chain(xs => Sequence.sortBy(xs, ...lenses));
    }

    /**
     * Truncates a Sequence at the first element for which a predicate returns true
     * (the element for which the predicate returns true is *not* included in the output)
     *
     * @param pred - The predicate to signal truncation
     *
     * @returns A new Sequence stopping just before the truncated element
     */
    public takeUntil(pred: (el: T) => boolean): Sequence<T> {
        return this.chain(function*(xs) {
            for (const el of xs) {
                if (pred(el)) return;
                yield el;
            }
        });
    }

    /**
     * Yields a constant value alongside each element of a Sequence
     *
     * @param withVal - The value to emit with each element
     *
     * @returns A new Sequence of tuples of the original Sequence elements and the given value
     */
    public with<U>(withVal: U): Sequence<[T, U]> {
        return this.chain(function*(xs) {
            for (const el of xs) yield [el, withVal];
        });
    }

    public [Symbol.iterator]() {
        return this.getIterator();
    }
}
