import React from "react";
import {CollapsibleFilterGrid} from "~/components/filter-grid";
import {DateFilter, NumberFilter} from "~/components/filters";
import {debounce} from "lodash";
import {TransferFilter, TransferFilterSetter} from "~/disbursement/use-transfer-query";


export const DisbursementFilters = ({filter, setFilter}: {filter: TransferFilter, setFilter: TransferFilterSetter }) => {
  const setFilterDebounced = debounce((key, value) => setFilter(key, value), 300);

  return (
    <CollapsibleFilterGrid
        sx={{
        mb: 2,
        "& .MuiAccordionDetails-root": {
          maxHeight: "30vh",
          overflow: "auto"
        }
      }}
    >
      <DateFilter
          filterKey="date"
          value={filter.date}
          onValueChange={value => setFilterDebounced("date", value)}
      />
      <NumberFilter
          type="BigNumber"
          filterKey="amount"
          value={filter.amount}
          onValueChange={value => setFilterDebounced("amount", value)}
      />
    </CollapsibleFilterGrid>
  );
};
