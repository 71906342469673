import React, {useEffect, useMemo} from "react";

import {BigNumber} from "bignumber.js";
import {flowResult} from "mobx";
import {observer} from "mobx-react-lite";

import {TransferFilters} from "../components/transfer-filters";
import {TransferListDisplay} from "../components/transfer-list-display";
import {TransferStore} from "../store";
import {Transfer} from "../transfer";
import {PageParams, SortDirection, SortParams} from "~/api/common-query-params";
import {pageNumberSuffix} from "~/api/pagination-envelope";
import {CollapsibleFilterGrid} from "~/components/filter-grid";
import {ButtonLink} from "~/components/link";
import {LoadingTreatment} from "~/components/loading-treatment";
import {Page} from "~/components/page";
import {FundraiseExemption, FundraiseState} from "~/entity/wefunder-types";
import {parseBoolean} from "~/utils/booleans";
import {inject} from "~/utils/di";
import {parsePositiveInt} from "~/utils/numbers";
import {parseBigNumberRange, Range} from "~/utils/range";
import {useAbortableEffect} from "~/utils/use-abortable-effect";
import {useDebouncedValue} from "~/utils/use-debounced-value";
import {useIsFirstRender} from "~/utils/use-is-first-render";
import {useQueryParam} from "~/utils/use-query-param";
import {useStateObject, useTypedStateObject} from "~/utils/use-state-object";
import {SortOptions} from "~/components/sort-options";
import {USER_SORT_KEYS, UserSortKey} from "~/user/api";
import {FilterAndSortBar} from "~/components/filter-and-sort-bar";
import {TRANSFER_SORT_KEYS, TransferSortKey} from "~/transfer/api";

/** Dashboard for viewing all Transfers */
export const TransferDashboard = inject({transferStore: TransferStore}, observer(function TransferDashboard({
    transferStore
}: {transferStore: TransferStore}) {
    const {transfers} = transferStore;

    const [pageParams, setPageParams] = useTypedStateObject<PageParams>()({
        page: useQueryParam("page", 1, {parser: parsePositiveInt, push: true, immediate: true}),
        perPage: useQueryParam("perPage", 10, {parser: parsePositiveInt, push: true, immediate: true})
    });

    const [sortParams, setSortParams] = useTypedStateObject<SortParams<TransferSortKey>>()({
        sortBy: useQueryParam<TransferSortKey | undefined>("sortBy", undefined),
        sortDir: useQueryParam<SortDirection | undefined>("sortDir", undefined)
    });

    const [filters, setFilters] = useStateObject({
        fromAccountId: useQueryParam<string | undefined>("fromAccountId", undefined),
        toAccountId: useQueryParam<string | undefined>("toAccountId", undefined),
        relatedAccountId: useQueryParam<string | undefined>("relatedAccountId", undefined),
        intention: useQueryParam<Transfer.Intention | undefined>("intention", undefined),
        state: useQueryParam<Transfer.State | undefined>("state", undefined),
        investmentId: useQueryParam<string | undefined>("investmentId", undefined),
        fundraiseId: useQueryParam<string | undefined>("fundraiseId", undefined),
        fundraiseExemption: useQueryParam<FundraiseExemption | undefined>("fundraiseExemption", undefined),
        fundraiseState: useQueryParam<FundraiseState | undefined>("fundraiseState", undefined),
        companyId: useQueryParam<string | undefined>("companyId", undefined),
        companyName: useQueryParam<string | undefined>("companyName", undefined),
        investorId: useQueryParam<string | undefined>("investorId", undefined),
        investorName: useQueryParam<string | undefined>("investorName", undefined),
        investorAdmin: useQueryParam<boolean | undefined>("investorAdmin", undefined, {parser: parseBoolean})
    });

    const [amountRange, setAmountRange] = useQueryParam<Range<BigNumber> | undefined>(
        "amount",
        undefined,
        {parser: parseBigNumberRange}
    );

    //Reset the page to 1 when the filters change
    const isFirstRender = useIsFirstRender();
    useEffect(() => {
        if (!isFirstRender) {
            setPageParams.page(1, {immediate: false});
        }
    }, [sortParams, filters]); //eslint-disable-line react-hooks/exhaustive-deps

    const fetchParams = useMemo(() => ({
        ...pageParams,
        ...filters,
        ...sortParams,
        ...amountRange?.toParams("amount")
    }), [filters, sortParams, amountRange, pageParams]);

    const debouncedFetchParams = useDebouncedValue(fetchParams, 150);
    useAbortableEffect(
        signal => flowResult(transferStore.fetchTransfers(debouncedFetchParams, signal)),
        [transferStore, debouncedFetchParams]
    );

    const pageTitle = "All Transfers";
    const pageNumberTitle = transfers.case({
        hasValue: transfers => pageNumberSuffix(transfers.pagination),
        else: () => ""
    });

    return (
        <Page
            title={pageTitle}
            tabTitle={pageTitle + pageNumberTitle}
            sx={{
                "& .PcPage-content": {
                    display: "grid",
                    gridTemplateRows: "auto minmax(0, 1fr)",
                    gridTemplateColumns: "100%",
                    alignItems: "start"
                }
            }}
            actions={<ButtonLink variant="contained" to="/transfers/create">New Transfer</ButtonLink>}
        >
            <FilterAndSortBar>{expandSort => <>
                <CollapsibleFilterGrid
                    sx={{
                        mb: 2,

                        "& .MuiAccordionDetails-root": {
                            maxHeight: "30vh",
                            overflow: "auto"
                        }
                    }}
                >
                    <TransferFilters
                        fromAccountId={filters.fromAccountId}
                        setFromAccountId={setFilters.fromAccountId}
                        toAccountId={filters.toAccountId}
                        setToAccountId={setFilters.toAccountId}
                        relatedAccountId={filters.relatedAccountId}
                        setRelatedAccountId={setFilters.relatedAccountId}
                        intention={filters.intention}
                        setIntention={setFilters.intention}
                        investmentId={filters.investmentId}
                        setInvestmentId={setFilters.investmentId}
                        fundraiseId={filters.fundraiseId}
                        setFundraiseId={setFilters.fundraiseId}
                        fundraiseExemption={filters.fundraiseExemption}
                        setFundraiseExemption={setFilters.fundraiseExemption}
                        fundraiseState={filters.fundraiseState}
                        setFundraiseState={setFilters.fundraiseState}
                        companyId={filters.companyId}
                        setCompanyId={setFilters.companyId}
                        companyName={filters.companyName}
                        setCompanyName={setFilters.companyName}
                        investorId={filters.investorId}
                        setInvestorId={setFilters.investorId}
                        investorName={filters.investorName}
                        setInvestorName={setFilters.investorName}
                        investorAdmin={filters.investorAdmin}
                        setInvestorAdmin={setFilters.investorAdmin}
                        state={filters.state}
                        setState={setFilters.state}
                        amountRange={amountRange}
                        setAmountRange={setAmountRange}
                    />
                </CollapsibleFilterGrid>
                <SortOptions
                    sortKeys={TRANSFER_SORT_KEYS}
                    sortBy={sortParams.sortBy}
                    onSortByChange={setSortParams.sortBy}
                    sortDirection={sortParams.sortDir}
                    onSortDirectionChange={setSortParams.sortDir}
                    expanded={expandSort}
                />
            </>}</FilterAndSortBar>

            <LoadingTreatment loadable={transfers} description="transfers" renderStaleValues observer>{(
                transfers,
                stale
            ) =>
                <TransferListDisplay
                    transfers={[...transfers.data.values()]}
                    paginationState={transfers.pagination}
                    stale={stale}
                    onPageChange={setPageParams.page}
                    onRowsPerPageChange={setPageParams.perPage}
                />
            }</LoadingTreatment>
        </Page>
    );
}));
