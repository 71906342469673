import {isEmpty} from "lodash";
import {Writable} from "ts-essentials";

import {Sequence} from "../sequence";
import {JsonTypeDescriptor, JsonTypes} from "./types";

export interface JsonPropOptions {
    readonly optional?: boolean;
}

export const jsonProp =
    (descriptor?: JsonTypeDescriptor<any>, {optional = false}: JsonPropOptions = {}) =>
    (target: Object, key: string) => {
        if (typeof target === "function") throw new TypeError("JsonProp cannot be used on static properties");

        let jsonProps: string[] | undefined = Reflect.getOwnMetadata("json:properties", target);
        if (!jsonProps) {
            jsonProps = [];
            Reflect.defineMetadata("json:properties", jsonProps, target);
        }

        jsonProps.push(key);

        Reflect.defineMetadata("json:descriptor", descriptor, target, key);
        Reflect.defineMetadata("json:optional", optional, target, key);
    };

export const jsonName = (name: string) => Reflect.metadata("json:sourceName", name);

export type JsonValidationErrorMap =
    | readonly string[]
    | {readonly [K in string]?: JsonValidationErrorMap} & {readonly [JsonValidationError.ROOT]?: readonly string[]};

export class JsonValidationError extends Error {
    public static readonly ROOT: unique symbol = Symbol("ROOT");

    public override get name() { return "JsonValidationError"; }

    private _message: string | null = null;
    public override get message() {
        return this._message ??=
            Sequence.from(JsonValidationError.enumerateErrors(this.errors)).collectToString(["\n", "\n", ""]);
    }

    private static * enumerateErrors(errors: JsonValidationErrorMap): Iterable<string> {
        if (Array.isArray(errors)) {
            for (const err of errors) yield `* ${err}`;
        }
        else {
            if (Object.hasOwn(errors, JsonValidationError.ROOT)) {
                for (const err of errors[JsonValidationError.ROOT]) yield `* ${err}`;
            }

            for (const [prop, propErrors] of Object.entries(errors)) {
                yield `${prop}:`;
                for (const errLine of JsonValidationError.enumerateErrors(propErrors)) yield " ".repeat(4) + errLine;
            }
        }
    }

    public constructor(public readonly dtoName: string, public readonly errors: JsonValidationErrorMap) {
        super();
    }
}

export abstract class JsonDto {
    protected init(thisStaticClass: Function, init: any = {}): void {
        if (typeof init !== "object" || init === null)
            throw new JsonValidationError(thisStaticClass.name, [`init must be an object, got ${init}`]);

        const {prototype} = thisStaticClass;

        const jsonProps: readonly string[] = Reflect.getOwnMetadata("json:properties", prototype) ?? [];
        const errors: Writable<JsonValidationErrorMap> = {};
        for (const prop of jsonProps) {
            const jsonDescriptor: JsonTypeDescriptor<any> =
                Reflect.getOwnMetadata("json:descriptor", prototype, prop)
                    ?? JsonTypes.getDescriptorForReflectedType(thisStaticClass, prop);
            const optional: boolean = Reflect.getOwnMetadata("json:optional", prototype, prop);
            const jsonName: string = Reflect.getOwnMetadata("json:sourceName", prototype, prop) ?? prop;

            const initValue = init[jsonName];

            if (initValue === null || initValue === undefined) {
                if (optional) {
                    //Normalize optional values to null. Allow a default to be set in the class.
                    this[prop] ??= null;
                }
                else {
                    errors[prop] = ["Value is required"];
                }

                continue;
            }

            if (jsonDescriptor.isOfType(initValue)) {
                this[prop] = initValue;
                continue;
            }

            const [value, valueErrors] = jsonDescriptor.parse(initValue);
            if (valueErrors) {
                errors[prop] = valueErrors;
            }
            else {
                this[prop] = value;
            }
        }

        if (!isEmpty(errors)) throw new JsonValidationError(thisStaticClass.name, errors);
    }

    //This makes no attempt to re-serialize any property values and is present mostly for compatibility with JsonDisplay
    public toJSON() { return this; }
}

export namespace JsonDto {
    export const discriminator: unique symbol = Symbol("JsonDto.discriminator");

    export const subtypes: unique symbol = Symbol("JsonDto.subtypes");

    //I would like to be more specific than just JsonDto here, but ADTs in TypeScript are inherently unsound
    //and inheritance is very much not the way the compiler wants you to implement them.
    //Constraining the type to T doesn't work as TypeScript does not have the concept of a "sealed" class hierarchy.
    export type ADT<T extends JsonDto> = (abstract new (init?: unknown) => JsonDto) & {
        readonly [discriminator]?: string,
        readonly [subtypes]: {readonly [typename: string]: Parseable<T> | undefined}
    };

    export type Parseable<T extends JsonDto> = (new (init?: unknown) => T) | ADT<T>;

    /** Parses an instance of a JsonDto from a JSON string */
    export function parse<T extends JsonDto>(dtoClass: Parseable<T>, json: string): T {
        return construct(dtoClass, JSON.parse(json));
    }

    /** Parses an array of JsonDto insances from a JSON array */
    export function parseArray<T extends JsonDto>(dtoClass: Parseable<T>, json: string): T[] {
        return constructArray(dtoClass, JSON.parse(json));
    }

    /** Constructs a JsonDto from already parsed JSON */
    export function construct<T extends JsonDto>(dtoClass: Parseable<T>, init: any = {}): T {
        if (init instanceof dtoClass) return init as T;

        if (Object.hasOwn(dtoClass, subtypes)) {
            const discriminatorProp: string = dtoClass[discriminator] ?? "type";
            const typeKey = init[discriminatorProp];
            if (typeof typeKey !== "string")
                throw new JsonValidationError(dtoClass.name, {
                    [discriminatorProp]: [`Discriminator value ${typeKey} is not a string`]
                });

            const subtype: Parseable<T> | undefined = dtoClass[subtypes][typeKey];
            if (!subtype)
                throw new JsonValidationError(dtoClass.name, {
                    [discriminatorProp]: [`Unknown type "${typeKey}"`]
                });

            return construct(subtype, init);
        }
        else {
            const tClass = dtoClass as new (init?: unknown) => T;
            return new tClass(init);
        }
    }

    /** Constructs an array of JsonDtos from already parsed JSON */
    export function constructArray<T extends JsonDto>(dtoClass: Parseable<T>, init: any): T[] {
        if (!Array.isArray(init))
            throw new JsonValidationError(dtoClass.name + "[]", [
                `Expected root value to be an array. Got ${typeof init}`
            ]);

        return init.map(x => construct(dtoClass, x));
    }
}
