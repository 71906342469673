import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {BigNumber} from "bignumber.js";
import {CURRENCIES, Currency} from "~/currency";
import Recon from "~/recon/recon";
import {Temporal} from "@js-temporal/polyfill";

export default class LineItem extends JsonDto {
    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp(JsonTypes.id)
    public readonly backendId!: number;

    @jsonProp(JsonTypes.array(JsonTypes.dto(Recon)))
    public readonly recons!: Recon[];

    @jsonProp(JsonTypes.stringUnion(CURRENCIES))
    public readonly currency!: Currency;

    @jsonProp()
    public readonly amount!: BigNumber;

    @jsonProp(JsonTypes.string, {optional: true})
    public readonly description!: string | null;

    @jsonProp(JsonTypes.string, {optional: true})
    public readonly reference!: string | null;

    @jsonProp()
    public readonly createdAt!: Temporal.Instant;

    public constructor(init?: unknown) {
        super();
        this.init(LineItem, init);
    }

    public isReconciled(): boolean {
        return this.amount === this.recons.reduce((total, recon) => total.plus(recon.amount), new BigNumber(0));
    }

    public hasPendingRecon(): boolean {
        return this.recons.filter(p => p.state === "pending").length > 0;
    }
}
