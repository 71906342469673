import React, {ForwardedRef, forwardRef, useCallback} from "react";
import {To, useHref, useLinkClickHandler} from "react-router-dom";

import {
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
    Link as MuiLink,
    LinkProps as MuiLinkProps
} from "@mui/material";
import classNames from "classnames";

import {Button, ButtonPropExtensions} from "./button";
import {useToIsActive} from "~/utils/routing";
import {transition} from "~/utils/styles";

interface UseLinkPropsParams {
    to: To;
    nav: boolean;
    hrefProp: string | undefined;
    replace: boolean;
    state: any;
    target: React.HTMLAttributeAnchorTarget | undefined;
    onClickProp: ((ev: React.MouseEvent<HTMLAnchorElement>) => void) | undefined;
}

function useLinkProps({to, nav, hrefProp, replace, state, target, onClickProp}: UseLinkPropsParams) {
    const toIsActive = useToIsActive(to);
    const isActive = nav && toIsActive;

    const routerHref = useHref(to);
    const href = hrefProp || routerHref;

    const handleClick = useLinkClickHandler(to, {replace, state, target});
    const onClick = useCallback((ev: React.MouseEvent<HTMLAnchorElement>) => {
        onClickProp?.(ev);
        if (!hrefProp && !ev.defaultPrevented) {
            handleClick(ev);
        }
    }, [hrefProp, onClickProp, handleClick]);

    return {href, onClick, isActive};
}

interface CommonLinkProps {
    readonly replace?: boolean;
    readonly state?: any;
    readonly to?: To;
    readonly nav?: boolean;
    readonly ariaCurrentValue?: React.AnchorHTMLAttributes<HTMLAnchorElement>["aria-current"];
}

export type LinkProps = MuiLinkProps & CommonLinkProps;

export const Link = forwardRef(function Link({
    onClick: onClickProp,
    replace = false,
    state,
    target,
    href: hrefProp,
    to = {},
    nav = false,
    ariaCurrentValue = "page",
    className,
    sx = [],
    ...props
}: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) {
    const {href, onClick, isActive} = useLinkProps({to, nav, hrefProp, replace, state, target, onClickProp});

    return <MuiLink
        ref={ref}
        href={href}
        onClick={onClick}
        target={target}
        className={classNames("PcLink-root", className, {active: isActive})}
        //Color transition consistent with the Button component
        sx={[{
            ...transition(["color", "short", "easeInOut"]),

            "&:hover": {
                color: "primary.light"
            }
        }, sx].flat()}
        {...(isActive && {"aria-current": ariaCurrentValue})}
        {...props}
    />;
});

export type ButtonLinkProps = MuiButtonProps<"a"> & ButtonPropExtensions & CommonLinkProps;

export const ButtonLink = forwardRef(function ButtonLink({
    onClick: onClickProp,
    replace = false,
    state,
    target,
    href: hrefProp,
    to = {},
    nav = false,
    ariaCurrentValue = "page",
    className,
    ...props
}: ButtonLinkProps, ref: ForwardedRef<HTMLAnchorElement>) {
    const {href, onClick, isActive} = useLinkProps({to, nav, hrefProp, replace, state, target, onClickProp});

    //Need to use our wrapped button to support the lightweight variant.
    //Typecast to get the href prop back.
    const WrappedMuiButton = Button as typeof MuiButton;
    return <WrappedMuiButton
        ref={ref}
        href={href}
        onClick={onClick}
        target={target}
        className={classNames("PcButtonLink-root", className, {active: isActive})}
        {...(isActive && {"aria-current": ariaCurrentValue})}
        {...props}
    />;
});
