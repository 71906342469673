import {User} from "./user";
import {BaseApi} from "~/api/base-api";
import {PageParams, SortParams} from "~/api/common-query-params";
import {FetchClient} from "~/api/fetch-client";
import {PaginationEnvelope} from "~/api/pagination-envelope";

export interface UserCreationDto {
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly password: string;
    readonly role: User.Role;
}

export type UserUpdateDto = Omit<Partial<User>, "id"> & {
    readonly password?: string
};

export type CurrentUserUpdateDto = Omit<Partial<User>, "id"> & {
    readonly password?: {
        readonly oldPassword: string,
        readonly newPassword: string
    }
};

export interface UserFilterParams {
    readonly userRole?: User.Role;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly email?: string;
}

export const USER_SORT_KEYS = [{key: "role"}, {key: "firstName"}, {key: "lastName"}, {key: "email"}] as const;

export type UserSortKey = (typeof USER_SORT_KEYS)[number]["key"];

export class UserApi extends BaseApi<User> {
    public constructor(fetchClient: FetchClient, errorHandler: (err: Error) => boolean) {
        super(fetchClient, errorHandler, "/users", User);
    }

    public async getUser(id: string, signal?: AbortSignal): Promise<User> {
        return await this.get(id, signal);
    }

    public async getCurrentUser(signal?: AbortSignal): Promise<User> {
        return await this.fetchJson(User, "GET", "profile", {signal});
    }

    public async createUser(userDetails: UserCreationDto, signal?: AbortSignal): Promise<User> {
        return await this.create(userDetails, signal);
    }

    public async updateUser(id: string, updates: UserUpdateDto, signal?: AbortSignal): Promise<User> {
        return await this.put(id, updates, signal);
    }

    public async updateCurrentUser(updates: CurrentUserUpdateDto, signal?: AbortSignal): Promise<User> {
        return await this.fetchJson(User, "PUT", "profile", {json: updates, signal});
    }

    public async findUsers(
        params: PageParams & SortParams<UserSortKey> & UserFilterParams,
        signal?: AbortSignal
    ): Promise<PaginationEnvelope<User[]>> {
        return await this.find(params, signal);
    }
}
