import {BigNumber} from "bignumber.js";
import {makeObservable, observable} from "mobx";

import {Backend} from "~/backend/backend";
import {CURRENCIES, Currency} from "~/currency";
import {Entity} from "~/entity/entity";
import {TransferIntentions, TransferStates} from "~/transfer/index";
import {Transfer} from "~/transfer/transfer";
import {TransferEvent} from "~/transfer/transfer-event";
import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {TransferMetadata} from "../transfer/transfer-metadata";

export class Disbursement extends JsonDto {
    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @observable
    @jsonProp(JsonTypes.stringUnion(TransferStates))
    public readonly state!: Transfer.State;

    @jsonProp(JsonTypes.array(JsonTypes.lazy(() => JsonTypes.dto(Transfer))))
    public readonly wires!: readonly Transfer[];

    @jsonProp(JsonTypes.bigNumber)
    public readonly amount!: BigNumber;

    @jsonProp(JsonTypes.dto(Backend))
    public readonly backend!: Backend;

    @jsonProp(JsonTypes.stringUnion(CURRENCIES))
    public readonly currency!: Currency;

    @jsonProp(JsonTypes.stringUnion(TransferIntentions))
    public readonly intention!: Transfer.Intention;

    @jsonProp(JsonTypes.dto(TransferMetadata))
    public readonly intentionData!: TransferMetadata;

    @jsonProp()
    public readonly tag!: string;

    @jsonProp(JsonTypes.array(JsonTypes.lazy(() => JsonTypes.dto(TransferEvent))))
    public readonly events!: readonly TransferEvent[];

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly fundraise!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly company!: Entity | null;

    public constructor(init?: unknown) {
        super();
        this.init(Disbursement, init);
   }
}
