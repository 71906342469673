import {
    experimental_sx as sx,
    Table,
    TableBody,
    TableBodyProps,
    TableCell,
    TableCellProps,
    TableContainer,
    TableContainerProps,
    TableHead,
    TableHeadProps,
    TableProps,
    TableRow,
    TableRowProps
} from "@mui/material";

import {ComponentOverrides} from "../mui-theme-utils";

export * from "./edit-columns-flyout";
export * from "./paginated-table";
export * from "./table-columns";
export * from "./use-local-storage-column-order";

export {
    Table,
    TableBody,
    TableBodyProps,
    TableCell,
    TableCellProps,
    TableContainer,
    TableContainerProps,
    TableHead,
    TableHeadProps,
    TableProps,
    TableRow,
    TableRowProps
};

export const tableOverrides: ComponentOverrides = {
    MuiTableHead: {
        styleOverrides: {
            root: sx({
                "& .MuiTableCell-head": {
                    whiteSpace: "nowrap"
                }
            })
        }
    }
};
