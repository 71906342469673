import {useState, useEffect} from "react";

//https://dev.to/arnonate/debouncing-react-query-with-hooks-2ek6

/**
 * Debounces changes to the value.
 * Value changes take effect at the end of the timeout, at which point the most recent value is used.
 *
 * @param value - The value to debounce
 * @param delay - The amount of time in milliseconds that the value must not be changed before changes take effect
 *
 * @returns The most recent value that was stable long enough to take effect
 */
export function useDebouncedValue<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay);

        //Cancel the timeout if value changes (also on delay change or unmount)
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
}
