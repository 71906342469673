import React, {useState} from "react";
import {UserTask} from "../user-task";
import {Accordion, AccordionDetails, AccordionSummary, Button, Stack, Tooltip, Typography} from "@mui/material";
import {LabeledValue} from "~/components/labeled-item";
import UserTasks, {TaskActionCommand} from "~/repositories/user-tasks";
import ActionButton, {ActionButtonProps} from "~/components/action-button";
import {useQuery} from "react-query";
import Transfers from "~/repositories/transfers";
import {RenderIf} from "~/recon/util";
import TransferView from "../../transfer/views/transfer-view";
import {Transfer} from "~/transfer/transfer";

const TransferIdRegex = /(?:T|t)ransfer (?<id>\d+)/um;

// This logic is cursed, but relatively simple - if a transfer is present - display it and disable the query (by setting the queryTransferId to undefined).
// Otherwise, fetch the transfer by id.
const TransferAccordion = ({fetchTransferId = undefined, transfer = undefined}: {fetchTransferId: string | undefined, transfer: Transfer | undefined}) => {
  const queryTransferId = transfer ? undefined : fetchTransferId;
  const relatedTransfer = useQuery(Transfers.get(queryTransferId));
  if (transfer === undefined && relatedTransfer.isSuccess) {
    transfer = relatedTransfer.data;
 }

  return (
    <RenderIf cond={!!transfer}>
      <Accordion sx={{backgroundColor: "#2a2a2a", my: 2, borderRadius: 2}}>
        <AccordionSummary>
          <Typography>Transfer {transfer?.id || fetchTransferId}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransferView transfer={transfer!}/>
        </AccordionDetails>
      </Accordion>
    </RenderIf>
  );
};

export default function UserTaskDetails(onTaskUpdate: (task: UserTask) => void) {
  const Component = ({row}: {row: UserTask}) => {
    const actionParams: TaskActionCommand[] = row.actions.map(action => ({taskId: Number(row.id), action: action.action}));
    const transferId = row.note?.match(TransferIdRegex)?.groups?.id;

    return (
      <Stack direction="column" sx={{py: 2}}>
        <LabeledValue label="Note" value={row.note}/>
        <LabeledValue label="State" value={row.state}/>
        <LabeledValue label="Created At" value={row.createdAt.toLocaleString()}/>
        <LabeledValue label="Canceled At" value={row.canceledAt ? row.canceledAt.toLocaleString() : null}/>
        <LabeledValue label="Resolved At" value={row.resolvedAt ? row.resolvedAt.toLocaleString() : null}/>

        {row.transfer ? 
          <TransferAccordion transfer={row.transfer} fetchTransferId={undefined}/> :
          (transferId ? <TransferAccordion transfer={undefined} fetchTransferId={transferId}/> : null)
       }

        <Stack direction="row" spacing={2} sx={{mt: 2}}>
          {row.actions.map((action, index) => 
            <ActionButton<UserTask>
                key={index}
                tooltipMessage={action.description}
                mutation={UserTasks.resolve(actionParams[index])}
                refetchKey={["user_tasks", row.id]}
                successMessage="Task Resolved"
                onSuccess={onTaskUpdate}
            >
              {action.name}
            </ActionButton>
          )}
        </Stack>
      </Stack>
    );
 };
  return Component;
}