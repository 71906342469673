import React, {memo} from "react";

import {startCase} from "lodash";

import {TransferSummary} from "../transfer-summary";
import {PaginationState} from "~/api/pagination-envelope";
import {Link} from "~/components/link";
import {
    EditColumnsFlyout,
    makeColumns,
    PaginatedTable,
    TableColumns,
    useLocalStorageColumnOrder
} from "~/components/table";
import {takeArgs} from "~/utils/event-helpers";
import {NoWrap, Span} from "~/utils/styles";
import {MoneyFormat} from "~/utils/money";

export interface TransferListDisplayProps {
    readonly elevation?: number;
    readonly transfers: Iterable<TransferSummary>;
    readonly paginationState: PaginationState;
    readonly stale: boolean;
    readonly onPageChange: (page: number) => void;
    readonly onRowsPerPageChange: (rowsPerPage: number) => void;
}

export const TransferListDisplay = memo(function TransferListDisplay({
    elevation = 1,
    transfers,
    paginationState,
    stale,
    onPageChange,
    onRowsPerPageChange
}: TransferListDisplayProps) {
    const columns = makeColumns<TransferSummary>()({
        id: ["ID", transfer => <Link to={`/transfers/${transfer.id}`}>{transfer.id}</Link>],
        intention: transfer => <NoWrap>{startCase(transfer.intention)}</NoWrap>,
        description: transfer =>
            transfer.intentionData &&
                <Span display="inline-block" minWidth="30ex">{transfer.intentionData.description}</Span>,
        amount: transfer => MoneyFormat.formatBigNumber(transfer.amount, transfer.currency),
        fromAccount: transfer =>
            transfer.fromAccount &&
                <Link to={`/accounts/${transfer.fromAccount.id}`}>
                    {transfer.fromAccount.name} ({transfer.fromAccount.id})
                </Link>,
        toAccount: transfer =>
            transfer.toAccount &&
                <Link to={`/accounts/${transfer.toAccount.id}`}>
                    {transfer.toAccount.name} ({transfer.toAccount.id})
                </Link>,
        state: transfer => startCase(transfer.state),
        tag: transfer => transfer.tag
    });

    const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder("transferColumnOrder", columns);

    return (
        <PaginatedTable
            elevation={elevation}
            paginationState={paginationState}
            loadingNextPage={stale}
            onPageChange={takeArgs(onPageChange, 1)}
            onRowsPerPageChange={takeArgs(onRowsPerPageChange, 1)}
            renderExtraToolbarContents={() =>
                <EditColumnsFlyout columns={columns} columnOrder={columnOrder} onColumnOrderChange={setColumnOrder}/>
            }
        >
            <TableColumns rowData={transfers} keyRowsBy="id" columnOrder={columnOrder} observer>
                {columns}
            </TableColumns>
        </PaginatedTable>
    );
});
