import React, {useCallback} from "react";
import {Navigate} from "react-router-dom";

import {Box} from "@mui/material";
import {observer} from "mobx-react-lite";
import QRCode from "react-qr-code";

import {AuthStore} from "../store";
import {Api} from "~/api/api";
import {Alert} from "~/components/alert";
import {Form} from "~/components/form";
import {LoadingTreatment} from "~/components/loading-treatment";
import {Page, PageWidth} from "~/components/page";
import {TextField} from "~/components/text-field";
import {Typography} from "~/components/typography";
import {inject} from "~/utils/di";
import {preventDefault} from "~/utils/event-helpers";
import {useLoadable} from "~/utils/loadable";
import {useAbortController} from "~/utils/use-abort-controller";
import {useInputState} from "~/utils/use-input-state";

export const TOTPRegistration = inject({api: Api, authStore: AuthStore}, observer(function TOTPRegistration({
    api,
    authStore
}: {api: Api, authStore: AuthStore}) {
    const {authentication, challengeToken} = authStore;

    const [totpSetup] = useLoadable(
        api.auth.setupTOTP,
        [challengeToken!, useAbortController()],
        {eager: true, disabled: !challengeToken, retry: true}
    );

    const [verification, onVerificationInput] = useInputState("");

    const verify = useCallback(() => authStore.verifyNewTOTP(verification), [authStore, verification]);

    if (!challengeToken) return <Navigate to="/" replace/>;

    return (
        <Page
            title="Register Authenticator App"
            width={PageWidth.NARROW}
            sx={{
                "& .PcPage-content": {
                    alignItems: "center",
                    gap: 2
                }
            }}
        >
            <LoadingTreatment loadable={totpSetup}>{({otpAuthUri, otpSecret}) => <>
                <Typography>
                    Register the code below with the TOTP-compatible authenticator app of your choice
                    (e.g., Google Authenticator) and then enter a one-time password to confirm.
                </Typography>

                {/* If the user is logged in, that means that they're replacing an existing authenticator */}
                {/* NOTE: If we add WebAuthn this assumption will no longer hold and we'll need to manually check */}
                {authentication.valueOrNull() !== null &&
                    <Alert severity="warning" sx={{width: 1}}>
                        This will remove your previously registered authenticator app
                    </Alert>
                }

                {/*
                    QR codes need a light-colored background.
                    See: https://github.com/rosskhanas/react-qr-code/blob/master/README.md
                */}
                <Box sx={{background: "white", p: "16px", width: "min-content"}}>
                    <QRCode value={otpAuthUri}/>
                </Box>
                <Typography variant="body2" textAlign="center">{otpSecret}</Typography>

                {/* Use authentication as submitStatus to surface errors */}
                <Form sx={{mt: 3, width: 1}} submitStatus={authentication} onSubmit={preventDefault(verify)}>
                    <TextField label="Verify OTP" value={verification} onInput={onVerificationInput}/>
                </Form>
            </>}</LoadingTreatment>
        </Page>
    );
}));
