import {FetchClient} from "./fetch-client";
import {VirtualAccountApi} from "~/account/api";
import {AchApi} from "~/ach/api";
import {AuthApi} from "~/auth/api";
import {BackendApi} from "~/backend/api";
import {TransferApi} from "~/transfer/api";
import {UserTaskApi} from "~/user-task/api";
import {UserApi} from "~/user/api";
import {LineItemApi} from "~/line-items/api";
import {StripePayoutApi} from "~/stripe-payouts/api";
import {StripePayoutTransactionApi} from "~/stripe-payout-transaction/api";

/** Root class containing APIs for all application domains */
export class Api {
    public readonly ach: AchApi;
    public readonly auth: AuthApi;
    public readonly backends: BackendApi;
    public readonly transfers: TransferApi;
    public readonly users: UserApi;
    public readonly userTasks: UserTaskApi;
    public readonly virtualAccounts: VirtualAccountApi;
    public readonly lineItems: LineItemApi;
    public readonly stripePayouts: StripePayoutApi;
    public readonly stripePayoutTransactions: StripePayoutTransactionApi;

    /**
     * Constructs a new Api object
     *
     * @param fetchClient - The root FetchClient to use for making API calls
     */
    public constructor(fetchClient: FetchClient, errorHandler: (err: Error) => boolean) {
        this.ach = new AchApi(fetchClient, errorHandler);
        this.auth = new AuthApi(fetchClient);
        this.backends = new BackendApi(fetchClient, errorHandler);
        this.transfers = new TransferApi(fetchClient, errorHandler);
        this.users = new UserApi(fetchClient, errorHandler);
        this.userTasks = new UserTaskApi(fetchClient, errorHandler);
        this.virtualAccounts = new VirtualAccountApi(fetchClient, errorHandler);
        this.lineItems = new LineItemApi(fetchClient, errorHandler);
        this.stripePayouts = new StripePayoutApi(fetchClient, errorHandler);
        this.stripePayoutTransactions = new StripePayoutTransactionApi(fetchClient, errorHandler);
    }
}
