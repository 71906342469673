import {useEffect, useMemo, useRef, useState} from "react";

export interface AbortableEffectController {
    readonly aborted: boolean;
    readonly abort: () => void;
    readonly unabort: () => void;
}

/**
 * Convenience hook for writing an async effect that makes use of an AbortSignal for cancellation
 *
 * @param effect
 * The effect to run. Is passed the AbortSignal from `abortController`.
 * It can return either just a Promise (resolving to void), or a tuple of the Promise and a cleanup function.
 * The returned cleanup function will be called after calling abort() on the AbortController.
 * @param deps - The dependency array for the effect.
 *
 * @returns
 * A controller object that can be used to abort and unabort the effect.
 * Abortion is persistent - the effect will not run until unaborted.
 */
export function useAbortableEffect(
    effect: (abortSignal: AbortSignal) => Promise<void> | [Promise<void>, () => void],
    deps: readonly unknown[]
): AbortableEffectController {
    const abortController = useRef(new AbortController());

    useEffect(() => () => abortController.current.abort(), [abortController]);

    const [aborted, setAborted] = useState(false);

    useEffect(() => {
        if (aborted) return;

        abortController.current.abort();
        abortController.current = new AbortController();

        let cleanup!: (() => void) | void;
        void (async () => {
            try {
                const effectResult = effect(abortController.current.signal);
                const promise = Array.isArray(effectResult) ? effectResult[0] : effectResult;
                cleanup = Array.isArray(effectResult) ? effectResult[1] : undefined;
                await promise;
            }
            catch (err) {
                if (!(err instanceof DOMException && err.name === "AbortError")) throw err;
                //Swallow AbortErrors
            }
        })();

        return cleanup;
    }, [...deps, aborted, abortController]); //eslint-disable-line react-hooks/exhaustive-deps

    return useMemo<AbortableEffectController>(() => ({
        aborted,

        abort() {
            abortController.current.abort();
            setAborted(true);
        },

        unabort() {
            setAborted(false);
        }
    }), [aborted, setAborted, abortController]);
}
