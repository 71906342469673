import React, {useCallback, useRef, useState} from "react";

import {Box, Stack} from "@mui/material";
import {visuallyHidden} from "@mui/utils";
import {findIndex, findLastIndex, memoize} from "lodash";

import {Button, IconButton} from "../button";
import {Checkbox} from "../checkbox";
import {Flyout, FlyoutHandle} from "../flyout";
import {Icon} from "../icon";
import {List, ListItem} from "../list";
import {ColumnMap, columnName, ColumnOrder} from "./table-columns";
import {memoGeneric} from "~/utils/memo-generic";
import {Sequence} from "~/utils/sequence";
import {transition} from "~/utils/styles";
import {usePreviousValue} from "~/utils/use-previous-value";

//Make hidden column IDs not a complete pain to use
const makeHiddenColumn = <ColId extends string>(col: Object) => ({
    hidden: col.toString() as ColId,
    toString() { return this.hidden; },
    valueOf() { return this.toString(); }
});

//Save a bit of typing
const swapArrayIndexes = (arr: unknown[], a: number, b: number) => [arr[a], arr[b]] = [arr[b], arr[a]];

interface AnimationState {
    readonly primaryIndex: number;
    readonly secondaryIndex: number;
    readonly primaryDirection: "up" | "down";
}

export interface EditColumnsFlyoutProps<ColId extends string> {
    readonly columns: ColumnMap<never>;
    readonly columnOrder: ColumnOrder<ColId>;
    readonly onColumnOrderChange: (newOrder: ColumnOrder<ColId>) => void;
}

export const EditColumnsFlyout = memoGeneric(function EditColumnsFlyout<ColId extends string>({
    columns,
    columnOrder,
    onColumnOrderChange
}: EditColumnsFlyoutProps<ColId>) {
    const flyout = useRef<FlyoutHandle>(null);
    const list = useRef<HTMLUListElement>(null);

    const processColumnOrder = useCallback(
        () => columnOrder.map(col => typeof col === "string" ? col : makeHiddenColumn<ColId>(col.hidden)),
        [columnOrder]
    );

    const [pendingColumnOrder, setPendingColumnOrder] = useState<ColumnOrder<ColId>>(processColumnOrder);
    const visibleColumnCount = Sequence.from(pendingColumnOrder).count(col => typeof col === "string");
    const prevColumnOrder = usePreviousValue(pendingColumnOrder);
    const [screenReaderMessage, setScreenReaderMessage] = useState("");
    const [animation, setAnimation] = useState<AnimationState | null>(null);

    //There are cases where columns are conditional and may be disabled by omitting them from the dictionary.
    //In these cases we want to preserve the columns in the order, but hide them from the UI
    const colIsEnabled = useCallback(
        (col: string | {readonly hidden: string}) => col.toString() in columns,
        [columns]
    );

    //eslint-disable-next-line react-hooks/exhaustive-deps
    const showHideColumn = useCallback(memoize((i: number) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        const newPendingColumnOrder = [...pendingColumnOrder];
        if (ev.target.checked) {
            newPendingColumnOrder[i] = pendingColumnOrder[i].toString() as ColId;
        }
        else {
            newPendingColumnOrder[i] = makeHiddenColumn<ColId>(pendingColumnOrder[i]);
        }

        setPendingColumnOrder(newPendingColumnOrder);
    }), [pendingColumnOrder, setPendingColumnOrder]);

    const moveColumn = useCallback((from: number, to: number) => {
        const newPendingColumnOrder = [...pendingColumnOrder];
        const col = pendingColumnOrder[from].toString();
        swapArrayIndexes(newPendingColumnOrder, from, to);
        setPendingColumnOrder(newPendingColumnOrder);
        setScreenReaderMessage(`Column "${columnName(col, columns[col])}" is now in position ${to + 1}`);

        setAnimation({primaryIndex: from, secondaryIndex: to, primaryDirection: from < to ? "down" : "up"});
        function onTransitionEnd(ev: Event) {
            if ((ev.target as HTMLElement).classList.contains("MuiListItem-root")) {
                setAnimation(null);
                list.current!.removeEventListener("transitionend", onTransitionEnd);
            }
        }
        list.current!.addEventListener("transitionend", onTransitionEnd);
    }, [pendingColumnOrder, setPendingColumnOrder, setScreenReaderMessage, columns]);

    //eslint-disable-next-line react-hooks/exhaustive-deps
    const moveColumnUp = useCallback(memoize((i: number) => () => {
        if (i === 0) return;
        //Skip over disabled columns so swaps adjacent to them don't appear to do nothing
        moveColumn(i, findLastIndex(pendingColumnOrder, colIsEnabled, i - 1));
    }), [moveColumn, pendingColumnOrder, colIsEnabled]);

    //eslint-disable-next-line react-hooks/exhaustive-deps
    const moveColumnDown = useCallback(memoize((i: number) => () => {
        if (i === pendingColumnOrder.length - 1) return;
        moveColumn(i, findIndex(pendingColumnOrder, colIsEnabled, i + 1));
    }), [moveColumn, pendingColumnOrder, colIsEnabled]);

    const resetToDefaults = useCallback(() => {
        setPendingColumnOrder(Object.keys(columns) as ColId[]);
        setScreenReaderMessage("Columns reset to default order and visibility");
    }, [columns, setPendingColumnOrder, setScreenReaderMessage]);

    const apply = useCallback(() => {
        onColumnOrderChange(pendingColumnOrder);
        flyout.current!.close();
    }, [onColumnOrderChange, pendingColumnOrder, flyout]);

    const cancel = useCallback(() => {
        flyout.current!.close();
    }, [flyout]);

    const onOpen = useCallback(() => {
        setPendingColumnOrder(processColumnOrder());
    }, [setPendingColumnOrder, processColumnOrder]);

    const onClose = useCallback(() => {
        setScreenReaderMessage("");
    }, [setScreenReaderMessage]);

    const columnsToRender = animation ? prevColumnOrder! : pendingColumnOrder;

    return (
        <Flyout
            ref={flyout}
            label="Edit Columns"
            buttonVariant="icon"
            icon={<Icon fa="view-column"/>}
            buttonProps={{autoTooltip: true}}
            popoverProps={{
                anchorOrigin: {horizontal: "left", vertical: "bottom"},
                sx: {
                    "& .MuiPopover-paper": {
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end"
                    }
                }
            }}
            onOpen={onOpen}
            onClose={onClose}
        >
            <List ref={list} dense sx={{overflowY: "auto", py: 0}}>
                {columnsToRender.map((col, i) => {
                    if (!colIsEnabled(col)) return null;

                    const colId = col.toString();
                    const colName = columnName(colId, columns[colId]);
                    return <ListItem
                        key={colId}
                        disableGutters
                        sx={[
                            {
                                p: 0,
                                //Extra right-hand padding to balance out the checkboxes
                                pr: 1,
                                transform: "translateY(0)"
                            },
                            //Only apply the transition when the animation is in progress.
                            //This prevents things appearing to animate a second time after the list reorders.
                            animation && transition(["transform", "shortest", "sharp"]),
                            i === animation?.primaryIndex && {
                                zIndex: 2,
                                transform: `translateY(${animation.primaryDirection === "up" ? "-100%" : "100%"})`
                            },
                            i === animation?.secondaryIndex && {
                                transform: `translateY(${animation.primaryDirection === "up" ? "100%" : "-100%"})`
                            }
                        ]}
                    >
                        <Box sx={{flex: 1, order: 2, pl: 1, pr: 2}}>{colName}</Box>
                        <Checkbox
                            size="small"
                            sx={{order: 1}}
                            title={`Show ${colName} Column`}
                            checked={typeof col === "string"}
                            disabled={typeof col === "string" && visibleColumnCount === 1}
                            onChange={showHideColumn(i)}
                        />
                        <IconButton
                            size="small"
                            sx={{order: 3}}
                            title={`Move  ${colName} Column Up`}
                            onClick={moveColumnUp(i)}
                            disabled={i === 0}
                        >
                            <Icon fa="up" sx={transition(["color", "shortest", "sharp"])}/>
                        </IconButton>
                        <IconButton
                            size="small"
                            sx={{order: 4}}
                            title={`Move ${colName} Column Down`}
                            onClick={moveColumnDown(i)}
                            disabled={i === columnsToRender.length - 1}
                        >
                            <Icon fa="down" sx={transition(["color", "shortest", "sharp"])}/>
                        </IconButton>
                    </ListItem>;
                })}
            </List>
            <Button variant="text" size="small" sx={{mb: 1}} onClick={resetToDefaults}>Reset to Defaults</Button>
            <Stack direction="row" gap={1}>
                <Button size="small" onClick={cancel}>Cancel</Button>
                <Button variant="contained" size="small" onClick={apply}>Apply</Button>
            </Stack>
            <Box component="output" sx={visuallyHidden}>{screenReaderMessage}</Box>
        </Flyout>
    );
});
