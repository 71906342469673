import {useEffect, useState} from "react";

/**
 * Provides an AbortController that refreshes to a new one after being aborted.
 * Automatically aborts when the hook is disposed.
 */
export function useAbortController(): AbortController {
    const [abortController, setAbortController] = useState(new AbortController());
    useEffect(() => {
        const onAbort = () => setAbortController(new AbortController());
        abortController.signal.addEventListener("abort", onAbort);

        return () => {
            abortController.abort();
            abortController.signal.removeEventListener("abort", onAbort);
        };
    }, [abortController]);
    return abortController;
}
