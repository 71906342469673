import React, {ForwardedRef, forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useState} from "react";

import {Box} from "@mui/material";
import {BigNumber} from "bignumber.js";
import {defaults, pick, startCase} from "lodash";
import {action, makeObservable, observable, runInAction} from "mobx";
import {observer} from "mobx-react-lite";
import {useSnackbar} from "notistack";
import {MarkRequired, Writable} from "ts-essentials";

import {TransferStore} from "../store";
import {Transfer} from "../transfer";
import {TransferCreationDto} from "../transfer-creation-dto";
import {IconButton} from "~/components/button";
import {Form, FormProps} from "~/components/form";
import {Icon} from "~/components/icon";
import {MenuItem} from "~/components/menu-item";
import {TextField} from "~/components/text-field";
import {assertExhaustive} from "~/utils/assert-exhaustive";
import {inject} from "~/utils/di";
import {useLoadedReaction} from "~/utils/loadable/reactions";
import {TransferMetadata} from "~/transfer/transfer-metadata";
import {TransferIntentions} from "~/transfer";

//Keys and defaults that will need to be kept in sync with the types

const BASE_KEYS = ["fromAccountId", "toAccountId", "amount"] as const;
const INVESTMENT_BASE_KEYS = [...BASE_KEYS, "wefunderPaymentId", "wefunderUserId", "wefunderInvestmentId"] as const;

const GENERAL_KEYS = [...BASE_KEYS, "reference", "description"] as const;

const INVESTMENT_CHARGE_KEYS = INVESTMENT_BASE_KEYS;
const INVESTMENT_REFUND_KEYS = INVESTMENT_BASE_KEYS;
const INVESTMENT_ADJUSTMENT_KEYS = INVESTMENT_BASE_KEYS;

const DEFAULTS = {
    fromAccountId: "",
    toAccountId: "",
    amount: new BigNumber(0),
    reference: "",
    description: "",
    wefunderPaymentId: "",
    wefunderInvestmentId: "",
    wefunderUserId: ""
} as const;

//Using a local MobX store to make it easier to handle the ADT
//and also to facilitate any potential refactoring into multiple components

class InitiateTransferFormStore {
    @observable
    private _dto: Writable<TransferCreationDto>;

    public get dto(): TransferCreationDto { return this._dto; }

    public constructor(defaultFromAccountId: string, defaultToAccountId: string) {
        makeObservable(this, undefined, {name: "InitiateTransferFormStore"});

        this._dto = defaults(
            {type: "general_transfer", fromAccountId: defaultFromAccountId, toAccountId: defaultToAccountId} as const,
            pick(DEFAULTS, GENERAL_KEYS)
        );
    }

    @action.bound
    public onIntentionChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const intention = ev.target.value as Transfer.Intention;

        //This switch is repetetive but this was the most concise way I could find to get this all to be fully typesafe
        switch (intention) {
            case "investment_charge":
                this._dto = defaults(
                    {type: intention},
                    pick(this._dto, INVESTMENT_CHARGE_KEYS),
                    pick(DEFAULTS, INVESTMENT_CHARGE_KEYS)
                );
                break;
            case "investment_refund":
                this._dto = defaults(
                    {type: intention},
                    pick(this._dto, INVESTMENT_REFUND_KEYS),
                    pick(DEFAULTS, INVESTMENT_REFUND_KEYS)
                );
                break;
            case "investment_adjustment":
                this._dto = defaults(
                    {type: intention},
                    pick(this._dto, INVESTMENT_ADJUSTMENT_KEYS),
                    pick(DEFAULTS, INVESTMENT_ADJUSTMENT_KEYS)
                );
                break;
        }
    }

    @action.bound
    public setFromAccountId(id: string) {
        this._dto.fromAccountId = id;
    }

    @action.bound
    public onFromAccountIdInput(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setFromAccountId(ev.target.value);
    }

    @action.bound
    public setToAccountId(id: string) {
        this._dto.toAccountId = id;
    }

    @action.bound
    public onToAccountIdInput(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setToAccountId(ev.target.value);
    }

    @action.bound
    public switchAccountIds() {
        [this._dto.fromAccountId, this._dto.toAccountId] = [this._dto.toAccountId, this._dto.fromAccountId];
    }

    @action.bound
    public onAmountInput(ev: React.ChangeEvent<HTMLInputElement>) {
        this._dto.amount = new BigNumber(ev.target.value);
    }

    @action.bound
    public onReferenceInput(ev: React.ChangeEvent<HTMLInputElement>) {
        this._dto.reference = ev.target.value;
    }

    @action.bound
    public onDescriptionInput(ev: React.ChangeEvent<HTMLInputElement>) {
        this._dto.description = ev.target.value;
    }

    @action.bound
    public onWefunderPaymentIdInput(ev: React.ChangeEvent<HTMLInputElement>) {
        if (!("wefunderPaymentId" in this._dto))
            throw new TypeError(`The ${this._dto.type} intention does not include wefunderPaymentId`);

        this._dto.wefunderPaymentId = ev.target.value;
    }

    // @action.bound
    // public onWefunderCashTransferIdInput(ev: React.ChangeEvent<HTMLInputElement>) {
    //     if (!("wefunderCashTransferId" in this._dto))
    //         throw new TypeError(`The ${this._dto.type} intention does not include wefunderCashTransferId`);
    //
    //     this._dto.wefunderCashTransferId = ev.target.value;
    // }

    @action.bound
    public onWefunderInvestmentIdInput(ev: React.ChangeEvent<HTMLInputElement>) {
        if (!("wefunderInvestmentId" in this._dto))
            throw new TypeError(`The ${this._dto.type} intention does not include wefunderInvestmentId`);

        this._dto.wefunderInvestmentId = ev.target.value;
    }

    @action.bound
    public onWefunderUserIdInput(ev: React.ChangeEvent<HTMLInputElement>) {
        if (!("wefunderUserId" in this._dto))
            throw new TypeError(`The ${this._dto.type} intention does not include wefunderUserId`);

        this._dto.wefunderUserId = ev.target.value;
    }
}

export interface InitiateTransferFormHandle {
    readonly setFromAccountId: (id: string) => void;
    readonly setToAccountId: (id: string) => void;
}

export interface InitiateTransferFormProps extends FormProps {
    readonly transferStore?: TransferStore;
    readonly onSuccess?: (newTransfer: Transfer) => void;
    readonly defaultFromAccountId?: string;
    readonly defaultToAccountId?: string;
    readonly handleRef?: Ref<InitiateTransferFormHandle>;
    readonly openAccountsSearch?: () => void;
    readonly accountsSearchOpen?: boolean;
}

export const InitiateTransferForm = inject(
    {transferStore: TransferStore},
    observer(forwardRef(function InitiateTransferForm({
        transferStore,
        onSuccess,
        onSubmit: onSubmitProp,
        defaultFromAccountId = "",
        defaultToAccountId = "",
        handleRef,
        openAccountsSearch,
        accountsSearchOpen = false,
        ...formProps
    }: MarkRequired<InitiateTransferFormProps, "transferStore">, ref: ForwardedRef<HTMLFormElement>) {
        const {enqueueSnackbar} = useSnackbar();

        const newTransfer = transferStore.transferCreationStatus;

        const [state] = useState(() => new InitiateTransferFormStore(defaultFromAccountId, defaultToAccountId));

        const onSubmit = useCallback((ev: React.FormEvent<HTMLFormElement>) => runInAction(() => {
            onSubmitProp?.(ev);
            if (!ev.defaultPrevented) {
                ev.preventDefault();
                transferStore.createTransfer(state.dto);
            }
        }), [onSubmitProp, transferStore, state]);

        useLoadedReaction(newTransfer, transfer => {
            enqueueSnackbar("Transfer successfully initiated", {variant: "success"});
            onSuccess?.(transfer);
        }, [onSuccess]);

        useEffect(() => () => transferStore.resetTransferCreationStatus(), [transferStore]);

        useImperativeHandle(handleRef, () => pick(state, "setFromAccountId", "setToAccountId"), [state]);

        return (
            <Form ref={ref} onSubmit={onSubmit} submitStatus={newTransfer} {...formProps}>
                <TextField select label="Intention" required value={state.dto.type} onChange={state.onIntentionChange}>
                    {TransferIntentions.map(intention =>
                        <MenuItem key={intention} value={intention}>{startCase(intention)}</MenuItem>
                    )}
                </TextField>
                <Box
                    sx={{
                        display: "grid",
                        width: 1,
                        grid: `
                            "source-account-id switch search" auto
                            "dest-account-id   switch search" auto /
                             1fr               auto   auto
                        `,
                        gap: 2,
                        alignItems: "center"
                    }}
                >
                    {/* TODO: Better account pickers (what should that look like?) */}
                    <TextField
                        label="Source Account ID"
                        sx={{gridArea: "source-account-id"}}
                        required
                        value={state.dto.fromAccountId}
                        onInput={state.onFromAccountIdInput}
                    />
                    <TextField
                        label="Destination Account ID"
                        sx={{
                            gridArea: "dest-account-id",
                            //MUI TextFields don't automatically ensure that their min-width can fit their label
                            minWidth: "30ex"
                        }}
                        required
                        value={state.dto.toAccountId}
                        onInput={state.onToAccountIdInput}
                    />
                    <IconButton
                        title="Switch Account IDs"
                        autoTooltip
                        sx={{gridArea: "switch"}}
                        disabled={!state.dto.fromAccountId && !state.dto.toAccountId}
                        onClick={state.switchAccountIds}
                    >
                        <Icon fa="swap-vert"/>
                    </IconButton>
                    {openAccountsSearch &&
                        <IconButton
                            title="Search Accounts"
                            autoTooltip
                            sx={{gridArea: "search"}}
                            onClick={openAccountsSearch}
                        >
                            <Icon fa="magnifying-glass" sx={[accountsSearchOpen && {color: "primary.main"}]}/>
                        </IconButton>
                    }
                </Box>
                {/* TODO: Handle invalid input better */}
                <TextField
                    type="number"
                    label="Amount"
                    required
                    value={state.dto.amount}
                    onInput={state.onAmountInput}
                />
                {"reference" in state.dto &&
                    <TextField
                        label="Reference"
                        required={state.dto.type !== "general_transfer"}
                        value={state.dto.reference}
                        onInput={state.onReferenceInput}
                    />
                }
                {"description" in state.dto &&
                    <TextField
                        label="Description"
                        required={state.dto.type !== "general_transfer"}
                        value={state.dto.description}
                        onInput={state.onDescriptionInput}
                    />
                }
                {"wefunderPaymentId" in state.dto &&
                    <TextField
                        label="Wefunder Payment ID"
                        required
                        value={state.dto.wefunderPaymentId}
                        onInput={state.onWefunderPaymentIdInput}
                    />
                }
                {"wefunderUserId" in state.dto &&
                    <TextField
                        label="Wefunder User ID"
                        required
                        value={state.dto.wefunderUserId}
                        onInput={state.onWefunderUserIdInput}
                    />
                }
                {/*{"wefunderCashTransferId" in state.dto &&*/}
                {/*    <TextField*/}
                {/*        label="Wefunder Cash Transfer ID"*/}
                {/*        required*/}
                {/*        value={state.dto.wefunderCashTransferId}*/}
                {/*        onInput={state.onWefunderCashTransferIdInput}*/}
                {/*    />*/}
                {/*}*/}
                {"wefunderInvestmentId" in state.dto &&
                    <TextField
                        label="Wefunder Investment ID"
                        required
                        value={state.dto.wefunderInvestmentId}
                        onInput={state.onWefunderInvestmentIdInput}
                    />
                }
            </Form>
        );
    }))
);
