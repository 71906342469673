import {action} from "mobx";

import {AuthStore} from "./store";

//Wrapping this function in a MobX action to suppress unobserved access warnings

/**
 * Applies authentication details from a Store to a Fetch request
 *
 * @param store - The AuthStore to get auth details from
 * @param requestOptions - The RequestInit object to augment
 */
export const applyAuth = action((store: AuthStore, requestOptions: RequestInit): void => {
    const auth = store.authentication.valueOrNull();

    if (!auth) return;

    requestOptions.headers = {...requestOptions.headers, Authorization: `Bearer ${auth.token}`};
});
