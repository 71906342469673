import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";

export interface UpdateMetadataData {
  description: string;
}

export class TransferMetadata extends JsonDto {
    @jsonProp(JsonTypes.string, {optional: true})
    public readonly reference!: string | null;

    @jsonProp(JsonTypes.string, {optional: true})
    public readonly description!: string | null;

    @jsonProp(JsonTypes.number, {optional: true})
    public readonly wfInvestmentId!: number | null;

    @jsonProp(JsonTypes.number, {optional: true})
    public readonly wfPaymentId!: number | null;

    @jsonProp(JsonTypes.number, {optional: true})
    public readonly wfRelatedPaymentId!: number | null;

    @jsonProp(JsonTypes.number, {optional: true})
    public readonly wfCashTransferId!: number | null;

    @jsonProp(JsonTypes.number, {optional: true})
    public readonly wfTrancheId!: number | null;

    @jsonProp(JsonTypes.number, {optional: true})
    public readonly wfPrepareDisbursementId!: number | null;

    @jsonProp(JsonTypes.number, {optional: true})
    public readonly reviewedBy!: number | null;

    public constructor(init?: unknown) {
        super();
        this.init(TransferMetadata, init);
    }
}
