import {useEffect, useRef} from "react";

/** Returns true if the component is rendering for the first time */
export function useIsFirstRender(): boolean {
    const isFirstRender = useRef(true);
    useEffect(() => {
        isFirstRender.current = false;
    }, [isFirstRender]);
    return isFirstRender.current;
}
