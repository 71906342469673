import {useCallback, useEffect, useMemo, useReducer, useState} from "react";

import {checkSignal} from "../check-signal";
import {useAbortController} from "../use-abort-controller";
import {useSynchronousEffect} from "../use-synchronous-effect";
import {error, Loadable, loaded, loading, pending} from "./index";

export namespace UseLoadableController {
    export interface Eager {
        /** Restarts the loadable process. Discards any previous result.  */
        readonly restart: () => void;
    }
}

export interface UseLoadableController extends UseLoadableController.Eager {
    /** Starts the loadable process if it has not already started */
    readonly start: () => void;

    /** Stops the loadable process if it has started and resets the data to pending */
    readonly stop: () => void;
}

export type UseLoadableState<T> = [Loadable<T>, UseLoadableController];
export namespace UseLoadableState {
    export type Eager<T> = [Loadable.Eager<T>, UseLoadableController.Eager];
}

export interface UseLoadableRetryConfig {
    readonly maxRetries?: number;
    readonly delayMs?: number;
}

const DEFAULT_RETRY_CONFIG: Required<UseLoadableRetryConfig> = {
    maxRetries: 2,
    delayMs: 500
};

export interface UseLoadableOptions {
    /**
     * If true, starts the process immediately upon first render. By default waits for an explicit call to `start`.
     * This option is not watched for changes.
     */
    readonly eager?: boolean;

    /** If true, any previous value will be kept as stale while loading */
    readonly keepStaleValues?: boolean;

    /**
     * Specifies an AbortController that can be used to cancel the process.
     * By default, you can pass an AbortController in the arguments array
     * and it will be used (and its signal passed to fn in its place).
     * This AbortController should come from a source like useAbortController() that refreshes on abort.
     */
    readonly abortController?: AbortController | null;

    /**
     * If true, prevents the process for running even if it has been started.
     * NOTE: If this option is provided alongside `eager`, the result will be a non-eager Loadable in the started state.
     */
    readonly disabled?: boolean;

    /** If provided, enables auto-retry logic */
    readonly retry?: UseLoadableRetryConfig | boolean;
}

//Custom type error message hack
type ABORT_CONTROLLER_CONVERSION_ERROR = {
    readonly "AbortControllers are passed to the function as AbortSignals unless you explicitly set the abortController option": unique symbol
};

//Transform the function signature to account for the AbortController -> AbortSignal behavior
type AbortControllersForSignals<Args extends readonly unknown[]> = {
    [I in keyof Args]:
        //If the function has an argument that can accept an AbortSignal, allow passing an AbortController
        AbortSignal extends Args[I] ? AbortController | Args[I] :
        //If the function expects an AbortController, cause an error since you won't be able to provide it this way
        Args[I] extends AbortController ? ABORT_CONTROLLER_CONVERSION_ERROR :
        Args[I]
};

//Overloads would produce a nicer implementation than conditional types, but there would be an annoying number of them

type UseLoadableReturn<T, Opts extends UseLoadableOptions> =
    Opts extends {eager: true, disabled?: undefined} ? UseLoadableState.Eager<T> : UseLoadableState<T>;

/**
 * Provides access to an async process as a Loadable.
 * Generally, MobX stores should be used for this sort of thing,
 * but sometimes in simpler cases a single hook is more convenient.
 *
 * @param fn - The async function to wrap in a Loadable
 * @param args
 * The arguments to fn.
 * By default, if one of these arguments is an AbortController, it will be stored and replaced with its signal.
 * @param options - Optional parameters
 *
 * @returns A tuple of the Loadable and a controller object that can be used to start/stop the process.
 */
export function useLoadable<
    Args extends readonly unknown[],
    T,
    HookArgs extends
        //This transformation needs to be done in a second type argument to prevent bad type inference with lambdas
        Opts extends {abortController: AbortController | null} ? Args : AbortControllersForSignals<Args>,
    Opts extends UseLoadableOptions = UseLoadableOptions
>(fn: (...args: Args) => Promise<T>, args: HookArgs, options?: Opts): UseLoadableReturn<T, Opts> {
    const retry = useMemo(
        () => options?.retry && {...(typeof options.retry === "object" && options.retry), ...DEFAULT_RETRY_CONFIG},
        [options?.retry]
    );

    const [started, setStarted] = useState(options?.eager);
    const running = started && !options?.disabled;

    const defaultAbortController = useAbortController();
    const [abortController, processedArgs] = useMemo(
        () => {
            if (options?.abortController === undefined) {
                for (let i = 0; i < args.length; i++) {
                    const arg = args[i];
                    if (arg instanceof AbortController) {
                        const processedArgs = [...args];
                        processedArgs[i] = arg.signal as any;
                        return [arg, processedArgs as any as Args];
                    }
                }
            }

            return [options?.abortController ?? defaultAbortController, args as Args];
        },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [options?.abortController, defaultAbortController, ...args]
    );

    let [loadable, setLoadable] = useReducer((prevLoadable: Loadable<T>, newLoadable: Loadable<T>) => {
        if (newLoadable.isLoading() && options?.keepStaleValues) return prevLoadable.toLoadingWithStaleValue();
        else return newLoadable;
    }, options?.eager && !options.disabled ? loading : pending);

    //Use a synchronous effect to avoid returning stale data after the function or its arguments change
    useSynchronousEffect(() => {
        if (running) {
            loadable = options?.keepStaleValues ? loadable.toLoadingWithStaleValue() : loading;
        }
        else {
            loadable = pending;
        }
    }, [fn, abortController, processedArgs]);

    useEffect(() => {
        let availableRetries = retry && retry.maxRetries || 0;
        let retryTimeout: ReturnType<typeof setTimeout> | null = null;
        async function attempt() {
            try {
                setLoadable(loading);
                const result = await fn(...processedArgs);
                checkSignal(abortController.signal);
                setLoadable(loaded(result));
            }
            catch (err) {
                if (err instanceof DOMException && err.name === "AbortError") {
                    //Swallow, we were canceled
                }
                else {
                    console.error(err);

                    if (retry && availableRetries-- > 0) {
                        console.log("(Retrying...)");
                        retryTimeout = setTimeout(() => {
                            if (abortController.signal.aborted) return;
                            void attempt();
                        }, retry.delayMs);
                    }
                    else if ("message" in err) {
                        setLoadable(error(err));
                    }
                    else {
                        setLoadable(error({message: typeof err === "string" ? err : JSON.stringify(err)}));
                    }
                }
            }
        }

        if (running) {
            void attempt();
        }

        return running ? () => {
            abortController.abort();

            if (retryTimeout) {
                clearTimeout(retryTimeout);
            }
        } : undefined;
    }, [abortController, fn, processedArgs, retry, running, setLoadable]);

    const controller = useMemo(() => ({
        restart: () => {
            setStarted(true);
            //This should cause a restart when the abortController refreshes
            abortController.abort();
        },

        ...(!(options?.eager && options.disabled === undefined) && {
            start: () => setStarted(true),

            stop: () => {
                setStarted(false);
                setLoadable(pending);
                abortController.abort();
            }
        })
    }), [abortController, options?.disabled, options?.eager, setStarted]);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return [loadable, controller] as any;
}

/**
 * Version of useLoadable that uses an inline function and deps rather than a pre-existing function and arguments
 *
 * @param fn - The effect function. Does not get passed any arguments.
 * @param deps - The dependency array for fn
 * @param options - Optional parameters
 *
 * @returns A tuple of the Loadable and a controller object that can be used to start/stop the process.
 */
export function useLoadableEffect<T, Opts extends UseLoadableOptions = UseLoadableOptions>(
    fn: () => Promise<T>,
    deps: readonly unknown[],
    options?: Opts
): UseLoadableReturn<T, Opts> {
    //eslint-disable-next-line react-hooks/exhaustive-deps
    const cb = useCallback(fn, deps);
    return useLoadable(cb, [], options);
}

//For now, not going to support any of the options of useLoadable for this version. They aren't generally used.
//One could *possibly* make a case for supporting retries or stale values, but I'll add them when I actually need them.

/**
 * Creates an async callback function which provides its result as Loadable state.
 * It is the responsibility of the caller to handle cancellation and avoid race conditions when necessary.
 *
 * @param fn - The callback function
 * @param deps - The dependency array for fn
 *
 * @returns A tuple of the wrapped callback and the current Loadable state (initially pending)
 */
export function useLoadableCallback<T, Args extends unknown[]>(
    fn: (...args: Args) => Promise<T>,
    deps: readonly unknown[]
): [(...args: Args) => void, Loadable<T>] {
    const [state, setState] = useState<Loadable<T>>(pending);
    const cb = useCallback(async (...args: Args) => {
        try {
            setState(loading);
            const result = await fn(...args);
            setState(loaded(result));
        }
        catch (err) {
            if (err instanceof DOMException && err.name === "AbortError") {
                //Swallow, we were canceled
            }
            else {
                console.error(err);

                if ("message" in err) {
                    setState(error(err));
                }
                else {
                    setState(error({message: typeof err === "string" ? err : JSON.stringify(err)}));
                }
            }
        }
    }, [setState, ...deps]); //eslint-disable-line react-hooks/exhaustive-deps

    return [cb, state];
}
