import {action, flow, makeObservable, observable} from "mobx";

import {SignInRequestDto} from "./api";
import {Authentication} from "./authentication";
import {AuthenticationRepository} from "./authentication-repository";
import {SignInResponse} from "./sign-in-response";
import {Api} from "~/api/api";
import {checkSignal} from "~/utils/check-signal";
import {constructorDependencies} from "~/utils/di";
import {errorContainer, ErrorContainer} from "~/utils/error-container";
import {Loadable, loadableContainer, LoadableContainer, loaded, loading, pending} from "~/utils/loadable";
import {awt} from "~/utils/mobx";

/** Store that holds authentication state */
@constructorDependencies
export class AuthStore {
    //This class does not use LoadableContainerFactory/ApiErrorHandler as it could cause a loop

    private readonly _authentication: LoadableContainer.Eager<Authentication | null> =
        loadableContainer.eager(loading, {name: "AuthStore.authentication"});

    /** The Authentication for the current session */
    public get authentication(): Loadable.Eager<Authentication | null> { return this._authentication.l; }

    @observable
    private _challengeToken: string | null = null;

    /** Session token for an in-progress MFA challenge */
    public get challengeToken() { return this._challengeToken; }

    private readonly _logOutStatus: LoadableContainer<void> =
        loadableContainer(pending, {name: "AuthStore.logOutStatus"});

    /** The status of a pending logout attempt */
    public get logOutStatus(): Loadable<void> { return this._logOutStatus.l; }

    @observable.ref
    private _logOutCausedByError: ErrorContainer | null = null;

    /** If present, indicates that the user has just been logged out automatically due to this error */
    public get logOutCausedByError(): ErrorContainer | null { return this._logOutCausedByError; }

    public constructor(private readonly api: Api, private readonly authenticationRepository: AuthenticationRepository) {
        makeObservable(this, undefined, {name: "AuthStore"});

        this.loadAuthentication();
    }

    @flow.bound
    private * loadAuthentication() {
        yield* this._authentication.run(this, function*() {
            return yield* awt(this.authenticationRepository.loadAuthentication());
        });
    }

    @flow.bound
    public * signIn(credentials: SignInRequestDto, signal?: AbortSignal) {
        if (this._authentication.l.isLoading() || this._authentication.l.valueOrNull() !== null)
            throw new Error("Cannot sign in while already signed/signing in");

        let resp: SignInResponse | null = null;
        yield* this._authentication.run(this, function*() {
            yield* awt(this.authenticationRepository.clearAuthentication());
            checkSignal(signal);

            resp = yield* awt(this.api.auth.signIn(credentials, signal));
            checkSignal(signal);

            switch (resp.type) {
                case "sign_in_success":
                    yield* awt(this.authenticationRepository.saveAuthentication(resp));
                    checkSignal(signal);
                    return resp;
                case "auth_challenge_required":
                case "auth_challenge_setup_required":
                    this._challengeToken = resp.sessionToken;
                    return null;
            }
        });

        //Why is TypeScript ignoring the annotation on the variable???
        return resp as SignInResponse | null;
    }

    @flow.bound
    public * updateAuthenticationDetails(details: Partial<Omit<Authentication, "token" | "userId">>) {
        //Just bail if we're not signed in, we don't really need to be mean and throw an exception here
        if (this._authentication.l.valueOrNull() === null) return;

        const newAuthentication = {...this._authentication.l.getValue()!, ...details};
        yield* awt(this.authenticationRepository.saveAuthentication(newAuthentication));
        this._authentication.l = loaded(newAuthentication);
    }

    @flow.bound
    public * answerSignInChallenge(answer: string, signal?: AbortSignal) {
        if (!this._challengeToken) throw new Error("No challenge to answer");

        yield* this._authentication.run(this, function*() {
            const signInSuccess = yield* awt(this.api.auth.answerChallenge(this._challengeToken!, answer));
            checkSignal(signal);
            this._challengeToken = null;
            yield* awt(this.authenticationRepository.saveAuthentication(signInSuccess));
            checkSignal(signal);
            return signInSuccess;
        });
    }

    @flow.bound
    public * verifyNewTOTP(answer: string, signal?: AbortSignal) {
        if (!this._challengeToken) throw new Error("No challenge to answer");

        yield* this._authentication.run(this, function*() {
            const signInSuccess = yield* awt(this.api.auth.verifyNewTOTP(this._challengeToken!, answer));
            checkSignal(signal);
            this._challengeToken = null;
            yield* awt(this.authenticationRepository.saveAuthentication(signInSuccess));
            checkSignal(signal);
            return signInSuccess;
        });
    }

    @action.bound
    public clearAuthenticationError() {
        if (this._authentication.l.hasError()) {
            this._authentication.l = loaded(null);
        }
    }

    @flow.bound
    public * signOut({causedByError}: {readonly causedByError?: {readonly message: string}} = {}) {
        if (this._authentication.l.valueOrNull() === null) throw new Error("Cannot log out when not logged in");

        yield* this._logOutStatus.run(this, function*() {
            //Mark the Authentication as loading so we don't get any weird race conditions,
            //but save the current value in case the log out fails
            const oldAuth = this._authentication.l;
            this._authentication.l = loading;

            this._logOutCausedByError = causedByError ? errorContainer(causedByError) : null;

            try {
                yield* awt(this.authenticationRepository.clearAuthentication());
                this._authentication.l = loaded(null);
            }
            catch (err) {
                if (causedByError) {
                    //If we were logging out automatically because of an error,
                    //null out authentication anyway because we're in a really bad state
                    this._authentication.l = loaded(null);
                }
                else {
                    this._authentication.l = oldAuth;
                }

                throw err;
            }
        });
    }

    @action.bound
    public resetLogOutStatus() {
        this._logOutStatus.l = pending;
        this._logOutCausedByError = null;
    }
}
