import {sortBy} from "lodash";
import {CreateObservableOptions, IObservableArray, observable, ObservableMap} from "mobx";

import {applyLens, Lens} from "./lens";
import {Sequence} from "./sequence";

/** Implements a Map with an associated mutable order */
export abstract class OrderableMap<
    K,
    V,
    TMap extends Map<K, V> = Map<K, V>,
    TArray extends K[] = K[]
> implements Map<K, V> {
    /** The underlying Map. Subclasses may override this property with a more specific type. */
    protected _map: TMap;

    /** The mutable order array. Subclasses may override this property with a more specific type. */
    protected _order: TArray;

    /** The order in which to list the map entries */
    public get order(): readonly K[] { return this._order; }

    //This object should be treated as a Map
    public get [Symbol.toStringTag]() { return "Map"; }

    //Return regular Maps for derived collections by default
    public get [Symbol.species]() { return Map; }

    public get size() { return this._map.size; }

    private static assertConsistency<K>(map: ReadonlyMap<K, unknown>, order: readonly K[]): void {
        //I doubt it will, but if this gets hit enough to cause performance issues:
        // if (process.env.NODE_ENV === "production") return;

        for (const key of order) {
            if (!map.has(key)) throw new Error(`Key ${key} is not present in map`);
        }

        const orderSet = new Set(order);
        for (const key of map.keys()) {
            if (!orderSet.has(key)) throw new Error(`Key ${key} is not present in order`);
        }
    }

    /**
     * @param map - The Map data to initialize the collection with
     * @param order - The initial collection order
     * @param getKey - A lens for getting the key from a collection item
     */
    public constructor(map: TMap, order: TArray) {
        OrderableMap.assertConsistency(map, order);

        this._map = map;
        this._order = order;
    }

    public clear(): void {
        this.clearMap();
        this.clearOrder();
    }

    /** Clears the Map component of the state. Override this and `clearOrder` instead of `clear`. */
    protected clearMap(): void {
        this._map.clear();
    }

    /** Clears the order component of the state. Override this and `clearMap` instead of `clear`. */
    protected clearOrder(): void {
        this._order.length = 0;
    }

    /** Replace's this OrderableMap's state with the given Map and order. Copies its inputs. */
    public replace(map: Iterable<[K, V]>, order: Iterable<K>): void {
        this.replaceMap(map);
        this.replaceOrder(order);

        OrderableMap.assertConsistency(this._map, this._order);
    }

    /** Replaces the Map component of the state. Override this and `replaceOrder` instead of `replace`. */
    protected abstract replaceMap(map: Iterable<[K, V]>): void;

    /** Replaces the order component of the state. Override this and `replaceMap` instead of `replace`. */
    protected abstract replaceOrder(order: Iterable<K>): void;

    public delete(key: K): boolean {
        if (!this.deleteFromMap(key)) return false;
        this.deleteFromOrder(key);
        return true;
    }

    /** Removes a key from the Map. Override this and `deleteFromOrder` instead of `delete`. */
    protected deleteFromMap(key: K): boolean {
        return this._map.delete(key);
    }

    /** Removes a key from the order. Override this and `deleteFromMap` instead of `delete`. */
    protected deleteFromOrder(key: K): void {
        this._order.splice(this._order.indexOf(key), 1);
    }

    public forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
        for (const [key, value] of this.entries()) {
            callbackfn.call(thisArg, value, key, this);
        }
    }

    public get(key: K): V | undefined {
        return this._map.get(key);
    }

    public has(key: K): boolean {
        return this._map.has(key);
    }

    public set(key: K, value: V, addAt: "start" | "end" | number = "end"): this {
        const newEntry = !this.has(key);
        this.addToMap(key, value);

        if (newEntry) {
            this.addToOrder(key, addAt);
        }

        return this;
    }

    /** Adds a key-value pair to the Map. Override this and `addToOrder` instead of `set`. */
    protected addToMap(key: K, value: V): void {
        this._map.set(key, value);
    }

    /** Adds a key to the order at the specified position. Override this and `addToMap` instead of `set`. */
    protected addToOrder(key: K, addAt: "start" | "end" | number): void {
        switch (addAt) {
            case "start":
                this._order.unshift(key);
                break;
            case "end":
                this._order.push(key);
                break;
            default:
                this._order.splice(addAt, 0, key);
                break;
        }
    }

    /** Moves the item at `fromIndex` to `toIndex`, pushing existing items forward */
    public moveItem(fromIndex: number, toIndex: number): void {
        const [key] = this._order.splice(fromIndex, 1);
        this._order.splice(toIndex, 0, key);
    }

    /** Swaps the items at two indexes */
    public swapItems(aIndex: number, bIndex: number): void {
        [this._order[bIndex], this._order[aIndex]] = [this._order[aIndex], this._order[bIndex]];
    }

    /**
     * Sorts this OrderableMap in-place.
     * By default, applies JavaScript's default string-based sorting algorithm to the keys.
     * If a comparator is given, it will be passed key-value pairs.
     */
    public sort(comparator?: (a: [K, V], b: [K, V]) => number): void {
        if (comparator) {
            this._order.sort((aKey, bKey) => comparator([aKey, this._map.get(aKey)!], [bKey, this._map.get(bKey)!]));
        }
        else {
            this._order.sort();
        }
    }

    /**
     * Sorts this OrderableMap using Lodash sortBy
     *
     * @param lenses - An ordered list of Lenses over the values in the map by which to sort
     */
    public sortBy(...lenses: Lens<V, unknown>[]): void {
        this.replaceOrder(sortBy(this._order, lenses.map(lens => (key: K) => applyLens(lens, this._map.get(key)!))));
    }

    /**
     * Maps this OrderableMap to an Array.
     * This is provided mainly as a convenience for use in JSX.
     * This method is eager. If you want a lazy `map`, use `Sequence`.
     *
     * @param fn - The mapping function. Is passed the value as the first argument.
     *
     * @returns A newly constructed Array with the mapped values.
     */
    public map<T>(fn: (value: V, key: K) => T): T[] {
        return Sequence.from(this).map(([key, value]) => fn(value, key)).collectToArray();
    }

    public * entries(): IterableIterator<[K, V]> {
        for (const key of this._order) {
            yield [key, this._map.get(key)!];
        }
    }

    public keys(): IterableIterator<K> {
        return this._order[Symbol.iterator]();
    }

    public * values(): IterableIterator<V> {
        for (const key of this._order) {
            yield this._map.get(key)!;
        }
    }

    public [Symbol.iterator](): IterableIterator<[K, V]> {
        return this.entries();
    }
}

/** A readonly type projection of OrderableMap */
export type ReadonlyOrderableMap<K, V> = Pick<OrderableMap<K, V>, keyof ReadonlyMap<K, V> | "order" | "map">;

/** An OrderableMap using basic JS Maps and Arrays */
export class SimpleOrderableMap<K, V> extends OrderableMap<K, V, Map<K, V>, K[]> {
    public constructor(entries: Iterable<[K, V]>, order: Iterable<K> = Sequence.from(entries).map(0)) {
        super(new Map(entries), [...order]);
    }

    protected override replaceMap(map: Iterable<[K, V]>): void {
        this._map = new Map(map);
    }

    protected override replaceOrder(order: Iterable<K>): void {
        this._order = [...order];
    }
}

export type ObservableOrderableMapOptions = CreateObservableOptions & {
    mapOptions?: CreateObservableOptions,
    arrayOptions?: CreateObservableOptions
};

/** An OrderableMap using MobX observable Maps and Arrays */
export class ObservableOrderableMap<K, V> extends OrderableMap<K, V, ObservableMap<K, V>, IObservableArray<K>> {
    public constructor(
        entries: Iterable<[K, V]>,
        order: Iterable<K> = Sequence.from(entries).map(0),
        {mapOptions, arrayOptions, name, ...options}: ObservableOrderableMapOptions = {}
    ) {
        mapOptions = {...options, ...mapOptions};
        arrayOptions = {...options, ...arrayOptions};

        if (name) {
            mapOptions.name = `${name}.map`;
            arrayOptions.name = `${name}.order`;
        }

        super(observable.map([...entries], mapOptions), observable.array([...order], arrayOptions));
    }

    protected override replaceMap(map: Iterable<[K, V]>): void {
        this._map.replace([...map]);
    }

    protected override replaceOrder(order: Iterable<K>): void {
        this._order.replace([...order]);
    }

    protected override clearOrder(): void {
        this._order.clear();
    }

    protected override deleteFromOrder(key: K): void {
        this._order.remove(key);
    }
}
