/** Like Instant.from but returns undefined instead of throwing on invalid input */
import {Temporal} from "@js-temporal/polyfill";
import {instantRange, Range} from "~/utils/range";


export function parseInstantOrUndefined(str: string): Temporal.Instant | undefined {
    try {
        return Temporal.Instant.from(str);
    }
    catch (err) {
        if (!(err instanceof RangeError)) throw err;
        return undefined;
    }
}

/** Like PlainDate.from but returns undefined instead of throwing on invalid input */
export function parsePlainDateOrUndefined(str: string): Temporal.PlainDate | undefined {
    try {
        return Temporal.PlainDate.from(str);
    }
    catch (err) {
        if (!(err instanceof RangeError)) throw err;
        return undefined;
    }
}

/** Converts a PlainDate to a string value suitable for use with an HTML date input */
export const plainDateToDateInputValue = (plainDate: Temporal.PlainDate | null | undefined) =>
    //Intermediate states while the user is typing a year may result in a year with a leading 0.
    //Temporal emits such years using the extended ISO year format (with a + and leading 0s).
    //HTML date inputs do not support this, so we need to strip the leading +0s.
    plainDate?.toString().replace(/^\+0+(\d{4})/u, "$1") ?? "";

/**
 * Converts a PlainDate to an Instant based on the current timezone
 * and whether the value is being used as a lower or upper bound
 */
export function plainDateToInstant(plainDate: Temporal.PlainDate, {bound}: {bound: "lower" | "upper"}): Temporal.Instant;
export function plainDateToInstant(plainDate: Temporal.PlainDate|null|undefined, {bound}: {bound: "lower" | "upper"}): Temporal.Instant|undefined;
export function plainDateToInstant(plainDate: Temporal.PlainDate|null|undefined, {bound}: {bound: "lower" | "upper"}): Temporal.Instant|undefined {
    return plainDate?.toZonedDateTime({
        //In order to rectify the UTC time used by the server with the user's timezone,
        //dates are converted to Instant via ZonedDateTime with the local timezone.
        //The lower bound will have its time set to midnight, while the upper is set to 23:59:59.999999999.
        plainTime: bound === "upper" ? "23:59:59.999999999" : undefined,
        timeZone: Temporal.Now.timeZone()
    }).toInstant();
}

export function plainDateRangeToInstant({lowerBound, upperBound}: Range<Temporal.PlainDate>): Range<Temporal.Instant> {
  return instantRange(
    plainDateToInstant(lowerBound, {bound: "lower"}) || null,
    plainDateToInstant(upperBound, {bound: "upper"})
  );
}

export function instantToDateTime(value: Temporal.Instant): Temporal.ZonedDateTime {
  return value.toZonedDateTimeISO({timeZone: Temporal.Now.timeZone()});
}

export function localeDateString(from: Temporal.Instant): string {
  return instantToDateTime(from).toPlainDate().toLocaleString();
}

/** Finds the latest of a list of Instants. Breaks ties by taking the earlier argument. Ignores nil values. */
export const latestInstant = (...instants: readonly (Temporal.Instant | null | undefined)[]): Temporal.Instant =>
    instants
        .filter((x): x is Temporal.Instant => !!x)
        .reduce((latest, next) => Temporal.Instant.compare(next, latest) > 0 ? next : latest);
