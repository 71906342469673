import React, {memo} from "react";

import {startCase} from "lodash";

import {TransferSummary} from "../transfer-summary";
import {PaginationState} from "~/api/pagination-envelope";
import {Link} from "~/components/link";
import {
    EditColumnsFlyout,
    makeColumns,
    PaginatedTable,
    TableColumns,
    useLocalStorageColumnOrder
} from "~/components/table";
import {takeArgs} from "~/utils/event-helpers";
import {NoWrap, Span} from "~/utils/styles";
import {TransferEvent} from "~/transfer/transfer-event";
import {JsonDisplay} from "~/components/json-display";

export interface TransferEventsListDisplayProps {
    readonly elevation?: number;
    readonly transferEvents: Iterable<TransferEvent>;
    readonly paginationState: PaginationState;
    readonly stale: boolean;
    readonly onPageChange: (page: number) => void;
    readonly onRowsPerPageChange: (rowsPerPage: number) => void;
}

export const TransferEventsListDisplay = memo(function TransferEventsListDisplay({
    elevation = 1,
    transferEvents,
    paginationState,
    stale,
    onPageChange,
    onRowsPerPageChange
}: TransferEventsListDisplayProps) {
    const columns = makeColumns<TransferEvent>()({
        id: ["ID", event => event.id],
        message: event => <NoWrap>{event.message}</NoWrap>,
        eventData: event => <JsonDisplay json={event.eventData}/>,
        occurredAt: event => event.occurredAt.toLocaleString(),
        state: event => startCase(event.state)
    });

    const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder("transferEventsColumnOrder", columns);

    return (
        <PaginatedTable
            {...{elevation, paginationState}}
            loadingNextPage={stale}
            onPageChange={takeArgs(onPageChange, 1)}
            onRowsPerPageChange={takeArgs(onRowsPerPageChange, 1)}
            renderExtraToolbarContents={() =>
                <EditColumnsFlyout columns={columns} columnOrder={columnOrder} onColumnOrderChange={setColumnOrder}/>
            }
        >
            <TableColumns rowData={transferEvents} keyRowsBy="id" columnOrder={columnOrder} observer>
                {columns}
            </TableColumns>
        </PaginatedTable>
    );
});
