import {ButtonProps} from "@mui/material/Button/Button";
import {QueryKey, useMutation, UseMutationOptions, useQueryClient} from "react-query";
import React, {useState} from "react";
import SnackbarCustom from "~/components/snackbar-custom";
import {LoadingButton} from "@mui/lab";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";

export interface ActionButtonProps<T> extends ButtonProps {
  mutation: UseMutationOptions;
  refetchKey: QueryKey;
  confirmationMessage?: string;
  successMessage?: string;
  tooltipMessage?: string;
  onSuccess?: (result: T) => void;
}

const ActionButton = <T = unknown>({mutation, refetchKey, successMessage = "Changes saved successfully!", confirmationMessage, sx, children, disabled, color, variant = "contained", size = "small", tooltipMessage = undefined, onSuccess = undefined}: ActionButtonProps<T>) => {
  const [open, setOpen] = useState<boolean>(false);
  const client = useQueryClient();
  const {mutateAsync, isLoading, isError, isSuccess, error, reset} = useMutation(mutation);

  const handleClose = () => setOpen(false);
  const handleSubmit = () =>
    confirmationMessage ? setOpen(true) : handleConfirm();

  const handleConfirm = async () => {
    try {
      const resp = await mutateAsync();
      await client.refetchQueries(refetchKey, {exact: false});
      if (onSuccess) {
        onSuccess(resp as T);
      }
      return resp;
    } catch (e) {
      console.error(e);
    } finally {
      setOpen(false);
    }
  };

  const ButtonElement = <LoadingButton loading={isLoading} onClick={handleSubmit} size={size} sx={sx} disabled={disabled || isLoading} color={color} variant={variant}>{children}</LoadingButton>;

  return (
    <>
      <SnackbarCustom open={isError} message={((error as any) || {}).message || "Unknown error happened"} onClose={() => reset()} color={"red"}/>
      <SnackbarCustom open={isSuccess} message={successMessage} onClose={() => reset()} color={"green"}  autoHideDuration={5000}/>
      {tooltipMessage ? 
        <Tooltip title={tooltipMessage}>{ButtonElement}</Tooltip> :
        ButtonElement
      }
      <Dialog open={open} onClose={handleClose} aria-labelledby="action-dialog">
        <DialogTitle id="acion-dialog-title">{"This action cannot be undone."}</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmationMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant={"text"} color={"secondary"} autoFocus>Cancel</Button>
          <Button onClick={handleConfirm} color={"error"}>Yes, Confirm Action!</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActionButton;
