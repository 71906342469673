import {PaginationEnvelope} from "~/api/pagination-envelope";
import {wfGet, wfPost} from "~/utils/wf-fetch";
import {constructDto, paginatedDto} from "./util";
import {TransferSummary} from "~/transfer/transfer-summary";
import {Transfer} from "~/transfer/transfer";
import {LineItemWithRecons} from "~/recon/line-item";
import {UpdateMetadataData} from "~/transfer/transfer-metadata";

const basePath = `${process.env.API_BASE}/transfers`;
const staleTime = 3 * 60 * 1000; // 3 minutes

const Transfers = {
  list: (params?: {[param: string]: unknown}) => ({
    queryKey: ["transfers", params],
    queryFn: () => wfGet<PaginationEnvelope<TransferSummary[]>>(basePath, params, paginatedDto(TransferSummary)),
    keepPreviousData: true,
    staleTime: staleTime
  }),
  get: (id: string | undefined) => ({
    queryKey: ["transfers", id],
    queryFn: () => wfGet<Transfer>(`${basePath}/${id}`, {}, constructDto(Transfer)),
    keepPreviousData: true,
    staleTime: staleTime,
    enabled: !!id
  }),
  processDescription: (id: string | undefined) => ({
    queryKey: ["transfers", "coordinator_description", id],
    queryFn: () => wfGet<{ data: JSON }>(`${basePath}/${id}/coordinator_description`, {}),
    keepPreviousData: true,
    staleTime: staleTime,
    enabled: !!id
  }),
  start: (id: string) => ({
    mutationKey: ["transfers", id, "start"],
    mutationFn: () => wfPost<any, Transfer>(null, `${basePath}/${id}/start`, {constructor: constructDto(Transfer)})
  }),
  cancel: (id: string) => ({
    mutationKey: ["transfers", id, "cancel"],
    mutationFn: () => wfPost<any, Transfer>(null, `${basePath}/${id}/cancel`, {constructor: constructDto(Transfer)})
  }),
  refresh: (id?: string) => ({
    mutationKey: ["transfers", id, "refresh"],
    enabled: !!id,
    mutationFn: () => wfPost<any, Transfer>(null, `${basePath}/${id}/refresh`, {constructor: constructDto(Transfer)})
  }),
  retry: (id?: string) => ({
    mutationKey: ["transfers", id, "retry"],
    enabled: !!id,
    mutationFn: () => wfPost<any, Transfer>(null, `${basePath}/${id}/retry`, {constructor: constructDto(Transfer)})
  }),
  updateMetadata: (id: string) => ({
    mutationKey: ["transfers", id, "start"],
    mutationFn: (data: UpdateMetadataData) => wfPost<any, Transfer>(data, `${basePath}/${id}/metadata`, {constructor: constructDto(Transfer)})
  })
};

export default Transfers;
