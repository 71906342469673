export interface PaginationState {
    readonly currentPage: number;
    readonly perPage: number;
    readonly totalPages: number;
    readonly totalItems: number;
}

export interface PaginationEnvelope<T> {
    readonly data: T;
    readonly pagination: PaginationState;
}

/** Maps the data in a PaginationEnvelope */
export function mapPageData<T, U>(envelope: PaginationEnvelope<T>, mapping: (x: T) => U): PaginationEnvelope<U> {
    return {...envelope, data: mapping(envelope.data)};
}

/** Returns a suffix that can be appended to a title to show the current page number */
export function pageNumberSuffix({currentPage, totalPages}: PaginationState): string {
    return totalPages > 0 ? ` (Page ${currentPage} / ${totalPages})` : "";
}
