import React, {useCallback, useEffect, useRef} from "react";

import {flowResult} from "mobx";
import {observer} from "mobx-react-lite";

import {USER_SORT_KEYS, UserSortKey} from "../api";
import {UserCreationForm} from "../components/user-creation-form";
import {UserFilters} from "../components/user-filters";
import {UserListDisplay} from "../components/user-list-display";
import {UserStore} from "../store";
import {User} from "../user";
import {PageParams, SortDirection, SortParams} from "~/api/common-query-params";
import {pageNumberSuffix} from "~/api/pagination-envelope";
import {Button} from "~/components/button";
import {Dialog, DialogHandle} from "~/components/dialog";
import {FilterAndSortBar} from "~/components/filter-and-sort-bar";
import {CollapsibleFilterGrid} from "~/components/filter-grid";
import {LoadingTreatment} from "~/components/loading-treatment";
import {Page} from "~/components/page";
import {SortOptions} from "~/components/sort-options";
import {inject} from "~/utils/di";
import {parsePositiveInt} from "~/utils/numbers";
import {useAbortableEffect} from "~/utils/use-abortable-effect";
import {useIsFirstRender} from "~/utils/use-is-first-render";
import {useQueryParam} from "~/utils/use-query-param";
import {useStateObject, useTypedStateObject} from "~/utils/use-state-object";

export const UserManagementDashboard = inject({userStore: UserStore}, observer(function UserManagementDashboard({
    userStore
}: {userStore: UserStore}) {
    const {users} = userStore;

    const userCreationDialog = useRef<DialogHandle>(null);

    const [pageParams, setPageParams] = useTypedStateObject<PageParams>()({
        page: useQueryParam("page", 1, {parser: parsePositiveInt, push: true, immediate: true}),
        perPage: useQueryParam("perPage", 10, {parser: parsePositiveInt, push: true, immediate: true})
    });

    const [sortParams, setSortParams] = useTypedStateObject<SortParams<UserSortKey>>()({
        sortBy: useQueryParam<UserSortKey | undefined>("sortBy", undefined),
        sortDir: useQueryParam<SortDirection | undefined>("sortDir", undefined)
    });

    const [filters, setFilters] = useStateObject({
        userRole: useQueryParam<User.Role | undefined>("userRole", undefined),
        firstName: useQueryParam<string | undefined>("firstName", undefined),
        lastName: useQueryParam<string | undefined>("lastName", undefined),
        email: useQueryParam<string | undefined>("email", undefined)
    });

    //Reset the page to 1 when the sort and filters change
    const isFirstRender = useIsFirstRender();
    useEffect(() => {
        if (!isFirstRender) {
            setPageParams.page(1, {immediate: false});
        }
    }, [sortParams, filters]); //eslint-disable-line react-hooks/exhaustive-deps

    useAbortableEffect(
        signal => flowResult(userStore.fetchUsers({...pageParams, ...sortParams, ...filters}, signal)),
        [userStore, pageParams, sortParams, filters]
    );

    const createUser = useCallback(() => userCreationDialog.current!.show(), [userCreationDialog]);

    const onUserCreated = useCallback(() => userCreationDialog.current!.hide(), [userCreationDialog]);

    const pageTitle = "Users";
    const pageNumberTitle = users.case({
        hasValue: users => pageNumberSuffix(users.pagination),
        else: () => ""
    });

    return (
        <Page
            title={pageTitle}
            tabTitle={pageTitle + pageNumberTitle}
            height="fixed"
            actions={
                users.hasStaleOrCurrentValue() &&
                    <Button variant="contained" onClick={createUser} disabled={users.isLoading()}>Create New</Button>
            }
        >
            <FilterAndSortBar>{expandSort => <>
                <CollapsibleFilterGrid>
                    <UserFilters
                        userRole={filters.userRole}
                        setUserRole={setFilters.userRole}
                        firstName={filters.firstName}
                        setFirstName={setFilters.firstName}
                        lastName={filters.lastName}
                        setLastName={setFilters.lastName}
                        email={filters.email}
                        setEmail={setFilters.email}
                    />
                </CollapsibleFilterGrid>
                <SortOptions
                    sortKeys={USER_SORT_KEYS}
                    sortBy={sortParams.sortBy}
                    onSortByChange={setSortParams.sortBy}
                    sortDirection={sortParams.sortDir}
                    onSortDirectionChange={setSortParams.sortDir}
                    expanded={expandSort}
                />
            </>}</FilterAndSortBar>
            <LoadingTreatment loadable={users} description="users" observer renderStaleValues>{(users, stale) =>
                <UserListDisplay
                    users={[...users.data.values()]}
                    paginationState={users.pagination}
                    onPageChange={setPageParams.page}
                    onRowsPerPageChange={setPageParams.perPage}
                    {...{stale}}
                />
            }</LoadingTreatment>
            <Dialog ref={userCreationDialog} dialogTitle="Create User">
                <UserCreationForm onSuccess={onUserCreated}/>
            </Dialog>
        </Page>
    );
}));
