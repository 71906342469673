import React, {memo, useCallback} from "react";

import {useFilterGridContext} from "../filter-grid";
import {ListItemText} from "../list";
import {TextField} from "../text-field";

export interface TextFilterProps {
    readonly filterKey: string;
    readonly label?: string;
    readonly inputLabel?: string;
    readonly value: string | undefined;
    readonly onValueChange: (value: string | undefined) => void;
    readonly compactSummary?: boolean;
}

export const TextFilter = memo(function TextFilter({
    filterKey,
    label,
    inputLabel = "Value",
    value,
    onValueChange,
    compactSummary
}: TextFilterProps) {
    const {GridFilter} = useFilterGridContext();

    const onValueInput = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => onValueChange(ev.target.value),
        [onValueChange]
    );

    const onRemove = useCallback(() => onValueChange(undefined), [onValueChange]);

    const getSummary = useCallback((label: string) => {
        if (!value) return null;
        if (compactSummary) return label;
        return `${label} = ${value}`;
    }, [compactSummary, value]);

    return (
        <GridFilter
            filterKey={filterKey}
            label={label}
            hasValue={value !== undefined}
            getSummary={getSummary}
            onRemove={onRemove}
        >{(focusRef, renderBaseFilterUi) =>
            renderBaseFilterUi(<>
                <ListItemText sx={{flex: "none"}}>{"="}</ListItemText>
                <TextField
                    semiControlled
                    inputRef={focusRef}
                    variant="standard"
                    size="small"
                    sx={{flex: 1}}
                    label={inputLabel}
                    value={value ?? ""}
                    onInput={onValueInput}
                />
            </>)
        }</GridFilter>
    );
});
