import {MutableRefObject, useState} from "react";

import {useForceRender} from "./use-force-render";

/**
 * Creates an object ref which causes a re-render when assigned to.
 * Useful for odd situations when you need to access an element ref from render.
 */
export function useRenderRef<T>(init: T | null): MutableRefObject<T | null> {
    const forceRender = useForceRender();

    let current = init;
    const [ref] = useState(() => ({
        get current() { return current; },
        set current(value) {
            current = value;
            forceRender();
        }
    }));

    return ref;
}
