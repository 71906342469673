import {PaginationEnvelope} from "~/api/pagination-envelope";
import {Backend} from "~/backend/backend";
import {wfGet} from "~/utils/wf-fetch";
import {paginatedDto} from "./util";

const Backends = {
  list: {
    queryKey: ["backends"],
    queryFn: () => wfGet<PaginationEnvelope<Backend[]>>(`${process.env.API_BASE}/backends`, {backendType: "internal_escrow"}, paginatedDto(Backend)),
    staleTime: Infinity
  }
};

export default Backends;
