import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {ReactNode} from "react";
import {Box, Stack} from "@mui/material";
import {ChoiceFlowTypes, ReconStagingContext} from "./recon-staging";
import {LineItemType} from "../line-item";

interface LineItemTargetOption {
  value: LineItemType;
  label: string;
  description: string;
}

const ItemTypeDescription = function({description, children}: {description: string, children: ReactNode}) {
  return (
      <Stack pl={2} mt={2}>
        {children}
        <Box ml={2} mt={1} mb={1} sx={{typography: "body2", color: "text.secondary"}}>
          {description}
        </Box>
      </Stack>
  );
};

export function LineItemTypeForm({value}: {value: LineItemType | null}) {
  const options: LineItemTargetOption[] = [
    {value: LineItemType.StripePayout, label: "Stripe Payout", description: "A bulk stripe payment consisting of charges, refunds and chargebacks. These usually correspond to investment charge/refund transfers and reversals."},
    {value: LineItemType.Deposit, label: "Deposit", description: "Payments incoming from wires, checks or bank transfers deposited directly into our escrow account. Correspond to investment charges."},
    {value: LineItemType.VitesseTransaction, label: "Vitesse Transaction", description: "Outgoing transactions from our Vitesse escrow account. Typically correspond to refunds and disbursements."},
    {value: LineItemType.EpayTransaction, label: "Epay Transaction", description: "Outgoing transactions from our SVB escrow accounts. Could relate to investment or cash refunds."}
  ];

  const staging = React.useContext(ReconStagingContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    staging.setLineItemType((event.target as HTMLInputElement).value as ChoiceFlowTypes);
  };

  return (
    <FormControl>
      <RadioGroup
          aria-labelledby="line-item-type-radio-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
      >
        {options.map(option =>
          <ItemTypeDescription key={option.value} description={option.description}>
            <FormControlLabel value={option.value} control={<Radio/>} label={option.label}/>
          </ItemTypeDescription>
        )}

      </RadioGroup>
    </FormControl>
  );
}
