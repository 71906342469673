import {Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React, {ReactComponentElement, useState} from "react";
import {IfDefined} from "~/recon/util";
import {Icon} from "./icon";

export type ColumnDef<Model> = { 
  title: string,
  getter: (model: Model) => React.ReactNode
};

export type Columns<Model> = ColumnDef<Model>[];

type RowDetails<Model> =  React.ComponentType<{row: Model}>;//React.FC<{ row: Model }>;

export interface SimpleTableProps<Model> {
  rows: Model[];
  columns: Columns<Model>;
  columnAlignment?: "left" | "center" | "right";
  cellAlignment?: "left" | "center" | "right";
  RowDetails?: RowDetails<Model>;
}

function Row<Model>({row, columns, cellAlignment, RowDetails}: {row: Model, columns: Columns<Model>, cellAlignment: "left" | "center" | "right", RowDetails?: RowDetails<Model>}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow>
        <IfDefined value={RowDetails}>
          <TableCell sx={{width: "0.5rem"}}>
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
            >
              {open ? <Icon fa="up"/> : <Icon fa="down"/>}
            </IconButton>
          </TableCell>
        </IfDefined>
        {columns.map((column, index) => 
          <TableCell key={index} align={cellAlignment}>
            {column.getter(row)}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={columns.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{margin: 1}}>
              {RowDetails ? <RowDetails row={row}/> : null}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function SimpleTable<Model>({rows, columns, columnAlignment = "left", RowDetails = undefined, cellAlignment = "left"}: SimpleTableProps<Model>) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            { RowDetails ? <TableCell/> : null }
            {columns.map((column, index) => 
              <TableCell key={index} align={columnAlignment}>{column.title}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) =>
            <Row key={index} row={row} columns={columns} cellAlignment={cellAlignment} RowDetails={RowDetails}/>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}