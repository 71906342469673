import React, {useCallback} from "react";
import {Navigate} from "react-router-dom";

import {observer} from "mobx-react-lite";

import {TOTPVerificationForm} from "../components/totp-verification-form";
import {AuthStore} from "../store";
import {Page, PageWidth} from "~/components/page";
import {inject} from "~/utils/di";

export const SignInChallenge = inject({authStore: AuthStore}, observer(function SignInChallenge({
    authStore
}: {authStore: AuthStore}) {
    const {authentication} = authStore;

    const answer = useCallback((otp: string) => authStore.answerSignInChallenge(otp), [authStore]);

    if (!authStore.challengeToken) return <Navigate to="/" replace/>;

    return (
        <Page title="MFA Verification" width={PageWidth.NARROW}>
            {/* Use authentication as submitStatus to surface errors */}
            <TOTPVerificationForm autoFocus submitStatus={authentication} onSubmit={answer}/>
        </Page>
    );
}));
