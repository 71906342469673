import React, {ForwardedRef, forwardRef, ReactNode, useId, useLayoutEffect} from "react";

import {AppBar, Box, Paper, Stack, SxProps, Theme, Toolbar, useScrollTrigger} from "@mui/material";
import classNames from "classnames";

import {Fade, Slide} from "./transitions";
import {Typography} from "./typography";
import {assignRef} from "~/utils/assign-ref";

const titleVariants = {
    large: "h2",
    medium: "h4",
    small: "h5"
} as const;

export interface AppHeaderProps {
    /**
     * A direct reference to the DOM node that serves as the scroll container for this header.
     * Due to an odd MUI API, this has to be a direct Node reference and not a React ref.
     * You can achieve this by using useState and passing the setter as a ref to the chosen element.
     */
    readonly scrollContainer: Node | undefined;

    /** The main title to render in the header */
    readonly title?: ReactNode;

    /** The size at which to render the title. Defaults to large. */
    readonly titleSize?: "large" | "medium" | "small";

    /** The level of the heading element to use when rendering the title. Defaults to 2. */
    readonly headingLevel?: 1 | 2 | 3 | 4 | 5 | 6; //Have to be more specific than number for component to typecheck

    /** A subtitle to render as part of the header, underneath the title */
    readonly subtitle?: ReactNode;

    /**
     * Children can be passed to completely override title, titleSize, headingLevel, and subtitle.
     * The children will replace the entire main content slot of the header.
     * The actions slot will stil be rendered.
     */
    readonly children?: ReactNode;

    /** Slot for rendering action buttons alongside the title */
    readonly actions?: ReactNode;

    readonly className?: string;

    /** The elevation to apply to the header when the container has scrolled. Defaults to 3. */
    readonly scrolledElevation?: number;

    readonly sx?: SxProps<Theme>;
}

export const AppHeader = forwardRef(function AppHeader({
    scrollContainer,
    title,
    titleSize = "large",
    headingLevel = 2,
    subtitle,
    actions,
    className,
    scrolledElevation = 3,
    sx = [],
    ...props //Don't destructure children so we can tell if it was passed
}: AppHeaderProps, ref: ForwardedRef<HTMLDivElement>) {
    const elevationTrigger = useScrollTrigger({target: scrollContainer, disableHysteresis: true, threshold: 0});
    const slideOutTrigger = useScrollTrigger({target: scrollContainer});

    //MUI transition components need to be able to set a ref on their child,
    //but I also need to forward a ref to the AppBar. Wrapping the AppBar in a div breaks styles.
    //There doesn't seem to be a prop on Slide that lets me get at its ref, either.
    //So... the best I can come up with is this awful hack.
    const appBarId = useId();
    useLayoutEffect(() => {
        assignRef(ref, document.getElementById(appBarId));
        return () => assignRef(ref, null);
    }, [ref, appBarId]);

    return (
        <Slide direction="down" in={!slideOutTrigger}>
            <AppBar
                className={classNames(
                    "PcAppHeader-root",
                    className,
                    {"PcAppHeader-elevated": elevationTrigger, "PcAppHeader-hidden": slideOutTrigger}
                )}
                id={appBarId}
                position="sticky"
                elevation={0}
                sx={[{background: "none", overflow: "visible"}, sx].flat()}
            >
                {/*
                    To allow for a fade-in, rather than setting the elevaton prop on AppBar,
                    use a Paper lowered below the content.
                */}
                <Fade in={elevationTrigger}>
                    <Paper
                        square
                        elevation={scrolledElevation}
                        sx={{position: "absolute", left: 0, top: 0, width: 1, height: 1, zIndex: -1}}
                    />
                </Fade>
                <Toolbar className="PcAppHeader-toolbar" sx={{justifyContent: "space-between", py: 3}}>
                    {"children" in props ?
                        props.children
                    :
                        <Typography
                            component="div"
                            variant={titleVariants[titleSize]}
                            sx={{display: "flex", flexDirection: "column"}}
                        >
                            <Box component={`h${headingLevel}`} sx={{m: 0, font: "inherit"}}>{title}</Box>
                            {subtitle &&
                                <Box
                                    role="doc-subtitle"
                                    sx={{
                                        fontSize: "0.75em",
                                        fontWeight: "normal",
                                        borderTop: 1,
                                        mt: 1,
                                        pt: 1,
                                        pl: 2,
                                        pr: 1
                                    }}
                                >
                                    {subtitle}
                                </Box>
                            }
                        </Typography>
                    }
                    <Stack className="PcAppHeader-actions" direction="row" ml={5} gap={2}>{actions}</Stack>
                </Toolbar>
            </AppBar>
        </Slide>
    );
});
