import {action, flow, flowResult, makeObservable} from "mobx";

import {CurrentUserUpdateDto, UserCreationDto, UserFilterParams, UserSortKey, UserUpdateDto} from "./api";
import {User} from "./user";
import {Api} from "~/api/api";
import {PageParams, SortParams} from "~/api/common-query-params";
import {mapPageData, PaginationEnvelope} from "~/api/pagination-envelope";
import {AuthStore} from "~/auth/store";
import {constructorDependencies} from "~/utils/di";
import {Loadable, loadableContainer, loaded, loading, pending} from "~/utils/loadable";
import {LoadableMap} from "~/utils/loadable/loadable-map";
import {awt} from "~/utils/mobx";
import {ObservableOrderableMap, ReadonlyOrderableMap} from "~/utils/orderable-map";
import {Sequence} from "~/utils/sequence";

@constructorDependencies
export class UserStore {
    private readonly _users = loadableContainer<PaginationEnvelope<ObservableOrderableMap<string, User>>>(pending);

    public get users(): Loadable<PaginationEnvelope<ReadonlyOrderableMap<string, User>>> { return this._users.l; }

    private readonly _currentUser = loadableContainer.eager<User>(loading);

    public get currentUser(): Loadable.Eager<User> { return this._currentUser.l; }

    private readonly userDetails = new LoadableMap<string, User>();

    private readonly _userCreationStatus = loadableContainer<void>(pending);

    public get userCreationStatus(): Loadable<void> { return this._userCreationStatus.l; }

    private readonly userUpdateStatusMap = new LoadableMap<string, void>();

    public get currentUserUpdateStatus(): Loadable<void> {
        return this.currentUser.case({
            hasValue: user => this.userUpdateStatusMap.get(user.id),
            else: () => pending
        });
    }

    public constructor(private readonly api: Api, private readonly authStore: AuthStore) {
        makeObservable(this, undefined, {name: "UserStore"});
    }

    @flow.bound
    public * fetchUsers(params: PageParams & SortParams<UserSortKey> & UserFilterParams, signal?: AbortSignal) {
        yield* this._users.run(this, function*() {
            const page = yield* awt(this.api.users.findUsers(params, signal));
            return mapPageData(page, d => Sequence.from(d)
                .keyBy("id")
                .collectToObservableOrderableMap({name: "users", deep: false})
            );
        }, {keepStaleValue: true});
    }

    @flow.bound
    public * fetchCurrentUser(signal?: AbortSignal) {
        yield* this._currentUser.run(this, function*() {
            return yield* awt(this.api.users.getCurrentUser(signal));
        });
    }

    @flow.bound
    public * fetchUserDetails(id: string, signal?: AbortSignal) {
        yield* this.userDetails.run(this, id, function*() {
            const user = yield* awt(this.api.users.getUser(id, signal));

            //Update currentUser if the ID matches
            if (user.id === this.authStore.authentication.valueOrNull()?.userId) {
                yield* this.setCurrentUser(user);
            }

            //Also update the object in the main list if it's present there
            this._users.l.tap(({data: users}) => {
                if (users.has(user.id)) {
                    users.set(id, user);
                }
            });

            return user;
        }, {keepStaleValue: true});
    }

    public getUserDetails(id: string): Loadable<User> {
        return this.userDetails.get(id);
    }

    @action.bound
    public clearUserDetails(...ids: string[]) {
        if (ids.length === 0) {
            this.userDetails.clear();
        }
        else {
            ids.forEach(id => this.userDetails.delete(id));
        }
    }

    @action.bound
    public clearUserInfo(): void {
        this._users.l = pending;
        this._currentUser.l = loading;
        this.clearUserDetails();
    }

    @flow.bound
    public * createUser(dto: UserCreationDto, signal?: AbortSignal) {
        yield* this._userCreationStatus.run(this, function*() {
            const newUser = yield* awt(this.api.users.createUser(dto, signal));
            this._users.l.valueOrNull()?.data.set(newUser.id, newUser, "start");
        }, {onAbort: pending});
    }

    @action.bound
    public resetUserCreationStatus() {
        this._userCreationStatus.l = pending;
    }

    @flow.bound
    public * updateUser(id: string, updates: UserUpdateDto, signal?: AbortSignal) {
        yield* this.userUpdateStatusMap.run(this, id, function*() {
            const updatedUser = yield* awt(this.api.users.updateUser(id, updates, signal));

            if (updatedUser.id === this.authStore.authentication.valueOrNull()?.userId) {
                yield* this.setCurrentUser(updatedUser);
            }

            this._users.l.tap(({data: users}) => {
                if (users.has(updatedUser.id)) {
                    users.set(updatedUser.id, updatedUser);
                }
            });

            this.userDetails.updateIfLoaded(updatedUser.id, () => updatedUser);
        }, {onAbort: pending});
    }

    public getUserUpdateStatus(id: string): Loadable<void> {
        return this.userUpdateStatusMap.get(id);
    }

    @action.bound
    public resetUserUpdateStatus(id: string) {
        this.userUpdateStatusMap.delete(id);
    }

    @flow.bound
    public * updateCurrentUser(updates: CurrentUserUpdateDto, signal?: AbortSignal) {
        if (!this._currentUser.l.isLoaded()) throw new Error("Load the current user before attempting to update it");

        yield* this.userUpdateStatusMap.run(this, this._currentUser.l.value.id, function*() {
            const updatedUser = yield* awt(this.api.users.updateCurrentUser(updates, signal));

            yield* this.setCurrentUser(updatedUser);

            this._users.l.tap(({data: users}) => {
                if (users.has(updatedUser.id)) {
                    users.set(updatedUser.id, updatedUser);
                }
            });

            this.userDetails.updateIfLoaded(updatedUser.id, () => updatedUser);
        }, {onAbort: pending});
    }

    @action.bound
    public resetCurrentUserUpdateStatus() {
        if (!this._currentUser.l.isLoaded()) return;

        this.userUpdateStatusMap.delete(this._currentUser.l.value.id);
    }

    private * setCurrentUser(user: User) {
        this._currentUser.l = loaded(user);
        yield* awt(flowResult(this.authStore.updateAuthenticationDetails({
            userFirstName: user.firstName,
            userLastName: user.lastName
        })));
    }
}
