import "./polyfills";

import React from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";

import createEmotionCache from "@emotion/cache";
import {CacheProvider as EmotionCacheProvider} from "@emotion/react";
import {createTheme, CssBaseline, GlobalStyles, ThemeProvider} from "@mui/material";
import localforage from "localforage";
import {ConfirmProvider} from "material-ui-confirm";
import {configure as configureMobx} from "mobx";
import {SnackbarProvider} from "notistack";

import {apiErrorHandler} from "./api-error-handler";
import {Api} from "./api/api";
import {FetchClient} from "./api/fetch-client";
import {App} from "./app";
import {applyAuth} from "./auth/apply-auth";
import {AuthenticationRepository} from "./auth/authentication-repository";
import {AuthStore} from "./auth/store";
import {componentOverrides} from "./components/mui-theme-overrides";
import {DIProvider} from "./utils/di";
import {QueryClient, QueryClientProvider} from "react-query";

/* Library configuration */

if ("Cypress" in window) {
    //Let Cypress know the app code has loaded
    (window as any).paymentCenter = true;

    //Allow manipulating localforage in integration tests
    (window as any).localforage = localforage;
}

localforage.config({
    name: "paymentCenter"
});

configureMobx({
    computedRequiresReaction: true,
    //With React components, this often isn't an actual problem.
    //I wish there were separate settings for observers and computed values.
    reactionRequiresObservable: false,
    observableRequiresReaction: true
});

/* Theme configuration */

const theme = createTheme({
    spacing: 6,

    typography: {
        //Fonts from main app
        fontFamily: "'Gotham SSm A', 'Gotham SSm B', Helvetica, Arial, sans-serif",

        //MUI's default header sizes are ENORMOUS
        h2: {fontSize: "2.5rem"},
        h3: {fontSize: "2.25rem"}
    },

    palette: {
        //Originally the site was dark-mode-only due to a bug in FAST,
        //however I've decided I like the contrast with the bright theme of the main Wefunder site,
        //so I'm intentionally keeping the behavior unless someone really wants light mode
        mode: "dark",
        primary: {
            //The Wefunder logo color!
            main: "#3076b7"
        }
    },

    components: componentOverrides
});

//It seems experimental_sx can't be used with GlobalStyles
const globalStyles = <GlobalStyles
    styles={{
        "html, body, #root": {
            width: "100%",
            height: "100%"
        }
    }}
/>;

/* DI initialization */

// False positive! There's a circular reference here, so this is needed.
// eslint-disable-next-line prefer-const
let authStore: AuthStore;
const api = new Api(
    new FetchClient({baseUrl: process.env.API_BASE, authApplicator: options => applyAuth(authStore, options)}),
    err => apiErrorHandler(authStore, err)
);
const authenticationRepository = new AuthenticationRepository();
authStore = new AuthStore(api, authenticationRepository);

/* https://github.com/emotion-js/emotion/issues/1105 */
const emotionCache = createEmotionCache({key: "pc"});
emotionCache.compat = true;

const queryClient = new QueryClient();

const root = createRoot(document.getElementById("root")!);
root.render(
    <React.StrictMode>
        <EmotionCacheProvider value={emotionCache}>
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme/>
                {globalStyles}
                <DIProvider
                    injects={[[AuthenticationRepository, authenticationRepository], [AuthStore, authStore], [Api, api]]}
                >
                    <QueryClientProvider client={queryClient}>
                        <SnackbarProvider anchorOrigin={{vertical: "bottom", horizontal: "center"}}>
                            {/*
                                Even though I've wrapped the Dialog component,
                                I don't currently need any of the customizations for simple confirm dialogs
                                and so I'm going ahead and using this off-the-shelf package for now.
                                If I later need to customize a confirm dialog beyond what this package allows,
                                I will implement a similar API on top of my own Dialog component.
                                (I am going to wrap the hook that goes with this provider because its API is stupid.)
                            */}
                            <ConfirmProvider
                                defaultOptions={{
                                    confirmationText: "Yes",
                                    cancellationText: "No",
                                    confirmationButtonProps: {variant: "contained"}
                                }}
                            >
                                <BrowserRouter>
                                    <App/>
                                </BrowserRouter>
                            </ConfirmProvider>
                        </SnackbarProvider>
                    </QueryClientProvider>
                </DIProvider>
            </ThemeProvider>
        </EmotionCacheProvider>
    </React.StrictMode>
);
