import React, {useCallback, useEffect, useMemo} from "react";

import {Stack} from "@mui/material";

import {
    FilterGrid,
    FilterGridHandle,
    FilterGridProps,
    FilterGridSummaryContextProvider,
    useFilterGridContext
} from "../filter-grid";
import {Sequence} from "~/utils/sequence";
import {useChainRef} from "~/utils/use-chain-ref";
import {useMap} from "~/utils/use-map";
import {usePreviousValue} from "~/utils/use-previous-value";

export interface NestedFilterGridProps extends Omit<FilterGridProps, "sx"> {
    readonly filterKey: string;
    readonly label?: string;
    readonly active: boolean;
    readonly onActiveChange: (active: boolean) => void;
}

export function NestedFilterGrid({
    filterKey,
    label,
    active: activeProp,
    onActiveChange,
    children,
    ...filterGridProps
}: NestedFilterGridProps) {
    const {activeFilters, filterNames, GridFilter} = useFilterGridContext();

    const active = activeFilters.has(filterKey);

    const nestedFilterGrid = useChainRef<FilterGridHandle>(null);

    const nestedFilterSummaries = useMap<string, string>();
    const nestedSummaryCtx = useMemo(
        () => ({filterSummaries: nestedFilterSummaries}),
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [nestedFilterSummaries.atom]
    );

    const getSummary = useCallback((label: string) =>
        nestedFilterSummaries.size > 0 ?
            Sequence.from(nestedFilterSummaries.values())
                .map(summary => `${label} ${summary}`)
                .collectToArray() :
            null,
    [nestedFilterSummaries.atom]); //eslint-disable-line react-hooks/exhaustive-deps

    //Propagate active state to parent
    const wasActive = usePreviousValue(active);
    useEffect(() => {
        if (wasActive !== null && active !== wasActive && active !== activeProp) {
            onActiveChange(active);
        }
    }, [active, wasActive, activeProp, onActiveChange]);

    const onRemove = useCallback(() => {
        nestedFilterGrid.current!.clear();
        onActiveChange(false);
    }, [nestedFilterGrid, onActiveChange]);

    const computedLabel = filterNames.get(filterKey);

    //Remove self from the list of filters entirely if the grid is empty
    if (!children || Array.isArray(children) && children.length === 0) return null;
    else return (
        <GridFilter
            filterKey={filterKey}
            label={label}
            hasValue={activeProp}
            getSummary={getSummary}
            onRemove={onRemove}
            sx={{flexDirection: "column", gap: 0}}
        >{(focusRef, renderBaseFilterUi) => <>
            <Stack direction="row" alignItems="baseline" justifyContent="space-between" width={1}>
                {renderBaseFilterUi()}
            </Stack>
            <FilterGridSummaryContextProvider value={nestedSummaryCtx}>
                <FilterGrid
                    ref={nestedFilterGrid.addChildRef("focusRef", focusRef as any)}
                    addFilterLabel={`Add ${computedLabel} Filter`}
                    clearLabel={`Clear ${computedLabel} Filters`}
                    sx={{
                        width: 1,
                        px: 2,
                        py: 0,

                        //In order to increase visual differentiation, have nested filters use a (dynamically)
                        //smaller font size. This will then need to be manually propagated to the various subcomponents
                        //that make up MUI inputs, as they have a lot of fixed or hard-coded internal font sizes.
                        fontSize: "smaller",

                        [`
                            & .MuiListItemText-primary,
                            & .MuiInputLabel-root,
                            & .MuiInput-root,
                            & .MuiFormControlLabel-label
                        `]: {
                            fontSize: "inherit"
                        },

                        "& .MuiIconButton-root": {
                            //IconButtons only have two sizes specified by a prop,
                            //so for dynamic shrinking, just eyeball an em value that looks right
                            fontSize: "1.2em"
                        },

                        "& .MuiChip-label": {
                            //The chip defaults to a hard-coded 0.8125rem.
                            //Just switching this value to em to make it relative looks good.
                            fontSize: "0.8125em"
                        },

                        "& .MuiButton-root": {
                            //The default font size for buttons is already smaller than a one-level-nested filter grid.
                            //So once again just eyeball something that looks alright.
                            fontSize: "0.9em"
                        },

                        "& .MuiRadio-root .MuiSvgIcon-root": {
                            //Same as chips, just switch the radio buttons from rem to em
                            fontSize: "1.5em"
                        }
                    }}
                    {...filterGridProps}
                >
                    {children}
                </FilterGrid>
            </FilterGridSummaryContextProvider>
        </>}</GridFilter>
    );
}
