import React, {memo, useCallback} from "react";

import {Box, Stack} from "@mui/material";

import {DateRangePicker} from "../date-range-picker";
import {useFilterGridContext} from "../filter-grid";
import {ListItemText} from "../list";
import {Range} from "~/utils/range";
import {Temporal} from "@js-temporal/polyfill";

export interface DateFilterProps {
    readonly filterKey: string;
    readonly label?: string;
    readonly minInputLabel?: string;
    readonly maxInputLabel?: string;
    //We need some value to use for when the user has cleared the inputs but has not deleted the filter.
    //There's no concept of an "empty" Range, so instead use "" to differ from undefined (which deletes the filter).
    readonly value: Range<Temporal.PlainDate> | "" | undefined;
    readonly onValueChange: (value: Range<Temporal.PlainDate> | "" | undefined) => void;
}

export const DateFilter = memo(function DateFilter({
    filterKey,
    label,
    minInputLabel = "Min",
    maxInputLabel = "Max",
    value,
    onValueChange
}: DateFilterProps) {
    const {GridFilter} = useFilterGridContext();

    const getSummary = useCallback((label: string) => value ? `${label} ${value.toMathString()}` : null, [value]);

    const onRangeChange = useCallback((range: Range<Temporal.PlainDate> | undefined) => {
        onValueChange(range ?? "");
    }, [onValueChange]);

    const onRemove = useCallback(() => onValueChange(undefined), [onValueChange]);

    return (
        <GridFilter
            filterKey={filterKey}
            label={label}
            hasValue={value !== undefined}
            getSummary={getSummary}
            onRemove={onRemove}
        >{(focusRef, renderBaseFilterUi) =>
            renderBaseFilterUi(<>
                <ListItemText sx={{flex: "none"}}>∈</ListItemText>
                <Stack direction="row" alignItems="baseline" gap="5px" flex={1}>
                    [
                    <DateRangePicker
                        ref={focusRef as any}
                        variant="standard"
                        sx={{
                            flex: 1,

                            "& .PcDateRangePicker-inputContainer": {
                                alignItems: "baseline"
                            }
                        }}
                        fromLabel={minInputLabel}
                        toLabel={maxInputLabel}
                        range={value || undefined}
                        onChange={onRangeChange}
                        hideClearButton
                        spacer={<Box mx="5px">,</Box>}
                    />
                    ]
                </Stack>
            </>)
        }</GridFilter>
    );
});
