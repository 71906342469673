import React, {ReactNode, useCallback, useRef, useState} from "react";
import {To} from "react-router-dom";

import {Container} from "@mui/material";
import {isEqual, pick} from "lodash";
import {flow, flowResult} from "mobx";
import {observer} from "mobx-react-lite";

import {Form} from "./components/form";
import {Icon} from "./components/icon";
import {Link} from "./components/link";
import {List, ListItem, ListItemButton} from "./components/list";
import {Page, PageWidth} from "./components/page";
import {PersistentTextField, PersistentTextFieldProps} from "./components/persistent-inputs";
import {Collapse} from "./components/transitions";
import {Typography} from "./components/typography";
import {UserStore} from "./user/store";
import {inject} from "./utils/di";
import {preventDefault} from "./utils/event-helpers";
import {Loadable} from "./utils/loadable";
import {awt} from "./utils/mobx";
import {transition} from "./utils/styles";
import {useCustomValidity} from "./utils/use-custom-validity";
import {useStateObject} from "./utils/use-state-object";

interface SettingsSectionProps {
    readonly heading: string;
    readonly form?: boolean;
    readonly dirty?: boolean;
    readonly onSave?: () => void;
    readonly saveStatus?: Loadable<unknown>;
    readonly children: ReactNode;
}

const SettingsSection = ({heading, form = false, dirty, onSave, saveStatus, children}: SettingsSectionProps) =>
    <Container>
        <Typography variant="h3">{heading}</Typography>
        {form ?
            <Form
                submitLabel="Save"
                submitDisabled={dirty === false}
                onSubmit={preventDefault(onSave)}
                submitStatus={saveStatus}
                sx={{
                    "& .PcForm-content": {
                        gap: 0
                    },

                    "& .PcForm-errors": {
                        mt: 1,
                        mb: 0
                    }
                }}
            >
                <List>
                    {children}
                </List>
            </Form>
        :
            <List>
                {children}
            </List>
        }
    </Container>;

const SettingsLink = ({to, children}: {to: To, children: ReactNode}) =>
    <ListItem>
        <ListItemButton
            component={Link}
            to={to}
            sx={{justifyContent: "space-between", ...transition(["color", "shortest", "easeInOut"])}}
        >
            {children}
            <Icon fa="angle-right"/>
        </ListItemButton>
    </ListItem>;

type SettingsTextFieldProps<T extends string> = PersistentTextFieldProps<T>;

const SettingsTextField = <T extends string>({sx = [], ...props}: SettingsTextFieldProps<T>) =>
    //The redundant listitem role helps Firefox maintain semantics with the wrapper divs introduced by Collapse
    <ListItem role="listitem">
        <PersistentTextField variant="filled" sx={[{width: 1}, sx].flat()} {...props}/>
    </ListItem>;

export const Settings = inject({userStore: UserStore}, observer(function Settings({userStore}: {userStore: UserStore}) {
    const currentUser = userStore.currentUser.getValue();

    const confirmEmailInput = useRef<HTMLInputElement>(null);
    const confirmNewPasswordInput = useRef<HTMLInputElement>(null);

    const [pendingUserInfo, setPendingUserInfo] = useStateObject({
        email: useState(currentUser.email),
        firstName: useState(currentUser.firstName),
        lastName: useState(currentUser.lastName)
    });

    const [confirmEmail, setConfirmEmail] = useState("");

    const persistedUserInfo = pick(currentUser, "email", "firstName", "lastName");

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");

    const onNewPasswordChange = useCallback((password: string) => {
        setNewPassword(password);

        if (!password) {
            setConfirmNewPassword("");
            setOldPassword("");
        }
    }, [setNewPassword, setConfirmNewPassword, setOldPassword]);

    const emailDirty = pendingUserInfo.email !== persistedUserInfo.email;

    useCustomValidity(
        confirmEmailInput,
        "Must match Email Address",
        !emailDirty || confirmEmail === pendingUserInfo.email
    );

    useCustomValidity(
        confirmNewPasswordInput,
        "Must match new password",
        !newPassword || confirmNewPassword === newPassword
    );

    const saveUserInfo = useCallback(() => flow(function*() {
        yield* awt(flowResult(userStore.updateCurrentUser({
            ...pendingUserInfo,
            ...(newPassword && {password: {oldPassword, newPassword}})
        })));

        if (userStore.currentUserUpdateStatus.isLoaded()) {
            setConfirmEmail("");
            setNewPassword("");
            setConfirmNewPassword("");
            setOldPassword("");
        }
    })(), [
        userStore,
        pendingUserInfo,
        newPassword,
        oldPassword,
        setConfirmEmail,
        setNewPassword,
        setConfirmNewPassword,
        setOldPassword
    ]);

    return (
        <Page title="Settings" width={PageWidth.NARROW}>
            <SettingsSection
                heading="User Profile"
                form
                dirty={!!newPassword || !isEqual(pendingUserInfo, persistedUserInfo)}
                onSave={saveUserInfo}
                saveStatus={userStore.currentUserUpdateStatus}
            >
                <SettingsTextField
                    type="email"
                    label="Email Address"
                    value={pendingUserInfo.email}
                    persistedValue={persistedUserInfo.email}
                    onValueChange={setPendingUserInfo.email}
                    required
                    autoComplete="email"
                />
                <Collapse in={emailDirty} unmountOnExit>
                    <SettingsTextField
                        type="email"
                        inputRef={confirmEmailInput}
                        variant="outlined"
                        label="Confirm Email Address"
                        value={confirmEmail}
                        onValueChange={setConfirmEmail}
                        required
                        autoComplete="email"
                    />
                </Collapse>
                <SettingsTextField
                    type="password"
                    label="Change Password"
                    value={newPassword}
                    onValueChange={onNewPasswordChange}
                    forceDirty={!!newPassword}
                    autoComplete="new-password"
                />
                <Collapse in={!!newPassword} unmountOnExit>
                    <div>
                        <SettingsTextField
                            type="password"
                            inputRef={confirmNewPasswordInput}
                            variant="outlined"
                            label="Confirm New Password"
                            value={confirmNewPassword}
                            onValueChange={setConfirmNewPassword}
                            required
                            autoComplete="new-password"
                        />
                        <SettingsTextField
                            type="password"
                            variant="outlined"
                            label="Current Password"
                            value={oldPassword}
                            onValueChange={setOldPassword}
                            required
                            autoComplete="current-password"
                        />
                    </div>
                </Collapse>
                <SettingsTextField
                    label="First Name"
                    value={pendingUserInfo.firstName}
                    persistedValue={persistedUserInfo.firstName}
                    onValueChange={setPendingUserInfo.firstName}
                    required
                />
                <SettingsTextField
                    label="Last Name"
                    value={pendingUserInfo.lastName}
                    persistedValue={persistedUserInfo.lastName}
                    onValueChange={setPendingUserInfo.lastName}
                    required
                />
            </SettingsSection>
            {/* <SettingsSection heading="Multi-Factor Authentication">
                <SettingsLink to="/register-totp">Register new authenticator app</SettingsLink>
            </SettingsSection> */}
        </Page>
    );
}));
