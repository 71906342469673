import React, {useCallback, useEffect} from "react";

import {Stack} from "@mui/material";
import {memoize} from "lodash";
import {flowResult} from "mobx";
import {observer} from "mobx-react-lite";

import {ACH_BATCH_SORT_KEYS, AchBatchSortKey} from "../api";
import {AchBatchDisplay, AchBatchSummaryDisplay} from "../components/ach-batch-display";
import {AchBatchFilters} from "../components/ach-batch-filters";
import {AchStore} from "../store";
import {PageParams, SortDirection, SortParams} from "~/api/common-query-params";
import {pageNumberSuffix, PaginationState} from "~/api/pagination-envelope";
import {Accordion, AccordionDetails, AccordionSummary} from "~/components/accordion";
import {FilterAndSortBar} from "~/components/filter-and-sort-bar";
import {CollapsibleFilterGrid} from "~/components/filter-grid";
import {LoadingTreatment} from "~/components/loading-treatment";
import {Page} from "~/components/page";
import {TablePagination} from "~/components/pagination";
import {SortOptions} from "~/components/sort-options";
import {parseBoolean} from "~/utils/booleans";
import {inject} from "~/utils/di";
import {takeArgs} from "~/utils/event-helpers";
import {parsePositiveInt} from "~/utils/numbers";
import {UnbulletedList} from "~/utils/styles";
import {useAbortableEffect} from "~/utils/use-abortable-effect";
import {useIsFirstRender} from "~/utils/use-is-first-render";
import {useQueryParam} from "~/utils/use-query-param";
import {useStateObject, useTypedStateObject} from "~/utils/use-state-object";

export const AchBatchDashboard = inject({achStore: AchStore}, observer(function AchBatchDashboard({
    achStore
}: {achStore: AchStore}) {
    const {batches} = achStore;

    const [pageParams, setPageParams] = useTypedStateObject<PageParams>()({
        page: useQueryParam("page", 1, {parser: parsePositiveInt, push: true, immediate: true}),
        perPage: useQueryParam("perPage", 10, {parser: parsePositiveInt, push: true, immediate: true})
    });

    const [sortParams, setSortParams] = useTypedStateObject<SortParams<AchBatchSortKey>>()({
        sortBy: useQueryParam<AchBatchSortKey | undefined>("sortBy", undefined),
        sortDir: useQueryParam<SortDirection | undefined>("sortDir", undefined)
    });

    const [filters, setFilters] = useStateObject({
        backendId: useQueryParam<string | undefined>("backendId", undefined),
        onlySubmitted: useQueryParam<boolean | undefined>("onlySubmitted", undefined, {parser: parseBoolean})
    });

    //Reset the page to 1 when the sort and filters change
    const isFirstRender = useIsFirstRender();
    useEffect(() => {
        if (!isFirstRender) {
            setPageParams.page(1, {immediate: false});
        }
    }, [sortParams, filters]); //eslint-disable-line react-hooks/exhaustive-deps

    //eslint-disable-next-line react-hooks/exhaustive-deps
    const onBatchCollapse = useCallback(memoize((batchId: string) => () => {
        achStore.clearBatchDetails(batchId);
    }), [achStore]);

    useAbortableEffect(
        signal => flowResult(achStore.fetchBatches({...pageParams, ...sortParams, ...filters}, signal)),
        [achStore, pageParams, sortParams, filters]
    );

    const pageTitle = "ACH Batches";
    const pageNumberTitle = batches.case({
        hasValue: batches => pageNumberSuffix(batches.pagination),
        else: () => ""
    });

    const renderPagination = ({totalItems, currentPage, perPage}: PaginationState) =>
        <TablePagination
            labelRowsPerPage="Results per page:"
            count={totalItems}
            onPageChange={takeArgs(setPageParams.page, 1)}
            page={currentPage}
            rowsPerPage={perPage}
            onRowsPerPageChange={takeArgs(setPageParams.perPage, 1)}
        />;

    return (
        <Page title={pageTitle} tabTitle={pageTitle + pageNumberTitle}>
            <FilterAndSortBar>{expandSort => <>
                <CollapsibleFilterGrid>
                    <AchBatchFilters
                        backendId={filters.backendId}
                        setBackendId={setFilters.backendId}
                        onlySubmitted={filters.onlySubmitted}
                        setOnlySubmitted={setFilters.onlySubmitted}
                    />
                </CollapsibleFilterGrid>
                <SortOptions
                    sortKeys={ACH_BATCH_SORT_KEYS}
                    sortBy={sortParams.sortBy}
                    onSortByChange={setSortParams.sortBy}
                    sortDirection={sortParams.sortDir}
                    onSortDirectionChange={setSortParams.sortDir}
                    expanded={expandSort}
                />
            </>}</FilterAndSortBar>
            <LoadingTreatment loadable={batches} description="batches" observer renderStaleValues>{(
                batches,
                stale
            ) => <>
                {/* TODO: Determine where to show a loading indicator when the data is stale */}
                {batches.data.size === 0 ?
                    "There are no results at this time"
                : <>
                    {renderPagination(batches.pagination)}
                    <Stack component={UnbulletedList} gap={1}>
                        {batches.data.map(batch =>
                            //Since each Accordion is inside of its own <li>, they won't group together,
                            //but I'm fine with this
                            <li key={batch.id}>
                                <Accordion TransitionProps={{unmountOnExit: true, onExited: onBatchCollapse(batch.id)}}>
                                    <AccordionSummary sx={{"& .MuiAccordionSummary-content": {mr: 2}}}>
                                        <AchBatchSummaryDisplay batch={batch}/>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <AchBatchDisplay batchId={batch.id}/>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                        )}
                    </Stack>
                    {renderPagination(batches.pagination)}
                </>}
            </>}</LoadingTreatment>
        </Page>
    );
}));
