import React from "react";
import {CollapsibleFilterGrid} from "~/components/filter-grid";
import {BooleanFilter, DateFilter, NumberFilter, TextFilter} from "~/components/filters";
import {LineItemFilter, LineItemFilterSetter} from "~/recon/use-line-item-query";
import {debounce} from "lodash";


export const LineItemFilters = ({filter, setFilter}: {filter: LineItemFilter, setFilter: LineItemFilterSetter }) => {
  const setFilterDebounced = debounce((key, value) => setFilter(key, value), 300);

  return (
    <CollapsibleFilterGrid
        sx={{
        mb: 2,
        "& .MuiAccordionDetails-root": {
          maxHeight: "30vh",
          overflow: "auto"
        }
      }}
    >
      <BooleanFilter
          filterKey={"reconciled"}
          value={filter.reconciled}
          onValueChange={value => setFilterDebounced("reconciled", value)}
      />
      <TextFilter
          filterKey="description"
          value={filter.description}
          onValueChange={value => setFilterDebounced("description", value)}
      />
      <TextFilter
          filterKey="reference"
          value={filter.reference}
          onValueChange={value => setFilterDebounced("reference", value)}
      />
      <DateFilter
          filterKey="date"
          value={filter.date}
          onValueChange={value => setFilterDebounced("date", value)}
      />
      <NumberFilter
          type="BigNumber"
          filterKey="amount"
          value={filter.amount}
          onValueChange={value => setFilterDebounced("amount", value)}
      />
    </CollapsibleFilterGrid>
  );
};
