import {Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Step, StepButton, StepContent, Stepper, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {BigNumber} from "bignumber.js";
import React, {useContext, useState} from "react";
import {VirtualAccountListItem} from "~/account/virtual-account-list-item";
import {TransferSummary} from "~/transfer/transfer-summary";
import {VirtualAccountBrowser} from "./virtual-account-browser";
import {RenderIf} from "../util";
import {ReconStagingContext} from "./recon-staging";
import {useMutation, useQueryClient} from "react-query";
import LineItems from "~/repositories/line-items";

export const UnattributedWireDialog = ({open, onClose, investmentId}: {investmentId: string | undefined, open: boolean, onClose: (t: TransferSummary | null) => void}) => {
  const staging = useContext(ReconStagingContext);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [wireAmount, setWireAmount] = useState<BigNumber>(new BigNumber(0));
  const [destinationAccount, setDestinationAccount] = useState<VirtualAccountListItem|undefined>(undefined);

  const client = useQueryClient();
  const {mutateAsync, isLoading, isError, isSuccess, error} = useMutation(LineItems.unattributedWire(staging.lineItem.id));

  const onWireAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = new BigNumber(event.target.value || "0");
    if (!value.isNaN()) {
      setWireAmount(value);
    }
  };
  const onAccountSelected = (data: VirtualAccountListItem) => {
    setActiveStep(1);
    setDestinationAccount(data);
    setWireAmount(staging.lineItem.amount);
  };
  const isSubmitDisabled = wireAmount.isZero() || destinationAccount === undefined;
  const onSubmit = async () => {
    const params = {
      destinationAccountId: Number(destinationAccount!.id),
      amount: wireAmount
    };
    const wireTransfer = await mutateAsync(params);
    onClose(wireTransfer);
  };

  const onCancel = () => onClose(null);
  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xl" scroll="paper">
      <DialogTitle>Create a Wire</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the destination virtual account, to which this wire should be attributed.
          <RenderIf cond={isError}>
            <Alert sx={{mt: 2, mb: 2}} severity="error">{error ? (error as any).error : null}</Alert>
          </RenderIf>
        </DialogContentText>

        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key={0}>
            <StepButton color="inherit" onClick={() => setActiveStep(0)}>
              <Typography>Choose the Destination Account</Typography>
            </StepButton>
            <StepContent>
              <VirtualAccountBrowser onSelected={onAccountSelected} investmentId={investmentId}/>
            </StepContent>
          </Step>

          <Step key={1}>
          <StepButton color="inherit" onClick={() => setActiveStep(1)}>
              <Typography>Transfer Details</Typography>
            </StepButton>
            <StepContent>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={4}>
                  Wire Amount:
                </Grid>
                <Grid item xs={8}>
                  <TextField variant="outlined" size="small" value={wireAmount} onChange={onWireAmountChange} inputProps={{inputMode: "numeric"}}/>
                </Grid>
              </Grid>
            </StepContent>
          </Step>
      </Stepper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onSubmit} disabled={isSubmitDisabled}>Create Wire Transfer</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnattributedWireDialog;
