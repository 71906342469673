import React, {ForwardedRef, forwardRef, useCallback, useEffect, useId, useRef} from "react";

import {Form, FormProps} from "~/components/form";
import {TextField} from "~/components/text-field";
import {Typography} from "~/components/typography";
import {preventDefault} from "~/utils/event-helpers";
import {useInputState} from "~/utils/use-input-state";

export interface TOTPVerificationFormProps extends Omit<FormProps, "onSubmit"> {
    readonly onSubmit: (otp: string) => void;
    readonly autoFocus?: boolean;
}

export const TOTPVerificationForm = forwardRef(function TOTPVerificationForm({
    autoFocus,
    onSubmit,
    ...formProps
}: TOTPVerificationFormProps, ref: ForwardedRef<HTMLFormElement>) {
    const otpField = useRef<HTMLInputElement>(null);

    const [otp, onOtpInput] = useInputState("");

    const submit = useCallback(() => onSubmit(otp), [onSubmit, otp]);

    //The autoFocus prop on TextField never seems to work, so do it with a mount effect
    useEffect(() => {
        if (autoFocus) {
            otpField.current!.focus();
        }
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const instructionsId = useId();

    return (
        <Form ref={ref} onSubmit={preventDefault(submit)} {...formProps}>
            <Typography id={instructionsId} component="p" fontSize="1.1rem">
                Please provide a one-time password from your authenticator app
            </Typography>
            <TextField
                inputRef={otpField}
                label="OTP"
                aria-describedby={instructionsId}
                required
                value={otp}
                onInput={onOtpInput}
            />
        </Form>
    );
});
