import React, {useCallback} from "react";

import {Box, Paper, Stack} from "@mui/material";
import {flowResult} from "mobx";
import {observer} from "mobx-react-lite";
import {MarkRequired} from "ts-essentials";

import {AchBatchItem} from "../ach-batch-item";
import {AchBatchSummary} from "../ach-batch-summary";
import {AchStore} from "../store";
import {Api} from "~/api/api";
import {Alert} from "~/components/alert";
import {LoadingButton} from "~/components/button";
import {useConfirm} from "~/components/dialog";
import {Link} from "~/components/link";
import {LoadingTreatment} from "~/components/loading-treatment";
import {
    EditColumnsFlyout,
    makeColumns,
    Table,
    TableColumns,
    TableContainer,
    useLocalStorageColumnOrder
} from "~/components/table";
import {inject} from "~/utils/di";
import {useLoadableCallback} from "~/utils/loadable";
import {useFetchIfPending} from "~/utils/loadable/use-fetch-if-pending";
import {WordGroup} from "~/utils/styles";

export interface AchBatchSummaryDisplayProps {
    //Pick just the properties we're using so that either an AchBatch or an AchBatchSummary can be passed
    readonly batch: Pick<AchBatchSummary,
        | "id"
        | "origin"
        | "createdAt"
        | "isSubmitted"
        | "submittedAt"
        | "isGenerated"
        | "generatedAt"
    >;
}

export const AchBatchSummaryDisplay = ({batch}: AchBatchSummaryDisplayProps) =>
    <Box whiteSpace="pre-wrap">
        <WordGroup><b>ID:</b> {batch.id}{" | "}</WordGroup>
        <WordGroup><b>Origin:</b> {batch.origin.name} ({batch.origin.id}){" | "}</WordGroup>
        <WordGroup><b>Created At:</b> {batch.createdAt.toLocaleString()}</WordGroup>
        {batch.isSubmitted() ? <>
            {" | "}<WordGroup><b>Submitted At:</b> {batch.submittedAt.toLocaleString()}</WordGroup>
        </> : batch.isGenerated() && <>
            {" | "}<WordGroup><b>Generated At:</b> {batch.generatedAt.toLocaleString()}</WordGroup>
        </>}
    </Box>;

export interface AchBatchDisplayProps {
    readonly api?: Api;
    readonly achStore?: AchStore;
    readonly batchId: string;
    readonly tablePaper?: boolean;
}

export const AchBatchDisplay = inject({api: Api, achStore: AchStore}, observer(function AchBatchDisplay({
    api,
    achStore,
    batchId,
    tablePaper = false
}: MarkRequired<AchBatchDisplayProps, "api" | "achStore">) {
    const confirm = useConfirm();

    const batchDetails = useFetchIfPending(
        achStore.getBatchDetails(batchId),
        signal => flowResult(achStore.fetchBatchDetails(batchId, signal)),
        [achStore, batchId]
    );

    const [generateNacha, nachaGenerationStatus] = useLoadableCallback(async () => {
        const batch = batchDetails.valueOrNull();
        if (!batch) return;

        if (await confirm({
            title: "Generate NACHA file for this batch?",
            description: "This will lock the batch and prevent it from being deleted.",
            dangerous: true
        })) {
            //Because downloading the NACHA file has side-effects, we need to know when the request has succeeded
            //so that we can refresh the batch details. This precludes just opening the download link in a new tab.
            //NACHA files shouldn't really be so big that doing the in-memory blob approach should cause any issues
            //(That's what the legacy NACHA tool does)
            const file = await api.ach.downloadNacha(batch);
            const url = URL.createObjectURL(file);
            const a = document.createElement("a");
            a.href = url;
            a.target = "_blank";
            a.download = file.name;
            a.addEventListener("click", () => {
                setTimeout(() => a.remove(), 0);
            });
            document.body.appendChild(a);
            a.click();

            achStore.fetchBatchDetails(batchId);
        }
    }, [batchDetails, confirm]);

    const markSubmitted = useCallback(async () => {
        if (await confirm({title: `Mark batch ${batchId} as submitted?`, description: "This cannot be undone."})) {
            achStore.markBatchSubmitted(batchId);
        }
    }, [achStore, batchId, confirm]);

    const destroy = useCallback(async () => {
        if (await confirm({
            title: `Delete ACH batch ${batchId}?`,
            description: "This cannot be undone.",
            dangerous: true
        })) {
            achStore.deleteBatch(batchId);
        }
    }, [achStore, batchId, confirm]);

    const submissionStatus = achStore.getBatchSubmissionStatus(batchId);
    const deletionStatus = achStore.getBatchDeletionStatus(batchId);
    const actionsDisabled = nachaGenerationStatus.isLoading()
        || submissionStatus.isLoading()
        || deletionStatus.isLoading();

    const columns = makeColumns<AchBatchItem>()({
        id: ["ID", item => item.id],
        createdAt: item => item.createdAt.toLocaleString(),
        amount: item => `$${item.amount}`,
        virtualAccount: item => <Link to={`/accounts/${item.accountId}`}>{item.accountId}</Link>
    });

    const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder("achBatchColumnOrder", columns);

    return (
        <LoadingTreatment loadable={batchDetails} description={`batch ${batchId} details`} renderStaleValues>{(
            batch,
            stale
        ) => <>
            <Stack sx={{gap: 2, "&:not(:empty)": {mb: 2}}}>
                {nachaGenerationStatus.hasError() &&
                    <Alert severity="error">Error downloading NACHA file: {nachaGenerationStatus.errorMessage}</Alert>
                }
                {submissionStatus.hasError() &&
                    <Alert severity="error">Error marking batch as submitted: {submissionStatus.errorMessage}</Alert>
                }
                {deletionStatus.hasError() &&
                    <Alert severity="error">Error deleting batch: {deletionStatus.errorMessage}</Alert>
                }
            </Stack>
            <Stack direction="row" px={1} gap={2} justifyContent="space-between">
                <EditColumnsFlyout columns={columns} columnOrder={columnOrder} onColumnOrderChange={setColumnOrder}/>
                <Stack direction="row" gap={2}>
                    <LoadingButton
                        variant="contained"
                        disabled={stale || actionsDisabled || !batch.fileUrl}
                        loading={nachaGenerationStatus.isLoading()}
                        onClick={generateNacha}
                    >
                        Generate NACHA
                    </LoadingButton>
                    <LoadingButton
                        variant="contained"
                        disabled={stale || actionsDisabled || !batch.isGenerated()}
                        loading={submissionStatus.isLoading()}
                        onClick={markSubmitted}
                    >
                        Mark as Submitted
                    </LoadingButton>
                    <LoadingButton
                        color="error"
                        disabled={stale || actionsDisabled || batch.isGenerated()}
                        loading={deletionStatus.isLoading()}
                        onClick={destroy}
                    >
                        Delete
                    </LoadingButton>
                </Stack>
            </Stack>
            <TableContainer {...(tablePaper && {component: Paper})} sx={[tablePaper && {mt: 2}]}>
                <Table>
                    <TableColumns rowData={batch.items} keyRowsBy="id" columnOrder={columnOrder}>
                        {columns}
                    </TableColumns>
                </Table>
            </TableContainer>
        </>}</LoadingTreatment>
    );
}));
