import {BigNumber} from "bignumber.js";

import {VirtualAccount} from "./virtual-account";
import {VirtualAccountListItem} from "./virtual-account-list-item";
import {BaseApi} from "~/api/base-api";
import {PageParams, RangeParams} from "~/api/common-query-params";
import {FetchClient} from "~/api/fetch-client";
import {PaginationEnvelope} from "~/api/pagination-envelope";
import {Backend} from "~/backend/backend";
import {FundraiseExemption, FundraiseState} from "~/entity/wefunder-types";
import {autobindAll} from "~/utils/autobind";

export type VirtualAccountParamsType = "investment_virtual_account" | "source_virtual_account";

export const VIRTUAL_ACCOUNT_SORT_KEYS = [{key: "id"}, {key: "name"}, {key: "balance"}, {key: "accountType"}, {key: "accountRole"}, {key: "backend"}, {key: "investorName"}, {key: "companyName"}] as const;

export type VirtualAccountSortKey = (typeof VIRTUAL_ACCOUNT_SORT_KEYS)[number]["key"];

export interface BaseAccountCreationDto {
    readonly name: string;
    readonly backendId: string;
    readonly type: VirtualAccountParamsType;
}

export interface InvestmentVirtualAccountCreationDto extends BaseAccountCreationDto {
    readonly wfInvestmentId: string;
    readonly type: "investment_virtual_account";
}

export interface SourceVirtualAccountCreationDto extends BaseAccountCreationDto {
    readonly type: "source_virtual_account";
}

export type VirtualAccountCreationDto =
    | InvestmentVirtualAccountCreationDto
    | SourceVirtualAccountCreationDto;

export type VirtualAccountFilterParams = RangeParams<BigNumber, "investmentAmount"> & {
    readonly name?: string,
    readonly accountType?: VirtualAccount.AccountType,
    readonly accountRole?: VirtualAccount.AccountRole,
    readonly backendType?: Backend.Type,
    readonly backendName?: string,
    readonly investmentId?: string,
    readonly fundraiseId?: string,
    readonly fundraiseExemption?: FundraiseExemption,
    readonly fundraiseState?: FundraiseState,
    readonly companyId?: string,
    readonly companyName?: string,
    readonly investorId?: string,
    readonly investorName?: string,
    readonly investorAdmin?: boolean
};

/** API for managing VirtualAccounts */
@autobindAll
export class VirtualAccountApi extends BaseApi<VirtualAccount, VirtualAccountListItem> {
    public constructor(fetchClient: FetchClient, errorHandler: (err: Error) => boolean) {
        super(fetchClient, errorHandler, "/virtual_accounts", VirtualAccount, VirtualAccountListItem);
    }

    /** Gets all VirtualAccounts */
    public async getAccounts(
        params: PageParams & VirtualAccountFilterParams,
        signal?: AbortSignal
    ): Promise<PaginationEnvelope<VirtualAccountListItem[]>> {
        return await this.find(params, signal);
    }

    /** Gets a VirtualAccount by ID */
    public async getAccount(id: string, signal?: AbortSignal): Promise<VirtualAccount> {
        return await this.get(id, signal);
    }

    /** Creates a VirtualAccount */
    public async createAccount(creationDto: VirtualAccountCreationDto, signal?: AbortSignal): Promise<VirtualAccount> {
        return await this.create(creationDto, signal);
    }

    /** Disables a VirtualAccount */
    public async disableAccount(id: string, signal?: AbortSignal): Promise<void> {
        await this.fetchVoid("PATCH", `${id}/status`, {queryParams: {newStatus: false}, signal});
    }

    /** Enables a VirtualAccount */
    public async enableAccount(id: string, signal?: AbortSignal): Promise<void> {
        await this.fetchVoid("PATCH", `${id}/status`, {queryParams: {newStatus: true}, signal});
    }
}
