import {StateLane} from "./state-lane";
import {VirtualAccount, VirtualAccountRole, VirtualAccountRoleImpl} from "./virtual-account";
import {Backend} from "~/backend/backend";
import {Entity} from "~/entity/entity";
import {JsonDto, jsonName, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {CURRENCIES, Currency} from "~/currency";

export class VirtualAccountListItem extends JsonDto {
    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp()
    public readonly name!: string;

    @jsonProp(JsonTypes.stringUnion(VirtualAccount.ACCOUNT_TYPES))
    public readonly accountType!: VirtualAccount.AccountType;

    @jsonProp(JsonTypes.dto(VirtualAccountRoleImpl))
    public readonly accountRole!: VirtualAccountRole;

    @jsonProp()
    @jsonName("backendView")
    public readonly backend!: Backend;

    @jsonProp(JsonTypes.stringUnion(CURRENCIES))
    public readonly currency!: Currency;

    @jsonProp(JsonTypes.array(JsonTypes.dto(StateLane)))
    public readonly stateLanes!: readonly StateLane[];

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly investment!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly fundraise!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly company!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly investor!: Entity | null;

    public readonly balanceSummary: readonly string[];

    public constructor(init?: unknown) {
        super();
        this.init(VirtualAccountListItem, init);

        this.balanceSummary = VirtualAccount.computeBalanceSummary(this.backend, this.stateLanes, this.currency);
    }
}
