/** The base page title */
export const BASE_PAGE_TITLE = "Payment Center";

/**
 * Enum of HTTP status codes referenced in the application.
 * This enum is not exhaustive. Codes are added as needed.
 */
export enum HttpStatus {
    UNAUTHORIZED = 401
}
