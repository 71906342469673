import {BigNumber} from "bignumber.js";

import {AchAccount} from "./ach-account";
import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {Temporal} from "@js-temporal/polyfill";

export class AchBatchItem extends JsonDto {
    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp()
    public readonly bankAccount!: AchAccount;

    @jsonProp()
    public readonly amount!: BigNumber;

    @jsonProp(JsonTypes.id)
    public readonly accountId!: string;

    @jsonProp(JsonTypes.instant, {optional: true})
    public readonly includedAt!: Temporal.Instant | null;

    @jsonProp()
    public readonly createdAt!: Temporal.Instant;

    public constructor(init?: unknown) {
        super();
        this.init(AchBatchItem, init);
    }
}
