export function parseBoolean(str: string): boolean | undefined {
    switch (str.toLowerCase()) {
        case "true":
            return true;
        case "false":
            return false;
        default:
            return undefined;
    }
}
