import React from "react";

import {BaseFilterSetProps} from "~/components/filter-grid";
import {BooleanFilter, TextFilter} from "~/components/filters";
import {Setters} from "~/utils/types";

export interface AchBatchFilterState {
    readonly backendId: string | undefined;
    readonly onlySubmitted: boolean | undefined;
}

export type AchBatchFiltersProps<OmitFilters extends keyof AchBatchFilterState = never> =
    BaseFilterSetProps<AchBatchFilterState, OmitFilters>;

export function AchBatchFilters<OmitFilters extends keyof AchBatchFilterState = never>({
    omit: _omit = [],
    ..._filterProps
}: AchBatchFiltersProps<OmitFilters>) {
    //These are impossible to use otherwise
    const omit = _omit as readonly (keyof AchBatchFilterState)[];
    const filterProps = _filterProps as any as AchBatchFilterState & Setters<AchBatchFilterState>;

    return <>
        {!omit.includes("backendId") &&
            <TextFilter
                filterKey="backendId"
                label="Backend ID"
                value={filterProps.backendId}
                onValueChange={filterProps.setBackendId}
            />
        }
        {!omit.includes("onlySubmitted") &&
            <BooleanFilter
                filterKey="onlySubmitted"
                label="Only Show Submitted Batches"
                value={filterProps.onlySubmitted}
                onValueChange={filterProps.setOnlySubmitted}
            />
        }
    </>;
}
