import {StripePayoutStore} from "~/stripe-payouts/store";
import {StripePayout} from "~/stripe-payouts/stripe-payout";
import {inject} from "~/utils/di";
import {observer} from "mobx-react-lite";
import {useStateObject, useTypedStateObject} from "~/utils/use-state-object";
import React, {useEffect, useMemo, useState} from "react";
import {Range} from "~/utils/range";
import {BigNumber} from "bignumber.js";
import {Temporal} from "@js-temporal/polyfill";
import {PageParams, SortDirection, SortParams} from "~/api/common-query-params";
import {STRIPE_PAYOUT_SORT_KEY, StripePayoutSortKey} from "~/stripe-payouts/api";
import {useIsFirstRender} from "~/utils/use-is-first-render";
import {instantToDateTime, localeDateString, plainDateToInstant} from "~/utils/temporal";
import {useDebouncedValue} from "~/utils/use-debounced-value";
import {useAbortableEffect} from "~/utils/use-abortable-effect";
import {flowResult} from "mobx";
import {makeColumns, PaginatedTable, TableColumns} from "~/components/table";
import {Chip} from "~/components/chip";
import {NoWrap, Span, UnbulletedList} from "~/utils/styles";
import {MoneyFormat} from "~/utils/money";
import {startCase} from "lodash";
import {Box, Stack} from "@mui/material";
import {Button} from "~/components/button";
import {FilterAndSortBar} from "~/components/filter-and-sort-bar";
import {CollapsibleFilterGrid} from "~/components/filter-grid";
import {DateFilter, NumberFilter, TextFilter} from "~/components/filters";
import {SortOptions} from "~/components/sort-options";
import {LoadingTreatment} from "~/components/loading-treatment";
import {takeArgs} from "~/utils/event-helpers";

interface StripePayoutsFilter {
  id?: string;
  description?: string;
  amount?: Range<BigNumber>;
  date?: Range<Temporal.PlainDate>;
}

interface StripePayoutsBrowserProps {
    stripePayoutStore: StripePayoutStore;
    backendId: string;
    reconcilePayout: (payout: StripePayout) => void;
    defaultFilter?: StripePayoutsFilter;
}

export const StripePayoutsBrowser = inject(
  {stripePayoutStore: StripePayoutStore},
  observer(function StripePayoutsBrowser({stripePayoutStore, reconcilePayout, backendId, defaultFilter} : StripePayoutsBrowserProps) {
    const {payouts} = stripePayoutStore;

    const [filters, setFilters] = useStateObject({
        id: useState<string | undefined>(defaultFilter?.id),
        description: useState<string | undefined>(defaultFilter?.description),
        amount: useState<Range<BigNumber> | undefined>(defaultFilter?.amount),
        date: useState<Range<Temporal.PlainDate> | "" | undefined>(defaultFilter?.date)
    });

    const [pageParams, setPageParams] = useTypedStateObject<PageParams>()({
        page: useState(1),
        perPage: useState(10)
    });

    const [sortParams, setSortParams] = useTypedStateObject<SortParams<StripePayoutSortKey>>()({
        sortBy: useState<StripePayoutSortKey | undefined>(undefined),
        sortDir: useState<SortDirection | undefined>(undefined)
    });

    const isFirstRender = useIsFirstRender();
    useEffect(() => {
        if (!isFirstRender) {
            setPageParams.page(1);
        }
    }, [filters]); //eslint-disable-line react-hooks/exhaustive-deps

    const fetchParams = useMemo(() => ({
        ...pageParams,
        id: filters.id,
        description: filters.description,
        reconcilable: true,
        ...filters.amount?.toParams("amount"),
        ...(filters.date ? filters.date.toParams("date", {transform: (value, role) => plainDateToInstant(value, {bound: role === "singleton" ? "lower" : role})}) : {}),
        ...sortParams
    }), [filters, sortParams, pageParams]);

    const debouncedFetchParams = useDebouncedValue(fetchParams, 150);

    useAbortableEffect(
        abortSignal => flowResult(stripePayoutStore.fetchPayouts(backendId, debouncedFetchParams, abortSignal)),
        [stripePayoutStore, debouncedFetchParams]
    );

    const columns = makeColumns<StripePayout>()({
        id: payout => <Chip label={payout.id}/>,
        description: payout => <Span display="inline-block" minWidth="30ex">{payout.description}</Span>,
        amount: payout => MoneyFormat.formatBigNumber(payout.amount, payout.currency, {negativesWithParenthesis: true}),
        status: payout => <NoWrap>{startCase(payout.status)}</NoWrap>,
        arrivalDate: payout => <NoWrap>{localeDateString(payout.arrivalDate)}</NoWrap>,
        actions: payout => <Stack component={UnbulletedList}>
            <Button size={"small"} color={"warning"} variant={"text"} onClick={() => reconcilePayout(payout)}>Select</Button>
        </Stack>
    });

    return <Box>
        <FilterAndSortBar>{expandSort =>
            <>
                <CollapsibleFilterGrid>
                    <TextFilter
                        filterKey="id"
                        value={filters.id}
                        onValueChange={setFilters.id}
                    />
                    <TextFilter
                        filterKey="description"
                        value={filters.description}
                        onValueChange={setFilters.description}
                    />
                    <DateFilter
                        filterKey="date"
                        value={filters.date}
                        onValueChange={setFilters.date}
                    />
                    <NumberFilter
                        type={"BigNumber"}
                        filterKey="amount"
                        value={filters.amount}
                        onValueChange={setFilters.amount}
                    />
                </CollapsibleFilterGrid>

                <SortOptions
                    sortKeys={STRIPE_PAYOUT_SORT_KEY}
                    sortBy={sortParams.sortBy}
                    onSortByChange={setSortParams.sortBy}
                    sortDirection={sortParams.sortDir}
                    onSortDirectionChange={setSortParams.sortDir}
                    expanded={expandSort}
                />
            </>
        }</FilterAndSortBar>

        <LoadingTreatment loadable={payouts} observer renderStaleValues>{(records, stale) =>
            <PaginatedTable
                paginationState={records.pagination}
                loadingNextPage={stale}
                onPageChange={takeArgs(setPageParams.page, 1)}
                onRowsPerPageChange={takeArgs(setPageParams.perPage, 1)}
            >
                <TableColumns rowData={[...records.data.values()]} keyRowsBy="id" observer>
                    {columns}
                </TableColumns>
            </PaginatedTable>
        }</LoadingTreatment>
    </Box>;
}));
