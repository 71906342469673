import {experimental_sx as sx} from "@mui/material";

import {ComponentOverrides} from "./mui-theme-utils";

export {Alert, AlertProps} from "@mui/material";

export const alertOverrides: ComponentOverrides = {
    MuiAlert: {
        styleOverrides: {
            message: sx({
                whiteSpace: "pre-wrap"
            })
        }
    }
};
