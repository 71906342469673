import {MutableRefObject, Ref} from "react";

export function assignRef<T>(ref: Ref<T>, value: T | null): void {
    if (typeof ref === "function") {
        ref(value);
    }
    else if (typeof ref === "object" && ref !== null) {
        (ref as MutableRefObject<T | null>).current = value;
    }
}
