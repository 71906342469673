import React, {memo} from "react";

import {startCase} from "lodash";

import {User} from "../user";
import {PaginationState} from "~/api/pagination-envelope";
import {Link} from "~/components/link";
import {
    EditColumnsFlyout,
    makeColumns,
    PaginatedTable,
    TableColumns,
    useLocalStorageColumnOrder
} from "~/components/table";
import {takeArgs} from "~/utils/event-helpers";

export interface UserListDisplayProps {
    readonly elevation?: number;
    readonly users: Iterable<User>;
    readonly paginationState: PaginationState;
    readonly stale: boolean;
    readonly onPageChange: (page: number) => void;
    readonly onRowsPerPageChange: (rowsPerPage: number) => void;
}

export const UserListDisplay = memo(function UserListDisplay({
    elevation,
    users,
    paginationState,
    stale,
    onPageChange,
    onRowsPerPageChange
}: UserListDisplayProps) {
    const columns = makeColumns<User>()({
        id: ["ID", user => <Link to={`/users/${user.id}`}>{user.id}</Link>],
        email: user => user.email,
        firstName: user => user.firstName,
        lastName: user => user.lastName,
        role: user => startCase(user.role)
    });

    const [columnOrder, setColumnOrder] = useLocalStorageColumnOrder("userColumnOrder", columns);

    return (
        <PaginatedTable
            elevation={elevation}
            paginationState={paginationState}
            loadingNextPage={stale}
            onPageChange={takeArgs(onPageChange, 1)}
            onRowsPerPageChange={takeArgs(onRowsPerPageChange, 1)}
            renderExtraToolbarContents={() =>
                <EditColumnsFlyout columns={columns} columnOrder={columnOrder} onColumnOrderChange={setColumnOrder}/>
            }
        >
            <TableColumns rowData={users} keyRowsBy="id" columnOrder={columnOrder}>
                {columns}
            </TableColumns>
        </PaginatedTable>
    );
});
