import React from "react";

import {experimental_sx as sx} from "@mui/material";

import {Icon} from "./icon";
import {ComponentOverrides} from "./mui-theme-utils";

export {
    Accordion,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps,
    AccordionDetails,
    AccordionDetailsProps
} from "@mui/material";

export const accordionOverrides: ComponentOverrides = {
    MuiAccordion: {
        styleOverrides: {
            root: sx({
                //Prevent ugly corner overflow on focused state
                overflow: "hidden"
            })
        }
    }
};

export const accordionSummaryOverrides: ComponentOverrides = {
    MuiAccordionSummary: {
        defaultProps: {
            expandIcon: <Icon fa="angle-down"/>
        },
        styleOverrides: {
            content: sx({
                alignItems: "center"
            })
        }
    }
};
