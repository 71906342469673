import React from "react";

import {lowerCase} from "lodash";
import {observer} from "mobx-react-lite";

import {Transfer} from "../transfer";
import {VirtualAccount} from "~/account/virtual-account";
import {Typography} from "~/components/typography";
import {Span} from "~/utils/styles";
import {MoneyFormat} from "~/utils/money";

export interface TransferAmountSummaryProps {
    readonly transfer: Transfer;
}

export const TransferAmountSummary = observer(function TransferAmountSummary({transfer}: TransferAmountSummaryProps) {
    const renderVirtualAccount = (account: VirtualAccount) =>
        `${lowerCase(account.accountType)} ${lowerCase(account.accountRole.type)} ${account.id} (${account.name})`;

    if (!transfer.fromAccount && !transfer.toAccount) return null;

    return (
        <Typography>
            <Span fontSize="larger" fontWeight="bold">{MoneyFormat.formatBigNumber(transfer.amount, transfer.currency)}</Span><br/>
            {transfer.fromAccount && <><b>From</b> {renderVirtualAccount(transfer.fromAccount)}<br/></>}
            {transfer.toAccount && <><b>To</b> {renderVirtualAccount(transfer.toAccount)}</>}
        </Typography>
    );
});
