import React, {ReactNode, useState} from "react";
import {LineItemType} from "~/recon/line-item";
import {startCase} from "lodash";
import {TextField} from "@mui/material";
import BigNumber from "bignumber.js";

export const RenderIf = ({cond, children}: {cond: boolean, children: ReactNode}) => {
  if (cond) {
    return (<>{children}</>);
  } else {
    return null;
  }
};

export function IfDefined<T>({value, children}: {value: T, children: ReactNode}) {
  if (value === null || value === undefined) {
    return null;
  } else {
    return (<>{children}</>);
  }
}

export function assertIn<T>(actual: T, ...expected: T[]): void {
  if (expected.indexOf(actual) === -1) {
    throw new Error(`Invalid call for recon flow state ${actual}`);
  }
}

export const labelForLineItemType = (v: LineItemType) => startCase(v);

export type NumericFieldProps = {
  variant?: "filled" | "outlined" | "standard",
  size?: "small" | "medium",
  value?: BigNumber,
  onChange: (value: BigNumber) => void
};

export const NumericField = (props: NumericFieldProps) => {
  const initial = (props.value !== undefined) ? props.value.toString() : "0";
  const [amountStr, setAmountStr] = useState<string>(initial);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAmountStr(value);
    const num = new BigNumber(value);
    if (num.isFinite()) {
      props.onChange(num);
    }
  };

  return (
    <TextField variant={props.variant || "outlined"} size={props.size || "small"} value={amountStr} onChange={onInputChange}/>
  );
};
