import {action, flow, makeObservable} from "mobx";

import {Api} from "~/api/api";
import {PageParams, SortParams} from "~/api/common-query-params";
import {mapPageData, PaginationEnvelope} from "~/api/pagination-envelope";
import {constructorDependencies} from "~/utils/di";
import {Loadable, loadableContainer, loading} from "~/utils/loadable";
import {awt} from "~/utils/mobx";
import {ObservableOrderableMap, ReadonlyOrderableMap} from "~/utils/orderable-map";
import {Sequence} from "~/utils/sequence";
import {StripePayout} from "~/stripe-payouts/stripe-payout";
import {StripePayoutFilterParam, StripePayoutSortKey} from "~/stripe-payouts/api";

@constructorDependencies
export class StripePayoutStore {
    private readonly _payouts = loadableContainer<PaginationEnvelope<ObservableOrderableMap<string, StripePayout>>>(loading);

    public constructor(private readonly api: Api) {
        makeObservable(this, undefined, {name: "StripePayoutStore"});
    }

    public get payouts(): Loadable<PaginationEnvelope<ReadonlyOrderableMap<string, StripePayout>>> { return this._payouts.l; }

    @flow.bound
    public * fetchPayouts(backendId: string, params: PageParams & SortParams<StripePayoutSortKey> & StripePayoutFilterParam, signal?: AbortSignal) {
        yield* this._payouts.run(this, function*() {
            const page = yield* awt(this.api.stripePayouts.getPayouts(backendId, params, signal));
            return mapPageData(page, d => Sequence.from(d)
                .keyBy("id")
                .collectToObservableOrderableMap({name: "stripe_payouts", deep: false})
            );
        }, {keepStaleValue: true});
    }

}
