import {Box, Paper} from "@mui/material";
import {startCase} from "lodash";
import {Accordion, AccordionDetails, AccordionSummary} from "~/components/accordion";
import {EntityDisplay} from "~/entity/components/entity-display";
import React from "react";
import {VirtualAccount} from "~/account/virtual-account";
import {observer} from "mobx-react-lite";
import {AccountTransfers} from "~/account/components/virtual-account-transfers";

interface  VirtualAccountDetailsProps {
    account: VirtualAccount;
}

export const VirtualAccountDetails = observer(function VirtualAccountDetails({account}: VirtualAccountDetailsProps) {
    return  <>
        <Paper component="p" sx={{m: 0, p: 2}}>
            <b>Backend:</b> {account.backend.name} ({account.backend.id}, {startCase(account.backend.backendType)})
        </Paper>
        <div>
            <Accordion defaultExpanded>
                <AccordionSummary>State Lanes</AccordionSummary>
                <AccordionDetails>
                    <Box component="dl" m={0}>
                        {account.stateLanes.map(lane =>
                            <div key={lane.id}>
                                <Box
                                    component="dt"
                                    sx={{
                                        display: "inline",
                                        fontWeight: "bold",
                                        marginRight: "1ex",

                                        "&::after": {
                                            content: "':'"
                                        }
                                    }}
                                >
                                    {startCase(lane.laneType)} ({lane.id})
                                </Box>
                                <Box component="dd" display="inline" m={0}>
                                    ${lane.amount.toString()}
                                </Box>
                            </div>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary>Entities</AccordionSummary>
                <AccordionDetails>
                    <EntityDisplay model={account} entities={["investment", "fundraise", "company", "investor"]}/>
                </AccordionDetails>
            </Accordion>
            {/*
                            Going ahead and leaving this eager so filter state doesn't get lost when you close it.
                            Can do something more sophisticated later if there's a need.
                        */}
            <Accordion>
                <AccordionSummary>Transfers</AccordionSummary>
                <AccordionDetails>
                    <AccountTransfers relatedAccountId={account.id}/>
                </AccordionDetails>
            </Accordion>
        </div>
    </>;
});
