import {action, flow, makeObservable} from "mobx";

import {UserTaskFilterParams, UserTaskSortKey} from "./api";
import {UserTask} from "./user-task";
import {Api} from "~/api/api";
import {PageParams, SortParams} from "~/api/common-query-params";
import {mapPageData, PaginationEnvelope} from "~/api/pagination-envelope";
import {constructorDependencies} from "~/utils/di";
import {Loadable, loadableContainer, loading} from "~/utils/loadable";
import {LoadableMap} from "~/utils/loadable/loadable-map";
import {awt} from "~/utils/mobx";
import {ObservableOrderableMap, ReadonlyOrderableMap} from "~/utils/orderable-map";
import {Sequence} from "~/utils/sequence";

@constructorDependencies
export class UserTaskStore {
    private readonly _tasks = loadableContainer.eager<PaginationEnvelope<ObservableOrderableMap<string, UserTask>>>(
        loading
    );

    public get tasks(): Loadable.Eager<PaginationEnvelope<ReadonlyOrderableMap<string, UserTask>>> {
        return this._tasks.l;
    }

    private readonly taskDetails = new LoadableMap<string, UserTask>();

    public constructor(private readonly api: Api) {
        makeObservable(this, undefined, {name: "UserTaskStore"});
    }

    @flow.bound
    public * fetchTasks(params: PageParams & SortParams<UserTaskSortKey> & UserTaskFilterParams, signal?: AbortSignal) {
        yield* this._tasks.run(this, function*() {
            const page = yield* awt(this.api.userTasks.findTasks(params, signal));
            return mapPageData(page, d => Sequence.from(d)
                .keyBy("id")
                .collectToObservableOrderableMap({name: "tasks", deep: false})
            );
        }, {keepStaleValue: true});
    }

    @flow.bound
    public * fetchTaskDetails(id: string, signal?: AbortSignal) {
        yield* this.taskDetails.run(this, id, function*() {
            const task = yield* awt(this.api.userTasks.getTask(id, signal));

            //Also update the object in the main list if it's present there
            this._tasks.l.tap(({data: tasks}) => {
                if (tasks.has(id)) {
                    tasks.set(id, task);
                }
            });

            return task;
        }, {keepStaleValue: true});
    }

    public getTaskDetails(id: string): Loadable<UserTask> {
        return this.taskDetails.get(id);
    }

    @action.bound
    public clearBatchDetails(...ids: string[]) {
        if (ids.length === 0) {
            this.taskDetails.clear();
        }
        else {
            ids.forEach(id => this.taskDetails.delete(id));
        }
    }
}
