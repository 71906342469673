import React, {ReactNode, useCallback, useId} from "react";

import {Menu as MuiMenu, MenuProps as MuiMenuProps} from "@mui/material";
import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";

import {Button, ButtonProps, IconButton, IconButtonProps} from "./button";
import {Icon} from "./icon";

//Omitting the props controlled by PopupState entirely for now. Can worry about wrapping them if I need them later.
export interface MenuProps<
    ButtonVariant extends ButtonProps["variant"] | "icon" = "icon"
> extends Omit<MuiMenuProps, keyof ReturnType<typeof bindMenu>> {
    readonly buttonVariant?: ButtonVariant;
    readonly label?: string;
    readonly icon?: ReactNode;
    readonly buttonProps?: Omit<
        ButtonVariant extends "icon" ? Omit<IconButtonProps, "title"> : ButtonProps,
        "variant" | "start" | keyof ReturnType<typeof bindTrigger>
    >;
    readonly renderTrigger?: (props: ReturnType<typeof bindTrigger>) => ReactNode;
    readonly keepOpenOnSelect?: boolean;
}

export function Menu<ButtonVariant extends ButtonProps["variant"] | "icon" = "icon">({
    label,
    buttonVariant = "icon",
    icon = buttonVariant === "icon" ? <Icon fa="ellipsis-v"/> : null,
    buttonProps,
    keepOpenOnSelect = false,
    onClick: onClickProp,
    renderTrigger,
    children,
    ...props
}: MenuProps<ButtonVariant>) {
    if (!renderTrigger && !label) throw new TypeError("label is required if not passing renderTrigger");

    const popupState = usePopupState({variant: "popover", popupId: useId()});

    const onMenuClick = useCallback(
        (ev: React.MouseEvent<HTMLDivElement>) => {
            onClickProp?.(ev);

            if (!ev.defaultPrevented && !keepOpenOnSelect && (ev.target as HTMLElement).closest(".MuiMenuItem-root")) {
                //Add a slight delay to let the click animation play
                setTimeout(() => popupState.close(), 250);
            }
        },
        //WTF? it doesn't like referencing popupState.close directly...
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [keepOpenOnSelect, onClickProp, popupState.close]
    );

    return <>
        {renderTrigger ?
            renderTrigger(bindTrigger(popupState))
        : buttonVariant === "icon" ?
            <IconButton title={label!} {...buttonProps} {...bindTrigger(popupState)}>{icon}</IconButton>
        :
            <Button
                variant={buttonVariant}
                {...buttonProps as any}
                {...bindTrigger(popupState)}
                start={icon}
            >
                {label}
            </Button>
        }
        <MuiMenu onClick={onMenuClick} {...props} {...bindMenu(popupState)}>
            {children}
        </MuiMenu>
    </>;
}
