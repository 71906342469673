import React, {ReactNode, useEffect, useId, useRef} from "react";

import {Box, Paper, Stack, Table, TableContainer, TableProps} from "@mui/material";

import {TablePagination, TablePaginationProps} from "../pagination";
import {LinearProgress} from "../progress";
import {PaginationState} from "~/api/pagination-envelope";
import {emphasize} from "~/utils/styles";
import {usePreviousValue} from "~/utils/use-previous-value";

export interface PaginatedTableProps extends TableProps {
    readonly elevation?: number;
    readonly paginationState: PaginationState;
    readonly loadingNextPage?: boolean;
    readonly onPageChange: (page: number, ev: React.MouseEvent<HTMLButtonElement> | null) => void;
    readonly onRowsPerPageChange?: (
        perPage: number,
        ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    readonly paginationProps?: Omit<
        TablePaginationProps,
        "count" | "onPageChange" | "page" | "rowsPerPage" | "onRowsPerPageChange"
    >;
    readonly renderExtraToolbarContents?: () => ReactNode;
}

/** Component implementing a common set of patterns and styles for applying pagination to a Table */
export function PaginatedTable({
    elevation = 1,
    paginationState,
    loadingNextPage,
    onPageChange,
    onRowsPerPageChange,
    paginationProps,
    sx = [],
    children,
    renderExtraToolbarContents,
    ...tableProps
}: PaginatedTableProps) {
    const container = useRef<HTMLDivElement>(null);

    //Reset the scroll position when the page changes
    const prevPage = usePreviousValue(paginationState.currentPage);
    useEffect(() => {
        if (prevPage && paginationState.currentPage !== prevPage) {
            container.current!.scrollTop = 0;
        }
    }, [container, paginationState.currentPage, prevPage]);

    const progressId = useId();

    const renderPagination = () => {
        const {totalItems, currentPage, perPage} = paginationState;
        return (
            <Box
                sx={{
                    position: "relative",

                    "&:first-child": {
                        //Keep the upper pagination from scrolling away to the left
                        position: "sticky",
                        left: 0,

                        "& .MuiTablePagination-root": {
                            px: 1,
                            borderBottom: 1,
                            borderColor: "divider"
                        }
                    },

                    "&:last-child .MuiTablePagination-root": {
                        px: 1,
                        borderTop: 1,
                        borderColor: "divider"
                    }
                }}
            >
                <TablePagination
                    count={totalItems}
                    onPageChange={onPageChange}
                    page={currentPage}
                    rowsPerPage={perPage}
                    onRowsPerPageChange={onRowsPerPageChange}
                    {...paginationProps}
                />
                {/*
                    The TablePagination component renders its own entire toolbar.
                    I'd like to position the extra contents in the left of that toolbar.
                    We can do this using absolute positioning.
                    The height of the toolbar, not including border, is 52px.
                 */}
                <Stack
                    direction="row"
                    gap={2}
                    alignItems="center"
                    position="absolute"
                    left={0}
                    top={0}
                    pl={2}
                    height="52px"
                >
                    {renderExtraToolbarContents?.()}
                </Stack>
            </Box>
        );
    };

    return (
        <Paper
            elevation={elevation}
            className="PcPaginatedTable-root"
            sx={[{
                position: "relative",
                maxHeight: 1,
                display: "grid",
                gridTemplateRows: "minmax(0, 1fr) auto",
                overflow: "hidden"
            }, sx].flat()}
        >
            {loadingNextPage &&
                <LinearProgress
                    id={progressId}
                    title="Loading new page..."
                    sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: 1,
                        background: "none"
                    }}
                />
            }
            <TableContainer ref={container}>
                {/*{renderPagination()}*/}
                <Table
                    stickyHeader
                    sx={{
                        "& .MuiTableHead-root .MuiTableCell-root": {
                            background: emphasize("background.default", 0.02),
                            boxShadow: 2
                        }
                    }}
                    {...tableProps}
                    aria-busy={loadingNextPage}
                    {...(loadingNextPage && {"aria-describedby": progressId})}
                >
                    {children}
                </Table>
            </TableContainer>
            {renderPagination()}
        </Paper>
    );
}
