import {isEqual} from "lodash";

/** The standard interface used by errors from the API */
export interface ErrorDto {
    readonly error: string;
}

export namespace ErrorDto {
    /** Returns true if the given value is an ErrorDto */
    export function isErrorDto(value: unknown): value is ErrorDto {
        return typeof value === "object"
            && value !== null
            && isEqual(Object.keys(value), ["error"])
            && typeof (value as any).error === "string";
    }
}
