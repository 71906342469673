import React from "react";

import {User} from "../user";
import {BaseFilterSetProps} from "~/components/filter-grid";
import {SelectFilter, TextFilter} from "~/components/filters";
import {Setters} from "~/utils/types";

export interface UserFilterState {
    readonly userRole: User.Role | undefined;
    readonly firstName: string | undefined;
    readonly lastName: string | undefined;
    readonly email: string | undefined;
}

export type UserFiltersProps<OmitFilters extends keyof UserFilterState = never> =
    BaseFilterSetProps<UserFilterState, OmitFilters>;

export function UserFilters<OmitFilters extends keyof UserFilterState = never>({
    omit: _omit = [],
    ..._filterProps
}: UserFiltersProps<OmitFilters>) {
    //These are impossible to use otherwise
    const omit = _omit as readonly (keyof UserFilterState)[];
    const filterProps = _filterProps as any as UserFilterState & Setters<UserFilterState>;

    return <>
        {!omit.includes("userRole") &&
            <SelectFilter
                filterKey="userRole"
                label="Role"
                options={User.ROLES}
                value={filterProps.userRole}
                onValueChange={filterProps.setUserRole}
            />
        }
        {!omit.includes("firstName") &&
            <TextFilter filterKey="firstName" value={filterProps.firstName} onValueChange={filterProps.setFirstName}/>
        }
        {!omit.includes("lastName") &&
            <TextFilter filterKey="lastName" value={filterProps.lastName} onValueChange={filterProps.setLastName}/>
        }
        {!omit.includes("email") &&
            <TextFilter filterKey="email" value={filterProps.email} onValueChange={filterProps.setEmail}/>
        }
    </>;
}
