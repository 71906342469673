import {getOrCompute} from "./maps";
import {Callable, Prefix, TupleN} from "./types";

type KeyboardEventHandler<TEl extends HTMLElement> = (ev: React.KeyboardEvent<TEl>) => void;

//key -> orig function -> wrapped function
const onKeyMemos = new Map<string, WeakMap<KeyboardEventHandler<any>, KeyboardEventHandler<any>>>();

/** Utility function for listening for a specific key */
export function onKey<TEl extends HTMLElement, THandler extends KeyboardEventHandler<TEl>>(
    key: string,
    handler: THandler
): THandler {
    const memo = getOrCompute(onKeyMemos, key, () => new WeakMap());
    return getOrCompute(memo, handler, () => (ev: React.KeyboardEvent<TEl>) => {
        if (ev.key !== key) return;
        handler(ev);
    }) as THandler;
}

//Structurally typed to be compatible with both Synthetic and native Events
type DefaultPreventable = {readonly preventDefault: () => void};
type PreventDefaultHandler<EV extends DefaultPreventable> = (ev: EV) => void;

const preventDefaultMemo = new WeakMap<PreventDefaultHandler<any>, PreventDefaultHandler<any>>();
const defaultPreventDefaultHandler = (ev: DefaultPreventable) => ev.preventDefault();

/** Utility function for calling preventDefault on an event */
export function preventDefault<EV extends DefaultPreventable = DefaultPreventable>(
    handler?: PreventDefaultHandler<EV>
): PreventDefaultHandler<EV> {
    if (!handler) return defaultPreventDefaultHandler;

    return getOrCompute(preventDefaultMemo, handler, () => (ev: EV) => {
        handler(ev);
        ev.preventDefault();
    });
}

const dropArgsMemo = new WeakMap<Callable, Map<number | undefined, Callable>>();

/**
 * Utility function for ignoring arguments passed to a function. Useful for event handlers in certain cases.
 *
 * @param f - The function to drop args to
 * @param n - The number of arguments to drop. By default drops all args.
 */
export function dropArgs<F extends N extends number ? Callable : () => any, N extends number | undefined = undefined>(
    f: F,
    n?: N
): N extends number ?
    (...args: [...TupleN<any, N>, ...Parameters<F>]) => ReturnType<F> :
    (...args: any[]) => ReturnType<F>
{
    const droppedMemo = getOrCompute(dropArgsMemo, f, () => new Map<number | undefined, Callable>());
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return getOrCompute(droppedMemo, n, () => n !== undefined ? (...args) => f(...args.slice(n)) : () => f()) as any;
}

const takeArgsMemo = new WeakMap<Callable, Map<number, Callable>>();

/**
 * Utility function for ignoring all but the first N arguments passed to a function.
 * Useful for event handlers in certain cases.
 *
 * @param f - The function to limit args to
 * @param n - The number of arguments to accept. Any subsequent arguments are ignored.
 */
export function takeArgs<F extends Callable, N extends number>(
    f: F,
    n: N
): (...args: Prefix<Parameters<F>, N>) => ReturnType<F> {
    const takeMemo = getOrCompute(takeArgsMemo, f, () => new Map<number, Callable>());
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return getOrCompute(takeMemo, n, () => (...args) => f(...args.slice(0, n)));
}
