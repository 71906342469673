import React from "react";

import {UserTask} from "../user-task";
import {BaseFilterSetProps} from "~/components/filter-grid";
import {BooleanFilter, SelectFilter, TextFilter} from "~/components/filters";
import {Setters} from "~/utils/types";

export interface UserTaskFilterState {
    readonly userId: string | undefined;
    readonly taskType: UserTask.Type | undefined;
    readonly taskState: UserTask.State | undefined;
    readonly includeUnassigned: boolean | undefined;
}

export type UserTaskFiltersProps<OmitFilters extends keyof UserTaskFilterState = never> =
    BaseFilterSetProps<UserTaskFilterState, OmitFilters>;

export function UserTaskFilters<OmitFilters extends keyof UserTaskFilterState = never>({
    omit: _omit = [],
    ..._filterProps
}: UserTaskFiltersProps<OmitFilters>) {
    //These are impossible to use otherwise
    const omit = _omit as readonly (keyof UserTaskFilterState)[];
    const filterProps = _filterProps as any as UserTaskFilterState & Setters<UserTaskFilterState>;

    return <>
        {!omit.includes("userId") &&
            <TextFilter
                filterKey="userId"
                label="User ID"
                value={filterProps.userId}
                onValueChange={filterProps.setUserId}
            />
        }
        {!omit.includes("taskType") &&
            <SelectFilter
                filterKey="type"
                options={UserTask.TYPES}
                value={filterProps.taskType}
                onValueChange={filterProps.setTaskType}
            />
        }
        {!omit.includes("taskState") &&
            <SelectFilter
                filterKey="state"
                options={UserTask.STATES}
                value={filterProps.taskState}
                onValueChange={filterProps.setTaskState}
            />
        }
        {!omit.includes("includeUnassigned") &&
            <BooleanFilter
                filterKey="includeUnassigned"
                value={filterProps.includeUnassigned}
                onValueChange={filterProps.setIncludeUnassigned}
            />
        }
    </>;
}
