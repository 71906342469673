import React from "react";

import {flowResult} from "mobx";
import {observer} from "mobx-react-lite";

import {getTaskDescription, UserTaskDisplay, UserTaskTitle} from "../components/user-task-display";
import {UserTaskStore} from "../store";
import {Page, PageWidth} from "~/components/page";
import {inject} from "~/utils/di";
import {useRequiredParams} from "~/utils/routing";
import {useAbortableEffect} from "~/utils/use-abortable-effect";

export const UserTaskDetailsPage = inject({userTaskStore: UserTaskStore}, observer(function UserTaskDetailsPage({
    userTaskStore
}: {userTaskStore: UserTaskStore}) {
    const {userTaskId} = useRequiredParams("userTaskId");

    const task = userTaskStore.getTaskDetails(userTaskId);

    useAbortableEffect(signal => [
        flowResult(userTaskStore.fetchTaskDetails(userTaskId, signal)),
        () => userTaskStore.clearBatchDetails(userTaskId)
    ], [userTaskId, userTaskStore]);

    return (
        <Page
            renderTitle={() => <UserTaskTitle taskId={userTaskId}/>}
            tabTitle={task.case({
                hasValue: task => `#${task.id} ${getTaskDescription(task)}`,
                else: () => `Task #${userTaskId}`
            })}
            width={PageWidth.MEDIUM}
        >
            <UserTaskDisplay taskId={userTaskId}/>
        </Page>
    );
}));
