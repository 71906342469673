import {wfGet} from "~/utils/wf-fetch";
import {constructArrayDto} from "./util";
import {TransferAmountStat} from "~/transfer/transfer-stats";

const basePath = `${process.env.API_BASE}/transfer_stats`;
const staleTime = 3 * 60 * 1000; // 3 minutes

const TransferStats = {
  amounts: (params: any) => ({
    queryKey: ["transfer_stats", "amounts", params],
    queryFn: () => wfGet<TransferAmountStat[]>(`${basePath}/amounts`, params, constructArrayDto(TransferAmountStat)),
    keepPreviousData: true,
    staleTime: staleTime
  })
};

export default TransferStats;
