import {Grid, Typography} from "@mui/material";
import {chunk} from "lodash";
import React, {ReactNode} from "react";

const DetailGrid = ({children}: {children: ReactNode}) => {
  const data = chunk(React.Children.map(children, child => child), 2);
  return (
    <Grid container spacing={1} width="100%">
      {data.map(([name, value], idx) =>
        <Grid item sm={12} md={6} lg={4} key={idx}>
          <Grid container spacing={2}>
            <Grid item width="30%">
              {name}
            </Grid>
            <Grid item width="70%">{value}</Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DetailGrid;
