import {useRef, useState} from "react";

import {bindAll} from "lodash";

import {getInitialValue, Initializer} from "./hook-utils";

/** A subclass of Map that accepts a notify function for use with React change detection */
export class HookedMap<K, V> extends Map<K, V> {
    //Return regular Maps for derived collections
    public override get [Symbol.species]() { return Map; }

    public constructor(private readonly notify: () => void, data?: Iterable<readonly [K, V]> | null | undefined) {
        super(data);
        bindAll(this, "set", "clear", "delete", "get", "has");
    }

    public override set(key: K, value: V): this {
        const hadValue = this.has(key);
        const origValue = this.get(key);

        super.set(key, value);

        if (!hadValue || value !== origValue) {
            //notify might not be defined yet if we're here from the constructor
            //eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            this.notify?.();
        }

        return this;
    }

    public override delete(key: K): boolean {
        const deleted = super.delete(key);

        if (deleted) {
            this.notify();
        }

        return deleted;
    }

    public override clear(): void {
        const wasEmpty = this.size > 0;

        super.clear();

        if (!wasEmpty) {
            this.notify();
        }
    }
}

/**
 * Allows efficiently using a Map in functional components
 *
 * @param init - Initial data for the Map, or a function returning initial data. Can be null or undefined.
 *
 * @returns
 * A Map that will cause a re-render when modified.
 * The methods "set", "clear", "delete", "get", and "has" are automatically bound.
 * The Map itself is a stable reference.
 * If you need to propagate re-renders from this Map to memoized components, you can pass map.atom as a dependency.
 */
export function useMap<K, V>(
    init?: Initializer<Iterable<readonly [K, V]> | null | undefined>
): Map<K, V> & {readonly atom: symbol} {
    //Change a symbol reference to notify React
    const [atom, setAtom] = useState(Symbol());
    const ref = useRef<Map<K, V> & {atom: symbol} | null>(null);
    ref.current ??= Object.assign(new HookedMap(() => setAtom(Symbol()), getInitialValue(init)), {atom});
    ref.current.atom = atom;
    return ref.current;
}
