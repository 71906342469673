import React, {useCallback, useMemo} from "react";

import {Autocomplete} from "../autocomplete";
import {useFilterGridContext} from "../filter-grid";
import {ListItemText} from "../list";
import {TextField} from "../text-field";
import {memoGeneric} from "~/utils/memo-generic";
import {Sequence} from "~/utils/sequence";
import {useChainRef} from "~/utils/use-chain-ref";

type Option<T extends string> = T | {readonly label: string, readonly value: T};

const getOptionValue = <T extends string>(opt: Option<T>) => typeof opt === "string" ? opt : opt.value;

const getOptionLabel = <T extends string>(opt: Option<T>) => typeof opt === "string" ? opt : opt.label;

export interface MultiSelectFilterProps<T extends string> {
    readonly filterKey: string;
    readonly label?: string;
    readonly inputLabel?: string;
    readonly options: readonly Option<T>[];
    readonly value: readonly T[] | undefined;
    readonly onValueChange: (value: readonly T[] | undefined) => void;
}

export const MultiSelectFilter = memoGeneric(function MultiSelectFilter<T extends string>({
    filterKey,
    label,
    inputLabel = "Values",
    options,
    value,
    onValueChange
}: MultiSelectFilterProps<T>) {
    const {GridFilter} = useFilterGridContext();

    //I don't need this ref directly, but I need the ChainRef to compose two passed-in refs
    const inputRef = useChainRef<HTMLInputElement>(null);

    const valueToOption = useMemo(() => Sequence.from(options).keyBy(getOptionValue).collectToMap(), [options]);
    const valueToLabel = useMemo(() =>
        Sequence.from(options)
            .map(opt => [getOptionValue(opt), getOptionLabel(opt)] as const)
            .collectToMap(),
        [options]
    );

    const getSummary = useCallback((label: string) => {
        if (value!.length === 0) return null;

        const valueLabel = value!.map(val => valueToLabel.get(val)).join(", ");
        return `${label}: ${valueLabel}`;
    }, [value, valueToLabel]);

    const onSelect = useCallback(
        (ev: React.SyntheticEvent, selection: readonly Option<T>[]) =>
            onValueChange(selection.map(getOptionValue)),
        [onValueChange]
    );

    const onRemove = useCallback(() => onValueChange(undefined), [onValueChange]);

    const autocompleteValue: Option<T>[] = useMemo(
        () => value?.map(x => valueToOption.get(x)!) ?? [],
        [value, valueToOption]
    );

    return (
        <GridFilter
            filterKey={filterKey}
            label={label}
            hasValue={value !== undefined}
            getSummary={getSummary}
            onRemove={onRemove}
        >{(focusRef, renderBaseFilterUi) =>
            renderBaseFilterUi(<>
                <ListItemText sx={{flex: "none"}}>∈</ListItemText>
                <Autocomplete
                    multiple
                    size="small"
                    sx={{flex: 1, display: "inline-flex"}}
                    options={options}
                    value={autocompleteValue}
                    onChange={onSelect}
                    renderInput={props =>
                        <TextField
                            {...props}
                            variant="standard"
                            label={inputLabel}
                            inputRef={
                                inputRef
                                    .addChildRef("focusRef", focusRef as any)
                                    .addChildRef("baseRef", props.inputRef)
                            }
                        />
                    }
                />
            </>)
        }</GridFilter>
    );
});
