import {experimental_sx as sx} from "@mui/material";

import {accordionOverrides, accordionSummaryOverrides} from "./accordion";
import {alertOverrides} from "./alert";
import {buttonOverrides, loadingButtonOverrides} from "./button";
import {dialogOverrides} from "./dialog";
import {menuItemOverrides} from "./menu-item";
import {ComponentOverrides} from "./mui-theme-utils";
import {tableOverrides} from "./table";
import {transitionOverrides} from "./transitions";

/** The set of component overrides to apply to the MUI theme */
export const componentOverrides: ComponentOverrides = {
    ...accordionOverrides,
    ...accordionSummaryOverrides,
    ...alertOverrides,
    ...buttonOverrides,
    ...dialogOverrides,
    ...loadingButtonOverrides,
    ...menuItemOverrides,
    ...tableOverrides,
    ...transitionOverrides,

    MuiPaper: {
        styleOverrides: {
            root: sx({
                overflow: "hidden"
            })
        }
    }
};
