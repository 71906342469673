import React, {ForwardedRef, Ref, useRef} from "react";

import {Autocomplete as MuiAutocomplete, AutocompleteProps, Box} from "@mui/material";

import {forwardRefGeneric} from "~/utils/forward-ref-generic";

export {AutocompleteProps};

declare module "@mui/material" {
    //Using interface augmentation here rather than wrapping the props interface
    //because wrapping the props interface breaks type inference for the generic component for some unknown reason
    interface AutocompleteRenderInputParams {
        readonly inputRef: Ref<HTMLInputElement>;
    }
}

export const Autocomplete = forwardRefGeneric(function Autocomplete<
    T,
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
>({
    getOptionLabel = (option: any) => option.label ?? option, //eslint-disable-line @typescript-eslint/no-unsafe-return
    renderInput,
    renderOption,
    noOptionsText = "No options",
    ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, ref: ForwardedRef<HTMLElement>) {
    const input = useRef<HTMLInputElement>(null);

    //The goal here is to allow for the options menu to inherit the font size of the input.
    //The problem is that the options are rendered via portal and so can't inherit via CSS.
    //The mildly hacky solution is: get a ref to the input and then get its computed font size.
    //This should be vaguely safe as the options list can't be summoned if the input hasn't been rendered.
    const getFontSize = () => window.getComputedStyle(input.current!).fontSize;

    return <MuiAutocomplete
        ref={ref}
        {...props}
        getOptionLabel={getOptionLabel}
        renderInput={props =>
            renderInput({...props, inputRef: input})
        }
        renderOption={(props, option, state) =>
            renderOption ?
                <Box display="contents" fontSize={getFontSize()}>
                    {renderOption(props, option, state)}
                </Box>
            :
                <Box component="li" {...props} sx={{fontSize: getFontSize()}}>{getOptionLabel(option)}</Box>
        }
        noOptionsText={
            <Box display="contents" fontSize={getFontSize}> {/* Passing getFontSize as a function to make it lazy */}
                {noOptionsText}
            </Box>
        }
    />;
});
