import React from "react";

import {Box, SxProps, Theme} from "@mui/material";
import classNames from "classnames";

export const Icon = ({fa, sx = [], className}: {fa: string, sx?: SxProps<Theme>, className?: string}) =>
    <Box
        component="i"
        className={classNames(`PcIcon-root fa fa-${fa}`, className)}
        sx={[{fontStyle: "normal", width: "1em", textAlign: "center"}, sx].flat()}
        aria-hidden="true"
    />;
