import {autobindAll} from "~/utils/autobind";
import {BaseApi} from "~/api/base-api";
import {FetchClient} from "~/api/fetch-client";
import {PageParams, RangeParams, SortParams} from "~/api/common-query-params";
import {PaginationEnvelope} from "~/api/pagination-envelope";
import LineItem from "~/line-items/line-item";
import {BigNumber} from "bignumber.js";

export const LINE_ITEM_SORT_KEY = [{key: "createdAt"}, {key: "amount"}] as const;

export type LineItemSortKey = (typeof LINE_ITEM_SORT_KEY)[number]["key"];

export type LineItemsFilterParams = {
    readonly description?: string | undefined,
    readonly reference?: string | undefined
} & RangeParams<BigNumber, "amount"> & RangeParams<string, "date">;

export interface VitesseStripePayoutReconDto {
    readonly type: "vitesse_stripe_payout_recon";
    readonly lineItemId: number;
    readonly stripePayoutId: string;
}

export interface VitesseWireInReconDto {
    readonly type: "vitesse_wire_in_recon";
    readonly lineItemId: number;
    readonly transferId: number;
    readonly adjust: boolean;
}

type ReconDto = VitesseStripePayoutReconDto | VitesseWireInReconDto;

@autobindAll
export class LineItemApi extends BaseApi<LineItem> {
    public constructor(fetchClient: FetchClient, errorHandler: (err: Error) => boolean) {
        super(fetchClient, errorHandler, "/line_items", LineItem);
    }

    public async reconcile(backendId: string, recon: ReconDto, signal?: AbortSignal): Promise<void> {
        return await this.fetchVoid("POST", backendId, {signal, json: recon});
    }

    public async getLineItem(backendId: string, lineItemId: string, signal?: AbortSignal): Promise<LineItem> {
        return await this.get(lineItemId, signal, (id) => `${backendId}/line/${id}`);
    }

    public async getLineItems(backendId: string, filters?: PageParams & SortParams<LineItemSortKey> & LineItemsFilterParams | undefined, signal?: AbortSignal): Promise<PaginationEnvelope<LineItem[]>> {
        return await this.find(filters, signal, backendId);
    }

}
