import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {Temporal} from "@js-temporal/polyfill";

export class Entity extends JsonDto {
    public static readonly TYPES = ["company", "fundraise", "investor", "investment"] as const;

    @jsonProp(JsonTypes.id)
    public readonly internalId!: string;

    @jsonProp(JsonTypes.id)
    public readonly externalId!: string;

    //TODO: Lock this down better
    @jsonProp(JsonTypes.any)
    public readonly data!: any;

    @jsonProp(JsonTypes.stringUnion(Entity.TYPES))
    public readonly type!: Entity.Type;

    @jsonProp()
    public readonly createdAt!: Temporal.Instant;

    @jsonProp(JsonTypes.array(JsonTypes.dto(Entity)), {optional: true})
    public readonly investments!: readonly Entity[] | null;

    @jsonProp(JsonTypes.array(JsonTypes.dto(Entity)), {optional: true})
    public readonly fundraises!: readonly Entity[] | null;

    @jsonProp(JsonTypes.array(JsonTypes.dto(Entity)), {optional: true})
    public readonly companies!: readonly Entity[] | null;

    @jsonProp(JsonTypes.array(JsonTypes.dto(Entity)), {optional: true})
    public readonly investors!: readonly Entity[] | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly investment!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly fundraise!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly company!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly investor!: Entity | null;

    public get wefunderUrl(): string {
        switch (this.type) {
            case "company":
                //eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return this.data.company_url;
            case "fundraise":
                return `${process.env.WEFUNDER_URL}/admin/fundraises/${this.externalId}`;
            case "investment":
                return `${process.env.WEFUNDER_URL}/admin/investments/${this.externalId}`;
            case "investor":
                return `${process.env.WEFUNDER_URL}/${this.data.username}`;
        }
    }

    public constructor(init?: unknown) {
        super();
        this.init(Entity, init);
    }
}

export namespace Entity {
    export type Type = (typeof Entity.TYPES)[number];
}
