import React, {
    ComponentClass,
    createContext,
    ForwardedRef,
    forwardRef,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState
} from "react";

import {fastTreeItem, fastTreeView, TreeItem, TreeView} from "@microsoft/fast-components";

import {BaseWrappedProps, wrap} from "./wrapper";
import {LazyJsx, renderLazy} from "~/utils/lazy-jsx";
import {useForceRender} from "~/utils/use-force-render";

interface TreeItemContext {
    renderContent: boolean;
    eager: boolean;
    nested: boolean;
}

const treeItemContext = createContext<TreeItemContext>({renderContent: true, eager: false, nested: false});

export type FastTreeView = TreeView;

type RawTreeViewProps = BaseWrappedProps<FastTreeView>;
const RawTreeView = wrap(fastTreeView());
RawTreeView.displayName = "RawTreeView";

export interface FastTreeViewProps extends RawTreeViewProps {
    readonly children: ReactNode;
}

export const FastTreeView = forwardRef(function FastTreeView({
    renderCollapsedNodes = false,
    children,
    ...rest
}: FastTreeViewProps, ref: ForwardedRef<FastTreeView>) {
    const itemContext = useMemo<TreeItemContext>(
        () => ({renderContent: true, eager: renderCollapsedNodes, nested: false}),
        [renderCollapsedNodes]
    );

    return (
        //I think this is a bug with the React wrapper:
        //the ref type shows as the React wrapper component when it's really forwarded to the real custom element
        <RawTreeView ref={ref as any} renderCollapsedNodes={renderCollapsedNodes} {...rest}>
            <treeItemContext.Provider value={itemContext}>
                {children}
            </treeItemContext.Provider>
        </RawTreeView>
    );
});

export type FastTreeItem = TreeItem;

interface RawTreeItemProps extends BaseWrappedProps<FastTreeItem, keyof typeof treeItemEvents> {
    readonly children?: ReactNode;
}

const treeItemEvents = {
    onExpandedChange: "expanded-change",
    onSelectedChange: "selected-change"
} as const;

//It seems https://github.com/microsoft/fast/issues/5387 is still happening for this component.
//Using a typecast to work around.
const RawTreeItem = wrap(fastTreeItem(), {events: treeItemEvents}) as ComponentClass<RawTreeItemProps>;
RawTreeItem.displayName = "RawTreeItem";

export interface FastTreeItemProps extends Omit<RawTreeItemProps, "items"> {
    readonly content?: LazyJsx;
    readonly items?: LazyJsx;
    readonly beforeContent?: ReactNode;
    readonly afterContent?: ReactNode;
    readonly expandCollapseGlyph?: ReactNode;
}
export const FastTreeItem = forwardRef(function FastTreeItem({
    selected: selectedProp,
    onSelectedChange: onSelectedChangeProp,
    expanded: expandedProp,
    onExpandedChange: onExpandedChangeProp,
    content,
    items,
    beforeContent,
    afterContent,
    expandCollapseGlyph,
    ...rest
}: FastTreeItemProps, ref: ForwardedRef<FastTreeItem>) {
    const ctx = useContext(treeItemContext);

    const expandedControlled = expandedProp !== undefined;
    const selectedControlled = selectedProp !== undefined;

    //Make sure controlled mode actually is fully controlled by forcing a re-render on changes
    const forceRender = useForceRender();

    const _onSelectedChange = useCallback((ev: Event) => {
        onSelectedChangeProp?.(ev);
        forceRender();
    }, [forceRender, onSelectedChangeProp]);
    const onSelectedChange = selectedControlled ? _onSelectedChange : onSelectedChangeProp;

    //If the component is being used in uncontrolled mode, we need to track the expanded state ourselves
    const [_expanded, setExpanded] = useState(expandedProp ?? false);
    const expanded = expandedProp ?? _expanded;

    const onExpandedChange = useCallback((ev: Event) => {
        onExpandedChangeProp?.(ev);

        if (expandedControlled) {
            forceRender();
        }
        else {
            setExpanded((ev.currentTarget as FastTreeItem).expanded);
        }
    }, [expandedControlled, onExpandedChangeProp, forceRender]);

    const childCtx = useMemo<TreeItemContext>(() => ({
        ...ctx,
        renderContent: ctx.renderContent && (ctx.eager || expanded),
        nested: true
    }), [ctx, expanded]);

    //The `slot` attribute needs to be directly on the the tree-item elements,
    //so instead of a `display: contents` div, use a context property to conditionally add the attribute
    return (
        <RawTreeItem
            ref={ref as any}
            {...(expandedControlled && {expanded})}
            {...(selectedControlled && {selected: selectedProp})}
            onSelectedChange={onSelectedChange}
            onExpandedChange={onExpandedChange}
            {...(ctx.nested && {slot: "item"})}
            {...rest}
        >
            {ctx.renderContent && <>
                {renderLazy(content)}
                <treeItemContext.Provider value={childCtx}>{renderLazy(items)}</treeItemContext.Provider>
            </>}
            {beforeContent && <div slot="before-content" style={{display: "contents"}}>{beforeContent}</div>}
            {afterContent && <div slot="after-content" style={{display: "contents"}}>{afterContent}</div>}
            {expandCollapseGlyph &&
                <div slot="expand-collapse-glyph" style={{display: "contents"}}>{expandCollapseGlyph}</div>
            }
        </RawTreeItem>
    );
});
