import React, {ReactNode, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useLocation, useMatch, useNavigate} from "react-router-dom";

import {LinearProgress, Paper, Stack, SxProps, Theme} from "@mui/material";
import {observer} from "mobx-react-lite";
import {MarkRequired} from "ts-essentials";

import {IconButton} from "./components/button";
import {Icon} from "./components/icon";
import {ButtonLink} from "./components/link";
import {TextField, textFieldHeight} from "./components/text-field";
import {Collapse, ComposeTransitions, Fade} from "./components/transitions";
import {TreeItem, TreeView} from "./components/tree-view";
import {Typography} from "./components/typography";
import {UserStore} from "./user/store";
import {inject} from "./utils/di";
import {onKey} from "./utils/event-helpers";
import {useLoadedReaction} from "./utils/loadable/reactions";
import {transition} from "./utils/styles";
import {useInputState} from "./utils/use-input-state";
import {UpdateFn} from "./utils/hook-utils";
import {EscrowListItem} from "./escrow/escrow";
import {useQuery} from "react-query";
import Escrow from "./repositories/escrow";

interface NavSidebarState {
    readonly collapsed: boolean;
    readonly toggleCollapsed: UpdateFn<void>;
    readonly setCollapsed: UpdateFn<boolean>;
}

export const NavSidebarStateContext = React.createContext<NavSidebarState>({collapsed: false, toggleCollapsed: () => {}, setCollapsed: (_) => {}});

export const NavSidebarStateProvider = ({children}: {children: ReactNode}) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const props = useMemo(() => ({collapsed: collapsed, toggleCollapsed: () => setCollapsed(!collapsed), setCollapsed: setCollapsed}), [collapsed, setCollapsed]);
    return (
        <NavSidebarStateContext.Provider value={props}>
            {children}
        </NavSidebarStateContext.Provider>
    );
};

interface NavItemProps {
    readonly userStore?: UserStore;
    readonly label: string;
    readonly to?: string;
    //I really hate this solution, but I can't think of anything better.
    //Fundamentally, because of how React Router works, there's no good way for the nav sidebar
    //to know what route has really been matched and what its params are.
    //This leads to false positives with paths like /widgets/create which look like /widgets/:id.
    //The only thing I could come up with was to maintain a literal array of these special cases.
    readonly excludePaths?: readonly string[];
    readonly adminOnly?: boolean;
    readonly children?: ReactNode;
}

const NavItem = inject({userStore: UserStore}, observer(function NavItem({
    userStore,
    label,
    to,
    excludePaths,
    adminOnly = false,
    children
}: MarkRequired<NavItemProps, "userStore">) {
    const location = useLocation();
    const navigate = useNavigate();

    const currentUser = userStore.currentUser.getValue();

    const dynamic = to?.includes(":");

    //Only supporting a single dynamic segment. I don't think more is practical.
    const [, dynamicParam] = to && /:([^/]+)/u.exec(to) || [];
    const match = useMatch(to ?? "");

    const [dynamicId, onDynamicIdInput, setDynamicId] = useInputState("");

    //On each page navigation, populate or clear the input
    useEffect(() => {
        const paramVal = match?.params[dynamicParam] ?? "";
        setDynamicId((excludePaths ?? []).includes(paramVal) ? "" : paramVal);
    }, [dynamicParam, match, setDynamicId, excludePaths, location.pathname]);

    const subbedTo = to?.replace(`:${dynamicParam}`, dynamicId);

    const goToDynamicRoute = useCallback(() => {
        if (!dynamicId) return;
        navigate(subbedTo!);
    }, [navigate, subbedTo, dynamicId]);

    const [expanded, setExpanded] = useState(true); //Default everything to expanded until things get crowded

    const isActive = !!match
        && (!dynamicParam || match.params[dynamicParam] === dynamicId && (!(excludePaths ?? []).includes(dynamicId)));

    if (adminOnly && currentUser.role !== "admin") return null;

    return (
        <TreeItem
            sx={{
                cursor: "default",
                "&::part(positioning-region)": {
                    height: (t: Theme) => `calc(${t.typography.body1.lineHeight}rem * 1.8)`,
                    alignItems: "center"
                }
            }}
            expanded={expanded}
            onExpandedChange={setExpanded}
            selected={isActive} //Use selection styling for active links
            content={
                !to ?
                    <Typography variant="button" sx={{textTransform: "none", p: 1}}>{label}</Typography>
                : dynamic ?
                    <Stack direction="row" alignItems="center" width={1} height={1}>
                        <Typography
                            component={TextField}
                            variant="button"
                            sx={{...textFieldHeight("calc(100% - 12px)"), mr: 1, flex: 1, textTransform: "none"}}
                            InputProps={{sx: {font: "inherit"}}}
                            InputLabelProps={{
                                sx: [
                                    //Keep the label's normal font weight
                                    {fontSize: "inherit", lineHeight: "inherit"},
                                    isActive && {color: "primary.light"}
                                ]
                            }}
                            label={label}
                            value={dynamicId}
                            onInput={onDynamicIdInput}
                            onKeyDown={onKey("Enter", goToDynamicRoute)}
                        />
                        <ComposeTransitions
                            transitions={[<Collapse orientation="horizontal"/>, <Fade/>]}
                            in={!!dynamicId}
                        >
                            <IconButton
                                size="small"
                                sx={{mr: 1}}
                                title="Go"
                                disabled={!dynamicId}
                                onClick={goToDynamicRoute}
                            >
                                <Icon fa="arrow-right"/>
                            </IconButton>
                        </ComposeTransitions>
                    </Stack>
                :
                    <ButtonLink
                        nav
                        variant="text"
                        sx={{
                            width: 1,
                            justifyContent: "flex-start",
                            color: "text.primary",
                            textTransform: "none",

                            "&:hover, &[aria-current='page']": {
                                background: "transparent",
                                color: "primary.light"
                            }
                        }}
                        to={to}
                    >
                        {label}
                    </ButtonLink>
            }
            items={children}
        />
    );
}));

export interface NavSidebarProps {
    readonly userStore?: UserStore;
    readonly sx?: SxProps<Theme>;
}

export const NavSidebar = inject({userStore: UserStore}, observer(function NavSidebar({
    userStore,
    sx = []
}: MarkRequired<NavSidebarProps, "userStore">) {
    const {currentUser} = userStore;
    const signedIn = currentUser.isLoaded();
    const escrowQuery = useQuery(Escrow.list);
    const renderLoading = () => <LinearProgress sx={{width: "100%", m: "auto"}}/>;

    //To avoid showing a slide animation on first page load,
    //don't render anything until the authentication has initially loaded
    const [render, setRender] = useState(false);
    useLoadedReaction(currentUser, () => setRender(true), [setRender]);

    const {collapsed, toggleCollapsed} = useContext(NavSidebarStateContext);
    // const [collapsed, toggleCollapsed] = useToggle(false);

    //I can't get the MUI transitions to do quite what I need here, so I'm rolling my own

    const sidebarWidth = "min(20vw, 450px)";
    const collapseButtonWidth = "44px"; //I just got this from the dev tools, not sure how else to know it

    if (!render) return null;
    else return (
        <Paper
            component="nav"
            elevation={5}
            square
            sx={[
                {
                    width: sidebarWidth,
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    position: "relative",
                    ...transition(["width", "standard", "easeInOut"])
                },
                !signedIn && {
                    width: 0,
                    //Hide the shadow when the sidebar isn't visible
                    boxShadow: 0
                },
                collapsed && {
                    width: collapseButtonWidth
                },
                sx
            ].flat()}
        >
            <IconButton
                size="small"
                sx={{m: 1}}
                title={`${collapsed ? "Expand" : "Collapse"} Navigation`}
                onClick={() => toggleCollapsed()}
            >
                {collapsed ? <Icon fa="angle-right"/> : <Icon fa="angle-left"/>}
            </IconButton>
            <TreeView
                id="nav"
                preventSelection
                renderCollapsedNodes
                sx={[
                    {
                        //Hard-code to the width of the sidebar to avoid text wrapping during the slide animation
                        width: sidebarWidth,

                        //Slide and fade the TreeView, leaving the collapse button.
                        //Use a translateX to prevent accidental clicks on the collapsed nav tree
                        //while still keeping everything accessible to screen readers.
                        ...transition([["opacity", "transform"], "100ms", "linear"])
                    },
                    (collapsed || !signedIn) && {
                        transform: `translateX(-${collapseButtonWidth})`,
                        opacity: 0
                    },
                    {
                        "& fast-tree-item::part(positioning-region)": {
                            //Background transition that matches the buttons
                            ...transition(["background", "short", "easeInOut"])
                        }
                    }
                ]}
            >
                {signedIn && <>
                    <NavItem label="Dashboard" to="/"/>
                    {escrowQuery.isLoading && renderLoading()}
                    {escrowQuery.isError && <Typography color="error">{(escrowQuery.error as any).message}</Typography>}
                    {escrowQuery.isSuccess &&
                        <NavItem label="Escrow" to="/escrow">
                            {escrowQuery.data.data.map(account => (
                                <NavItem key={account.backendId} label={account.name} to={`/escrow/${account.backendId}`}/>
                            ))}
                        </NavItem>
                    }

                    <NavItem label="Virtual Accounts" to="/accounts">
                        <NavItem label="Account ID" to="/accounts/:accountId" excludePaths={["create"]}/>
                    </NavItem>
                    <NavItem label="Transfers" to="/transfers">
                        <NavItem label="Initiate Transfer" to="/transfers/create"/>
                        <NavItem label="Transfer ID" to="/transfers/:transferId" excludePaths={["create"]}/>
                    </NavItem>
                    <NavItem label="Reconciliation" to="/recon"/>
                    <NavItem label="Disbursements" to="/disbursements"/>
                    <NavItem label="ACH Batches" to="/ach-batches"/>
                    <NavItem label="Tasks" to="/tasks"/>
                    <NavItem label="Users" to="/users" adminOnly>
                        <NavItem label="User ID" to="/users/:userId"/>
                    </NavItem>
                </>}
            </TreeView>
        </Paper>
    );
}));
