import React, {ForwardedRef, forwardRef, startTransition, useCallback, useLayoutEffect, useRef} from "react";

import {SxProps, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, Theme} from "@mui/material";

import {SxSize, transition} from "~/utils/styles";
import {useChainRef} from "~/utils/use-chain-ref";

//Due to the floating label effect, setting the height of the MUI TextField is a bit... tricky.
//So a style mixin is useful for doing it concisely.

/**
 * SX mixin for setting the height of a TextField
 *
 * @param height
 * The height to set the text field to.
 *
 * @returns An object that can be mixed into SX styles
 */
export const textFieldHeight = (height: SxSize): SxProps<Theme> => ({
    height,

    "& .MuiInputBase-root": {
        height: 1
    },

    "& label": {
        //The default height of the TextField component is 56px.
        //To keep the label centered in its non-shrunken state,
        //we need to balance the position out based on half of the difference
        top: "calc(-0.5 * (56px - 100%))",

        //Good lord I wish CSS transitions composed...
        //This is the default transition setup, with an additional transition applied to `top`
        //in order to smooth out the jump in position when focusing the input
        ...transition([["color", "transform", "max-width", "top"], "shorter", "easeOut"]),

        "&[data-shrink='true']": {
            top: 0
        }
    }
});

export type TextFieldProps = MuiTextFieldProps & {
    readonly semiControlled?: boolean
};

export const TextField = forwardRef(function TextField({
    select,
    SelectProps,
    semiControlled = false,
    value,
    onChange: onChangeProp,
    onInput: onInputProp,
    inputRef: inputRefProp,
    ...props
}: TextFieldProps, ref: ForwardedRef<HTMLDivElement>) {
    const root = useChainRef<HTMLDivElement>(null, [["ref", ref]]);
    const inputRef = useChainRef<{value: string}>(null, [["inputRefProp", inputRefProp]]);
    const lastSetValue = useRef(value);

    //The goal here is to allow for the Select options menu to inherit the font size of the input.
    //The problem is that the options are rendered via portal and so can't inherit via CSS.
    //The mildly hacky solution is: get a ref to the input root container and then get its computed font size.
    //This should be vaguely safe as the options list can't be summoned if the input hasn't been rendered.
    const getFontSize = () => window.getComputedStyle(root.current!).fontSize;

    const onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        if (semiControlled) {
            startTransition(() => {
                //onChange and onInput are the same thing in React, so need to try both
                onChangeProp?.(ev);
                onInputProp?.(ev);
                lastSetValue.current = ev.target.value;
            });
        }
        else {
            onChangeProp?.(ev);
            onInputProp?.(ev);
        }
    }, [onChangeProp, onInputProp, semiControlled]);

    useLayoutEffect(() => {
        if (semiControlled && value !== lastSetValue.current) {
            inputRef.current!.value = value as string;
            lastSetValue.current = value;
        }
    }, [inputRef, semiControlled, value]);

    return <MuiTextField
        ref={root}
        inputRef={inputRef}
        select={select}
        {...(semiControlled ? {defaultValue: value} : {value})}
        onChange={onChange}
        SelectProps={{
            ...SelectProps,
            MenuProps: {
                ...SelectProps?.MenuProps,

                //MenuList is the outermost component that doesn't get rendered until the menu is opened.
                //The MenuItem component is overridden separately to default to fontSize: "inherit".
                MenuListProps: {
                    ...SelectProps?.MenuProps?.MenuListProps,
                    sx: [
                        {
                            fontSize: getFontSize //Pass getFontSize as a function to make it lazy
                        },
                        SelectProps?.MenuProps?.MenuListProps?.sx ?? []
                    ].flat()
                }
            }
        }}
        {...props}
    />;
});
