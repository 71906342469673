import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";

import {flowResult} from "mobx";
import {observer} from "mobx-react-lite";

import {AuthStore} from "../store";
import {Form} from "~/components/form";
import {Page, PageWidth} from "~/components/page";
import {TextField} from "~/components/text-field";
import {UnauthorizedError} from "~/errors";
import {inject} from "~/utils/di";
import {preventDefault} from "~/utils/event-helpers";
import {useInputState} from "~/utils/use-input-state";
import {useStateObject} from "~/utils/use-state-object";

/** The sign in screen for the application */
export const SignIn = inject({authStore: AuthStore}, observer(function SignIn({authStore}: {authStore: AuthStore}) {
    const {authentication, logOutStatus, logOutCausedByError} = authStore;

    const navigate = useNavigate();

    const [creds, setCreds] = useStateObject({
        email: useInputState(""),
        password: useInputState("")
    });

    const signIn = useCallback(async () => {
        authStore.resetLogOutStatus();
        const signInResponse = await flowResult(authStore.signIn(creds));
        switch (signInResponse?.type) {
            case "auth_challenge_required":
                navigate("/signin/challenge");
                break;
            case "auth_challenge_setup_required":
                navigate("/register-totp");
                break;
        }
    }, [authStore, creds, navigate]);

    return (
        <Page title="Sign In" width={PageWidth.NARROW}>
            <Form
                onSubmit={preventDefault(signIn)}
                addErrors={[
                    authentication.hasError() && `Error logging in: ${authentication.errorMessage}`,
                    logOutCausedByError && (
                        logOutCausedByError.error instanceof UnauthorizedError ?
                            "Your session has expired, please log in again" :
                            `You were logged out automatically by an error: ${logOutCausedByError.errorMessage}`
                    ),
                    logOutStatus.hasError() && `There was an error logging you out: ${logOutStatus.errorMessage}`
                ]}
            >
                <TextField
                    type="email"
                    autoComplete="email"
                    label="Email"
                    required
                    value={creds.email}
                    onInput={setCreds.email}
                />
                <TextField
                    type="password"
                    autoComplete="current-password"
                    label="Password"
                    required
                    value={creds.password}
                    onInput={setCreds.password}
                />
            </Form>
        </Page>
    );
}));
