import {BigNumber} from "bignumber.js";
import {action, makeObservable, observable} from "mobx";

import {TransferMetadata} from "./transfer-metadata";
import {VirtualAccount} from "~/account/virtual-account";
import {Entity} from "~/entity/entity";
import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {TransferEvent} from "~/transfer/transfer-event";
import {TransferIntentions, TransferStates, TransferTags} from "~/transfer/index";
import {CURRENCIES, Currency} from "~/currency";
import Recon from "~/recon/recon";
import {LineItem} from "~/recon/line-item";

//This is the first DTO where there's a real need to update it in-place.
//The approach I'm taking to observability is:
//Mark properties that could reasonably change with @observable annotations but leave them readonly.
//Allow updating through a bespoke `update` method which accepts a partial of just observable properties.
//Since readonly is compile-time-only, we can use Object.assign to make the updates
//without adding lots of noise to the property declarations.
//Local types can be used to keep these method definitions DRY with subclassing.
//I'm not marking arrays/collections as observable until there's a specific need to do otherwise.

export class Transfer extends JsonDto {

    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp(JsonTypes.id, {optional: true})
    public readonly parentId!: string | null;

    @observable
    @jsonProp(JsonTypes.stringUnion(TransferStates))
    public readonly state!: Transfer.State;

    @jsonProp(JsonTypes.array(JsonTypes.lazy(() => JsonTypes.dto(TransferChild))))
    public readonly children!: readonly TransferChild[];

    @jsonProp(JsonTypes.bigNumber)
    public readonly amount!: BigNumber;

    @jsonProp(JsonTypes.dto(VirtualAccount), {optional: true})
    public readonly fromAccount!: VirtualAccount | null;

    @jsonProp(JsonTypes.dto(VirtualAccount), {optional: true})
    public readonly toAccount!: VirtualAccount | null;

    @jsonProp(JsonTypes.stringUnion(CURRENCIES), {optional: true})
    public readonly currency!: Currency | null;

    @jsonProp(JsonTypes.stringUnion(TransferIntentions))
    public readonly intention!: Transfer.Intention;

    @jsonProp(JsonTypes.dto(TransferMetadata), {optional: true})
    public readonly intentionData!: TransferMetadata | null;

    @jsonProp(JsonTypes.dto(Recon), {optional: true})
    public readonly recon!: Recon | null;

    @jsonProp(JsonTypes.dto(LineItem), {optional: true})
    public readonly lineItem!: LineItem | null;

    @jsonProp()
    public readonly tag!: string;

    @jsonProp(JsonTypes.array(JsonTypes.lazy(() => JsonTypes.dto(TransferEvent))))
    public readonly events!: readonly TransferEvent[];

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly investment!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly fundraise!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly company!: Entity | null;

    @jsonProp(JsonTypes.dto(Entity), {optional: true})
    public readonly investor!: Entity | null;

    @jsonProp(JsonTypes.any, {optional: true})
    public readonly stripeData!: any | null;

    public constructor(init?: unknown) {
        super();
        this.init(Transfer, init);
        makeObservable(this);
    }

    @action
    public update(updates: Partial<Pick<Transfer, TransferObservableProperties>>) {
        Object.assign(this, updates);
    }
}

export namespace Transfer {
    export type State = (typeof TransferStates)[number];

    export type Tag = (typeof TransferTags)[number];

    export type Intention = (typeof TransferIntentions)[number];
}

type TransferObservableProperties = "state";

export class TransferChild extends JsonDto {
    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp(JsonTypes.id, {optional: true})
    public readonly parentId!: string | null;

    @jsonProp(JsonTypes.stringUnion(TransferStates))
    public readonly state!: Transfer.State;

    @jsonProp(JsonTypes.bigNumber, {optional: true})
    public readonly amount!: BigNumber | null;

    @jsonProp(JsonTypes.dto(VirtualAccount), {optional: true})
    public readonly fromAccount!: VirtualAccount | null;

    @jsonProp(JsonTypes.dto(VirtualAccount), {optional: true})
    public readonly toAccount!: VirtualAccount | null;

    @jsonProp(JsonTypes.stringUnion(TransferIntentions))
    public readonly intention!: Transfer.Intention;

    @jsonProp(JsonTypes.stringUnion(CURRENCIES), {optional: true})
    public readonly currency!: Currency | null;

    @jsonProp(JsonTypes.dto(TransferMetadata), {optional: true})
    public readonly intentionData!: TransferMetadata | null;

    @jsonProp()
    public readonly tag!: string;

    public constructor(init?: unknown) {
        super();
        this.init(TransferChild, init);
    }
}
