import {AuthStore} from "./auth/store";
import {HttpStatus} from "./constants";
import {ApiError, ResponseError, UnauthorizedError} from "./errors";

export function apiErrorHandler(authStore: AuthStore, err: Error): boolean {
    const responseError =
        err instanceof ResponseError ? err :
        err instanceof ApiError && err.cause instanceof ResponseError ? err.cause :
        null;

    //In the event of a 401 Unauthorized response, automatically log the user out
    if (responseError?.response.status === HttpStatus.UNAUTHORIZED) {
        authStore.signOut({causedByError: new UnauthorizedError(err)});
        return true;
    }

    return false;
}
