import React, {ForwardedRef, forwardRef, ReactNode, Ref, useCallback} from "react";
import {useNavigate} from "react-router-dom";

import {AppBar, Box, Link as RawMuiLink, Paper, Stack, SxProps, Theme, Toolbar} from "@mui/material";
import {observer} from "mobx-react-lite";
import {MarkRequired} from "ts-essentials";

import {AuthStore} from "./auth/store";
import {Button} from "./components/button";
import {Icon} from "./components/icon";
import {ButtonLink, Link} from "./components/link";
import PaymentCenterLogo from "./payment-center.svg";
import {inject} from "./utils/di";
import {transition} from "./utils/styles";

const SkipLink = forwardRef(function SkipLink({
    href,
    children
}: {href: string, children: ReactNode}, ref: ForwardedRef<HTMLAnchorElement>) {
    //Use JavaScript to implement the anchor behavior without changing the window's actual URL fragment
    const onClick = useCallback((ev: React.MouseEvent<HTMLAnchorElement>) => {
        document.querySelector<HTMLElement>(href)!.focus();
        ev.preventDefault();
    }, [href]);

    return (
        <Paper
            //Using a raw MUI link for its styles
            component={RawMuiLink}
            ref={ref}
            href={href}
            onClick={onClick}
            elevation={15}
            sx={{
                color: "primary.light",
                position: "fixed",
                top: "5px",
                left: "50%",
                fontSize: "0.75rem",
                p: 0.5,
                border: "1px solid currentColor",
                transform: "translateX(-50%) translateY(calc(-100% - 5px))",
                ...transition(["transform", "short", "easeInOut"]),

                "&.Mui-focusVisible": {
                    transform: "translateX(-50%) translateY(0%)"
                },

                "&:not(.Mui-focusVisible)": {
                    boxShadow: "none"
                }
            }}
        >
            {children}
        </Paper>
    );
});

export interface HeaderProps {
    readonly authStore?: AuthStore;

    /** A ref to the first skip link */
    readonly skipLinkRef?: Ref<HTMLAnchorElement>;

    readonly sx?: SxProps<Theme>;
}

/** The header for the overall application */
export const Header = inject({authStore: AuthStore}, observer(function Header({
    authStore,
    skipLinkRef,
    sx = []
}: MarkRequired<HeaderProps, "authStore">) {
    const auth = authStore.authentication.valueOrNull();

    const navigate = useNavigate();

    const signOut = useCallback(() => {
        authStore.signOut();
        navigate("/signin");
    }, [authStore, navigate]);

    return (
        <AppBar
            position="relative"
            elevation={10}
            sx={[{height: "5rem", zIndex: "appBar", justifyContent: "center"}, sx].flat()}
        >
            <Toolbar sx={{justifyContent: "space-between"}}>
                <SkipLink ref={skipLinkRef} href="#main">Skip to main content</SkipLink>
                {!!auth &&
                    <SkipLink href="#nav">Skip to navigation</SkipLink>
                }

                <Stack alignItems="flex-start">
                    <ButtonLink
                        variant="lightweight"
                        sx={{height: "2em"}}
                        href={process.env.WEFUNDER_URL}
                        start={<Icon fa="angle-left"/>}
                    >
                        Back to Wefunder
                    </ButtonLink>
                    <Box component="h1" sx={{margin: 0, fontSize: "1.5rem", fontWeight: 500}}>
                        <Box
                            component={PaymentCenterLogo}
                            aria-hidden="true"
                            sx={{verticalAlign: "text-bottom", width: "1.2em", height: "1.2em"}}
                        />{" "}
                        <Link
                            underline="hover"
                            sx={{
                                "&:not(:hover, :active, .Mui-focusVisible)": {
                                    color: "text.primary"
                                },

                                "&:hover, &.Mui-focusVisible": {
                                    color: "primary.light"
                                },

                                "&:active": {
                                    color: "primary.dark"
                                }
                            }}
                            to="/"
                        >
                            Payment Center
                        </Link>
                    </Box>
                </Stack>

                <Stack direction="row" alignItems="center" gap="1ex">
                    {!!auth && <>
                        <span>Hi, {auth.userFirstName}</span>|
                        <Link
                            nav
                            to="/settings"
                            underline="hover"
                            sx={{
                                fontWeight: 500,

                                "&.active": {
                                    fontWeight: "bold",
                                    color: "primary.light"
                                }
                            }}
                        >
                            Settings
                        </Link>|
                        <Button variant="lightweight" sx={{fontSize: "inherit"}} onClick={signOut}>Sign Out</Button>
                    </>}
                </Stack>
            </Toolbar>
        </AppBar>
    );
}));
