import {To, useLocation, useParams, useResolvedPath} from "react-router-dom";

/** Wrapper around react-router `useParams` that throws if the given params are missing */
export function useRequiredParams<Keys extends string>(...keys: Keys[]): {readonly [K in Keys]: string} {
    const params = useParams<Keys>();

    for (const key of keys) {
        //Dynamic keying has always been a pain point in TypeScript, but I'm really surprised I need to cast here...
        if (!params[key as any]) throw new Error(`Path parameter ${key} is required`);
    }

    return params as {readonly [K in Keys]: string};
}

/** Given a To value, returns whether the current page matches it. Used to implement nav links. */
export function useToIsActive(to: To): boolean {
    const location = useLocation();
    const path = useResolvedPath(to);
    return location.pathname === path.pathname;
}
