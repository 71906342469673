import {useMemo} from "react";

import {mapValues} from "lodash";

type Shape<T extends object> = {readonly [K in keyof T]: readonly [value: T[K], set: unknown, rawSet?: unknown]};
type UnknownShape = Shape<{[key: string]: unknown}>;

type SetObj<TShape extends UnknownShape> = {
    readonly [K in keyof TShape]: TShape[K] extends [value: any, set: infer Setter, rawSet?: infer RawSetter] ?
        RawSetter extends undefined ? Setter : Setter & {readonly directly: RawSetter} :
        never
};

/**
 * Convenience hook for combining multiple state hooks into one object. Useful for forms.
 *
 * @param shape
 * The shape of the object to create.
 * Each property should be passed the result of a `useState`-style hook.
 *
 * @returns
 * A tuple of two elements.
 * The first element is the state object.
 * The second element is a parallel object of setters.
 * If the hook for a property returns three elements, it is assumed that the third element is the "raw" setter
 * and exposed as `directly` on the primary setter.
 *
 * @example
 * ```tsx
 * const [obj, setObj] = useStateObject({a: useCheckboxState(false), b: useInputState("")});
 * const submitForm = useCallback(() => postData(obj), [postData, obj]);
 * const clearForm = useCallback(() => {
 *     setObj.a.directly(false);
 *     setObj.b.directly("");
 * }, [setObj]);
 * return (
 *     <form onSubmit={submitForm}>
 *         <input type="checkbox" checked={obj.a} onChange={setObj.a}/>
 *         <input type="text" value={obj.b} onInput={setObj.b}/>
 *     </form>
 * );
 * ```
 */
export function useStateObject<TShape extends UnknownShape>(shape: TShape): [
    obj: {readonly [K in keyof TShape]: TShape[K] extends [infer T, ...any] ? T : never},
    setObj: SetObj<TShape>
] {
    const obj = useMemo(
        () => mapValues(shape, 0),
        //eslint-disable-next-line react-hooks/exhaustive-deps
        Object.values(shape).map(x => x[0])
    );
    const setObj = useMemo(
        () => mapValues(shape, ([, set, rawSet]) => Object.assign(set as {}, {directly: rawSet})),
        //Setters are *supposed* to be stable, but in some stupid cases they aren't
        //eslint-disable-next-line react-hooks/exhaustive-deps
        Object.values(shape).flatMap(([, set, rawSet]) => [set, rawSet])
    );

    //eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return [obj, setObj] as any;
}

/**
 * Form of `useStateObject` that allows specifying a type for the object.
 * This needs to be a separate, curried function due to the limitations of TypeScript's type inference.
 */
export const useTypedStateObject = <T extends object>() => <TShape extends Shape<T>>(shape: TShape) =>
    //False positive due to the currying
    //eslint-disable-next-line react-hooks/rules-of-hooks
    useStateObject(shape) as [obj: T, setObj: SetObj<TShape>];
