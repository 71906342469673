import {PaginationEnvelope} from "~/api/pagination-envelope";
import {JsonDto} from "~/utils/json-validation";

export function paginatedDto<T extends JsonDto>(dtoClass: JsonDto.Parseable<T>): (data: unknown) => PaginationEnvelope<T[]> {
  return (data: unknown) => {
    // I hate you typescript
    const anyEnvelope = data as PaginationEnvelope<any>;
    const dtoData = JsonDto.constructArray<T>(dtoClass, anyEnvelope.data);
    return {
      data: dtoData,
      pagination: anyEnvelope.pagination
    };
  };
}

export function constructDto<T extends JsonDto>(dtoClass: JsonDto.Parseable<T>): (data: unknown) => T {
  return function(data: unknown) {
    const dataAny = data as any;
    if (dataAny.data !== undefined) {
      return JsonDto.construct<T>(dtoClass, dataAny.data);
    } else {
      return JsonDto.construct<T>(dtoClass, dataAny);
    }
  };
}

export function constructArrayDto<T extends JsonDto>(dtoClass: JsonDto.Parseable<T>): (data: unknown) => T[] {
  return function(data: unknown) {
    const dataAny = data as any;
    if (dataAny.data !== undefined) {
      return JsonDto.constructArray<T>(dtoClass, dataAny.data);
    } else {
      return JsonDto.constructArray<T>(dtoClass, dataAny);
    }
  };
}
