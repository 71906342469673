import React, {ReactNode, useCallback} from "react";

import {Paper} from "@mui/material";
import {startCase} from "lodash";
import {flowResult, runInAction} from "mobx";
import {observer} from "mobx-react-lite";

import {TransferAmountSummary} from "../components/transfer-amount-summary";
import {TransferStore} from "../store";
import {Transfer} from "../transfer";
import {Accordion, AccordionDetails, AccordionSummary} from "~/components/accordion";
import {Button} from "~/components/button";
import {useConfirm} from "~/components/dialog";
import {LoadingTreatment} from "~/components/loading-treatment";
import {Menu} from "~/components/menu";
import {MenuItem} from "~/components/menu-item";
import {Page} from "~/components/page";
import {EntityDisplay} from "~/entity/components/entity-display";
import {inject} from "~/utils/di";
import {useRequiredParams} from "~/utils/routing";
import {InlineDefinition, InlineTerm} from "~/utils/styles";
import {useAbortableEffect} from "~/utils/use-abortable-effect";
import {TransferEventsListDisplay} from "~/transfer/components/transfer-events-list-display";
import {useTypedStateObject} from "~/utils/use-state-object";
import {PageParams} from "~/api/common-query-params";
import {useQueryParam} from "~/utils/use-query-param";
import {parsePositiveInt} from "~/utils/numbers";
import {PaginationState} from "~/api/pagination-envelope";
import {VirtualAccountDetails} from "~/account/components/virtual-account-details";
import TransferView from "./transfer-view";
import TransferRepo from "~/repositories/transfers";
import ActionButton from "~/components/action-button";

interface TransferDetailsProps {
    readonly transfer: Transfer;
    readonly extraProperties?: ReactNode;
    readonly hideSummary?: boolean;
    //If we end up needing to show different entities for different intentions, we can turn this prop into an array.
    //Will need to apply the same memoization workaround as in EntityDisplay
    readonly hideEntities?: boolean;
    readonly hideEvents?: boolean;
    readonly extraAccordions?: ReactNode;
}

const TransferDetails = observer(function TransferDetails({
    transfer,
    extraProperties,
    hideSummary = false,
    hideEntities = false,
    hideEvents = false,
    extraAccordions
}: TransferDetailsProps) {
    const [eventsPageParams, setEventsPageParams] = useTypedStateObject<PageParams>()({
        page: useQueryParam("page", 1, {parser: parsePositiveInt, push: true, immediate: true}),
        perPage: useQueryParam("perPage", 10, {parser: parsePositiveInt, push: true, immediate: true})
    });

    const paginationState: PaginationState = {
        currentPage: (eventsPageParams.page || 1),
        perPage: (eventsPageParams.perPage || 10),
        totalPages: transfer.events.length / (eventsPageParams.perPage || 1),
        totalItems: transfer.events.length
    };

    return <>
        <Paper component="dl" sx={{m: 0, p: 2}}>
            <div>
                <InlineTerm>State</InlineTerm>
                <InlineDefinition>{startCase(transfer.state)}</InlineDefinition>
            </div>
            <div>
                <InlineTerm>Tag</InlineTerm>
                <InlineDefinition>{transfer.tag}</InlineDefinition>
            </div>
            {transfer.intentionData?.reference &&
                <div>
                    <InlineTerm>Reference</InlineTerm>
                    <InlineDefinition>{transfer.intentionData.reference}</InlineDefinition>
                </div>
            }
            {extraProperties}
        </Paper>
        {!hideSummary &&
            <Paper sx={{p: 2, "&:empty": {display: "none"}}}>
                <TransferAmountSummary transfer={transfer}/>
            </Paper>
        }

        {transfer.fromAccount && <div>
            <Accordion>
                <AccordionSummary>From account</AccordionSummary>
                <AccordionDetails>
                    <VirtualAccountDetails account={transfer.fromAccount}/>
                </AccordionDetails>
            </Accordion>
        </div>}

        {transfer.toAccount && <div>
            <Accordion>
                <AccordionSummary>To account</AccordionSummary>
                <AccordionDetails>
                    <VirtualAccountDetails account={transfer.toAccount}/>
                </AccordionDetails>
            </Accordion>
        </div>}

        <div>
            {!hideEntities &&
                <Accordion>
                    <AccordionSummary>Entities</AccordionSummary>
                    <AccordionDetails>
                        <EntityDisplay
                            model={transfer}
                            entities={["investment", "fundraise", "company", "investor"]}
                        />
                    </AccordionDetails>
                </Accordion>
            }
        </div>
        <div>
            {!hideEvents &&
                <Accordion>
                    <AccordionSummary>Transfer Events</AccordionSummary>
                    <AccordionDetails>
                        <TransferEventsListDisplay
                            transferEvents={transfer.events.slice(
                                (paginationState.currentPage - 1) * paginationState.perPage,
                                paginationState.currentPage * paginationState.perPage
                            )}
                            stale={false}
                            paginationState={paginationState}
                            onPageChange={setEventsPageParams.page}
                            onRowsPerPageChange={setEventsPageParams.perPage}
                        />
                    </AccordionDetails>
                </Accordion>
            }

            {extraAccordions}
        </div>
    </>;
});

/** Page displaying the details of a single transfer */
export const TransferDetailsPage = inject({transferStore: TransferStore}, observer(function TransferDetailsPage({
    transferStore
}: {transferStore: TransferStore}) {
    const {transferId} = useRequiredParams("transferId");

    const confirm = useConfirm();

    const transferDetails = transferStore.getTransferDetails(transferId);

    useAbortableEffect(
        signal => flowResult(transferStore.fetchTransferDetails(transferId, signal)),
        [transferStore, transferId]
    );

    const startTransfer = useCallback(() => runInAction(async () => {
        const transfer = transferDetails.getValue();

        if (transfer.state !== "pending") return;

        if (await confirm({
            title: "Start transfer",
            description: `Do you want to initiate the transfer #${transfer.id}?`,
            dangerous: false
        })) {
            transferStore.startTransfer(transfer.id);
        }
    }), [confirm, transferStore, transferDetails]);

    const cancelTransfer = useCallback(() => runInAction(async () => {
        const transfer = transferDetails.getValue();

        // if (!["pending", "running"].includes(transfer.state)) return;

        if (await confirm({
            title: `Cancel transfer ${transfer.id}?`,
            description: "This cannot be undone.",
            dangerous: true
        })) {
            transferStore.cancelTransfer(transfer.id);
        }
    }), [confirm, transferStore, transferDetails]);

    let title = `Transfer ${transferId}`;
    transferDetails.tap(transfer => {
        if (transfer.intentionData?.description) {
            title += ` | ${transfer.intentionData.description}`;
        }
    });

    const subtitle = transferDetails.case({
        loaded: transfer => startCase(transfer.intention),
        else: () => null
    });

    // const refresh = () => TransferRepo.refresh(transferId);

    return (
        <Page
            title={title}
            titleSize="medium"
            subtitle={subtitle}
            sx={{
                //Can't use gap 'cause it messes with the scroll padding pseudo-element
                "& .PcPage-content > * + *": {
                    mt: 2
                }
            }}
            actions={
                transferDetails.isLoaded() && <>
                    <Button variant="contained" onClick={startTransfer} color="success" disabled={transferDetails.value.state !== "pending"}>
                        Start
                    </Button>
                    <Menu label="More Options">
                      <ActionButton
                          sx={{pl: 2}}
                          mutation={TransferRepo.refresh(transferId)}
                          refetchKey={["transfers", transferId]}
                          successMessage={"Successfully refreshed transfer"}
                          color="success"
                          variant="text"
                      >
                        Refresh
                      </ActionButton>
                      <MenuItem sx={{color: "error.main"}} onClick={cancelTransfer}>Cancel</MenuItem>
                    </Menu>
                </>
            }
        >
            <LoadingTreatment loadable={transferDetails} description="transfer details" observer>{transfer => {
                switch (transfer.intention) {
                    //TODO: How should each of the new intentions be treated/displayed?
                    default:
                        return <TransferView transfer={transfer}/>;
                }
            }}</LoadingTreatment>
        </Page>
    );
}));
