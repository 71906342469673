/** Wrapper object that can be used to store an error message, whether or not it came from an Error */
export interface ErrorContainer {
    /** A message describing the error */
    readonly errorMessage: string;

    /** The Error instance that was thrown, if any */
    readonly error: Error | null;
}

/**
 * Creates an ErrorContainer
 *
 * @param source - An object containing an error message. Can be an instance of Error.
 *
 * @returns A new ErrorContainer with message and Error instance from the given source
 */
export const errorContainer = (source: {message: string}): ErrorContainer => ({
    errorMessage: source.message,
    error: source instanceof Error ? source : null
});
