import React from "react";

import {Box} from "@mui/material";
import {flowResult} from "mobx";
import {observer} from "mobx-react-lite";

import {AchBatchDisplay, AchBatchSummaryDisplay} from "../components/ach-batch-display";
import {AchStore} from "../store";
import {LoadingTreatment} from "~/components/loading-treatment";
import {Page} from "~/components/page";
import {inject} from "~/utils/di";
import {useRequiredParams} from "~/utils/routing";
import {useAbortableEffect} from "~/utils/use-abortable-effect";

export const SingleAchBatchPage = inject({achStore: AchStore}, observer(function SingleAchBatchPage({
    achStore
}: {achStore: AchStore}) {
    const {batchId} = useRequiredParams("batchId");

    const batchDetails = achStore.getBatchDetails(batchId);

    useAbortableEffect(signal => [
        flowResult(achStore.fetchBatchDetails(batchId, signal)),
        () => achStore.clearBatchDetails(batchId)
    ], [achStore, batchId]);

    return (
        <Page title={`ACH Batch ${batchId}`}>
            <LoadingTreatment loadable={batchDetails} description="batch details">{batch => <>
                {/* Can't use an actual <p> element, as <div> can't be a child of <p> */}
                <Box role="paragraph" sx={{mb: 2, px: 1}}>
                    <AchBatchSummaryDisplay batch={batch}/>
                </Box>
                <AchBatchDisplay batchId={batch.id} tablePaper/>
            </>}</LoadingTreatment>
        </Page>
    );
}));
