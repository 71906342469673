import React, {useState} from "react";
import {Panel} from "~/recon/recon-page";
import {Icon} from "~/components/icon";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert, Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  LinearProgress,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import TransferView from "~/transfer/views/transfer-view";
import {useMutation, useQuery, useQueryClient} from "react-query";
import TransferRepo from "~/repositories/transfers";
import {Error} from "~/recon/components/recon-review";
import {TransferChild} from "~/transfer/transfer";
import {every, keys, map, orderBy, startCase} from "lodash";
import {MoneyFormat} from "~/utils/money";
import {BigNumber} from "bignumber.js";
import ActionButton from "~/components/action-button";
import {AlertColor} from "@mui/material/Alert/Alert";
import {Close, Edit} from "@mui/icons-material";
import CircularProgressButton, {confirmButtonStateFromFlags} from "~/components/CircularProgressButton";
import Disbursements from "~/repositories/disbursements";
import {Disbursement} from "./disbursement";
import {DisbursementTransferView} from "./disbursement-transfer-view";
import {RenderIf} from "~/recon/util";
import {LineItem} from "~/recon/line-item";

enum TransferState {
  Running = "running",
  Succeeded = "succeeded",
  Failed = "failed",
  Canceled = "canceled",
}

const alertSeverity = (state: TransferState): AlertColor => {
  switch (state) {
    case TransferState.Running: return "info";
    case TransferState.Succeeded: return "success";
    case TransferState.Failed: return "error";
    case TransferState.Canceled: return "warning";
 }
};

const DescriptionUpdate = ({rootTransferId, transferId, defaultValue}: {rootTransferId: string, transferId: string, defaultValue: string}) => {
  const [value, setValue] = React.useState(defaultValue);
  const [editing, setEditing] = useState(false);

  const client = useQueryClient();
  const {mutateAsync, isLoading, isError, isSuccess, error, reset} = useMutation(TransferRepo.updateMetadata(transferId));

  const toggleEditing = () => {
    if (editing) {
      reset();
      setValue(defaultValue);
   }
    setEditing(!editing);
 };

  const save = async () => {
    const result = await mutateAsync({description: value});

    // optimistic update and refetch in the background
    setValue(result.intentionData?.description || "");
    await client.refetchQueries(["transfers", rootTransferId], {exact: false});

    // state cleanup and reset
    setEditing(false);
    reset();
 };

  const dirty = value !== defaultValue;

  return (
    <FormControl sx={{mb: 2, width: "95%"}} variant="filled">
      <Input
          id="wire-description"
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
          readOnly={!editing}
          disabled={!editing}
          endAdornment={
            <InputAdornment position="end">
              {dirty && <CircularProgressButton onClick={save} state={confirmButtonStateFromFlags(isSuccess, isError, isLoading)} error={((error as any) || {}).message}/>}
              <IconButton aria-label="toggle edit" onClick={toggleEditing} size="small" disabled={isLoading}>
                {!editing ? <Edit fontSize="small" color="info"/> : <Close fontSize="small" color="error"/>}
              </IconButton>
            </InputAdornment>
       }
      />
    </FormControl>
  );
};

const TargetDetails = ({rootTransferId, transfer, details}: {rootTransferId: string, transfer: TransferChild, details?: JSON}) => {
  const amount = transfer.amount || new BigNumber(0);
  const description = transfer.intentionData?.description || `${startCase(transfer.tag)} transfer`;

  const coordinatorDescription: string | undefined = ((details || {}) as any).description;
  const state: TransferState | undefined = ((details || {}) as any).state?.type;

  const account = transfer.toAccount!;
  const wire = account.backend.wireInfo;

  const lft = 1;
  const scd = 5;

  return (
    <Paper elevation={4} sx={{mb: 2}}>
      <Grid container spacing={1} m={4}>
        <DescriptionUpdate defaultValue={description} transferId={transfer.id} rootTransferId={rootTransferId}/>
        <Grid item xs={lft}><Typography variant={"body2"}>Total:</Typography></Grid>
        <Grid item xs={scd}><Typography variant={"body1"} fontWeight={600}>{MoneyFormat.formatBigNumber(amount, account.currency)}</Typography></Grid>

        <Grid item xs={lft}><Typography variant={"body2"}>Transfer:</Typography></Grid>
        <Grid item xs={scd}><Typography variant={"body2"} fontWeight={600}>{transfer.id}</Typography></Grid>

        <Grid item xs={lft}><Typography variant={"body2"}>To:</Typography></Grid>
        <Grid item xs={scd}><Typography variant={"body2"}>{`(${account.id}) | ${account.name} [${account.currency}]`}</Typography></Grid>

        <Grid item xs={lft}><Typography variant={"body2"}>On:</Typography></Grid>
        <Grid item xs={scd}><Typography variant={"body2"}>{`Backend (${account.backend.id}) ${account.backend.name}`}</Typography></Grid>

        <Grid item xs={lft}><Typography variant={"body2"}>Wire Info:</Typography></Grid>
        {wire && <Grid item xs={scd}><Typography variant={"body2"}>{`[${wire.currency.toUpperCase()}] ${wire.number} ${wire.country || ""}`}</Typography></Grid>}
      </Grid>
      {state && <Alert severity={alertSeverity(state)} sx={{opacity: 0.6}}>{`${startCase(state)} | ${coordinatorDescription}`}</Alert>}
    </Paper>
  );
};

const StartTransfer = ({transfer, enabled, itemLen, amount}: {transfer: Disbursement, enabled: boolean, itemLen: number, amount: string}) => (
  <ActionButton
      mutation={TransferRepo.start(transfer.id)}
      refetchKey={["transfers", transfer.id]}
      confirmationMessage={`You are about to send ${itemLen} wire${(itemLen > 1) ? "s" : ""}, total of ${amount}.\nAre you sure?`}
      color="warning"
      disabled={!enabled}
  >
    Submit Wires
  </ActionButton>
);

const RetryTransfer = ({transfer, enabled}: {transfer: Disbursement, enabled: boolean}) => (
  <ActionButton
      mutation={TransferRepo.retry(transfer.id)}
      refetchKey={["transfers", transfer.id]}
      confirmationMessage={`This action will restart and submit all${transfer.state === "running" ? " failed" : ""} wires. Proceed?`}
      color="warning"
      disabled={!enabled}
  >
    Retry Wires
  </ActionButton>
);


const CancelTransfer = ({transfer, enabled}: {transfer: Disbursement, enabled: boolean}) => (
  <ActionButton
      mutation={TransferRepo.cancel(transfer.id)}
      refetchKey={["transfers", transfer.id]}
      confirmationMessage={`The transfer will be deleted, you will need to prepare another one for disbursal.\nDelete wire transfer for ${transfer.backend.name}?`}
      successMessage={"Request to cancel transfer submitted."}
      color="error"
      variant="text"
      disabled={!enabled}
  >
    Cancel Transfer
  </ActionButton>
);

const ReconcileTransfer = ({transfer, enabled, inferredRecons}: {transfer: Disbursement, enabled: boolean, inferredRecons: {[key: string]: LineItem | null}}) => {
  const payload = {
    disbursementTransferId: Number(transfer.id),
    reconItems: Object.entries(inferredRecons).map(([wireId, lineItem]) => ({
      wireId: Number(wireId),
      lineItemId: lineItem!.id
    }))
  };
  return (
    <ActionButton
        mutation={Disbursements.reconcile(payload)}
        refetchKey={["disbursements", transfer.id]}
        successMessage={"Disbursement reconciled."}
        color="warning"
        variant="text"
        disabled={!enabled}
    >
      Reconcile Transfer
    </ActionButton>
  );
};

const CoordinatorDebug = ({details}: {details: JSON}) => (
  <Accordion>
    <AccordionSummary aria-controls="debug-content" id="debug-header">
      <Typography variant={"subtitle2"} sx={{fontWeight: 400}}>Coordinator debug information</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography variant={"body2"} sx={{wordBreak: "break-all", whiteSpace: "break-spaces", fontSize: "0.8rem", fontFamily: "monospace", color: "antiquewhite"}}>
        {JSON.stringify(details, null, 4)}
      </Typography>
    </AccordionDetails>
  </Accordion>
);

const DisbursementDetails = ({disbursement}: {disbursement: Disbursement}) => {
  const descQuery = useQuery(TransferRepo.processDescription(disbursement.id));
  const reconQuery = useQuery(Disbursements.inferRecons(disbursement.id));

  const coordinator: any = (descQuery.data || {}).data || {};
  const externalProcesses = coordinator.externalProcesses || {};

  const wires = orderBy(disbursement.wires, w => w.toAccount?.id);
  const isPending = ["running", "pending"].includes(disbursement.state);
  const canRetry = ["failed", "running"].includes(disbursement.state);
  const inferredRecons = reconQuery.data?.inferred || {};
  const canFullyReconcile = every(wires, (w) => !!inferredRecons[w.id] && inferredRecons[w.id]!.amount.abs().eq(w.amount.abs()));

  return (
    <Stack spacing={2} mt={2}>
      <Alert severity={!coordinator.description ? "warning" : "info"}>{coordinator.description || "Transfer state not available. Did you start it?"}</Alert>
      <RenderIf cond={canFullyReconcile}>
        <Alert severity="success">This transfer can be automatically reconciled</Alert>
      </RenderIf>
      <DisbursementTransferView transfer={disbursement}/>

      <Typography variant={"subtitle2"} sx={{mt: 4, fontWeight: 400}}>Wire Transfers</Typography>
      {map(wires, (wire) => <TargetDetails key={wire.id} transfer={wire} details={externalProcesses[`transfers/${disbursement.id}/external_process/${wire.id}`]} rootTransferId={disbursement.id}/>)}
      {coordinator && <CoordinatorDebug details={coordinator}/>}
      <Stack direction="row" justifyContent="space-between" sx={{mt: 4, mb: 4, pb: 4}}>
        {!canRetry && <StartTransfer transfer={disbursement} enabled={isPending} itemLen={keys(wires).length} amount={MoneyFormat.formatBigNumber(disbursement.amount, disbursement.currency)}/>}
        {canRetry && <RetryTransfer transfer={disbursement} enabled={canRetry}/>}
        <CancelTransfer transfer={disbursement} enabled={isPending}/>
        <ReconcileTransfer transfer={disbursement} enabled={canFullyReconcile} inferredRecons={inferredRecons}/>
      </Stack>
    </Stack>
  );
};

const DisbursementItem = ({transferId, onClose}: {transferId: string | undefined, onClose: () => void}) => {
  const query = useQuery(Disbursements.get(transferId));

  const transfer = query.data;
  const isPending = transfer?.state === "pending";
  return (
    <Panel sx={{overflow: "auto"}}>
      <Stack direction={"row"} alignItems={"center"} mt={1} justifyContent={"space-between"}>
        <Stack direction={"row"} alignItems={"center"}>
          <IconButton onClick={onClose} sx={{ml: -2, mr: 2}}>
            <Icon fa="close"/>
          </IconButton>
          <Typography variant={"subtitle2"} fontWeight={400}>Disbursement Transfer Summary</Typography>
        </Stack>
        {!isPending &&
          <ActionButton
              mutation={TransferRepo.refresh(transferId)}
              refetchKey={["transfers", transferId]}
              successMessage={"Successfully refreshed wire transfers"}
              color="success"
              variant="text"
          >
          Refresh
        </ActionButton>}
      </Stack>
      <Divider/>
      <Stack>
        {query.isFetching ? <LinearProgress/> : <Container sx={{height: "4px"}}/>}
        {query.isError && <Error error={query.error}/>}
        {transfer && <DisbursementDetails disbursement={transfer}/>}
      </Stack>
    </Panel>
  );
};

export default DisbursementItem;

