import {BigNumber} from "bignumber.js";
import {entries, every, forOwn, isEmpty, isObject} from "lodash";

import {Sequence} from "../sequence";
import {JsonDto, JsonValidationError, JsonValidationErrorMap} from "./index";
import {Temporal} from "@js-temporal/polyfill";

export type JsonParseResult<T> = readonly [T] | readonly [null | undefined, JsonValidationErrorMap];

export interface JsonTypeDescriptor<T> {
    readonly isOfType: (val: unknown) => val is T;
    readonly parse: (input: unknown) => JsonParseResult<T>;
}

export namespace JsonTypes {
    export function lazy<T>(getDescriptor: () => JsonTypeDescriptor<T>): JsonTypeDescriptor<T> {
        let descriptor: JsonTypeDescriptor<T> | null = null;
        return {
            isOfType: (x): x is T => (descriptor ??= getDescriptor()).isOfType(x),
            parse: x => (descriptor ??= getDescriptor()).parse(x)
        };
    }

    export const parsePrimitive = <T>(...typenames: readonly string[]) => (x: unknown): JsonParseResult<T> =>
        typenames.includes(typeof x) ? [x as T] : [null, [`Expected ${typenames.join(" | ")}, got ${typeof x}`]];

    export const composeParsers =
        <T, U>(parser1: (x: unknown) => JsonParseResult<T>, parser2: (x: T) => JsonParseResult<U>) =>
        (x: unknown): JsonParseResult<U> => {
            const [t, errors] = parser1(x);
            if (errors) return [null, errors];
            return parser2(t!);
        };

    const primitiveDescriptor = <T>(typename: string): JsonTypeDescriptor<T> => ({
        isOfType: (x): x is T => typeof x === typename,
        parse: parsePrimitive<T>(typename)
    });

    export const number = primitiveDescriptor<number>("number");
    export const string = primitiveDescriptor<string>("string");
    export const boolean = primitiveDescriptor<boolean>("boolean");

    export const unknown: JsonTypeDescriptor<unknown> = {
        isOfType: (x): x is unknown => true,
        parse: x => [x]
    };

    export const any: JsonTypeDescriptor<any> = {
        isOfType: (x): x is any => true,
        parse: x => [x]
    };

    export const bigint: JsonTypeDescriptor<bigint> = {
        isOfType: (x): x is bigint => typeof x === "bigint",
        parse: composeParsers(parsePrimitive<string | number>("string", "number"), x => {
            try {
                return [BigInt(x)];
            }
            catch (err) {
                if (!(err instanceof SyntaxError)) throw err;
                return [null, [err.message]];
            }
        })
    };

    export const bigNumber: JsonTypeDescriptor<BigNumber> = {
        isOfType: BigNumber.isBigNumber,
        parse: composeParsers(parsePrimitive<string | number>("string", "number"), x => {
            const bn = new BigNumber(x);
            if (bn.isNaN()) return [null, [`${x} is not a valid BigNumber`]];
            return [bn];
        })
    };

    export const instant: JsonTypeDescriptor<Temporal.Instant> = {
        isOfType: (x): x is Temporal.Instant => x instanceof Temporal.Instant,
        parse: composeParsers(parsePrimitive<string>("string"), x => {
            try {
                return [Temporal.Instant.from(x)];
            }
            catch (err) {
                if (!(err instanceof RangeError)) throw err;
                return [null, [err.message]];
            }
        })
    };

    export const stringUnion = <T extends string>(
        cases: readonly T[],
        {allowWrapper = false}: {readonly allowWrapper?: boolean} = {}
    ): JsonTypeDescriptor<T> => ({
        isOfType: (x): x is T => cases.includes(x as any),
        parse: (x: any) => {
            if (cases.includes(x)) return [x as T];
            else if (allowWrapper && cases.includes(x.type)) return [x.type as T];
            else return [null, [`Expected one of ${JSON.stringify(cases)}, got ${x}`]];
        }
    });

    export const object = <T>(descriptor: JsonTypeDescriptor<T>): JsonTypeDescriptor<{[key: string]: T}> => ({
        isOfType: (x): x is {[key: string]: any} => isObject(x) && Object.values(x).every(it => descriptor.isOfType(it)),
        parse: (x: unknown) => [x as {[key: string]: T}]
    });

    export const array = <T>(descriptor: JsonTypeDescriptor<T>): JsonTypeDescriptor<readonly T[]> => ({
        isOfType: (x): x is readonly T[] => Array.isArray(x) && x.every(it => descriptor.isOfType(it)),
        parse(x) {
            if (!Array.isArray(x)) return [null, [`Expected array, got ${typeof x}`]];

            const parseResult = x.map(descriptor.parse);

            const errors = Sequence.from(parseResult)
                .map(1)
                .keyBy((x, i) => i)
                .filter(([, errors]) => !!errors)
                .collectToObject();

            if (!isEmpty(errors)) return [null, errors];

            return [parseResult.map(([val]) => val!)];
        }
    });

    export const map = <T>(descriptor: JsonTypeDescriptor<T>): JsonTypeDescriptor<{ [key: string]: T }> => ({
        isOfType: (x): x is { [key: string]: T } => isObject(x) && every(x, (it) => descriptor.isOfType(it)),
        parse(x) {
            if (!isObject(x)) return [null, [`Expected map, got ${typeof x}`]];

            const parseResult = entries(x).map(([key, value]) => ({key: key, value: descriptor.parse(value)}));
            const result: { [key: string]: T } = {};
            parseResult.forEach(({key, value}) => {
                if (value[1]) {
                    return [null, "Unable to parse map"];
                } else {
                    result[key] = value[0]!;
                }
            });

            return [result];
        }
    });

    //TODO: export const tuple

    export const dto = <T extends JsonDto>(dtoClass: JsonDto.Parseable<T>): JsonTypeDescriptor<T> => ({
        isOfType: (x): x is T => x instanceof dtoClass,
        parse(x) {
            try {
                return [JsonDto.construct(dtoClass, x)];
            }
            catch (err) {
                if (!(err instanceof JsonValidationError)) throw err;
                return [null, err.errors];
            }
        }
    });

    export const id: JsonTypeDescriptor<string> = {
        isOfType: (x): x is string => typeof x === "string",
        parse: composeParsers(parsePrimitive<string | number>("string", "number"), x => [String(x)])
    };

    export function getDescriptorForReflectedType(clazz: Function, prop: string): JsonTypeDescriptor<any> {
        const reflectedType: Function | undefined = Reflect.getOwnMetadata("design:type", clazz.prototype, prop);

        if (reflectedType === undefined)
            throw new TypeError(`Reflected type of ${clazz.name}#${prop} is undefined. This is not supported.`);
        if (reflectedType === Object)
            throw new TypeError(`Unable to reflect type for ${clazz.name}#${prop}. Type must be manually annotated.`);
        if (reflectedType === Array)
            throw new TypeError(
                `Type of ${clazz.name}#${prop} reflects as Array. Use JsonTypes.array to specify element type.`
            );

        switch (reflectedType) {
            case Number:
                return number;
            case String:
                return string;
            case Boolean:
                return boolean;
            case BigInt:
                return bigint;
            case BigNumber:
                return bigNumber;
            case Temporal.Instant:
                return instant;
            default:
                if (JsonDto.prototype.isPrototypeOf(reflectedType.prototype))
                    return dto(reflectedType as JsonDto.Parseable<any>);

                throw new TypeError(
                    `Unknown reflected type ${reflectedType.name} for ${clazz.name}#${prop}. `
                        + "Only BigNumber, Instant, and subclasses of JsonDto can be handled automatically."
                );
        }
    }
}
