import {last, map, uniq, startCase} from "lodash";
import React from "react";
import {useLocation} from "react-router-dom";
import {useRequiredParams} from "~/utils/routing";
import {useQuery} from "react-query";
import Escrow from "~/repositories/escrow";
import {RenderIf} from "~/recon/util";
import {AccountRoleBreakdown, EscrowDetails, StripeAccountBalance, TransferTagBreakdown} from "./escrow";
import {Box, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {LabeledItem, LabeledValue} from "~/components/labeled-item";
import {Page} from "~/components/page";
import {MoneyFormat} from "~/utils/money";
import BigNumber from "bignumber.js";
import {Currency} from "~/currency";

export const EscrowDashboard = () => {
  // An ugly hack because the NavItem thing doesn't let you pass in a path fragment
  const location = useLocation();
  const backendId = last(location.pathname.split("/"));
  
  const escrowQuery = useQuery(Escrow.get(backendId!));

  return (
    <Page title={`Backend #${backendId} - ${escrowQuery.data?.name || ""}`}
        sx={{
          "& .PcPage-content": {
              display: "grid",
              gridTemplateRows: "auto minmax(0, 1fr)",
              gridTemplateColumns: "100%",
              alignItems: "start"
          }
        }}
    >
      <RenderIf cond={escrowQuery.isSuccess}>
        <EscrowView escrow={escrowQuery.data!}/>
      </RenderIf>
    </Page>
  );
};

const TransferStatsTable = ({escrow}: {escrow: EscrowDetails}) => {
  const tags = uniq(escrow.transferStats.map(s => s.tag));
  const states = uniq(escrow.transferStats.map(s => s.state));
  const getAmount = (state: string | undefined, tag: string) => {
    const amount = escrow.transferStats.find(stat => stat.state === state && stat.tag === tag)?.total;
    if (amount) {
      return <span>{MoneyFormat.formatNumber(amount, escrow.currency)}</span>;
    } else {
      return null;
    }
  };

  return (
    <Table sx={{minWidth: 650}} aria-label="simple table" size="small">
      <TableHead>
        <TableRow>
          <TableCell/>
          {tags.map(tag => <TableCell key={tag}>{startCase(tag)}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {states.map(state => (
          <TableRow key={state || "total"}>
            <TableCell>{startCase(state || "total")}</TableCell>
            {tags.map(tag => (
              <TableCell key={tag}>{getAmount(state, tag)}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const StripeBalanceTable = ({stripeAccountBalance}: {stripeAccountBalance: StripeAccountBalance}) => {
  const balanceCategories = Object.keys(stripeAccountBalance.balance);
  const balanceSourceTypes = ["total", ...uniq(Object.values(stripeAccountBalance.balance).map(bds => bds.map(b => Object.keys(b.sourceTypes))).flat(2))];
  // category -> source type -> currency -> amount
  const balance: { [key: string]: {[key: string]: {[key: string]: BigNumber}} } = Object.fromEntries(balanceCategories.map(category =>
    [category, Object.fromEntries(balanceSourceTypes.map(bst => [bst, {}]))]
  ));
  Object.entries(stripeAccountBalance.balance).forEach(([category, breakdowns]) => {
    breakdowns.forEach(breakdown => {
      balance[category].total[breakdown.currency] = breakdown.amount;
      Object.entries(breakdown.sourceTypes).forEach(([sourceType, value]) => {
        balance[category][sourceType][breakdown.currency] = value;
      });
    });
  });

  return (
    <Table sx={{minWidth: 650}} aria-label="simple table" size="small">
      <TableHead>
        <TableRow>
          <TableCell/>
          {balanceSourceTypes.map(tp => <TableCell key={tp}>{startCase(tp)}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {balanceCategories.map(category => (
          <TableRow key={category}>
            <TableCell>{startCase(category)}</TableCell>
            {balanceSourceTypes.map(tp => (
              <TableCell key={tp}>
                <Stack direction="column">
                  {Object.entries(balance[category][tp]).map(([currency, amount]) => (
                    <Typography key={currency} variant="body2" component="span">
                      {MoneyFormat.formatNumber((amount as unknown) as number, currency as Currency)}
                    </Typography>
                  ))}
                </Stack>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const TransferTagBreakdownView = ({data, currency}:{data: TransferTagBreakdown[], currency: Currency}) => {
  const columnLabels = uniq(data.map(row => row.laneType));
  const rowLabels = uniq(data.map(row => row.tag));
  const cells = {};
  for (const c of columnLabels) {
    for (const r of rowLabels) {
      cells[c] ||= {};
      cells[c][r] = data.find(row => row.laneType === c && row.tag === r) || null;
    }
  }

  return (
    <Table sx={{minWidth: 650}} aria-label="simple table" size="small">
      <TableHead>
        <TableRow>
          <TableCell/>
          {columnLabels.map(label => <TableCell key={label}>{startCase(label)}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {rowLabels.map(rl => (
          <TableRow key={rl}>
            <TableCell>{startCase(rl)}</TableCell>
            {columnLabels.map(cl => (
              <TableCell key={cl}>
                {cells[cl][rl]?.amount && MoneyFormat.formatNumber(cells[cl][rl].amount, currency)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const AccountRoleBreakdownView = ({data, currency}:{data: AccountRoleBreakdown[], currency: Currency}) => {
  const columnLabels = uniq(data.map(row => row.laneType));
  const rowLabels = uniq(data.map(row => row.accountRole));
  const cells = {};
  for (const c of columnLabels) {
    for (const r of rowLabels) {
      cells[c] ||= {};
      cells[c][r] = data.find(row => row.laneType === c && row.accountRole === r) || null;
    }
  }

  return (
    <Table sx={{minWidth: 650}} aria-label="simple table" size="small">
      <TableHead>
        <TableRow>
          <TableCell/>
          {columnLabels.map(label => <TableCell key={label}>{startCase(label)}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {rowLabels.map(rl => (
          <TableRow key={rl}>
            <TableCell>{startCase(rl)}</TableCell>
            {columnLabels.map(cl => (
              <TableCell key={cl}>
                {cells[cl][rl]?.amount && MoneyFormat.formatNumber(cells[cl][rl].amount, currency)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const EscrowView = ({escrow}: {escrow: EscrowDetails}) => {
  const adjustmentAmount = escrow.adjustmentAccount.stateLanes.find(a => a.laneType === "Settled")?.amount.toNumber();
  const stripeFeesAmount = escrow.stripeFeesAccount.stateLanes.find(a => a.laneType === "Settled")?.amount.toNumber();

  return (
    <Paper sx={{p: 2}}>
      <Stack direction="column">
        <LabeledValue label="Internal ID" value={escrow.id}/>
        <LabeledValue label="Stripe ID" value={escrow.stripeAccountBalance.id}/>
        <LabeledValue label="Name" value={escrow.name}/>
        <LabeledValue label="Escrow Provider" value={escrow.escrowProvider}/>
        <LabeledValue label="Currency" value={escrow.currency}/>
        <LabeledItem label="Investment Funds">
          <Table sx={{minWidth: 650, mb: 2}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Settled</TableCell>
                <TableCell>Incoming</TableCell>
                <TableCell>Outgoing</TableCell>
                <TableCell>Failed</TableCell>
                <TableCell>Adjustments</TableCell>
                <TableCell>Stripe Fees</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {MoneyFormat.formatNumber(escrow.investmentStats.settled.toNumber(), escrow.currency)}
                </TableCell>
                <TableCell>
                  {MoneyFormat.formatNumber(escrow.investmentStats.incoming.toNumber(), escrow.currency)}
                </TableCell>
                <TableCell>
                  {MoneyFormat.formatNumber(escrow.investmentStats.outgoing.toNumber(), escrow.currency)}
                </TableCell>
                <TableCell>
                  {MoneyFormat.formatNumber(escrow.investmentStats.failed.toNumber(), escrow.currency)}
                </TableCell>
                <TableCell>
                  {MoneyFormat.formatNumber(adjustmentAmount!, escrow.currency)}
                </TableCell>
                <TableCell>
                  {MoneyFormat.formatNumber(stripeFeesAmount!, escrow.currency)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </LabeledItem>

        <Box sx={{my: 3}}/>

        <LabeledItem label="Account Breakdown">
          <AccountRoleBreakdownView data={escrow.accountRoleBreakdown} currency={escrow.currency}/>
        </LabeledItem>

        <Box sx={{my: 3}}/>

        <LabeledItem label="Transfer Breakdown">
          <TransferTagBreakdownView data={escrow.transferTagBreakdown} currency={escrow.currency}/>
        </LabeledItem>

        <Box sx={{my: 3}}/>

        <LabeledItem label="Stripe Account Balance">
          <StripeBalanceTable stripeAccountBalance={escrow.stripeAccountBalance}/>
        </LabeledItem>
      </Stack>
    </Paper>
  );
};

export default EscrowView;