import React, {useCallback, useId, useRef, useState} from "react";

import {Paper} from "@mui/material";
import {pick, startCase} from "lodash";
import {flow, flowResult} from "mobx";
import {observer} from "mobx-react-lite";

import {UserStore} from "../store";
import {User} from "../user";
import {Alert} from "~/components/alert";
import {Button, LoadingButton} from "~/components/button";
import {Icon} from "~/components/icon";
import {LoadingTreatment} from "~/components/loading-treatment";
import {MenuItem} from "~/components/menu-item";
import {Page, PageWidth} from "~/components/page";
import {PersistentTextField} from "~/components/persistent-inputs";
import {Collapse} from "~/components/transitions";
import {inject} from "~/utils/di";
import {preventDefault} from "~/utils/event-helpers";
import {awt} from "~/utils/mobx";
import {useRequiredParams} from "~/utils/routing";
import {InlineDefinition, InlineTerm} from "~/utils/styles";
import {useAbortableEffect} from "~/utils/use-abortable-effect";
import {useCustomValidity} from "~/utils/use-custom-validity";
import {useStateObject} from "~/utils/use-state-object";

export const UserProfile = inject({userStore: UserStore}, observer(function UserProfile({
    userStore
}: {userStore: UserStore}) {
    const {userId} = useRequiredParams("userId");

    const user = userStore.getUserDetails(userId);
    const userUpdateStatus = userStore.getUserUpdateStatus(userId);

    useAbortableEffect(signal => [
        flowResult(userStore.fetchUserDetails(userId, signal)),
        () => userStore.clearUserDetails(userId)
    ], [userStore, userId]);

    const [editMode, setEditMode] = useState(false);
    const enterEditMode = useCallback(() => setEditMode(true), [setEditMode]);
    const leaveEditMode = useCallback(() => setEditMode(false), [setEditMode]);

    const editFormId = useId();

    return (
        <Page
            title={user.case({
                hasValue: user => `${user.firstName} ${user.lastName}`,
                else: () => `User #${userId}`
            })}
            width={PageWidth.MEDIUM}
            actions={
                user.hasStaleOrCurrentValue() && (
                    editMode ? <>
                        {/*
                            Need to put unique keys on these buttons
                            so that the ripple effect doesn't transfer when they switch out
                        */}
                        <Button key="cancel" onClick={leaveEditMode}>Cancel</Button>
                        <LoadingButton
                            type="submit"
                            key="save"
                            form={editFormId}
                            variant="contained"
                            loading={userUpdateStatus.isLoading()}
                        >
                            Save
                        </LoadingButton>
                    </> :
                        <Button key="edit" startIcon={<Icon fa="pencil"/>} onClick={enterEditMode}>Edit</Button>
                )
            }
        >
            <LoadingTreatment loadable={user}>{user =>
                editMode ? <>
                    {userUpdateStatus.hasError() &&
                        <Alert severity="error" sx={{mb: 2}}>{userUpdateStatus.errorMessage}</Alert>
                    }
                    <UserEditForm id={editFormId} onSaved={leaveEditMode} {...{user, userStore}}/>
                </> :
                    <Paper component="dl" sx={{m: 0, p: 2}}>
                        <div>
                            <InlineTerm>Email</InlineTerm>
                            <InlineDefinition>{user.email}</InlineDefinition>
                        </div>
                        <div>
                            <InlineTerm>Role</InlineTerm>
                            <InlineDefinition>{startCase(user.role)}</InlineDefinition>
                        </div>
                    </Paper>
            }</LoadingTreatment>
        </Page>
    );
}));

interface UserEditFormProps {
    readonly user: User;
    readonly id: string;
    readonly userStore: UserStore;
    readonly onSaved: () => void;
}

function UserEditForm({user, id, userStore, onSaved}: UserEditFormProps) {
    const confirmEmailInput = useRef<HTMLInputElement>(null);
    const confirmNewPasswordInput = useRef<HTMLInputElement>(null);

    const [pendingUserInfo, setPendingUserInfo] = useStateObject({
        email: useState(user.email),
        firstName: useState(user.firstName),
        lastName: useState(user.lastName),
        role: useState(user.role)
    });

    const [confirmEmail, setConfirmEmail] = useState("");

    const persistedUserInfo = pick(user, "email", "firstName", "lastName", "role");

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const onNewPasswordChange = useCallback((password: string) => {
        setNewPassword(password);

        if (!password) {
            setConfirmNewPassword("");
        }
    }, [setNewPassword, setConfirmNewPassword]);

    const emailDirty = pendingUserInfo.email !== persistedUserInfo.email;

    useCustomValidity(
        confirmEmailInput,
        "Must match Email Address",
        !emailDirty || confirmEmail === pendingUserInfo.email
    );

    useCustomValidity(
        confirmNewPasswordInput,
        "Must match new password",
        !newPassword || confirmNewPassword === newPassword
    );

    const saveUserInfo = useCallback(() => flow(function*() {
        yield* awt(flowResult(userStore.updateUser(user.id, {
            ...pendingUserInfo,
            ...(newPassword && {password: newPassword})
        })));

        if (userStore.getUserUpdateStatus(user.id).isLoaded()) {
            setConfirmEmail("");
            setNewPassword("");
            setConfirmNewPassword("");
            onSaved();
        }
    })(), [
        userStore,
        pendingUserInfo,
        newPassword,
        setConfirmEmail,
        setNewPassword,
        setConfirmNewPassword,
        user.id,
        onSaved
    ]);

    return (
        //Using a regular <form> element since we're using external buttons and don't need dialog support
        <Paper
            component="form"
            onSubmit={preventDefault(saveUserInfo)}
            sx={{display: "flex", flexDirection: "column", gap: 3, p: 4}}
            {...{id}}
        >
            <PersistentTextField
                type="email"
                label="Email Address"
                value={pendingUserInfo.email}
                persistedValue={persistedUserInfo.email}
                onValueChange={setPendingUserInfo.email}
                required
                autoComplete="email"
            />
            <Collapse in={emailDirty} unmountOnExit>
                <PersistentTextField
                    type="email"
                    inputRef={confirmEmailInput}
                    variant="outlined"
                    label="Confirm Email Address"
                    value={confirmEmail}
                    onValueChange={setConfirmEmail}
                    required
                    autoComplete="email"
                />
            </Collapse>
            <PersistentTextField
                type="password"
                label="Change Password"
                value={newPassword}
                onValueChange={onNewPasswordChange}
                forceDirty={!!newPassword}
                autoComplete="new-password"
            />
            <Collapse in={!!newPassword} unmountOnExit>
                <PersistentTextField
                    type="password"
                    inputRef={confirmNewPasswordInput}
                    label="Confirm New Password"
                    value={confirmNewPassword}
                    onValueChange={setConfirmNewPassword}
                    required
                    autoComplete="new-password"
                />
            </Collapse>
            <PersistentTextField
                label="First Name"
                value={pendingUserInfo.firstName}
                persistedValue={persistedUserInfo.firstName}
                onValueChange={setPendingUserInfo.firstName}
                required
            />
            <PersistentTextField
                label="Last Name"
                value={pendingUserInfo.lastName}
                persistedValue={persistedUserInfo.lastName}
                onValueChange={setPendingUserInfo.lastName}
                required
            />
            <PersistentTextField
                select
                label="Role"
                value={pendingUserInfo.role}
                persistedValue={persistedUserInfo.role}
                onValueChange={setPendingUserInfo.role}
                required
            >
                {User.ROLES.map(role =>
                    <MenuItem key={role} value={role}>{startCase(role)}</MenuItem>
                )}
            </PersistentTextField>
        </Paper>
    );
}
