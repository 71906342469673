import {RefObject, useEffect} from "react";

/**
 * Applies a custom validation to an input
 *
 * @param ref
 * An object ref to the input to be validated.
 * If the ref is not set when the validation effect runs, it will try one more time in a new task.
 * @param description - The description to be provided to the HTML validation API for invalid input
 * @param valid - Whether the input should currently be considered valid
 */
export function useCustomValidity(ref: RefObject<HTMLInputElement>, description: string, valid: boolean): void {
    useEffect(() => {
        if (valid) {
            ref.current?.setCustomValidity("");
        }
        else {
            if (ref.current) {
                ref.current.setCustomValidity(description);
            }
            else {
                setTimeout(() => ref.current?.setCustomValidity(description), 0);
            }
        }
    }, [description, ref, valid]);
}
