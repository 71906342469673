import {AchBatch} from "./ach-batch";
import {AchBatchSummary} from "./ach-batch-summary";
import {BaseApi} from "~/api/base-api";
import {PageParams, SortParams} from "~/api/common-query-params";
import {FetchClient} from "~/api/fetch-client";
import {PaginationEnvelope} from "~/api/pagination-envelope";
import {PaymentCenterError} from "~/errors";
import {checkSignal} from "~/utils/check-signal";

export interface AchBatchFilterParams {
    readonly backendId?: string;
    readonly onlySubmitted?: boolean;
}

export const ACH_BATCH_SORT_KEYS = [{key: "id", label: "ID"}, {key: "submittedAt"}] as const;

export type AchBatchSortKey = (typeof ACH_BATCH_SORT_KEYS)[number]["key"];

export interface AchBatchCreationDto {
    readonly backendId: string;
}

export interface AchBatchUpdateParams {
    readonly excludeItemIds?: readonly string[];
    readonly includeItemIds?: readonly string[];
}

export class AchApi extends BaseApi<AchBatch, AchBatchSummary> {
    public constructor(fetchClient: FetchClient, errorHandler: (err: Error) => boolean) {
        super(fetchClient, errorHandler, "/ach_batches", AchBatch, AchBatchSummary);
    }

    public async getBatch(id: string, signal?: AbortSignal): Promise<AchBatch> {
        return await this.get(id, signal);
    }

    public async getBatches(
        params: PageParams & SortParams<AchBatchSortKey> & AchBatchFilterParams,
        signal?: AbortSignal
    ): Promise<PaginationEnvelope<readonly AchBatchSummary[]>> {
        return await this.find(params, signal);
    }

    public async createBatch(dto: AchBatchCreationDto, signal?: AbortSignal): Promise<AchBatch> {
        return await this.create(dto, signal);
    }

    public async deleteBatch(id: string, signal?: AbortSignal): Promise<void> {
        await this.delete(id, signal);
    }

    public async updateBatch(id: string, params: AchBatchUpdateParams, signal?: AbortSignal): Promise<AchBatch> {
        return await this.fetchJson(AchBatch, "PUT", id, {...params, signal});
    }

    public async markBatchSubmitted(id: string, signal?: AbortSignal): Promise<void> {
        await this.fetchVoid("PUT", `${id}/submitted`, {signal});
    }

    public async downloadNacha(batch: AchBatch, signal?: AbortSignal): Promise<File> {
        if (!batch.fileUrl)
            throw new PaymentCenterError(`Cannot download NACHA: batch ${batch.id} does not have a file URL`);

        try {
            const resp = await this.fetchClient.get(`${batch.id}/download`, {headers: {Accept: "*/*"}, signal});
            checkSignal(signal);

            //This should always be set, but I'm gonna be cautious
            const contentDisposition = resp.headers.get("Content-Disposition") ?? "";
            const [, filename = batch.id + ".ach"] = /filename="(.*)"$/u.exec(contentDisposition) ?? [];

            const data = await resp.blob();
            checkSignal(signal);

            return new File([data], filename, {type: "text/plain"});
        }
        catch (err) {
            this.handleFetchError(err);
        }
    }
}
