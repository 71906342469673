import {StripePayout} from "~/stripe-payouts/stripe-payout";
import {BigNumber} from "bignumber.js";
import {Temporal} from "@js-temporal/polyfill";
import {autobindAll} from "~/utils/autobind";
import {BaseApi} from "~/api/base-api";
import {FetchClient} from "~/api/fetch-client";
import {PageParams, SortParams} from "~/api/common-query-params";
import {PaginationEnvelope} from "~/api/pagination-envelope";
import {StripePayoutTransaction, StripePayoutTransactionWithTransfer} from "~/stripe-payout-transaction/stripe-payout-transaction";

export const STRIPE_PAYOUT_TRANSACTION_SORT_KEY = [{key: "amount"}, {key: "createdAt"}, {key: "status"}] as const;

export type StripePayoutTransactionSortKey = (typeof STRIPE_PAYOUT_TRANSACTION_SORT_KEY)[number]["key"];

export interface StripePayoutTransactionFilterParam {
    readonly status?: StripePayoutTransaction.Status|undefined;
    readonly amountGt?: BigNumber|undefined;
    readonly amountLt?: BigNumber|undefined;
    readonly amountEq?: BigNumber|undefined;
    readonly dateEq?: Temporal.Instant|undefined;
    readonly dateGt?: Temporal.Instant|undefined;
    readonly dateLt?: Temporal.Instant|undefined;
}

@autobindAll
export class StripePayoutTransactionApi extends BaseApi<StripePayoutTransactionWithTransfer> {
    public constructor(fetchClient: FetchClient, errorHandler: (err: Error) => boolean) {
        super(fetchClient, errorHandler, "/line_items/payouts", StripePayoutTransactionWithTransfer);
    }

    public async getTransactions(payoutId: string, filters?: PageParams & SortParams<StripePayoutTransactionSortKey> & StripePayoutTransactionFilterParam | undefined, signal?: AbortSignal): Promise<PaginationEnvelope<StripePayoutTransactionWithTransfer[]>> {
        return await this.find(filters, signal, `${payoutId}/transactions`);
    }

}
