import React, {ForwardedRef, forwardRef, Fragment, ReactNode, useCallback} from "react";

import {Box, experimental_sx as sx, Stack, styled, SxProps, Theme} from "@mui/material";

import {Alert} from "./alert";
import {Button, LoadingButton} from "./button";
import {DialogActions, DialogContent, useDialogHandle} from "./dialog";
import {ErrorContainer} from "~/utils/error-container";
import {Loadable} from "~/utils/loadable";

const SimpleButtonContainer = styled("div")(sx({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    mt: 2,
    gap: 2
}));

export interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
    /** If true, the Submit button is disabled */
    readonly submitDisabled?: boolean;

    /**
     * A Loadable indicating the status of form submission.
     * The result is ignored, but the loading and error states are reflected in the UI.
     */
    readonly submitStatus?: Loadable<unknown>;

    /**
     * Additional errors to display in the form, separate from the status Loadable.
     * Errors can be either ErrorContainers or plain strings.
     * For convenience, false, null, and undefined can also be included in the array and are ignored.
     */
    readonly addErrors?: readonly (ErrorContainer | string | false | null | undefined)[];

    /**
     * Whether to show a Cancel button on the form.
     * Defaults to true if rendering inside a Dialog, otherwise false.
     * If rendering inside a Dialog, pressing Cancel will automatically close it.
     */
    readonly showCancelButton?: boolean;

    /** Called when the cancel button is pressed */
    readonly onCancel?: () => void;

    readonly submitLabel?: string;

    readonly cancelLabel?: string;

    readonly sx?: SxProps<Theme>;

    readonly children?: ReactNode;
}

export const Form = forwardRef(function Form({
    submitDisabled = false,
    submitStatus,
    addErrors,
    showCancelButton,
    onCancel,
    submitLabel = "Submit",
    cancelLabel = "Cancel",
    children,
    sx = [],
    onSubmit,
    ...formAttrs
}: FormProps, ref: ForwardedRef<HTMLFormElement>) {
    const dialogHandle = useDialogHandle();
    const inDialog = !!dialogHandle;

    showCancelButton ??= inDialog;

    const cancel = useCallback(() => {
        dialogHandle?.hide();
        onCancel?.();
    }, [dialogHandle, onCancel]);

    const submit = useCallback((ev: React.FormEvent<HTMLFormElement>) => {
        if (submitDisabled) return;
        onSubmit?.(ev);
    }, [submitDisabled, onSubmit]);

    const OuterContentContainer = inDialog ? DialogContent : Fragment;
    const ButtonContainer = inDialog ? DialogActions : SimpleButtonContainer;

    return (
        <Box component="form" ref={ref} className="PcForm-root" sx={sx} onSubmit={submit} {...formAttrs}>
            <OuterContentContainer>
                <Stack className="PcForm-content" gap={2}>
                    <Stack
                        className="PcForm-errors"
                        gap={1}
                        sx={{
                            mt: inDialog ? -1 : 0, //Looks better against the dialog title spacing
                            mb: 2,

                            "&:empty": {
                                display: "none"
                            }
                        }}
                    >
                        {submitStatus?.hasError() &&
                            <Alert severity="error" sx={{"& .MuiAlert-message": {whiteSpace: "pre-wrap"}}}>
                                {submitStatus.errorMessage}
                            </Alert>
                        }
                        {addErrors?.map((err, i) =>
                            (err !== null && err !== undefined && err !== false) &&
                                <Alert key={i} severity="error" sx={{"& .MuiAlert-message": {whiteSpace: "pre-wrap"}}}>
                                    {typeof err === "string" ? err : err.errorMessage}
                                </Alert>
                        )}
                    </Stack>
                    {children}
                </Stack>
            </OuterContentContainer>
            <ButtonContainer className="PcForm-buttons">
                {showCancelButton &&
                    <Button onClick={cancel}>{cancelLabel}</Button>
                }
                <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={submitDisabled}
                    loading={submitStatus?.isLoading()}
                >
                    {submitLabel}
                </LoadingButton>
            </ButtonContainer>
        </Box>
    );
});
