import {ErrorDto} from "./error-dto";
import {FetchClient, FetchRequestOptions} from "./fetch-client";
import {mapPageData, PaginationEnvelope} from "./pagination-envelope";
import {ApiError, ResponseError} from "~/errors";
import {checkSignal} from "~/utils/check-signal";
import {JsonDto} from "~/utils/json-validation";

/** Abstract base class for Apis. Provides conveniences and common error handling. */
export abstract class BaseApi<T extends JsonDto, Summary extends JsonDto = T> {
    protected readonly fetchClient: FetchClient;

    public constructor(
        fetchClient: FetchClient,
        errorHandler: (err: Error) => boolean,
        baseUrl: string,
        dtoClass: JsonDto.Parseable<T & Summary>
    );
    public constructor(
        fetchClient: FetchClient,
        errorHandler: (err: Error) => boolean,
        baseUrl: string,
        dtoClass: JsonDto.Parseable<T>,
        summaryClass: JsonDto.Parseable<Summary>
    );
    public constructor(
        fetchClient: FetchClient,
        protected readonly errorHandler: (err: Error) => boolean,
        baseUrl: string,
        protected readonly dtoClass: JsonDto.Parseable<T>,
        protected readonly summaryClass: JsonDto.Parseable<Summary> = dtoClass as any
    ) {
        this.fetchClient = fetchClient.childClient(baseUrl);
    }

    protected handleFetchError(err: unknown): never {
        let apiError: ApiError | null = null;
        if (err instanceof Error) {
            //Just propagate AbortErrors
            if (err instanceof DOMException && err.name === "AbortError") throw err;

            //Wrap well-formed API errors in a more specific type
            if (err instanceof ResponseError && err.responseBody) {
                let json: unknown = undefined;
                try {
                    json = JSON.parse(err.responseBody);
                }
                catch {
                    //Swallow
                }

                if (ErrorDto.isErrorDto(json)) {
                    apiError = new ApiError(json, err);
                }
            }

            //Additional error handling can be passed in. Convert handled errors to AbortErrors.
            if (this.errorHandler(apiError ?? err))
                //DOMException's constructor doesn't accept a `cause` parameter, but the property is mutable
                throw Object.assign(new DOMException("An API exception was automatically handled", "AbortError"), {
                    cause: err
                });
        }

        throw apiError ?? err;
    }

    /** Convenience method for making void-returning API calls. Includes common error handling */
    protected async fetchVoid(method: string, path: string, options: FetchRequestOptions): Promise<void> {
        try {
            await this.fetchClient.fetch(path, {method, ...options});
        }
        catch (err) {
            this.handleFetchError(err);
        }
    }

    /** Convenience method for fetching JSON, respecting any AbortSignal provided */
    protected async fetchJson<T extends JsonDto, ExpectArray extends boolean = false>(
        dtoClass: JsonDto.Parseable<T>,
        method: string,
        path: string,
        {
            signal,
            array = false as ExpectArray,
            ...otherOptions
        }: FetchRequestOptions & {readonly array?: ExpectArray} = {}
    ): Promise<ExpectArray extends true ? PaginationEnvelope<T[]> : T> {
        try {
            const resp = await this.fetchClient.fetch(path, {method, signal, ...otherOptions});
            checkSignal(signal);

            const json = await resp.json();
            checkSignal(signal);

            if (array) {
                //Leaving PaginationEnvelope as a sloppy typecast so I don't have to deal with generics in JsonDto
                const paginationEnvelope = json as PaginationEnvelope<any[]>;

                //eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return mapPageData(paginationEnvelope, d => JsonDto.constructArray(dtoClass, d)) as any;
            }
            else {
                //eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return JsonDto.construct(dtoClass, json.data) as any;
            }
        }
        catch (err) {
            this.handleFetchError(err);
        }
    }

    //These methods are protected rather than public so that they can be named/typed appropriately

    /** Searches for records based on query parameters */
    protected async find(
        queryParams?: {readonly [param: string]: any},
        signal?: AbortSignal,
        path: string = ""
    ): Promise<PaginationEnvelope<Summary[]>> {
        return await this.fetchJson(this.summaryClass, "GET", path, {queryParams, signal, array: true});
    }

    /** Gets all the records in the collection */
    protected async getAll(signal?: AbortSignal, path: string = ""): Promise<Summary[]> {
        return (await this.find(undefined, signal, path)).data;
    }

    /** Gets a single record by ID */
    protected async get(id: string, signal?: AbortSignal, idToPath: (id: string) => string = x => x): Promise<T> {
        return await this.fetchJson(this.dtoClass, "GET", idToPath(id), {signal});
    }

    /** Creates a record */
    protected async create(creationDto: object, signal?: AbortSignal, path: string = ""): Promise<T> {
        return await this.fetchJson(this.dtoClass, "POST", path, {json: creationDto, signal});
    }

    protected async put(
        id: string,
        dto: object,
        signal?: AbortSignal,
        idToPath: (id: string) => string = x => x
    ): Promise<T> {
        return await this.fetchJson(this.dtoClass, "PUT", idToPath(id), {json: dto, signal});
    }

    /** Deletes a record */
    protected async delete(id: string, signal?: AbortSignal, idToPath: (id: string) => string = x => x): Promise<void> {
        return await this.fetchVoid("DELETE", idToPath(id), {signal});
    }
}
