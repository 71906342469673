import {Currency} from "~/currency";
import {BigNumber} from "bignumber.js";

export namespace MoneyFormat {
    type FormatOptions = {negativesWithParenthesis: boolean};

    export const defaultFormatOptions: FormatOptions = {
        negativesWithParenthesis: false
    };

    function processFormatOptions(formatted: string, options: FormatOptions) {
        const addParenthesis = (value: string) => {
            if (!value.startsWith("-")) return value;
            return `(${value.substring(1)})`;
        };

        return options.negativesWithParenthesis ? addParenthesis(formatted) : formatted;
    }

    export function currencySymbol(currency: Currency | undefined): String {
        if (currency === undefined) {
            return "";
        } else {
            return currency === "usd" ? "$" : "€";
        }
    }

    export function formatBigNumber(value: BigNumber, currency: Currency | undefined | null = "usd", options: FormatOptions = defaultFormatOptions) {
        return processFormatOptions(value.toFormat({
            decimalSeparator: ".",
            groupSeparator: ",",
            groupSize: 3,
            secondaryGroupSize: 0,
            fractionGroupSeparator: " ",
            fractionGroupSize: 0,
            prefix: !currency || currency === "usd" ? "$" : "€"
        }), options);
    }

    export function formatNumber(value: number | BigInt, currency: Currency | undefined | null = "usd", options: FormatOptions = defaultFormatOptions) {
        return processFormatOptions(value.toLocaleString(undefined, {style: "currency", currency: currency ?? "usd"}), options);
    }
}
