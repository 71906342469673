import React, {useEffect, useReducer, useRef} from "react";
import {Navigate, RouteObject, useLocation, useRoutes} from "react-router-dom";

import {Box, Stack} from "@mui/material";
import {flowResult} from "mobx";
import {observer} from "mobx-react-lite";

import {VirtualAccountDashboard} from "./account/views/virtual-account-dashboard";
import {VirtualAccountDetailsPage} from "./account/views/virtual-account-details-page";
import {AchBatchDashboard} from "./ach/views/ach-batch-dashboard";
import {SingleAchBatchPage} from "./ach/views/single-ach-batch-page";
import {AuthStore} from "./auth/store";
import {SignIn} from "./auth/views/sign-in";
import {SignInChallenge} from "./auth/views/sign-in-challenge";
import {TOTPRegistration} from "./auth/views/totp-registration";
import {defaultRenderError, LoadingTreatment} from "./components/loading-treatment";
import {LinearProgress} from "./components/progress";
import {Header} from "./header";
import {Home} from "./home";
import monaySrc from "./monay.png";
import {NavSidebar, NavSidebarStateProvider} from "./nav-sidebar";
import {Settings} from "./settings";
import {TransferDashboard} from "./transfer/views/transfer-dashboard";
import {TransferDetailsPage} from "./transfer/views/transfer-details-page";
import {UserTaskDashboard} from "./user-task/views/user-task-dashboard";
import {UserTaskDetailsPage} from "./user-task/views/user-task-details-page";
import {UserStore} from "./user/store";
import {User} from "./user/user";
import {UserManagementDashboard} from "./user/views/user-management-dashboard";
import {UserProfile} from "./user/views/user-profile";
import {inject} from "./utils/di";
import {useAbortableEffect} from "./utils/use-abortable-effect";
import {usePreviousValue} from "./utils/use-previous-value";
import {InitiateTransferPage} from "./transfer/views/initiate-transfer-page";
import {ReconPage} from "~/recon/recon-page";
import {useQuery} from "react-query";
import Escrow from "./repositories/escrow";
import EscrowView, {EscrowDashboard} from "./escrow/escrow-view";
import DisbursementPage from "~/disbursement/disbursement-page";

type KonamiState =
    | null
    | "Up1"
    | "Up2"
    | "Down1"
    | "Down2"
    | "Left1"
    | "Right1"
    | "Left2"
    | "Right2"
    | "B"
    | "A"
    | "Enter";

function reduceKonami(state: KonamiState, key: string): KonamiState {
    switch (state) {
        case null:
            if (key === "ArrowUp") return "Up1";
            return null;
        case "Up1":
            if (key === "ArrowUp") return "Up2";
            return null;
        case "Up2":
            if (key === "ArrowDown") return "Down1";
            return null;
        case "Down1":
            if (key === "ArrowDown") return "Down2";
            return null;
        case "Down2":
            if (key === "ArrowLeft") return "Left1";
            return null;
        case "Left1":
            if (key === "ArrowRight") return "Right1";
            return null;
        case "Right1":
            if (key === "ArrowLeft") return "Left2";
            return null;
        case "Left2":
            if (key === "ArrowRight") return "Right2";
            return null;
        case "Right2":
            if (key === "b") return "B";
            return null;
        case "B":
            if (key === "a") return "A";
            return null;
        case "A":
            if (key === "Enter") return "Enter";
            return null;
        case "Enter":
            return "Enter";
    }
}

//Exported for testing
export function getRoutes({loggedInUser}: {loggedInUser: User | null}): RouteObject[] {
    const loggedOutRoutes: RouteObject[] = [
        {path: "signin", children: [
            {index: true, element: <SignIn/>},
            {path: "challenge", element: <SignInChallenge/>}
        ]},
        {path: "*", element: <Navigate to="signin" replace/>}
    ];

    const loggedInRoutes: RouteObject[] = [
        {path: "signin", children: [
            {index: true, element: <Navigate to="/" replace/>},
            {path: "challenge", element: <Navigate to="/" replace/>}
        ]},

        {index: true, element: <Home/>},
        {path: "settings", element: <Settings/>},

        {path: "escrow/:backend_id", element: <EscrowDashboard/>},

        {path: "transfers", children: [
            {index: true, element: <TransferDashboard/>},
            {path: "create", element: <InitiateTransferPage/>},
            {path: ":transferId", element: <TransferDetailsPage/>}
        ]},

        {path: "accounts", children: [
            {index: true, element: <VirtualAccountDashboard/>},
            {path: ":accountId", element: <VirtualAccountDetailsPage/>}
        ]},

        {path: "ach-batches", children: [
            {index: true, element: <AchBatchDashboard/>},
            {path: ":batchId", element: <SingleAchBatchPage/>}
        ]},

        {path: "recon", children: [
            {index: true, element: <ReconPage/>}
        ]},
        {path: "disbursements", children: [
          {index: true, element: <DisbursementPage/>}
        ]},
        {path: "tasks", children: [
            {index: true, element: <UserTaskDashboard/>},
            {path: ":userTaskId", element: <UserTaskDetailsPage/>}
        ]},

        ...(loggedInUser?.role === "admin" ? [
            {path: "users", children: [
                {index: true, element: <UserManagementDashboard/>},
                {path: ":userId", element: <UserProfile/>}
            ]}
        ] : []),

        //TODO: Do we want a 404 page?
        {path: "*", element: <Navigate to="/" replace/>}
    ];

    return [
        //Due to its necessity as part of both the login flow and Settings,
        //the TOTP registration page is accessible whether you're logged in or not
        {path: "register-totp", element: <TOTPRegistration/>},
        ...(loggedInUser ? loggedInRoutes : loggedOutRoutes)
    ];
}

const RouterView = ({loggedInUser}: {loggedInUser: User | null}) => useRoutes(getRoutes({loggedInUser}));

/** The root component of the application */
export const App = inject({authStore: AuthStore, userStore: UserStore}, observer(function App({
    authStore,
    userStore
}: {authStore: AuthStore, userStore: UserStore}) {
    const {authentication} = authStore;
    const {currentUser} = userStore;

    const location = useLocation();
    const previousPathname = usePreviousValue(location.pathname);

    const skipLink = useRef<HTMLAnchorElement>(null);

    //Eagerly fetch the current user upon signing in to make sure our session is still valid
    const signedIn = authentication.valueOrNull() !== null;
    useAbortableEffect(async signal => {
        if (signedIn) {
            await flowResult(userStore.fetchCurrentUser(signal));
        }
        else {
            //Make sure to clear it when we sign out
            userStore.clearUserInfo();
        }
    }, [signedIn, userStore]);

    const [konamiState, handleKonamiKeypress] = useReducer(reduceKonami, null);

    useEffect(() => {
        const onKeyDown = (ev: KeyboardEvent) => handleKonamiKeypress(ev.key);
        document.addEventListener("keydown", onKeyDown);
        return () => document.removeEventListener("keydown", onKeyDown);
    }, [handleKonamiKeypress]);

    //Focus the skip link when the view changes
    useEffect(() => {
        if (previousPathname !== null && location.pathname !== previousPathname) {
            skipLink.current!.focus();
        }
    }, [previousPathname, location.pathname, skipLink]);

    const loadingWidth = 8 / 10;

    const renderLoading = () => <LinearProgress sx={{width: loadingWidth, m: "auto"}}/>;

    return (
        <Box
            sx={[
                {
                    width: 1,
                    height: 1,
                    display: "grid",
                    grid: `
                        "header header"        auto
                        "nav    view"          minmax(0, 1fr) /
                         auto   minmax(0, 1fr)
                    `
                },
                konamiState === "Enter" && {
                    backgroundImage: `url(${monaySrc})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }
            ]}
        >
            <Header sx={{gridArea: "header"}} skipLinkRef={skipLink}/>

            <NavSidebarStateProvider>
                <NavSidebar sx={{gridArea: "nav"}}/>
                <Stack gridArea="view" alignItems="center" sx={[konamiState === "Enter" && {textShadow: "2px 2px black"}]}>
                    <LoadingTreatment
                        loadable={authentication}
                        renderLoading={renderLoading}
                        renderError={() => <RouterView loggedInUser={null}/>}
                    >{auth =>
                        auth ?
                            <LoadingTreatment
                                loadable={currentUser}
                                renderLoading={renderLoading}
                                renderError={(errorMessage, error) =>
                                    <Box width={loadingWidth}>
                                        {defaultRenderError()(errorMessage, error)}
                                    </Box>
                                }
                            >{user =>
                                <RouterView loggedInUser={user}/>
                            }</LoadingTreatment>
                        :
                            <RouterView loggedInUser={null}/>
                    }</LoadingTreatment>
                </Stack>
            </NavSidebarStateProvider>

        </Box>
    );
}));
