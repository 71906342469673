import React, {ForwardedRef, ReactNode, useEffect, useId, useImperativeHandle, useRef} from "react";

import {bindPopover, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";

import {Button, ButtonProps, IconButton, IconButtonProps} from "./button";
import {Popover, PopoverProps} from "./popover";
import {forwardRefGeneric} from "~/utils/forward-ref-generic";
import {usePreviousValue} from "~/utils/use-previous-value";

export interface FlyoutHandle {
    readonly open: (context?: any) => void;
    readonly close: (context?: any) => void;
}

export interface FlyoutProps<ButtonVariant extends ButtonProps["variant"] | "icon" = "outlined"> {
    readonly buttonVariant?: ButtonVariant;
    readonly label?: string;
    readonly icon?: ReactNode;
    //Omitting the props controlled by PopupState entirely for now. Can worry about wrapping them if I need them later.
    readonly buttonProps?: Omit<
        ButtonVariant extends "icon" ? Omit<IconButtonProps, "title"> : ButtonProps,
        "variant" | "start" | keyof ReturnType<typeof bindTrigger>
    >;
    readonly renderTrigger?: (props: ReturnType<typeof bindTrigger>) => ReactNode;
    readonly popoverProps?: Omit<PopoverProps, keyof ReturnType<typeof bindPopover>>;
    readonly onOpen?: (context: any) => void;
    readonly onClose?: (context: any) => void;
    readonly children?: ReactNode;
}

/** Convenience component combining a Popover with a button using material-ui-popup-state */
export const Flyout = forwardRefGeneric(function Flyout<
    ButtonVariant extends ButtonProps["variant"] | "icon" = "outlined"
>({
    buttonVariant,
    label,
    icon,
    renderTrigger,
    buttonProps,
    popoverProps,
    onOpen,
    onClose,
    children
}: FlyoutProps<ButtonVariant>, ref: ForwardedRef<FlyoutHandle>) {
    if (!renderTrigger && !label) throw new TypeError("label is required if not passing renderTrigger");

    const popupState = usePopupState({variant: "popover", popupId: useId()});

    //Stupidly, Popover only has an onClose event, but not an onOpen,
    //plus the Flyout API needs to allow passing context values.
    //Solution: fire the event listeners from effects, storing context in a ref.
    const openCloseContext = useRef<any>(undefined);

    useImperativeHandle(ref, () => ({
        open(context) {
            if (!popupState.isOpen) {
                openCloseContext.current = context;
                popupState.open();
            }
        },

        close(context) {
            if (popupState.isOpen) {
                openCloseContext.current = context;
                popupState.close();
            }
        }
    }), [popupState]);

    const wasOpen = usePreviousValue(popupState.isOpen);
    useEffect(() => {
        if (wasOpen !== null && popupState.isOpen !== wasOpen) {
            if (popupState.isOpen) {
                onOpen?.(openCloseContext.current);
            }
            else {
                onClose?.(openCloseContext.current);
            }

            openCloseContext.current = undefined;
        }
    }, [onClose, onOpen, popupState.isOpen, wasOpen, openCloseContext]);

    return <>
        {renderTrigger ?
            renderTrigger(bindTrigger(popupState))
        : buttonVariant === "icon" ?
            <IconButton title={label!} {...buttonProps} {...bindTrigger(popupState)}>{icon}</IconButton>
        :
            <Button
                variant={buttonVariant}
                {...buttonProps as any}
                {...bindTrigger(popupState)}
                start={icon}
            >
                {label}
            </Button>
        }
        <Popover {...bindPopover(popupState)} {...popoverProps}>
            {children}
        </Popover>
    </>;
});
