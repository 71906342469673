import React, {
    createContext,
    ForwardedRef,
    forwardRef,
    useCallback,
    useContext,
    useImperativeHandle,
    useMemo,
    useState
} from "react";

import {
    Box,
    Dialog as MuiDialog,
    DialogProps as MuiDialogProps,
    DialogTitle,
    experimental_sx as sx
} from "@mui/material";
import {ConfirmOptions as _ConfirmOptions, useConfirm as _useConfirm} from "material-ui-confirm";

import {IconButton} from "./button";
import {Icon} from "./icon";
import {ComponentOverrides} from "./mui-theme-utils";
import {onKey} from "~/utils/event-helpers";
import {LazyJsx, renderLazy} from "~/utils/lazy-jsx";

export {
    DialogActions,
    DialogActionsProps,
    DialogContent,
    DialogContentProps,
    DialogContentText,
    DialogContentTextProps
} from "@mui/material";

export const dialogOverrides: ComponentOverrides = {
    MuiDialogActions: {
        styleOverrides: {
            root: sx({
                //The default DialogActions padding is less than the title and content,
                //because it's designed for text-style buttons.
                //I use filled/outlined buttons in this app, so the padding needs to match to look right.
                px: 3,
                pb: 2
            })
        }
    }
};

export interface DialogHandle {
    readonly show: () => void;
    readonly hide: () => void;
}

const dialogContext = createContext<DialogHandle | null>(null);

/** If being rendered inside a Dialog, returns its handle */
export const useDialogHandle = () => useContext(dialogContext);

export interface DialogProps extends Omit<MuiDialogProps, "open" | "children"> {
    /**
     * Whether the dialog is visible by default.
     * Use the `show()` and `hide()` methods on the component handle to open and close the dialog.
     */
    readonly defaultOpen?: boolean;

    /** Hides the default close button */
    readonly hideCloseButton?: boolean;

    /** The title of the dialog */
    readonly dialogTitle?: LazyJsx;

    readonly children?: LazyJsx;
}

export const Dialog = forwardRef(function Dialog({
    defaultOpen = false,
    hideCloseButton = false,
    onClose: onCloseProp,
    dialogTitle,
    //Override the default for maxWidth to disable it by default.
    //For some reason setting this default in the theme overrides doesn't work.
    maxWidth = false,
    children,
    ...props
}: DialogProps, ref: ForwardedRef<DialogHandle>) {
    const [open, setOpen] = useState(defaultOpen);

    const show = useCallback(() => setOpen(true), [setOpen]);

    const hide = useCallback(() => setOpen(false), [setOpen]);

    const onClose = useCallback((ev: {}, reason: "backdropClick" | "escapeKeyDown") => {
        onCloseProp?.(ev, reason);
        if (!(ev as Event).defaultPrevented) { //Why is ev typed as {} in the Dialog/Modal API??
            hide();
        }
    }, [hide, onCloseProp]);

    const handle = useMemo(() => ({show, hide}), [show, hide]);
    useImperativeHandle(ref, () => handle, [handle]);

    return (
        <dialogContext.Provider value={handle}>
            <MuiDialog {...{open, onClose, maxWidth}} {...props}>
                {open && <>
                    {(dialogTitle !== undefined || !hideCloseButton) &&
                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 1}}>
                            {dialogTitle !== undefined &&
                                <DialogTitle sx={{pr: 0}}>{renderLazy(dialogTitle)}</DialogTitle>
                            }
                            {!hideCloseButton &&
                                //Right margin is eyeballed to get the X to line up with the content
                                <IconButton sx={{my: 1, ml: "auto", mr: "12px"}} title="Close" onClick={hide}>
                                    <Icon fa="close"/>
                                </IconButton>
                            }
                        </Box>
                    }
                    {renderLazy(children)}
                </>}
            </MuiDialog>
        </dialogContext.Provider>
    );
});

export interface ConfirmOptions extends _ConfirmOptions {
    /**
     * If true, the action is presented as dangerous.
     * Currently this just affects button colors.
     */
    readonly dangerous?: boolean;
}

export function useConfirm(): (options: ConfirmOptions) => Promise<boolean | null> {
    const _confirm = _useConfirm();
    return useCallback(({dangerous, confirmationButtonProps, dialogProps, ...options}: ConfirmOptions) =>
        //Ugh, why another API that uses Promise rejection in place of just *returning false*. This isn't an error!!
        //Additional stupidity: if you close the dialog via the backdrop, the Promise *never resolves*,
        //possibly leaving stuff in a loading state (Apparently this is intentional??). So we need to do this nonsense.
        new Promise(resolve => {
            _confirm({
                confirmationButtonProps: {
                    color: dangerous ? "error" : "primary",
                    ...confirmationButtonProps
                },
                dialogProps: {
                    ...dialogProps,
                    //useConfirm clobbers onClose, so I'm having to use the old deprecated event listeners instead...
                    onBackdropClick: () => resolve(null),
                    onKeyDown: onKey("Escape", () => resolve(null))
                },
                ...options
            })
                .then(() => resolve(true))
                .catch(() => resolve(false));
        }),
    [_confirm]);
}
