import {useRef} from "react";

import {useAbortableEffect} from "../use-abortable-effect";
import {Loadable} from "./index";

/**
 * Returns the given Loadable, calling `fetch` if it is in the pending state.
 * Intended for use with store values that might have already been fetched by another component.
 * Only the given dependencies are watched for changes. You should not include the Loadable in dependencies.
 */
export function useFetchIfPending<T>(
    loadable: Loadable<T>,
    fetch: (signal: AbortSignal) => Promise<void>,
    fetchDeps: readonly unknown[]
): Loadable<T> {
    //This works around what I'm fairly sure is an issue exclusive to React 18 Strict Mode double-mount:
    //The double-mount happens so quickly (maybe synchronously?) that the second mount happens
    //*before the AbortSignal handlers run*.
    //This causes a problem where, on the second mount, the hook sees *its own loading state*
    //and fails to make the second (real) fetch call.
    //I don't think this could ever happen from user-land offscreening, as the second mount has to happen
    //so quickly that it beats the (immediately enqueued) AbortSignal handlers.
    //Because this is most likely a development-only issue, I'm okay with a slightly hacky solution:
    //use a ref to track whether *we* triggered the loading state, and if it is true when the hook re-runs,
    //call fetch again regardless of the state.
    //To prevent this from causing extra fetch calls, the Loadable itself is not included in the dependency array.
    const weAreLoading = useRef(false);

    useAbortableEffect(async signal => {
        try {
            if (loadable.isPending() || weAreLoading.current) {
                weAreLoading.current = true;
                await fetch(signal);
            }
        }
        finally {
            weAreLoading.current = false;
        }
    }, fetchDeps);

    return loadable;
}
