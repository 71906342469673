import {JsonDto, jsonProp} from "~/utils/json-validation";

export class TOTPSetup extends JsonDto {
    @jsonProp()
    public readonly sessionToken!: string;

    @jsonProp()
    public readonly otpAuthUri!: string;

    @jsonProp()
    public readonly otpSecret!: string;

    public constructor(init?: unknown) {
        super();
        this.init(TOTPSetup, init);
    }
}
