import React from "react";
import {Transfer} from "../transfer";
import Paper from "@mui/material/Paper";
import {capitalize, first, sortBy, startCase} from "lodash";
import {IfDefined, RenderIf} from "~/recon/util";
import {Stack, Typography} from "@mui/material";
import {MoneyFormat} from "~/utils/money";
import {VirtualAccount} from "~/account/virtual-account";
import {Entity} from "~/entity/entity";
import BasicTable from "~/components/basic-table";
import {TransferEvent} from "../transfer-event";
import {ExternalLink, LabeledItem, LabeledItemWithDetails, LabeledValue} from "~/components/labeled-item";
import {LineItem, LineItemEntry} from "~/recon/line-item";
import Recon from "~/recon/recon";
import lineItems from "~/repositories/line-items";


const StripePaymentMethodData = ({data}: { data: any }) => {
  if (data === null || data === undefined) {
    return null;
  } else if (data.type === "credit_card") {
    return <span>{data.brand} Credit Card ending in {data.lastFour}</span>;
  } else {
    return <span>{data.country} SEPA bank account.</span>;
  }
};

export const VirtualAccountSummary = ({account}: { account: VirtualAccount | null }) => {
  if (account === null) {
    return null;
  } else {
    return (
      <div>
        {account.name} - {startCase(account.backend.backendType)}
        <ExternalLink url={`/accounts/${account.id}`}/>
      </div>
    );
  }
};

export const VirtualAccountDetails = ({account}: { account: VirtualAccount | null }) => {
  if (account === null) {
    return null;
  } else {
    return (
      <>
        <LabeledItem label="ID">
          {account.id}
        </LabeledItem>
        <LabeledItem label="Currency">
          {account.currency}
        </LabeledItem>
        <LabeledItem label="Backend">
          #{account.backend.id} {account.backend.name} - {startCase(account.backend.backendType)}
        </LabeledItem>
        {account.stateLanes.filter(lane => !lane.amount.isZero()).map((lane) =>
          <LabeledItem label={`${lane.laneType} amount`}>
            {MoneyFormat.formatBigNumber(lane.amount, account.currency)}
          </LabeledItem>
        )}
      </>
    );
  }
};

const InvestorDetails = ({investor}: { investor: Entity }) =>
  <LabeledItemWithDetails label="Investor" summary={investor.data?.fullName}>
    <LabeledValue label="Id" value={investor.externalId}/>
    <LabeledValue label="First Name" value={investor.data?.firstName}/>
    <LabeledValue label="Last Name" value={investor.data?.lastName}/>
    <LabeledValue label="Username" value={investor.data?.username}/>
    <LabeledValue label="Email" value={investor.data?.email}/>
    <LabeledValue label="City" value={investor.data?.city}/>
  </LabeledItemWithDetails>;

const InvestmentDetails = ({investment}: { investment: Entity }) =>
  <LabeledItemWithDetails label="Investment" summary={<span>{investment.externalId}<ExternalLink url={`https://wefunder.com/admin/investments/${investment.externalId}`} newTab/></span>}>
    <LabeledValue label="Id" value={investment.externalId}/>
    <LabeledValue label="State" value={investment.data.state}/>
    <LabeledValue label="Amount" value={investment.data.investmentAmount}/>
  </LabeledItemWithDetails>;

export const FundraiseDetails = ({fundraise}: { fundraise: Entity }) =>
  <LabeledItemWithDetails label="Fundraise" summary={<span>{fundraise.externalId}</span>}>
    <LabeledValue label="Id" value={fundraise.externalId}/>
    <LabeledValue label="State" value={fundraise.data.state}/>
    <LabeledValue label="Structure" value={fundraise.data.structure}/>
    <LabeledValue label="Funding Type" value={fundraise.data.fundingType}/>
    <LabeledValue label="Offering Type" value={fundraise.data.offeringType}/>
  </LabeledItemWithDetails>;

export const CompanyDetails = ({company}: { company: Entity }) =>
  <LabeledItemWithDetails label="Company" summary={<span>{company.data?.name}</span>}>
    <LabeledValue label="Id" value={company.externalId}/>
    <LabeledValue label="City" value={company.data?.city}/>
    <LabeledValue label="State" value={company.data?.state}/>
    <LabeledValue label="Country" value={company.data?.country}/>
    <LabeledValue label="Tagline" value={company.data?.tagline}/>
  </LabeledItemWithDetails>;


const LineItemDetails = ({lineItemEntry}: {lineItemEntry: LineItemEntry}) => {
  if (lineItemEntry.type === "vitesse_account_entry") {
    return (
      <>
        <LabeledItem label="Account Id">{`${lineItemEntry.accountId} (Vitesse)`}</LabeledItem>
        <LabeledItem label="Account Entry Id">{lineItemEntry.accountEntryId}</LabeledItem>
        <LabeledItem label="Narative">{lineItemEntry.narrative}</LabeledItem>
        <LabeledItem label="Reference 1">{lineItemEntry.reference1}</LabeledItem>
        <LabeledItem label="Reference 2">{lineItemEntry.reference2}</LabeledItem>
      </>
    );
  }
  return null;
};

const ReconInfo = ({lineItem, recon}: {lineItem: LineItem, recon: Recon}) => (
  <Paper sx={{p: 2}}>
    <Stack direction="column">
      <LabeledItem label="Recon Id">{recon.id}</LabeledItem>
      <LabeledItem label="Recon State"><b>{capitalize(recon.state)}</b></LabeledItem>
      <LabeledItem label="Recon Target">{lineItem.target?.type || ""}</LabeledItem>
      <LabeledItem label="Recon Amount"><b>{MoneyFormat.formatBigNumber(recon.amount, lineItem.currency)}</b></LabeledItem>
      <Typography variant="body2" sx={{my: 2}}>Reconciled against line item:</Typography>
      <LabeledItem label="Line Item Id">{lineItem.id}</LabeledItem>
      <LabeledItem label="Line Item Amount"><b>{MoneyFormat.formatBigNumber(lineItem.amount, lineItem.currency)}</b></LabeledItem>
      <LineItemDetails lineItemEntry={lineItem.entry}/>
      <LabeledItem label="Created At">{lineItem.date.toLocaleString()}</LabeledItem>
    </Stack>
  </Paper>
);

export const TransferView = ({transfer}: { transfer: Transfer }) => {
  const hasStripeServiceFee = transfer.intention === "investment_charge" && transfer.children.length === 1 && transfer.children[0].tag === "stripe_service_fees";
  const stripeServiceFees = hasStripeServiceFee ? transfer.children[0].amount : null;
  const events = sortBy(transfer.events, e => e.id);
  const firstEvent = first(events);
  const eventsColumns = [
    {id: "id", title: "ID"},
    {id: "state", title: "State"},
    {id: "message", title: "Message"},
    {id: "occuredAt", title: "Occured At", valueGetter: (v: TransferEvent) => v.occurredAt.toLocaleString()}
  ];

  return (
    <Stack spacing={4}>
      <Paper sx={{p: 2}}>
        <Stack direction="column">
          <LabeledItem label="Amount">
            {MoneyFormat.formatBigNumber(transfer.amount, transfer.currency)}
          </LabeledItem>
          <RenderIf cond={hasStripeServiceFee}>
            <LabeledItem label="Stripe Service Fee">
              {stripeServiceFees ? MoneyFormat.formatBigNumber(stripeServiceFees, transfer.currency) : null}
            </LabeledItem>
          </RenderIf>
          <LabeledItem label="State">
            {startCase(transfer.state)}
          </LabeledItem>
          <LabeledItem label="Tag">
            {startCase(transfer.tag)}
          </LabeledItem>
          <IfDefined value={transfer.intentionData?.reference}>
            <LabeledItem label="Reference">
              {transfer.intentionData?.reference}
            </LabeledItem>
          </IfDefined>

          <IfDefined value={firstEvent?.occurredAt}>
            <LabeledItem label="Created At">
              {firstEvent?.occurredAt.toLocaleString()}
            </LabeledItem>
          </IfDefined>

          <IfDefined value={transfer.fromAccount}>
            <LabeledItemWithDetails label="From" summary={<VirtualAccountSummary account={transfer.fromAccount}/>}>
              <VirtualAccountDetails account={transfer.fromAccount}/>
            </LabeledItemWithDetails>
          </IfDefined>
          <IfDefined value={transfer.toAccount}>
            <LabeledItemWithDetails label="To" summary={<VirtualAccountSummary account={transfer.toAccount}/>}>
              <VirtualAccountDetails account={transfer.toAccount}/>
            </LabeledItemWithDetails>
          </IfDefined>

          <IfDefined value={transfer.investor}>
            <InvestorDetails investor={transfer.investor!}/>
          </IfDefined>

          <IfDefined value={transfer.investment}>
            <InvestmentDetails investment={transfer.investment!}/>
          </IfDefined>

          <IfDefined value={transfer.fundraise}>
            <FundraiseDetails fundraise={transfer.fundraise!}/>
          </IfDefined>

          <IfDefined value={transfer.company}>
            <CompanyDetails company={transfer.company!}/>
          </IfDefined>

          <IfDefined value={transfer.stripeData?.paymentIntent}>
            <LabeledItemWithDetails label="Stripe Payment Intent" summary={<span>{transfer.stripeData?.paymentIntent?.id} <ExternalLink url={`https://dashboard.stripe.com/payments/${transfer.stripeData?.paymentIntent?.id}`} newTab/></span>}>
              <LabeledValue label="Amount" value={transfer.stripeData?.paymentIntent?.amount}/>
              <LabeledValue label="Fee" value={transfer.stripeData?.paymentIntent?.stripeFeeAmount}/>
              <LabeledValue label="Status" value={transfer.stripeData?.paymentIntent?.status}/>
              <LabeledValue label="Description" value={transfer.stripeData?.paymentIntent?.description}/>
              <LabeledValue label="Account Id" value={transfer.stripeData?.paymentIntent?.stripeAccountId}/>
              <LabeledValue label="Payment Method Id" value={transfer.stripeData?.paymentIntent?.stripePaymentMethodId}/>
              <LabeledValue label="Customer Id" value={transfer.stripeData?.paymentIntent?.stripeCustomerId}/>
              <LabeledValue label="Created At" value={transfer.stripeData?.paymentIntent?.createdAt}/>
            </LabeledItemWithDetails>
          </IfDefined>

          <IfDefined value={transfer.stripeData?.paymentMethod}>
            <LabeledItemWithDetails label="Stripe Payment Method" summary={transfer.stripeData?.paymentMethod?.id}>
              <LabeledItem label="Details">
                <StripePaymentMethodData data={transfer.stripeData?.paymentMethod?.paymentMethodData}/>
              </LabeledItem>
              <LabeledItem label="Off Session">
                {(transfer.stripeData?.paymentMethod?.offSession as boolean || false).toString()}
              </LabeledItem>
              <LabeledValue label="Platform PM Id" value={transfer.stripeData?.paymentMethod?.platformPaymentMethodId}/>
              <LabeledValue label="Account Id" value={transfer.stripeData?.paymentMethod?.stripeAccountId}/>
              <LabeledValue label="Customer Id" value={transfer.stripeData?.paymentMethod?.stripeCustomerId}/>
              <LabeledValue label="Created At" value={transfer.stripeData?.paymentMethod?.createdAt}/>
            </LabeledItemWithDetails>
          </IfDefined>

          <IfDefined value={transfer.stripeData?.customer}>
            <LabeledItemWithDetails label="Stripe Customer" summary={transfer.stripeData?.customer?.id}>
              <LabeledValue label="Platform Customer Id" value={transfer.stripeData?.customer?.platformCustomerId}/>
              <LabeledValue label="Account Id" value={transfer.stripeData?.customer?.stripeAccountId}/>
              <LabeledValue label="Wefunder User Id" value={transfer.stripeData?.customer?.wfUserId}/>
              <LabeledValue label="Created At" value={transfer.stripeData?.customer?.createdAt}/>
            </LabeledItemWithDetails>
          </IfDefined>

          <LabeledItem label="Events">
            <BasicTable columns={eventsColumns} rows={events}/>
          </LabeledItem>
        </Stack>
      </Paper>
      {!!transfer.lineItem && !!transfer.recon && <ReconInfo lineItem={transfer.lineItem} recon={transfer.recon}/>}
    </Stack>
  );
};

export default TransferView;
