import React, {createContext, FunctionComponent, useContext, useMemo, useRef} from "react";

import {MarkOptional} from "ts-essentials";

import {Tagged} from "./types";

/** A type of tag used to identify erased types in the DI container */
export type DITag<T> = symbol & Tagged<T>;

/** Creates a DITag for the given type */
export const diTag = <T extends unknown>(name: string) => Symbol(name) as DITag<T>;

/** A key in the DI container. Either a class or a DITag. */
export type DIKey<T> = (new (...args: any[]) => T) | DITag<T>;

/** A mapping from a DIKey to a value of that type */
export type Injection<T> = readonly [DIKey<T>, T];

type DIContainer = Map<DIKey<unknown>, unknown>;

const diContext = createContext<DIContainer>(new Map());

interface DIProviderProps {
    /** The values to inject */
    readonly injects: readonly Injection<unknown>[];

    readonly children?: React.ReactNode;
}
export function DIProvider({injects, children}: DIProviderProps) {
    const parentContainer = useContext(diContext);
    const childContainer = useMemo(
        () => new Map([...parentContainer, ...injects]),
        //In order to properly memoize on an array literal, flatten it and spread it into the dependency array
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [parentContainer, ...injects.flat()]
    );
    return <diContext.Provider value={childContainer}>{children}</diContext.Provider>;
}

const DEPENDENCIES: unique symbol = Symbol("DEPENDENCIES");

type ExtractArgsFromDeps<Deps extends DIKey<unknown>[]> = {
    [I in keyof Deps]: Deps[I] extends DIKey<infer T> ? T : Deps[I]
};

/** Decorator used to manually annotate the dependencies of a class */
export const dependencies =
    <Deps extends DIKey<unknown>[]>(...deps: Deps) =>
    (clazz: new (...args: ExtractArgsFromDeps<Deps>) => any) => {
        clazz[DEPENDENCIES] = deps;
    };

/** Annotates a constructor parameter to inject an erased type */
export const tagged =
    (tag: DITag<unknown>) =>
    //When applying a parameter decorator to a constructor parameter,
    //the given target is the class and the property is undefined
    (target: new (...args: any[]) => any, property: undefined, parameterIndex: number) => {
        const deps = target[DEPENDENCIES] ??= [];
        deps[parameterIndex] = tag; //Not worrying about leaving holes
    };

/**
 * Decorator used to automatically inject dependencies into a class based on its constructor signature.
 * Erased types such as interfaces will need to be tagged using `@tagged`.
 */
export const constructorDependencies = (clazz: new (...args: any[]) => any) => {
    const paramTypes: (new (...args: any[]) => any)[] = Reflect.getOwnMetadata("design:paramtypes", clazz);
    const deps = clazz[DEPENDENCIES] ??= [];
    for (let i = 0; i < clazz.length; i++) {
        if (deps[i]) continue;

        const paramType = paramTypes[i];
        if (paramType === Object)
            throw new TypeError(
                `Cannot auto-inject parameter ${i} of class ${clazz.name} - reflected type is Object. `
                    + "The parameter type was likely erased. Erased types need to be tagged."
            );

        deps[i] = paramType;
    }
};

function constructType<T>(
    key: DIKey<unknown>,
    container: DIContainer,
    newInjections: DIContainer = new Map()
): [T, DIContainer] {
    if (typeof key !== "function") throw new TypeError(`Cannot auto-construct non-class type ${key.description}`);
    const clazz = key;

    if (!Array.isArray(clazz[DEPENDENCIES]))
        throw new TypeError(`Cannot auto-construct class ${clazz.name} without declared dependencies`);

    const args = [];
    for (const depKey of clazz[DEPENDENCIES] as DIKey<unknown>[]) {
        if (container.has(depKey) || newInjections.has(depKey)) {
            args.push(container.get(depKey) ?? newInjections.get(depKey));
        }
        else {
            const [dep] = constructType(depKey, container, newInjections);
            args.push(dep);
            newInjections.set(depKey, dep);
        }
    }

    return [new clazz(...args) as T, newInjections];
}

type ExtractPropsFromDeps<Deps extends {readonly [prop: string]: DIKey<unknown>}> = {
    readonly [K in keyof Deps]: Deps[K] extends DIKey<infer T> ? T : Deps[K]
};

/**
 * Injects one or more dependencies into a component.
 * WARNING: This initial implementation is naive and does not attempt to linearize dependencies.
 * That is a problem for future me if we keep using this ;)
 */
export function inject<
    Deps extends {readonly [prop: string]: DIKey<unknown>},
    Props extends ExtractPropsFromDeps<Deps>
>(deps: Deps, component: FunctionComponent<Props>): FunctionComponent<MarkOptional<Props, keyof Deps>> {
    const injectedComponentDisplayName = `inject(${
        component.displayName
            || component.name
            //HACK: There is a bug in React where display name is handled weirdly by memo.
            //`type` is an internal property that the real display name can be fetched from.
            //Regardless of doing this, it also shows the memo hook on its own line in the dev tools.
            || (component as any).type?.displayName
            || (component as any).type?.name
            || "anonymous"
    })`;
    return {[injectedComponentDisplayName](props: MarkOptional<Props, keyof Deps>) {
        const container = useContext(diContext);

        //useMemo hooks need to be idempotent.
        //I want to avoid having it cause problems if an auto-constructed dependency has an effectful constructor.
        //To do so, cache the child container in a ref. Since the child container depends entirely on the props,
        //and prop changes will always cause re-renders anyway, this shouldn't get stale.
        const childContainer = useRef<DIContainer | null>(null);

        const injectedProps = useMemo(
            () => {
                let childContainerModified = false;

                /** Call me to make changes to the child container! */
                function modifyChildContainer(modify: (childContainer: DIContainer) => void) {
                    if (!childContainerModified) {
                        childContainer.current = new Map(childContainer.current ?? container);
                        childContainerModified = true;
                    }

                    modify(childContainer.current!);
                }

                const injectedProps = {...props};
                for (const [prop, diKey] of Object.entries(deps)) {
                    if (injectedProps[prop] !== undefined) {
                        //Handle the case where a dependency was previously not explicitly provided and now is.
                        //GC any instance held in the child container.
                        //(Really, no one should be doing this, but it at least shouldn't break things *here*.)
                        if (!container.has(diKey)) {
                            modifyChildContainer(cc => cc.delete(diKey));
                        }

                        continue;
                    }

                    if (container.has(diKey) || childContainer.current?.has(diKey)) {
                        injectedProps[prop as any] = container.get(diKey) ?? childContainer.current!.get(diKey) as any;
                    }
                    else {
                        modifyChildContainer(cc => {
                            const [value, newInjections] = constructType(diKey, cc);
                            injectedProps[prop as any] = value as any;
                            cc.set(diKey, value);
                            for (const [newKey, newValue] of newInjections) {
                                cc.set(newKey, newValue);
                            }
                        });
                    }
                }

                return injectedProps;
            },
            //Similar trick to the above - assuming/hoping the order of props is stable
            //eslint-disable-next-line react-hooks/exhaustive-deps
            [container, ...Object.values(props)]
        );

        const Component = component;
        return (
            childContainer.current ?
                <diContext.Provider value={childContainer.current}>
                    <Component {...injectedProps as unknown as Props}/>
                </diContext.Provider>
            :
                <Component {...injectedProps as unknown as Props}/>
        );
    }}[injectedComponentDisplayName];
}
