import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {get} from "lodash";
import {ReactNode} from "react";

export interface BasicTableColumn<Row, V> {
  id: string;
  title: string;
  valueGetter?: (row: Row) => V;
  valueFormatter?: (value: V) => string;
  renderComponent?: (data: V) => ReactNode;
}
export interface BasicTableProps {
  columns: BasicTableColumn<any, any>[];
  rows: object[];
  total?: string | number;
}

function formatOpt<R extends String>(value: R | undefined, formatter?: (_: R) => string): string {
  if (!value) {
    return "";
  } else {
    return !formatter ? value.toString() : formatter(value);
  }
}

function getOrDefault<Row, V>(id: string, row: Row, getter?: (row: Row) => V): V {
  return !getter ? get(row, id) as V : getter(row);
}

export default function BasicTable({columns, rows, total}: BasicTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 650}} size="small">
        <TableHead>
          <TableRow>
            {columns.map(({id, title}) => <TableCell key={id}>{title}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) =>
            <TableRow sx={{"&:last-child td, &:last-child th": {border: 0}}} key={index}>
              {columns.map(({id, valueGetter, valueFormatter, renderComponent}) =>
                <TableCell key={id}>
                  { renderComponent ?
                    renderComponent(getOrDefault(id, row, valueGetter)) :
                    formatOpt(getOrDefault(id, row, valueGetter), valueFormatter)
                  }
                </TableCell>)
              }
            </TableRow>
          )}
          {total && <TableRow>
            <TableCell colSpan={columns.length - 2}/>
            <TableCell>Total</TableCell>
            <TableCell>{total}</TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
