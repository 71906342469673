import {ErrorDto} from "./api/error-dto";

/** Base class for application-specific Errors */
export class PaymentCenterError extends Error {
    public override get name(): string {
        return this.constructor.name;
    }
}

/** Error thrown for non-2XX Fetch responses */
export class ResponseError extends PaymentCenterError {
    /**
     * Constructs a new ResponseError
     *
     * @param response - The Response that caused the exception
     * @param responseBody - The body of the Response parsed as text, if a body could be retrieved
     */
    public constructor(public readonly response: Response, public readonly responseBody?: string) {
        super(`${response.status} ${response.url}`);
    }
}

/**
 * Error thrown for well-formed errors from the backend API.
 * The original ResponseError is available as `cause`.
 */
export class ApiError extends PaymentCenterError {
    /**
     * Constructs a new ApiError
     *
     * @param errorDto - The parsed ErrorDto from the API error
     * @param cause - The underlying ResponseError
     */
    public constructor(public readonly errorDto: ErrorDto, cause: ResponseError) {
        super(errorDto.error, {cause});
    }
}

/** Error thrown when the current session is invalid */
export class UnauthorizedError extends PaymentCenterError {
    public constructor();
    public constructor(message: string);
    public constructor(cause: Error);
    public constructor(message: string, cause: Error);
    public constructor(messageOrCause?: string | Error, maybeCause?: Error) {
        const cause = maybeCause ?? (messageOrCause instanceof Error ? messageOrCause : undefined);
        const message =
            typeof messageOrCause === "string" ? messageOrCause :
            cause ? cause.message :
            "Session is invalid or expired";
        super(message, {cause});
    }
}
