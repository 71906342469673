import React, {useEffect, useState} from "react";
import {UserTask} from "../user-task";
import {Page} from "~/components/page";
import SimpleTable, {Columns} from "~/components/simple-table";
import {startCase, truncate} from "lodash";
import UserTaskDetails from "./user-task-details";
import UserTasks from "~/repositories/user-tasks";
import {useQuery, useQueryClient} from "react-query";
import {Stack, TablePagination} from "@mui/material";
import {RenderIf} from "~/recon/util";
import {useStateObject} from "~/utils/use-state-object";
import {useQueryParam} from "~/utils/use-query-param";
import {useIsFirstRender} from "~/utils/use-is-first-render";
import {parseBoolean} from "~/utils/booleans";
import {CollapsibleFilterGrid} from "~/components/filter-grid";
import {UserTaskFilters} from "../components/user-task-filters";

const columns: Columns<UserTask> = [
  {title: "Type", getter: (task) => startCase(task.taskType.toString())},
  {title: "State", getter: (task) => startCase(task.state)},
  {title: "Note", getter: (task) => truncate(task.note || "", {length: 50})},
  {title: "Date", getter: (task) => task.createdAt.toLocaleString()}
];

export const UserTaskDashboard = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [filters, setFilters] = useStateObject({
      userId: useQueryParam<string | undefined>("userId", undefined),
      taskType: useQueryParam<UserTask.Type | undefined>("taskType", undefined),
      taskState: useQueryParam<UserTask.State | undefined>("taskState", undefined),
      includeUnassigned: useQueryParam<boolean | undefined>("includeUnassigned", undefined, {parser: parseBoolean})
  });

  //Reset the page to 1 when the sort and filters change
  const isFirstRender = useIsFirstRender();
  useEffect(() => {
      if (!isFirstRender) {
          setPage(1);
      }
  }, [filters]); //eslint-disable-line react-hooks/exhaustive-deps

  const queryParams = {page: page, perPage: perPage, ...filters};
  const query = useQuery(UserTasks.list(queryParams));
  const rows: UserTask[] = query.isSuccess ? query.data.data : [];
  const totalRows = query.isSuccess ? query.data.pagination.totalItems : 0;
  const queryClient = useQueryClient();

  const onTaskUpdate = (task: UserTask) => {
    const idx = rows.findIndex(t => t.id === task.id);
    if (idx >= 0) {
      const updatedRows = [...rows];
      updatedRows[idx] = task;
      queryClient.setQueryData(["user_tasks", queryParams], {...query.data, data: updatedRows});
    }
  };

  return (
    <Page
        title="User Tasks"
        tabTitle="User Tasks"
        height="fixed"
    >
      <RenderIf cond={query.isSuccess}>
          <Stack direction="column">
          <CollapsibleFilterGrid sx={{mb: 2}}>
            <UserTaskFilters
                userId={filters.userId}
                setUserId={setFilters.userId}
                taskType={filters.taskType}
                setTaskType={setFilters.taskType}
                taskState={filters.taskState}
                setTaskState={setFilters.taskState}
                includeUnassigned={filters.includeUnassigned}
                setIncludeUnassigned={setFilters.includeUnassigned}
            />
          </CollapsibleFilterGrid>

          <SimpleTable rows={rows} columns={columns} RowDetails={UserTaskDetails(onTaskUpdate)}/>
          <Stack direction="row">
            {/* <Pagination size="small" sx={{py: 1}} count={query.data?.pagination.totalPages} page={page} onPageChange={}}/> */}
            <TablePagination
                component="div"
                count={totalRows}
                page={page - 1}
                rowsPerPage={perPage}
                onPageChange={(_, val) => setPage(val + 1)}
                onRowsPerPageChange={(event) => setPerPage(parseInt(event.target.value, 10))}
                showFirstButton
                showLastButton
            />
          </Stack>
        </Stack>
      </RenderIf>
    </Page>
  );
};

// export const UserTaskDashboard2 = inject({userTaskStore: UserTaskStore}, observer(function UserTaskDashboard({
//     userTaskStore
//}: {userTaskStore: UserTaskStore}) {
//     const {tasks} = userTaskStore;

//     const [pageParams, setPageParams] = useTypedStateObject<PageParams>()({
//         page: useQueryParam("page", 1, {parser: parsePositiveInt, push: true, immediate: true}),
//         perPage: useQueryParam("perPage", 10, {parser: parsePositiveInt, push: true, immediate: true})
//    });

//     const [sortParams, setSortParams] = useTypedStateObject<SortParams<UserTaskSortKey>>()({
//         sortBy: useQueryParam<UserTaskSortKey | undefined>("sortBy", undefined),
//         sortDir: useQueryParam<SortDirection | undefined>("sortDir", undefined)
//    });

//     const [filters, setFilters] = useStateObject({
//         userId: useQueryParam<string | undefined>("userId", undefined),
//         taskType: useQueryParam<UserTask.Type | undefined>("taskType", undefined),
//         taskState: useQueryParam<UserTask.State | undefined>("taskState", undefined),
//         includeUnassigned: useQueryParam<boolean | undefined>("includeUnassigned", undefined, {parser: parseBoolean})
//    });

//     //Reset the page to 1 when the sort and filters change
//     const isFirstRender = useIsFirstRender();
//     useEffect(() => {
//         if (!isFirstRender) {
//             setPageParams.page(1, {immediate: false});
//        }
//    }, [sortParams, filters]); //eslint-disable-line react-hooks/exhaustive-deps

//     useAbortableEffect(
//         signal => flowResult(userTaskStore.fetchTasks({...pageParams, ...sortParams, ...filters}, signal)),
//         [userTaskStore, pageParams, sortParams, filters]
//     );

//     const pageTitle = "Tasks";
//     const pageNumberTitle = tasks.case({
//         hasValue: tasks => pageNumberSuffix(tasks.pagination),
//         else: () => ""
//    });

//     return (
//         <Page
//             title={pageTitle}
//             tabTitle={pageTitle + pageNumberTitle}
//             height="fixed"
//         >
//             <FilterAndSortBar>{expandSort => <>
//                 <CollapsibleFilterGrid>
//                     <UserTaskFilters
//                         userId={filters.userId}
//                         setUserId={setFilters.userId}
//                         taskType={filters.taskType}
//                         setTaskType={setFilters.taskType}
//                         taskState={filters.taskState}
//                         setTaskState={setFilters.taskState}
//                         includeUnassigned={filters.includeUnassigned}
//                         setIncludeUnassigned={setFilters.includeUnassigned}
//                     />
//                 </CollapsibleFilterGrid>
//                 <SortOptions
//                     sortKeys={USER_TASK_SORT_KEYS}
//                     sortBy={sortParams.sortBy}
//                     onSortByChange={setSortParams.sortBy}
//                     sortDirection={sortParams.sortDir}
//                     onSortDirectionChange={setSortParams.sortDir}
//                     expanded={expandSort}
//                 />
//             </>}</FilterAndSortBar>
//             <LoadingTreatment loadable={tasks} description="tasks" observer renderStaleValues>{(tasks, stale) =>
//                 tasks.data.size === 0 ?
//                     "There are no results at this time"
//                 :
//                     // <UserTaskListDisplay
//                     //     context={"userTaskColumnOrder"}
//                     //     tasks={[...tasks.data.values()]}
//                     //     paginationState={tasks.pagination}
//                     //     stale={stale}
//                     //     onPageChange={setPageParams.page}
//                     //     onRowsPerPageChange={setPageParams.perPage}
//                     // />
//                 <SimpleTable rows={[...tasks.data.values()]} columns={columns} RowDetails={UserTaskDetails}/>
//            }</LoadingTreatment>
//         </Page>
//     );
//}));
