import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {BigNumber} from "bignumber.js";
import {CURRENCIES, Currency} from "~/currency";
import {Temporal} from "@js-temporal/polyfill";
import {Json} from "~/utils/types";

export class StripePayout extends JsonDto {
    public static readonly STATUSES = ["paid", "canceled", "in_transit", "pending", "failed"] as const;

    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp(JsonTypes.stringUnion(StripePayout.STATUSES))
    public readonly status!: StripePayout.Status;

    @jsonProp(JsonTypes.id)
    public readonly stripeAccountId!: number;

    @jsonProp(JsonTypes.id, {optional: true})
    public readonly balanceTransactionId!: number;

    @jsonProp(JsonTypes.id, {optional: true})
    public readonly originalPayoutId!: number;

    @jsonProp(JsonTypes.id, {optional: true})
    public readonly reversedById!: number;

    @jsonProp(JsonTypes.bigNumber)
    public readonly amount!: BigNumber;

    @jsonProp(JsonTypes.instant)
    public readonly arrivalDate!: Temporal.Instant;

    @jsonProp()
    public readonly method!: string;

    @jsonProp(JsonTypes.stringUnion(CURRENCIES))
    public readonly currency!: Currency;

    @jsonProp()
    public readonly description!: string;

    @jsonProp(JsonTypes.any)
    public readonly metadata!: Json;

    @jsonProp()
    public readonly sourceType!: string;


    @jsonProp(JsonTypes.string, {optional: true})
    public readonly statementDescriptor!: string;

    @jsonProp()
    public readonly type!: string;

    @jsonProp(JsonTypes.instant)
    public readonly createdAt!: Temporal.Instant;

    @jsonProp(JsonTypes.instant)
    public readonly updatedAt!: Temporal.Instant;


    public constructor(init?: unknown) {
        super();
        this.init(StripePayout, init);
    }
}

export namespace StripePayout {
    export type Status = (typeof StripePayout.STATUSES)[number];
}
