import React, {useEffect, useState} from "react";
import {VirtualAccountListItem} from "~/account/virtual-account-list-item";
import {useVirtualAccountQuery, VirtualAccountFilters} from "./virtual-account-filters";
import {usePreviousValue} from "~/utils/use-previous-value";
import {LinearProgress, Stack} from "@mui/material";
import {Error} from "~/recon/components/recon-review";
import {DataGrid, GridColDef, GridRowId, GridRowsProp} from "@mui/x-data-grid";
import {VirtualAccount} from "~/account/virtual-account";
import {Backend} from "~/backend/backend";
import {first} from "lodash";

const columns: GridColDef[] = [
  {field: "id", headerName: "Id", flex: 0.2},
  {field: "name", headerName: "Name", flex: 1}
];

export const VirtualAccountBrowser = ({onSelected, investmentId}: {investmentId: string | undefined, onSelected: (data: VirtualAccountListItem) => void }) => {
  const defaultFilters = {
    accountType: "internal" as VirtualAccount.AccountType,
    accountRole: "investment" as VirtualAccount.AccountRole,
    backendType: "internal_escrow" as Backend.Type,
    investmentId: investmentId
  };
  const [virtualAccountId, setVirtualAccountId] = useState<string|undefined>(undefined);
  const {query, filters, filterSetter, sort, setSort, setPage, setPageSize, page, pageSize} =
    useVirtualAccountQuery({defaultFilters: defaultFilters, defaultSort: [{field: "id", sort: "desc"}]});
  const previousRowCount = usePreviousValue(query.data?.pagination.totalItems) || 0;

  useEffect(() => {
    if (virtualAccountId !== undefined) {
      const account = query.data?.data.find(v => v.id === virtualAccountId);
      if (account !== undefined) {
        onSelected(account);
      }
    }
  }, [virtualAccountId, query.data, onSelected]);

  return (
    <Stack>
      <VirtualAccountFilters filter={filters} setFilter={filterSetter}/>
      {query.isFetching && <LinearProgress/>}
      {query.isError && <Error error={query.error}/>}
      <DataGrid
          rows={query.data?.data || []}
          columns={columns}
          autoHeight
          rowsPerPageOptions={[10, 30, 50, 100]}
          onSelectionModelChange={ids => setVirtualAccountId(first(ids) as (string | undefined))}
          selectionModel={virtualAccountId ? [virtualAccountId as GridRowId] : []}
          disableDensitySelector
          disableColumnFilter
          disableColumnSelector
          hideFooterSelectedRowCount
          onSortModelChange={setSort}
          sortModel={sort}
          onPageSizeChange={setPageSize}
          onPageChange={page => setPage(page + 1)}
          paginationMode={"server"}
          pageSize={query.data?.pagination.perPage || pageSize}
          page={(query.data?.pagination.currentPage || page) - 1}
          rowCount={query.data?.pagination.totalItems || previousRowCount}
      />
    </Stack>
  );
};
