import React, {ForwardedRef, forwardRef, ReactNode, useCallback} from "react";

import {Box, Stack, SxProps, Theme} from "@mui/material";

import {IconButton} from "./button";
import {Icon} from "./icon";
import {TextField} from "./text-field";
import {Typography} from "./typography";
import {plainDateRange, Range} from "~/utils/range";
import {parsePlainDateOrUndefined, plainDateToDateInputValue} from "~/utils/temporal";
import {Temporal} from "@js-temporal/polyfill";

//Not using the MUI X DateRangePicker because 1) it's overpriced, and 2) it doesn't support Temporal well yet.
//I already had this component from my work in the main app, and I simply adapted it to use Temporal,
//and used MUI TextFields for the inputs so their appearance matched the rest of the app.

export interface DateRangePickerProps {
    /** A Range object representing the value of the picker. May be undefined if no value has been set. */
    readonly range: Range<Temporal.PlainDate> | undefined;

    /** Handler called when the value of the picker changes */
    readonly onChange: (range: Range<Temporal.PlainDate> | undefined) => void;

    /**
     * The label for the overall range picker.
     * If passed, the two date inputs will be rendered in a fieldset with this as the legend.
     */
    readonly label?: string;

    /** Label for the "from" input. Defaults to "From". */
    readonly fromLabel?: string;

    /** Label for the "to" input. Defaults to "To". */
    readonly toLabel?: string;

    /** The variant to use for the individual inputs */
    readonly variant?: "standard" | "filled" | "outlined";

    /** Content to render between the inputs. Defaults to an empty spacer. */
    readonly spacer?: ReactNode;

    /** If true, the clear button is not rendered */
    readonly hideClearButton?: boolean;

    readonly sx?: SxProps<Theme>;
}

/**
 * A simple date range input using two native date inputs.
 *
 * Forwards refs to the first date input.
 */
export const DateRangePicker = forwardRef(function DateRangePicker({
    range,
    onChange,
    label,
    fromLabel = "From",
    toLabel = "To",
    variant = "outlined",
    spacer = <Box width={t => t.spacing(2)}/>,
    hideClearButton = false,
    sx = []
}: DateRangePickerProps, ref: ForwardedRef<HTMLInputElement>) {
    const hasInput = !!range;

    const onFromChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const newLower = parsePlainDateOrUndefined(ev.target.value) ?? null;

        if (!range || newLower && range.upperBound && Temporal.PlainDate.compare(newLower, range.upperBound) > 0) {
            onChange(plainDateRange(newLower));
        }
        else {
            onChange(range.withLowerBound(newLower));
        }
    }, [onChange, range]);

    const onToChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const newUpper = parsePlainDateOrUndefined(ev.target.value) ?? null;
        if (!range) {
            onChange(plainDateRange(null, newUpper));
        }
        else {
            onChange(range.withUpperBound(newUpper));
        }
    }, [onChange, range]);

    const clear = useCallback(() => onChange(undefined), [onChange]);

    const renderContainer = (children: ReactNode) =>
        label ?
            <Box component="fieldset" className="PcDateRangePicker-root" sx={[{border: "none", m: 0, p: 0}, sx].flat()}>
                <Typography component="legend" variant="subtitle1" sx={{mb: 1}}>{label}</Typography>
                {children}
            </Box>
        :
            <Box className="PcDateRangePicker-root" sx={sx}>{children}</Box>;

    const fromValue = plainDateToDateInputValue(range?.lowerBound);
    const toValue = plainDateToDateInputValue(range?.upperBound);
    return (
        renderContainer(
            <Stack
                className="PcDateRangePicker-inputContainer"
                direction="row"
                width={1}
                justifyContent="space-between"
                alignItems="center"
            >
                {/*
                    The one annoyance with native date inputs is that they don't accept placeholders or pseudo-elements.
                    I'd like to be able to have the empty state read "Any date", but there's no good way to do that.
                    (There are some buggy hacks with dynamically changing the input type but I don't want to do that.)
                */}
                <TextField
                    type="date"
                    inputRef={ref}
                    size="small"
                    variant={variant}
                    sx={{width: 1}}
                    label={fromLabel}
                    value={fromValue}
                    onChange={onFromChange}
                    InputLabelProps={{shrink: true}} //Native date inputs always appear to be filled
                />
                {spacer}
                <TextField
                    type="date"
                    size="small"
                    variant={variant}
                    sx={{width: 1}}
                    label={toLabel}
                    value={toValue}
                    onChange={onToChange}
                    inputProps={{min: fromValue}}
                    InputLabelProps={{shrink: true}}
                />
                {!hideClearButton &&
                    <IconButton
                        title={label ? `Clear ${label}` : "Clear"}
                        size="small"
                        color="error"
                        sx={{ml: 1}}
                        disabled={!hasInput}
                        onClick={clear}
                    >
                        <Icon fa="close"/>
                    </IconButton>
                }
            </Stack>
        )
    );
});
