import {StripePayout} from "~/stripe-payouts/stripe-payout";
import {BigNumber} from "bignumber.js";
import {Temporal} from "@js-temporal/polyfill";
import {autobindAll} from "~/utils/autobind";
import {BaseApi} from "~/api/base-api";
import {FetchClient} from "~/api/fetch-client";
import {PageParams, SortParams} from "~/api/common-query-params";
import {PaginationEnvelope} from "~/api/pagination-envelope";

export const STRIPE_PAYOUT_SORT_KEY = [{key: "id"}, {key: "amount"}, {key: "status"}, {key: "createdAt"}] as const;

export type StripePayoutSortKey = (typeof STRIPE_PAYOUT_SORT_KEY)[number]["key"];

export interface StripePayoutFilterParam {
    readonly id?: string|undefined;
    readonly status?: StripePayout.Status|undefined;
    readonly amountGt?: BigNumber|undefined;
    readonly amountLt?: BigNumber|undefined;
    readonly amountEq?: BigNumber|undefined;
    readonly description?: string|undefined;
    readonly dateEq?: Temporal.Instant|undefined;
    readonly dateGt?: Temporal.Instant|undefined;
    readonly dateLt?: Temporal.Instant|undefined;
}

@autobindAll
export class StripePayoutApi extends BaseApi<StripePayout> {
    public constructor(fetchClient: FetchClient, errorHandler: (err: Error) => boolean) {
        super(fetchClient, errorHandler, "/line_items", StripePayout);
    }

    public async getPayouts(backendId: string, filters?: PageParams & SortParams<StripePayoutSortKey> & StripePayoutFilterParam | undefined, signal?: AbortSignal): Promise<PaginationEnvelope<StripePayout[]>> {
        return await this.find(filters, signal, `/${backendId}/payouts`);
    }

}
