import {BigNumber} from "bignumber.js";

import {JsonDto, jsonProp} from "~/utils/json-validation";
import {JsonTypes} from "~/utils/json-validation/types";
import {StateLaneType, StateLaneTypes} from "~/account/state-lane-type";

export class StateLane extends JsonDto {

    @jsonProp(JsonTypes.id)
    public readonly id!: string;

    @jsonProp(JsonTypes.stringUnion(StateLaneTypes))
    public readonly laneType!: StateLaneType;

    @jsonProp()
    public readonly amount!: BigNumber;

    public constructor(init?: unknown) {
        super();
        this.init(StateLane, init);
    }
}
