import {Callable} from "./types";

//These types and functions are needed because of https://github.com/microsoft/TypeScript/issues/37663
//Plus, they're a bit more concise anyway

/** Type-safe way of describing a React Hooks style initialization parameter */
export type Initializer<T> = [T] extends [Callable] ? () => T : (T | (() => T));

/**
 * Version of Initializer that can be used in implementation signatures.
 * For some reason the conditional type doesn't play well with the overload compatibility check.
 */
export type _ImplInitializer<T> = T | (() => T);

/** Evaluates an Initializer */
export const getInitialValue = <T>(initializer: Initializer<T>): T =>
    //eslint-disable-next-line @typescript-eslint/no-unsafe-return
    typeof initializer === "function" ? initializer() : initializer;

/** Type-safe way of describing a React Hooks style update paramater */
export type Updater<T> = [T] extends [Callable] ? (prevValue: T) => T : (T | ((prevValue: T) => T));

/** Evaluates an Updater given the previous value */
export const getUpdatedValue = <T>(updater: Updater<T>, prevValue: T): T =>
    //eslint-disable-next-line @typescript-eslint/no-unsafe-return
    typeof updater === "function" ? updater(prevValue) : updater;

/** The update side of a React Hooks style value tuple */
export type UpdateFn<T> = (updater: Updater<T>) => void;

/** The type of the tuple returned from a typical React Hook state function */
export type HookValueTuple<T> = [value: T, update: UpdateFn<T>];
