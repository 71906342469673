import defaultTo from "lodash/defaultTo";
import isPlainObject from "lodash/isPlainObject";
import localforage from "localforage";
import {Authentication} from "~/auth/authentication";

/**
 * Shamelessly stolen from wefunder codebase.
 * Builds a URL from a string and object-formatted query params
 *
 * @param endpoint - The base URL string to use. This can be relative to the current page.
 * @param queryParams
 * An object containing modifications to `endpoint`'s query parameters.
 * Array values are serialized using Rails-style array parameters.
 * Plain objects are serialized as dotted params to support Rails' nested query params.
 * An explicit undefined causes any existing value for the param in `endpoint` to be deleted.
 * All other values are stringified and URI encoded.
 *
 * @returns A URL object constructed from the provided parameters
 */
function buildUrl(endpoint: string, queryParams?: {[param: string]: unknown}): URL {
  const url = new URL(endpoint, window.location.href);

  function buildParams(prefix: string, obj: {[param: string]: unknown}): void {
    for (const [param, value] of Object.entries(obj)) {
      const prefixedParam = prefix ? `${prefix}[${param}]` : param;
      if (Array.isArray(value)) {
        if (value.length > 0) {
          url.searchParams.delete(prefixedParam);
          url.searchParams.delete(`${prefixedParam}[]`);
          for (const el of value) {
            url.searchParams.append(`${prefixedParam}[]`, el);
          }
        }
      }
      else if (isPlainObject(value)) {
        buildParams(prefixedParam, value as {[param: string]: unknown});
      }
      else if (value === undefined) {
        url.searchParams.delete(prefixedParam);
      }
      else if (value !== null) {
        //NOTE: Currently ignoring the cases of null and booleans.
        //Rails can accept a nil query param by doing `?x` with no =, but URLSearchParams doesn't support this.
        //Likewise, one could adopt a convention where boolean params are based on presence rather than strings.
        //For now, null, true, and false will all just be stringified like any other value.
        url.searchParams.set(prefixedParam, `${value}`);
      }
    }
  }

  if (queryParams) {
    buildParams("", queryParams);
  }

  return url;
}

const provisionHeaders = (options: any): any => {
  const def = defaultTo(options, {});
  const headers = {
    ...defaultTo(def.headers, {}),
    "Authorization": `Bearer ${options.authToken}`
  };

  if (def.body) {
    headers["Content-Type"] = "application/json";
  }

  return {...def, headers: headers};
};

type Constructor<T> = (value: unknown) => T;

type WfFetchOptions<T> = {
  query?: {[param: string]: any},
  body?: string,
  method?: "GET" | "POST" | "DELETE" | "HEAD" | "PUT" | "OPTIONS" | "PATCH",
  constructor?: Constructor<T>
};

const wfFetch = async function<T>(endpoint: string, options?: WfFetchOptions<T>): Promise<T> {
  const url = buildUrl(endpoint, options?.query);
  const authentication = await localforage.getItem<Authentication>("authentication");

  const response = await fetch(url.toString(), provisionHeaders({...options, authToken: authentication?.token}));

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }
  const json = await response.json();
  if (options?.constructor !== undefined) {
    return options.constructor(json);
  } else {
    // Dangerzoneeee
    return json as T;
  }
};

export const wfGet = function<T>(endpoint: string, query?: {[param: string]: any}, constructor: Constructor<T> | undefined = undefined): Promise<T> {
  return wfFetch(endpoint, {method: "GET", query: query, constructor: constructor});
};

export const wfDelete = async<T> (endpoint: string, options?: WfFetchOptions<T>): Promise<void> => {
  await wfFetch(endpoint, {...options, method: "DELETE"});

};

// export const wfPost = function<T, R>(cons: (_:any) => R, data: T, endpoint: string, options?: WfFetchOptions): Promise<R> {
export const wfPost = function<T, R>(data: T, endpoint: string, options?: WfFetchOptions<R>): Promise<R> {
  return wfFetch(endpoint, {...options, body: JSON.stringify(data), method: "POST"});
};

export const wfPut = function<T, R>(cons: (_:any) => R, data: T, endpoint: string, options?: WfFetchOptions<R>): Promise<R> {
  return wfFetch(endpoint, {...options, body: JSON.stringify(data), method: "PUT"});
};

export default wfFetch;
