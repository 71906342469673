import {useEffect} from "react";

import {ErrorContainer} from "../error-container";
import {Loadable} from "./index";

/** Executes an effect when the given loadable enters the loading state */
export function useLoadingReaction(loadable: Loadable<unknown>, reaction: () => void, deps: unknown[]): void {
    useEffect(
        () => {
            if (loadable.isLoading()) {
                reaction();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadable, ...deps]
    );
}

/** Executes an effect when the given loadable enters the loaded state */
export function useLoadedReaction<T>(loadable: Loadable<T>, reaction: (result: T) => void, deps: unknown[]): void {
    useEffect(
        () => {
            if (loadable.isLoaded()) {
                reaction(loadable.value);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadable, ...deps]
    );
}

/** Executes an effect when the given loadable enters the error state */
export function useErrorReaction(
    loadable: Loadable<unknown>,
    reaction: (error: ErrorContainer) => void,
    deps: unknown[]
): void {
    useEffect(
        () => {
            if (loadable.hasError()) {
                reaction(loadable);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loadable, ...deps]
    );
}
