import React, {ReactElement, ReactNode, useRef, useState} from "react";

import {Box, Stack, SxProps, Theme} from "@mui/material";
import classNames from "classnames";

import {AppHeader} from "./app-header";
import {DrawerProps} from "./drawer";
import {transition} from "~/utils/styles";
import {usePageTitle} from "~/utils/use-page-title";

export enum PageWidth {
    NARROW = "800px",
    MEDIUM = "1024px",
    MAX = 1
}

export type PageHeight = "fixed" | "scroll";

export interface PageProps {
    /** The title of the page. Used for both the page header and the tab title. */
    readonly title?: string;

    /**
     * Renders the title contents. Mutually exclusive with title.
     * Should only be used if you need to render arbitrary JSX in the title.
     * If this is passed, tabTitle should also be passed.
     */
    readonly renderTitle?: () => ReactNode;

    /** The size at which to render the title. Defaults to large. */
    readonly titleSize?: "large" | "medium" | "small";

    /** The title to use for the browser tab. Defaults to title. */
    readonly tabTitle?: string;

    /** A subtitle to render as part of the header, underneath the title */
    readonly subtitle?: ReactNode;

    /**
     * Renders the entire main content slot of the header. Replaces title, renderTitle, titleSize, and subtitle.
     * If this is passed, tabTitle should also be passed.
     */
    readonly renderHeaderContents?: () => ReactNode;

    readonly "data-testid"?: string;

    /** Slot for rendering action buttons alongside the title */
    readonly actions?: ReactNode;

    /** Sets how the (max)-width of the page behaves. Defaults to PageWidth.MAX. */
    readonly width?: PageWidth;

    /**
     * Controls the height of the page.
     * Defaults to allowing scroll. Set to "fixed" for pages with internal scrolling.
     */
    readonly height?: PageHeight;

    readonly sx?: SxProps<Theme>;

    /** The contents of the page body */
    readonly children: ReactNode;

    /** Slot for rendering a Drawer. The argument to this prop must be a Drawer component. */
    readonly drawer?: ReactElement;
}

/** Base component for rendering a page of the application */
export function Page({
    title,
    renderTitle,
    titleSize = "large",
    tabTitle = title,
    subtitle,
    renderHeaderContents,
    actions,
    width: widthSetting = PageWidth.MAX,
    height: heightSetting = "scroll",
    sx = [],
    children,
    drawer,
    "data-testid": testId
}: PageProps) {
    usePageTitle(tabTitle);

    const appHeader = useRef<HTMLDivElement>(null);

    const [scrollContainer, setScrollContainer] = useState<Node | undefined>(undefined);
    const [drawerPinnedOpen, setDrawerPinnedOpen] = useState(false);
    const [drawerWidth, setDrawerWidth] = useState("0");

    return (
        //Horizontal Stack for drawer positioning
        <Stack
            direction="row"
            className={classNames("PcPage-root", {"PcPage-drawerPinnedOpen": drawerPinnedOpen})}
            {...(testId && {"data-testid": testId})}
            sx={[{width: 1, height: 1, overflow: "hidden", position: "relative"}, sx].flat()}
        >
            {/* Single-column grid for header and content. Grid instead of flex due to overflow issues. */}
            <Box
                component="main"
                ref={setScrollContainer}
                id="main"
                tabIndex={-1} //Focusable for purposes of the skip link
                sx={{
                    height: 1,
                    flex: 1,
                    display: "grid",
                    gridTemplateRows: "auto minmax(0, 1fr)",
                    gridTemplateColumns: "100%",
                    justifyItems: "center",
                    overflow: "auto"
                }}
            >
                <AppHeader
                    ref={appHeader}
                    className="PcPage-header"
                    sx={{
                        "& .PcAppHeader-toolbar": {
                            width: 1,
                            maxWidth: widthSetting,
                            m: "auto"
                        }
                    }}
                    title={renderTitle?.() || title}
                    {...{scrollContainer, titleSize, subtitle, actions}}
                    {...(renderHeaderContents && {children: renderHeaderContents()})}
                />
                <Stack
                    className="PcPage-content"
                    sx={[
                        {
                            width: 1,
                            maxWidth: widthSetting,
                            px: 3,

                            //Scroll-end padding trick
                            "&::after": {
                                content: "''",
                                display: "block",
                                height: (t: Theme) => t.spacing(3),
                                flexShrink: 0
                            },

                            //In this context, we don't want shrinking
                            "& > *": {
                                flexShrink: 0
                            }
                        },
                        heightSetting === "fixed" && {
                            display: "grid",
                            gridTemplateRows: "auto minmax(0, 1fr)",
                            gridTemplateColumns: "100%",
                            alignItems: "start"
                        }
                    ]}
                >
                    {children}
                </Stack>
            </Box>
            <Box
                className="PcPage-drawerContainer"
                sx={[
                    {flexShrink: 0},
                    drawerPinnedOpen ? {
                        width: drawerWidth,
                        ...transition(["width", "enteringScreen", "easeOut"])
                    } : {
                        width: 0,
                        ...transition(["width", "leavingScreen", "sharp"])
                    }
                ]}
            >
                {drawer &&
                    React.cloneElement<DrawerProps>(drawer, {
                        _onPinnedOpenChange: setDrawerPinnedOpen,
                        _onWidthChange: setDrawerWidth,
                        _pageHeaderRef: appHeader
                    })
                }
            </Box>
        </Stack>
    );
}
