import React, {ForwardedRef, forwardRef, useCallback, useEffect, useMemo, useState} from "react";

import {defaults, startCase} from "lodash";
import {flowResult} from "mobx";
import {observer} from "mobx-react-lite";
import {MarkRequired} from "ts-essentials";

import {VirtualAccountCreationDto} from "../api";
import {VirtualAccountStore} from "../store";
import {VirtualAccount} from "../virtual-account";
import {Api} from "~/api/api";
import {Form, FormProps} from "~/components/form";
import {LoadingTreatment} from "~/components/loading-treatment";
import {MenuItem} from "~/components/menu-item";
import {TextField} from "~/components/text-field";
import {inject} from "~/utils/di";
import {useLoadable} from "~/utils/loadable";
import {useLoadedReaction} from "~/utils/loadable/reactions";
import {useAbortController} from "~/utils/use-abort-controller";

export interface VirtualAccountCreationFormProps extends FormProps {
    readonly virtualAccountStore?: VirtualAccountStore;
    readonly api?: Api;
    readonly onSuccess?: () => void;
}

const supportedAccountRoles: VirtualAccount.AccountRole[] = ["investment", "source"];

const BASE_DEFAULTS = {
    name: "",
    backendId: ""
} as const;

const INVESTMENT_DEFAULTS = {
    wfInvestmentId: ""
} as const;

/** Form for creating a VirtualAccount */
export const VirtualAccountCreationForm = inject(
    {virtualAccountStore: VirtualAccountStore, api: Api},
    observer(forwardRef(function VirtualAccountCreationForm(
        {
            virtualAccountStore,
            api,
            onSuccess,
            onSubmit: onSubmitProp,
            submitDisabled,
            ...formProps
        }: MarkRequired<VirtualAccountCreationFormProps, "virtualAccountStore" | "api">,
        ref: ForwardedRef<HTMLFormElement>
    ) {
        const [dto, setDto] = useState<VirtualAccountCreationDto>(defaults(
            {type: "investment_virtual_account"} as const,
            BASE_DEFAULTS,
            INVESTMENT_DEFAULTS
        ));

        const [backends] = useLoadable(api.backends.getBackends, [undefined, useAbortController()], {eager: true});
        const accountCreationAbortController = useAbortController();

        //When the backends load, set the input to the first one so we have valid state when the form loads
        useLoadedReaction(backends, backends => setDto(dto => ({...dto, backendId: backends[0].id})), [setDto]);

        const onSubmit = useCallback(async (ev: React.FormEvent<HTMLFormElement>) => {
            onSubmitProp?.(ev);
            if (!ev.defaultPrevented) {
                ev.preventDefault();
                await flowResult(virtualAccountStore.createVirtualAccount(dto, accountCreationAbortController.signal));
                onSuccess?.();
            }
        }, [accountCreationAbortController, dto, onSubmitProp, virtualAccountStore, onSuccess]);

        useEffect(() => () => virtualAccountStore.resetVirtualAccountCreationStatus(), [virtualAccountStore]);

        const onRoleChanged = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
            const selectedRole = evt.target.value as VirtualAccount.AccountRole;
            switch (selectedRole) {
                case "investment":
                    setDto(dto =>
                        defaults(
                            {type: "investment_virtual_account"},
                            dto,
                            BASE_DEFAULTS,
                            INVESTMENT_DEFAULTS
                        )
                    );
                    break;
                case "source":
                    setDto(dto =>
                        defaults(
                            {type: "source_virtual_account"},
                            dto,
                            BASE_DEFAULTS
                        )
                    );
                    break;
            }
        }, [setDto]);

        const role = useMemo(() => {
            switch (dto.type) {
                case "investment_virtual_account": return "investment";
                case "source_virtual_account": return "source";
            }
        }, [dto.type]);

        const onBackendChanged = useCallback((evt: React.ChangeEvent<HTMLInputElement>) =>
            setDto(dto => ({...dto, backendId: evt.target.value})), [setDto]);
        const onNameChanged = useCallback((evt: React.ChangeEvent<HTMLInputElement>) =>
            setDto(dto => ({...dto, name: evt.target.value})), [setDto]);
        const onInvestmentIdChanged = useCallback((evt: React.ChangeEvent<HTMLInputElement>) =>
            setDto(dto => ({...dto, wfInvestmentId: evt.target.value})), [setDto]);

        return (
            <Form
                ref={ref}
                onSubmit={onSubmit}
                submitDisabled={submitDisabled || backends.isLoading()}
                submitStatus={virtualAccountStore.virtualAccountCreationStatus}
                {...formProps}
            >
                <LoadingTreatment loadable={backends}>{backends => <>
                    <TextField select label="Account Role" required value={role} onChange={onRoleChanged}>
                        {VirtualAccount.ACCOUNT_ROLES.map(role =>
                            <MenuItem key={role} disabled={!supportedAccountRoles.includes(role)} value={role}>{startCase(role)}</MenuItem>
                        )}
                    </TextField>
                    <TextField label="Name" required value={dto.name} onInput={onNameChanged}/>
                    <TextField select label="Backend" required value={dto.backendId} onChange={onBackendChanged}>
                        {backends.data.map(backend =>
                            <MenuItem key={backend.id} value={backend.id}>
                                {backend.name} ({startCase(backend.backendType)})
                            </MenuItem>
                        )}
                    </TextField>
                    {dto.type === "investment_virtual_account" &&
                        <TextField
                            label="Wefunder Investment ID"
                            required
                            value={dto.wfInvestmentId}
                            onInput={onInvestmentIdChanged}
                        />
                    }
                </>}</LoadingTreatment>
            </Form>
        );
    }))
);
