import {wfGet, wfPost} from "~/utils/wf-fetch";
import {constructDto} from "./util";
import {Disbursement} from "~/disbursement/disbursement";
import {InferredRecons} from "~/disbursement/inferred-recons";

const basePath = `${process.env.API_BASE}/disbursements`;
const staleTime = 3 * 60 * 1000; // 3 minutes

export interface DisbursementReconItem {
  wireId: number;
  lineItemId: number;
}
export interface DisbursementReconParams {
  disbursementTransferId: number;
  reconItems: DisbursementReconItem[];
}

const Disbursements = {
  get: (id: string | undefined) => ({
    queryKey: ["disbursements", id],
    queryFn: () => wfGet<Disbursement>(`${basePath}/${id}`, {}, constructDto(Disbursement)),
    keepPreviousData: true,
    staleTime: staleTime,
    enabled: !!id
  }),
  inferRecons: (id: string | undefined) => ({
    queryKey: ["disbursements", "infer_recons", id],
    queryFn: () => wfGet<InferredRecons>(`${basePath}/infer_recons/${id}`, {}, constructDto(InferredRecons)),
    keepPreviousData: true,
    staleTime: staleTime,
    enabled: !!id
  }),
  reconcile: (data: DisbursementReconParams) => ({
    mutationFn: () => wfPost<DisbursementReconParams, Disbursement>(data, `${basePath}/recon`, {constructor: constructDto(Disbursement)})
  })
};

export default Disbursements;
