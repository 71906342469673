import {MutableRefObject, Ref, useState} from "react";

import {assignRef} from "./assign-ref";

export interface ChainRef<T> extends MutableRefObject<T | null> {
    /** A Map from names to children this ref should update */
    readonly children: Map<string, Ref<T>>;

    /**
     * Add a child to this ChainRef.
     * Any time this ChainRef is assigned to, all children will be updated to the same value.
     * The name is needed to track changes to the ref object itself and avoid memory leaks.
     * When a child is initially added, it is immediately passed the current value of the ref
     * (if it has been assigned one).
     * Returns this for chaining and convenience in JSX callbacks.
     */
    readonly addChildRef: (name: string, childRef: Ref<T> | undefined) => ChainRef<T>;
}

/**
 * Creates a ChainRef, a ref that can forward its value to other refs.
 * This is useful when you need to both retain a ref to an element yourself
 * but also pass that same element to a parent component via a forwaded ref or other ref prop.
 *
 * @param initialValue - An initial value for the ref. This default value is **not** forwarded to children.
 * @param initialChildren - A mapping of initial children to add to the ref.
 */
export function useChainRef<T>(
    initialValue: T | null,
    initialChildren?: Iterable<[string, Ref<T> | undefined]>
): ChainRef<T> {
    const [chainRef] = useState(() => {
        let value = initialValue;
        let assigned = false;
        let updateChildrenAsync: Ref<T>[] | null = null;

        function updateChild(child: Ref<T>) {
            assignRef(child, value);
        }

        function updateChildAsync(child: Ref<T>) {
            if (!updateChildrenAsync) {
                updateChildrenAsync = [];
                void Promise.resolve().then(() => {
                    if (updateChildrenAsync) {
                        for (const child of updateChildrenAsync) {
                            updateChild(child);
                        }

                        updateChildrenAsync = null;
                    }
                });
            }

            updateChildrenAsync.push(child);
        }

        const chainRef: ChainRef<T> = {
            children: new Map(),

            get current() { return value; },
            set current(newValue) {
                value = newValue;
                assigned = true;
                //If we get assigned a new value, cancel assigning an old one
                updateChildrenAsync = null;
                for (const child of this.children.values()) {
                    updateChild(child);
                }
            },

            addChildRef(name, childRef) {
                if (childRef) {
                    if (childRef !== this.children.get(name)) {
                        this.children.set(name, childRef);

                        if (assigned) {
                            //If we're updating a new child with an existing value,
                            //we need to defer to a microtask so that the assignment happens after React nulls out
                            //any old usages of the same ref object.
                            updateChildAsync(childRef);
                        }
                    }
                }
                else {
                    this.children.delete(name);
                }

                return this;
            }
        };

        return chainRef;
    });

    if (initialChildren) {
        for (const [name, childRef] of initialChildren) {
            chainRef.addChildRef(name, childRef);
        }
    }

    return chainRef;
}
