import React, {useMemo} from "react";

import {Box} from "@mui/material";
import {isEqual, mapValues, pick, startCase} from "lodash";
import {observer} from "mobx-react-lite";
import {PickKeys} from "ts-essentials";

import {Entity} from "../entity";
import {Accordion, AccordionDetails, AccordionSummary} from "~/components/accordion";
import {Icon} from "~/components/icon";
import {JsonDisplay} from "~/components/json-display";
import {ButtonLink} from "~/components/link";
import {Sequence} from "~/utils/sequence";
import {useComputed} from "~/utils/use-computed";
import {useStabilizedValue} from "~/utils/use-stabilized-value";

//We need to apply MobX observer to this component as the entity might be an observable.
//However, observer automatically applies memo and does not allow passing a custom comparator...
//This presents a problem with the fact that `entities` expects an array literal.
//The best solution I could come up with is to introduce a wrapper component that applies useStabilizedValue
//before forwarding the props to the real component.

export interface EntityDisplayProps<T> {
    readonly model: T;
    readonly entities: readonly (PickKeys<T, Entity | null | undefined> & string)[];
}

export function EntityDisplay<T>({model, entities}: EntityDisplayProps<T>) {
    const stabilizedEntities = useStabilizedValue(entities, isEqual);
    return <_EntityDisplay {...{model}} entities={stabilizedEntities}/>;
}

const _EntityDisplay = observer(function _EntityDisplay<T>({model, entities}: EntityDisplayProps<T>) {
    const entityValues = useComputed(() => pick(model, entities), [model, entities]);

    const processedEntities = useMemo(() =>
        mapValues(entityValues, function processEntity(entity: any): any {
            if (!entity) return entity;

            //Flatten the data into the object
            const processedEntity = {...entity, ...entity.data};
            delete processedEntity.data;

            //Delete the type as it's generally redundant
            delete processedEntity.type;

            //Rename the IDs
            processedEntity.id = processedEntity.internalId;
            delete processedEntity.internalId;
            processedEntity.wefunderId = processedEntity.externalId;
            delete processedEntity.externalId;

            //Recurse into associated entities
            for (const assoc of ["investments", "fundraises", "companies", "investors"] as const) {
                if (processedEntity[assoc]) {
                    processedEntity[assoc] = (processedEntity[assoc] as Entity[]).map(processEntity);
                }
            }

            return processedEntity;
        }),
    [entityValues]);

    return (
        <div>
            {Sequence.from(entities).map(entityProp => {
                const entity = model[entityProp] as any as Entity | null | undefined;
                if (!entity) return null;

                return (
                    <Accordion
                        key={entityProp}
                        elevation={2}
                        TransitionProps={{unmountOnExit: true}}
                    >
                        <AccordionSummary>{startCase(entityProp)}</AccordionSummary>
                        <AccordionDetails>
                            <ButtonLink
                                variant="lightweight"
                                href={entity.wefunderUrl}
                                target="_blank"
                                endIcon={<Icon fa="angle-right"/>}
                            >
                                View on Wefunder
                            </ButtonLink>
                            <JsonDisplay json={processedEntities[entityProp]} hideNils/>
                        </AccordionDetails>
                    </Accordion>
                );
            }).filter(Boolean).ifEmpty(() => [
                <Box key="[empty]" fontStyle="italic">No Entities</Box>
            ])}
        </div>
    );
});
