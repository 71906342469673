import React from "react";

import {Paper, Stack} from "@mui/material";
import {startCase} from "lodash";
import {observer} from "mobx-react-lite";
import {MarkRequired} from "ts-essentials";

import {UserTaskStore} from "../store";
import {UserTask} from "../user-task";
import {Icon} from "~/components/icon";
import {Link} from "~/components/link";
import {LoadingTreatment} from "~/components/loading-treatment";
import {Typography} from "~/components/typography";
import {inject} from "~/utils/di";
import {InlineDefinition, InlineTerm} from "~/utils/styles";

const STATUS_ICONS = {
    pending: <Icon fa="circle-filled" sx={{color: "blue", verticalAlign: "middle"}}/>,
    resolved: <Icon fa="check-circle-bts" sx={{color: "green", verticalAlign: "middle"}}/>,
    closed: <Icon fa="times-circle" sx={{color: "gray", verticalAlign: "middle"}}/>
} as const;

//In order to facilitate use as either a Drawer or a Page,
//these components do not do any data fetching on their own.
//The root Drawer or Page component should do that.

/** Gets a short, simple textual description for a UserTask based on its task type */
export function getTaskDescription(task: UserTask): string {
    switch (task.taskType) {
        case "failed_transfer":
            return `Transfer ${task.transfer!.id} failed`;
        case "event_defect":
            if ("eventId" in task.taskData) {
                return `Defect event #${task.taskData.id}`;
            } else {
                return "Defect event";
            }
        case "unfinished_transfer":
            return "";
    }
}

export interface UserTaskTitleProps {
    readonly userTaskStore?: UserTaskStore;
    readonly taskId: string;
    readonly hyperlinkTaskId?: boolean;
}

/** Renders markup suitable for use with the renderTitle props of Page and Drawer */
export const UserTaskTitle = inject({userTaskStore: UserTaskStore}, observer(function UserTaskTitle({
    userTaskStore,
    taskId,
    hyperlinkTaskId = false
}: MarkRequired<UserTaskTitleProps, "userTaskStore">) {
    const task = userTaskStore.getTaskDetails(taskId);

    return (
        <Stack>
            <Typography variant="h3">
                {hyperlinkTaskId ? <Link to={`/user-tasks/${taskId}`}>#{taskId}</Link> : `#${taskId}`}
                {task.case({
                    hasValue: getTaskDescription,
                    else: () => ""
                })}
            </Typography>
            {task.case({
                hasValue: task => <p>{STATUS_ICONS[task.state]} {startCase(task.state)}</p>,
                else: () => null
            })}
        </Stack>
    );
}));

//TODO: UserTaskActions component for changing status

export interface UserTaskDisplayProps {
    readonly userTaskStore?: UserTaskStore;
    readonly taskId: string;
    readonly paperElevation?: number;
}

export const UserTaskDisplay = inject({userTaskStore: UserTaskStore}, observer(function UserTaskDisplay({
    userTaskStore,
    taskId,
    paperElevation = 1
}: MarkRequired<UserTaskDisplayProps, "userTaskStore">) {
    const task = userTaskStore.getTaskDetails(taskId);

    return (
        <LoadingTreatment loadable={task} description="task details" renderStaleValues>{task => <>
            {/* TODO: This should probably be a Form with a Textarea once it's editable */}
            <b>Notes:</b>
            <p>{task.note ?? <i>None</i>}</p>

            <Paper component="dl" elevation={paperElevation}>
                <div>
                    <InlineTerm>Created at</InlineTerm>
                    <InlineDefinition>{task.createdAt.toLocaleString()}</InlineDefinition>
                </div>
                <div>
                    <InlineTerm>Updated at</InlineTerm>
                    <InlineDefinition>{task.updatedAt.toLocaleString()}</InlineDefinition>
                </div>
            </Paper>
        </>}</LoadingTreatment>
    );
}));
