/** Utility type for operating on either Maps or WeakMaps */
export type AnyMap<K, V> =
    | Map<K, V>
    | (K extends object ? WeakMap<K, V> : never);

/**
 * Retrieves a value from a (Weak)Map, or computes and inserts it if it is not found
 *
 * @param map - The (Weak)Map to operate on
 * @param key - The key to retrieve a value for
 * @param computation - The computation to use to obtain a new value if none is already present
 *
 * @returns Either the existing value, or a newly computed value if there was none
 */
export function getOrCompute<K, V>(map: AnyMap<K, V>, key: K, computation: () => V): V {
    if (map.has(key)) return map.get(key)!;
    else {
        const computedValue = computation();
        map.set(key, computedValue);
        return computedValue;
    }
}
